import { RestHelper } from '../helpers/RestHelper';
             
export class ContractService {

  private _restHelper;

  constructor() {
    this._restHelper = new RestHelper();
  }

  async Save(payload : any)  {
    const url : string  = "Contract/Save";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async Edit(payload : any)  {
    const url : string  = "Contract/Edit";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  
  async GetByEmployeeID(payload : any)  {
    const url : string  = "Contract/GetByEmployeeID";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async New(payload : any)  {
    const url : string  = "Contract/New";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async Calculate(payload : any)  {
    const url : string  = "Contract/Calculate";
    let response = await this._restHelper.post(url, payload);
    return response;
  }
}
