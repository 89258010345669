import * as React from "react";
import {
  Checkbox,
  Link,
  TextField,
  TextFieldPropsSizeOverrides,
  Typography,
} from "@mui/material";
import { IValidationMessage } from "../helpers/common.interfaces";
import { OverridableStringUnion } from "@mui/types";
import CurrencyHelper from "../helpers/CurrencyHelper";

//export interface IValidationMessage {controlId: string, message: string}

export interface Props {
  Record: {};
  Id: string;
  Required?: boolean;
  Disabled?: boolean;
  PlaceHolder: string;
  Type?: string;
  Label?: string;
  AutoFocus?: boolean;
  HandleChange(e: React.ChangeEvent<HTMLInputElement>): void;
  HandleBlur(e: React.FocusEvent<HTMLInputElement>): void;
  HandleFocus(e: React.FocusEvent<HTMLInputElement>): void;
  ValidationMessages?: IValidationMessage[];
  MultiLine?: boolean;
  Rows?: number;
  TextAlign?: string;
  Size?: string;
  IsMoney?: boolean;
}

export default function MyCheckbox(args: Props) {
  const [checked, setChecked] = React.useState(false);
  let hasError = false;
  let message = "";
  let Record = args.Record;
  let controlId = args.Id;
  let disabled = args.Disabled;
  let placeHolder = args.PlaceHolder;
  let required = args.Required;

  //Vo zavisnot od Value i HasError se refreshira komponentata
  let value: boolean = false;
  let currentValue = Record[args.Id as keyof {}];
  if (currentValue) {
    value = currentValue;
  }

  
  //Proveri dali ima greska
  if (required === true) {
    let errorCollection = args.ValidationMessages;
    if (errorCollection != null && errorCollection.length > 0) {
      let currentOption = errorCollection.find((x : IValidationMessage) => x.controlId === controlId); //vidi vo validation.helper.js
      if (currentOption != null) {
        hasError = true;
        message = currentOption.message;
      }
    }
  }

  const handleChange = (event: any) => {
    setChecked(event.target.checked);
    let customerArgs: any = {
      target: { name: controlId, value: event.target.checked },
    };
    args.HandleChange(customerArgs);
  };

  //let value : boolean = true;

  return React.useMemo(() => {
    return (
      <Typography variant="subtitle2">
        <Checkbox
          size="small"
          checked={value}
          disabled={disabled}
          onChange={handleChange}
        />
        {placeHolder} 
              <Link
                  underline="none"
                  rel="noopener noreferrer"
                  target="_blank"
                  fontSize={12}
                  //sx={{ color: "#C5D5E0" }}
                  href="https://docs.google.com/document/d/1uZsqk7bBRF10ZOQwm9hjEe76dvF8QGPh/edit?rtpof=true&sd=true"
                >
                  {" "}
                  (Further Specification)
                </Link>
                
        {message !== "" &&  <div style={{"color":"#D32F2F"}}>{message}</div>}
      </Typography>
    );
  }, [controlId, value, disabled, message]);
}
