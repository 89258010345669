// @ts-check
import Typography from "@mui/material/Typography";

import { Breadcrumbs } from "@mui/material";
import { enumBreadcrumbPage, enumRoutes } from "../../helpers/common.constants";
import { useEffect, useState } from "react";
import Link from "@mui/material/Link";

export default function VacancyBreadcrumbs({ page, Id, disabled }) {
  const [showPage, setShowPage] = useState(false);

  //1 --> Go zapaluva pri sekoja promena na InputValue i go formatira vo EUR format
  useEffect(() => {
    if (!disabled) {
      setShowPage(true);
    }
  }, [disabled]); //default

  const RenderPage = () => {
    if (!showPage) {
      return <div />;
    }
    if (page === enumBreadcrumbPage.Search) {
      return <Search />;
    } else if (page === enumBreadcrumbPage.Overview) {
      return <Overview />;
    } else if (page === enumBreadcrumbPage.Master) {
      return <Master />;
    } else if (page === enumBreadcrumbPage.Notes) {
      return <Contracts />;
    }
    return <div />;
  };

  const Overview = () => {
    return (
      <Breadcrumbs color="primary" variant="body2" aria-label="breadcrumb">
        <Link
          underline="none"
          rel="noopener noreferrer"
          href={enumRoutes.Employee_Dashboard}
        >
          Home
        </Link>
        <Link
          underline="none"
          rel="noopener noreferrer"
          href={enumRoutes.Vacancy_Search}
        >
          Search
        </Link>
        <Typography variant="body2" color="text.primary">
          Overview
        </Typography>
      </Breadcrumbs>
    );
  };

  const Search = () => {
    return (
      <Breadcrumbs color="primary" variant="body2" aria-label="breadcrumb">
        <Link
          underline="none"
          rel="noopener noreferrer"
          href={enumRoutes.Vacancy_Search}
        >
          Home
        </Link>
        <Typography variant="body2" color="text.primary">
          Search
        </Typography>
      </Breadcrumbs>
    );
  };

  const Master = () => {
    return (
      <Breadcrumbs color="primary" variant="body2" aria-label="breadcrumb">
        <Link
          underline="none"
          rel="noopener noreferrer"
          href={enumRoutes.Vacancy_Search}
        >
          Home
        </Link>
        <Link
          underline="none"
          rel="noopener noreferrer"
          href={enumRoutes.Vacancy_Search}
        >
          Search
        </Link>
        <Typography variant="body2" color="text.primary">
          Vacancy
        </Typography>
      </Breadcrumbs>
    );
  };

  const Contracts = () => {
    return (
      <Breadcrumbs color="primary" variant="body2" aria-label="breadcrumb">
        <Link
          underline="none"
          rel="noopener noreferrer"
          href={enumRoutes.Employee_Dashboard}
        >
          Home
        </Link>
        <Link
          underline="none"
          rel="noopener noreferrer"
          href={enumRoutes.Employee_Search}
        >
          Search
        </Link>
        <Link
          underline="none"
          rel="noopener noreferrer"
          href={enumRoutes.Employee_Edit.replace(":EmployeeID", Id)}
        >
          Employee
        </Link>
        <Typography variant="body2" color="text.primary">
          Contracts
        </Typography>
      </Breadcrumbs>
    );
  };

  return <RenderPage />;
}
