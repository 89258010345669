import LocalStorageHelper from "../helpers/LocalStorageHelper";
import { RestHelper } from "../helpers/RestHelper";
import { enumLocalStorageArgs } from "../helpers/common.constants";

export class FinTimeService {
  private _restHelper;

  constructor() {
    this._restHelper = new RestHelper();
  }

  async Search(payload: any) {
    const url: string = "FinTime/Search";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  //da se prefrli vo Myemployee
  async MyTimeEntries(payload: any) {
    const url: string = "MyEmployee/MyTimeEntries";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  
  async CreateAndEdit(payload: any) {
    const url: string = "FinTime/CreateAndEdit";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async Edit(payload: any) {
    const url: string = "FinTime/Edit";
    let response = await this._restHelper.post(url, payload);
    return response;
  }


  async Save(payload: any) {
    const url: string = "FinTime/Save";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  
  async Reopen(payload: any) {
    const url: string = "FinTime/Reopen";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async Recreate(payload: any) {
    const url: string = "FinTime/Recreate";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async Export(payload: any) {
    const url: string = "FinTime/Export";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async Delete(payload: any) {
    const url: string = "FinTime/Delete";
    let response = await this._restHelper.post(url, payload);
    return response;
  }
 
}
