import { ValidatorHelper } from "../helpers/ValidatorHelper";
import { GeneralService } from "../services/GeneralService";

export default class GeneralActions {

  async GetRecord(SQLStatement: any) {
    //napravi validacija
    let response = await new GeneralService().GetRecord(SQLStatement);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async GetRecords(SQLStatement: any) {
    //napravi validacija
    let response = await new GeneralService().GetRecords(SQLStatement);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async GetList(SQLStatement: any) {
    //napravi validacija
    let response = await new GeneralService().GetList(SQLStatement);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }
}
