import { RestHelper } from '../helpers/RestHelper';
             
export class SystemLogService {

  private _restHelper;

  constructor() {
    this._restHelper = new RestHelper();
  }

  async Search(payload : any)  {
    const url : string  = "SystemLog/Search";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async SearchByRecord(payload : any)  {
    const url : string  = "SystemLog/SearchByRecord";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async Prepare(payload : any)  {
    const url : string  = "SystemLog/Prepare";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

}
