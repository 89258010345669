import { ValidatorHelper } from "../helpers/ValidatorHelper";
import { DocumentService } from "../services/DocumentService";

export default class DocumentActions {

async GetFolderContentAsync(args : any) {
  let response = await new DocumentService().GetFolderContentByProject(args);
  let responses = [response];
  return new ValidatorHelper().validateServerResponses(responses); //procitaj najgore kako raboti
}

async GetFolderContentByFolderIdAsync(rootFolderId : any, folderId : any) {
  let response = await new DocumentService().GetFolderContentByFolderId(rootFolderId, folderId);
  let responses = [response];
  return new ValidatorHelper().validateServerResponses(responses); //procitaj najgore kako raboti
}

async CreateFolderAsync(args : any) {
  let response = await new DocumentService().CreateFolder(args);
  let responses = [response];
  return new ValidatorHelper().validateServerResponses(responses); //procitaj najgore kako raboti
}

async RenameFolderAsync(args : any) {
  let response = await new DocumentService().RenameFolder(args);
  let responses = [response];
  return new ValidatorHelper().validateServerResponses(responses); //procitaj najgore kako raboti
}


async DeleteFolderAsync(args : any) {
  let response = await new DocumentService().DeleteFolder(args);
  let responses = [response];
  return new ValidatorHelper().validateServerResponses(responses); //procitaj najgore kako raboti
}


async DeleteFileAsync(args : any) {
  let response = await new DocumentService().DeleteFile(args);
  let responses = [response];
  return new ValidatorHelper().validateServerResponses(responses); //procitaj najgore kako raboti
}

async UploadFileAsync(args : any) {
  let response = await new DocumentService().UploadFile(args);
  let responses = [response];
  return new ValidatorHelper().validateServerResponses(responses); //procitaj najgore kako raboti
}

async DownloadFileAsync(args : any) {
  let response = await new DocumentService().DownloadFile(args);
  return response;
}
}