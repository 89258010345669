import * as React from 'react';
import Alert from '@mui/material/Alert';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Modal } from '@mui/material';

export interface Props {
  HandleModalClose(e: any): void,
  HandleModalCancel(e: any): void,
  Title?: string,
  Content?: string,
  IsOpen: boolean
};

export default function MyDialog(args: Props) {


  const [open, setOpen] = React.useState(args.IsOpen);


  const onModalClose = (e: any) => {
    args.HandleModalClose(e);
    setOpen(false);
  };


  //Dodavanje na Handle vo samiot content
  //zemi go CONTENT od CreateFolder ili pak od RenameFolder componentata 
  //Najdi gi "props" i dodaj use eden handler OnModalHasChanges so soodveten handler 
  //togas ispisi go {content} vo page-ot
  let content: any = args.Content;
  if (content) {
    //site paramteri koj ke bidat prateni od parent-ot primerno
    // let args = { FolderId: FolderId, IsNew: true };
    // let control = <ManageFolder {...args} />;
    //za da se procitaat vo samiot Child ke mora da se viknat 
    //kako args.props.IsNew ili args.props.FolderId
    let props: any = args.Content;


    props = { ...props, OnModalClose: onModalClose};
    content = { ...content, props };
  }


  //1 --> Go zapaluva pri sekoja promena na InputValue i go formatira vo EUR format 
  React.useEffect(() => {
    setOpen(open);
  }, [open]); //default

  React.useEffect(() => {
    setOpen(args.IsOpen);
  }, [args.IsOpen]); //default

  return (
    <Dialog open={open}>
      <DialogContent>
        <DialogContentText>
          {content}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}