import { IErrorsInput, ValidatorHelper } from "../helpers/ValidatorHelper";
import { AuthenticateService } from "../services/AuthenticateService";
import { UserService } from "../services/UserService";

export default class UserActions {
  

  async GetUserDetails() {
    let response = await new UserService().GetUserDetails();
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  
  async ChangePasswordAsync(Record : any) {

    let jsonInputData : IErrorsInput[]  = [
      
      {
        element: "Password",
        type: "required",
        value: Record.Password,
        message: 'Password is required'
      }
    ]
    
    //validacija
    let validationMessages = new ValidatorHelper().validateClient(jsonInputData);
    if (validationMessages.length > 0) {
      let response = {
        error: true, //oznaci deka ima greska
        error_description: "Unable to save",
        ValidationMessages: validationMessages //vrati gi greskite za GUI
      };
      return response;
    }

    
    //napravi validacija
    let response = await new UserService().ChangePassword(Record);
    let responses  = [response]
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

}
