import { RestHelper } from '../helpers/RestHelper';

export class StaffService {

  private _restHelper;

  constructor() {
    this._restHelper = new RestHelper();
  }

  async GetAll(payload = {}) {
    const url: string = "Staff/GetAll";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

}
