import { IErrorsInput, ValidatorHelper } from "../helpers/ValidatorHelper";
import { LookupDataService } from "../services/LookupDataService";
import { CountryService } from "../services/CountryService";
import { EmployeeService } from "../services/EmployeeService";
import { StaffService } from "../services/StaffService";
import { enumSharedMsgs } from "../helpers/common.constants";
import { VacancyService } from "../services/VacancyService";

export default class VacancyActions {
  async InitAsync() {
    let responseLookup = await new LookupDataService().GetAll();
    let responseCountry = await new CountryService().GetAll();
    
    //let responseSuperiors = await new LookupDataService().GetSuperiors();
    let responseYesOrNo = await new LookupDataService().GetYesNo();
    let responseOpenClose = await new LookupDataService().GetOpenClose();
    let contractMonthData = await new LookupDataService().GetContractMonths();
    let vacancyData = await new LookupDataService().GetVacancies();

    let responses = [
      responseLookup,
      responseCountry,
      //responseSuperiors,
      responseYesOrNo,
      responseOpenClose,
      contractMonthData,
      vacancyData,
    ];

    //napravi validacija
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async SaveAsync(Record: any) {
    let jsonInputData: IErrorsInput[] = [
      {
        element: "vaName",
        type: "required",
        value: Record.vaName,
        message: "Vacancy Name is required",
      },
      {
        element: "vaCountryID",
        type: "SelectEmpty",
        value: Record.vaCountryIDs,
        message: "Country is required (at least one)",
      },
    ];

    if (!Record.SkipValidation) {
      //validacija
      let validationMessages = new ValidatorHelper().validateClient(
        jsonInputData
      );
      if (validationMessages.length > 0) {
        let response = {
          error: true, //oznaci deka ima greska
          error_description: enumSharedMsgs.SaveClientError,
          ValidationMessages: validationMessages, //vrati gi greskite za GUI
        };
        return response;
      }
    }

    //napravi validacija
    let response = await new VacancyService().Save(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async SaveApplicantAsync(Record: any) {
    let jsonInputData: IErrorsInput[] = [
      {
        element: "vapVacancyID",
        type: "SelectEmpty",
        value: Record.vapVacancyID,
        message: "Vacancy Name is required",
      },
      {
        element: "vapFirstName",
        type: "required",
        value: Record.vapFirstName,
        message: "First name is required",
      },
      {
        element: "vapLastName",
        type: "required",
        value: Record.vapLastName,
        message: "Last name is required",
      },
      {
        element: "vapEmail",
        type: "required",
        value: Record.vapEmail,
        message: "Email is required",
      },
      {
        element: "vapReside",
        type: "required",
        value: Record.vapReside,
        message: "Reside country is required",
      },
      {
        element: "vapLanguages",
        type: "SelectEmpty",
        value: Record.vapLanguages,
        message: "Spoken languages is required",
      },
    ];

    if (!Record.SkipValidation) {
      //validacija
      let validationMessages = new ValidatorHelper().validateClient(
        jsonInputData
      );
      if (validationMessages.length > 0) {
        let response = {
          error: true, //oznaci deka ima greska
          error_description: enumSharedMsgs.SaveClientError,
          ValidationMessages: validationMessages, //vrati gi greskite za GUI
        };
        return response;
      }
    }

    //napravi validacija
    let response = await new VacancyService().SaveApplicant(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async SearchAsync(Record: any) {
    console.log(Record);
    //napravi validacija
    let response = await new VacancyService().Search(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async SearchApplicantAsync(Record: any) {
    console.log(Record);
    //napravi validacija
    let response = await new VacancyService().SearchApplicant(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async ExportAsync(Record: any) {
    //napravi validacija
    let response = await new EmployeeService().Export(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async ExportEmployeeLeaveRequestAllYearsAsync(Record: any) {
    //napravi validacija
    let response =
      await new EmployeeService().ExportEmployeeLeaveRequestAllYears(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async EditAsync(Record: any) {
    //napravi validacija
    let response = await new VacancyService().Edit(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async EditApplicantAsync(Record: any) {
    //napravi validacija
    let response = await new VacancyService().EditApplicant(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async GeneralSearchAsync(Record: any) {
    //napravi validacija
    let response = await new EmployeeService().GeneralSearch(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async GeneralFindTextByIdAsync(Record: any) {
    //napravi validacija
    let response = await new EmployeeService().GeneralFindTextById(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async UploadAsync(Record: any) {
    //napravi validacija
    let response = await new VacancyService().UploadCost(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async DownloadCostAsync(Record: any) {
    //napravi validacija
    let response = await new VacancyService().DownloadCost(Record);
    return response;
  }
}
