import { ValidatorHelper } from "../helpers/ValidatorHelper";
import { LookupDataService } from "../services/LookupDataService";
import { FinTimeService } from "../services/FinTimeService";
import { FinProjectService } from "../services/FinProjectService";

export default class FinTimeActions {

  
  async CreateAndEditAsync(Record: any) {
    let response = await new FinTimeService().CreateAndEdit(Record);
    
    let responses = [response];

    //napravi validacija
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async EditAsync(Record: any) {
    let response = await new FinTimeService().Edit(Record);
    
    let responses = [response];

    //napravi validacija
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async SearchAsync(Record: any) {
    //napravi validacija
    let response = await new FinTimeService().Search(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async RecreateAsync(Record: any) {
    //napravi validacija
    let response = await new FinTimeService().Recreate(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async ReopenAsync(Record: any) {
    //napravi validacija
    let response = await new FinTimeService().Reopen(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  
  async MyTimeEntriesAsync(Record: any) {
    //napravi validacija
    let response = await new FinTimeService().MyTimeEntries(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async SaveAsync(Record: any) {
    //napravi validacija
    
    let response = await new FinTimeService().Save(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async ExportAsync(Record: any) {
    //napravi validacija
    let response = await new FinTimeService().Export(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  
  async DeleteAsync(Record: any) {
    //napravi validacija
    let response = await new FinTimeService().Delete(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }


}
