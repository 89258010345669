import { IErrorsInput, ValidatorHelper } from "../helpers/ValidatorHelper";
import { StaffService } from "../services/StaffService";
import { SystemUserService } from "../services/SystemUserService";
import { LogTypeService } from "../services/LogTypeService";
import { SystemLogService } from "../services/SystemLogService";
import { LookupDataService } from "../services/LookupDataService";
import { NotificationService } from "../services/NotificationService";
import { EmailService } from "../services/EmailService";


export default class EmailActions {

  async SendNotificationsAsync(Record : any) {
    let response  = await new EmailService().SendNotifications(Record);
    let responses = [response]
    //napravi validacija
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

}