import { RestHelper } from '../helpers/RestHelper';

export class ActionPointsService {

  private _restHelper;

  constructor() {
    this._restHelper = new RestHelper();
  }

  async Search(payload = {}) {
    const url: string = "ActionPoints/Search";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async GetTotalActionPoints(payload = {}) {
    const url: string = "ActionPoints/GetTotalActionPoints";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  
}
