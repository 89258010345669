// import * as React from 'react';
// import Alert from '@mui/material/Alert';

// export default function MyAlert(props: any) {

//   //let open = true;
//   const { Loading, Message, HasError, HasInfo } = props;

//   //inicijalno alerrt message controlata e hidden
//   const [open, setOpen] = React.useState(false);

//   //ovoj event se pali pri sekoja promena na message, vnatre dokolku e poln ke se otvori ALERT-ot vo sprotivno ke se izgasi
//   React.useEffect(() => {
//     if (Message) { //ako imam greska pokazi allert
//       if (Message === "") {
//         setOpen(false);
//       }
//       else {
//         setOpen(true);
//       }
//     }
//     else {
//       setOpen(false);  //ako nema greska na sleden load izgasi ALERT
//     }
//     return () => { setOpen(false); }; //pri odenje nadvor od strana (na drug page) obezbedi da se izgasi alert-ot
//   }, [Message]);   //default

//   const ShowMessage = () => {
//     return (
//       open && Message && <Alert style={{ marginBottom: 10 }} severity={HasError ? "error" : HasInfo ? "info" : "success"}>{parseMessage(Message)}</Alert>
//     )
//   }

//   if (Loading && !open) { //ako e loading i ako ne treba da pokaze message
//     return "";
//   }
//   return ShowMessage();
// }

// function parseMessage(msg: string) {

//   if (!msg) return "";
//   let arrMsgs = msg.split('\n');
//   if (arrMsgs.length === 0) {
//     return "";
//   }
//   //ako ima \n vo string-ot togas iterriraj
//   //dokolku e poslen item od array togas ne dodavaj break
//   //https://www.freecodecamp.org/forum/t/newline-in-react-string-solved/68484/6
//   const output = arrMsgs.map((item, i) => {
//     if (i === arrMsgs.length - 1) {
//       return <div key={i}>{item}</div>;
//     }
//     else {
//       return <div key={i}>{item}<br /></div>;
//     }
//   });
//   return output;
// }
import * as React from "react";
import Alert from "@mui/material/Alert";
import { Button, IconButton, Snackbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function MyAlert(props: any) {
  //let open = true;
  const { Loading, Message, HasError, HasInfo, Timing } = props;

  //inicijalno alerrt message controlata e hidden
  const [open, setOpen] = React.useState(false);

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    // if (reason === "clickaway") {
    //   return;
    // }

    setOpen(false);
  };

  //ovoj event se pali pri sekoja promena na message, vnatre dokolku e poln ke se otvori ALERT-ot vo sprotivno ke se izgasi
  React.useEffect(() => {
    if (Message) {
      //ako imam greska pokazi allert
      if (Message === "") {
        setOpen(false);
      } else {
        setOpen(true);
      }
    } else {
      setOpen(false); //ako nema greska na sleden load izgasi ALERT
    }
    return () => {
      setOpen(false);
    }; //pri odenje nadvor od strana (na drug page) obezbedi da se izgasi alert-ot
  }, [Message]); //default

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const ShowMessage = () => {
    return (
      open &&
      Message && (
        <>
          <Snackbar
            open={open}
            autoHideDuration={!Timing ?  6000 : Timing} //default na 6 sec vo sprotivno ako e definiran
            onClose={handleClose}
            message={parseMessage(Message)}
            action={action}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <Alert
              //style={{ marginBottom: 10 }}
              onClose={handleClose}
              severity={HasError ? "error" : HasInfo ? "info" : "success"}
            >
              {parseMessage(Message)}
            </Alert>
          </Snackbar>
          {/* <Alert
            style={{ marginBottom: 10 }}
            severity={HasError ? "error" : HasInfo ? "info" : "success"}
          >
            {parseMessage(Message)}
          </Alert> */}
        </>
      )
    );
  };

  if (Loading && !open) {
    //ako e loading i ako ne treba da pokaze message
    return "";
  }
  return ShowMessage();
}

function parseMessage(msg: string) {
  if (!msg) return "";
  let arrMsgs = msg.split("\n");
  if (arrMsgs.length === 0) {
    return "";
  }
  //ako ima \n vo string-ot togas iterriraj
  //dokolku e poslen item od array togas ne dodavaj break
  //https://www.freecodecamp.org/forum/t/newline-in-react-string-solved/68484/6
  const output = arrMsgs.map((item, i) => {
    if (i === arrMsgs.length - 1) {
      return <div key={i}>{item}</div>;
    } else {
      return (
        <div key={i}>
          {item}
          <br />
        </div>
      );
    }
  });
  return output;
}
