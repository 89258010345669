// @ts-check
import { useEffect } from "react";
import * as React from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import MyText from "../../controls/MyText";
import { useState } from "react";
import MyAlert from "../../controls/MyAlert";
import {
  enumBreadcrumbPage,
  enumLookupTypes,
  enumPage,
  enumPagination,
  enumRoutes,
  enumSharedMsgs,
} from "../../helpers/common.constants";
import SystemUserActions from "../../actions/SystemUserActions";
import Button from "@mui/material/Button";
import MyCheckList from "../../controls/MyCheckList";
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import CurrencyHelper from "../../helpers/CurrencyHelper";
import LogHelper from "../../helpers/LogHelper";
import { useLocation, useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import EditIcon from "@mui/icons-material/Edit";
import SystemUserBreadcrumbs from "./SystemUserBreadcrumbs";

const RecordDefault = {
  PageSize: enumPagination.PageSize,
  DonorIDs: "",
  CountryIDs: "",
  RegionIDs: "",
  PhaseIDs: "",
  Years: new Date().getFullYear().toString(),
};

export default function SystemUserSearch() {
  const [Record, setRecord] = useState(RecordDefault);
  const [RolesData, setRolesData] = useState([]);
  const [MessageData, setMessageData] = useState({});
  const [Records, setRecords] = useState([]);
  const [IsInitDone, setIsInitDone] = useState(false);
  const navigate = useNavigate();

  //Za Refreshiranje na strana mu treba;
  const location = useLocation();

  //za Responsive
  const largeScreen = useMediaQuery("(min-width:600px)");

  //****************************************** */
  //HOOKS SECTION HERE
  //****************************************** */

  //1 --> Ke se povika ovoj HOOK samo dokolku se SMENI url-ot poradi toa na new dodavame Ticks() za da forcirame refresh na strana na sekoj click
  useEffect(() => {
    initData();
  }, [location.pathname + "/" + location.search]);

  //2 --> Od koga e sredena InitData() povikaj Search  (https://stackoverflow.com/questions/54069253/the-usestate-set-method-is-not-reflecting-a-change-immediately)
  useEffect(() => {
    if (IsInitDone) {
      searchData();
    }
  }, [IsInitDone]);

  //****************************************** */
  //METHOD  SECTION HERE
  //****************************************** */
  const setPageMsg = (state, response, customSuccessMsg) => {
    if (state == enumPage.LoadStart) {
      setMessageData((a) => ({
        ...a,
        Message: "Please wait...",
        Loading: true,
        HasInfo: true,
      }));
    } else if (state == enumPage.LoadSuccess) {
      setMessageData((a) => ({ ...a, Loading: false, Message: "" }));
    } else if (state == enumPage.LoadStartWithMsg) {
      setMessageData((a) => ({
        ...a,
        Loading: true,
        Message: customSuccessMsg,
        HasInfo: true,
      }));
    } else if (state == enumPage.LoadSuccessWithMsg) {
      setMessageData((a) => ({
        ...a,
        Loading: false,
        Message: customSuccessMsg,
      }));
    } else if (state == enumPage.ErrorServer) {
      if (response.ValidationMessages) {
        setRecord((prevState) => ({
          ...prevState,
          ValidationMessages: response.ValidationMessages,
        })); //za da se reflekgiraat po ZSNText,
      }
      setMessageData((a) => ({
        ...a,
        HasError: true,
        Message: response.error_description,
        Loading: false,
      }));
    } else if (state == enumPage.SaveSuccess) {
      setMessageData((a) => ({
        ...a,
        Loading: false,
        HasError: false,
        Message: enumSharedMsgs.Save,
      }));
    } else if (state == enumPage.ActionSuccessWithMsg) {
      setMessageData((a) => ({
        ...a,
        Loading: false,
        HasError: false,
        Message: customSuccessMsg,
      }));
    }
  };

  const initData = () => {
    async function fetchData() {
      //NAJVAZNO da napravi prvo reset na s'e
      setIsInitDone(false);
      setRecord(RecordDefault);
      setPageMsg(enumPage.LoadStart);

      let responses = await new SystemUserActions().InitAsync(); // await LookupActions.InitAsync();
      if (!responses.error) {
        let rolesData = responses[0].data;
        setRolesData(rolesData);

        //so ova ke povika search preku useEffect
        setIsInitDone(true);
        setPageMsg(enumPage.LoadSuccess);
      } else {
        setPageMsg(enumPage.ErrorServer, responses);
      }
    }
    fetchData();
  };

  const searchData = () => {
    async function fetchData() {
      setPageMsg(enumPage.LoadStart);

      let responses = await new SystemUserActions().SearchAsync(Record);
      if (!responses.error) {
        const records = responses[0].data;
        setRecords(records); //za da se reflekgiraat po ZSNText,
        setPageMsg(enumPage.LoadSuccess);
      } else {
        setPageMsg(enumPage.ErrorServer, responses);
      }
    }

    fetchData();
  };

  //****************************************** */
  //HANDLERS SECTION HERE
  //****************************************** */
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name + ":" + value);
    setRecord((prevState) => ({ ...prevState, [name]: value }));
  };

  //****************************************** */
  //GUI DISPLAY
  //****************************************** */
  const ToolbarButtons = () => {
    return (
      <React.Fragment>
        <Button
          sx={{ ml: 3, mr: 2, mt: 3.5 }}
          variant="contained"
          color="success"
          size="small"
          disabled={MessageData.Loading}
          onClick={() => searchData()}
        >
          Search
        </Button>
      </React.Fragment>
    );
  };

  return (
    <Typography sx={{ my: 5, mx: 2 }} color="text.secondary">
      <SystemUserBreadcrumbs
        page={enumBreadcrumbPage.Search}
        disabled={MessageData.Loading}
      />

      <MyAlert {...MessageData} />

      <Card sx={{ minWidth: 275, borderRadius: 2, mb: 2 }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={3}>
              <MyText
                Id="FullName"
                PlaceHolder="Search by Name"
                Record={Record}
                HandleChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <MyText
                Id="UserName"
                PlaceHolder="Search by Email"
                Record={Record}
                HandleChange={handleChange}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <MyCheckList
                Id="Roles"
                Record={Record}
                PlaceHolder="Roles"
                HandleChange={handleChange}
                Options={RolesData}
              />
            </Grid>
            <Grid>
              <ToolbarButtons />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" align="left">
                Total Records found: {Records.length}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>


      
      <RenderCards Records={Records} />
      <RenderTable Records={Records} />
    </Typography>
  );
}

const RenderTable = ({ Records }) => {
  const navigate = useNavigate();

  return React.useMemo(() => {
    return (
      <TableContainer sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>
              {" "}
              <Typography variant="h4" align="center">
                Full Name
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h4" align="center">
                Email
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h4" align="center">
                Enabled
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h4" align="center">
                Role
              </Typography>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Records.map((row, index) => (
            <TableRow
              key={row.Id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>
                {index + 1}.
                <Link
                  href={enumRoutes.SystemUser_Overview.replace(
                    ":UserId",
                    row.Id
                  )}
                  underline="none"
                  rel="noopener noreferrer"
                >
                  {row.FullName}
                </Link>
              </TableCell>
              <TableCell>{row.UserName}</TableCell>
              <TableCell align="center">{row.EnabledYesNo}</TableCell>
              <TableCell align="center">{row.RoleName}</TableCell>
              <TableCell align="center">
                <Tooltip title={"Edit " + row.FullName}>
                  <IconButton
                    size="small"
                    name="details"
                    onClick={() =>
                      navigate(
                        enumRoutes.SystemUser_Edit.replace(":UserId", row.Id)
                      )
                    }
                  >
                    <EditIcon sx={{ fontSize: 15 }} />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>

    );
  }, [Records]);
};

const RenderCards = ({ Records }) => {
  const navigate = useNavigate();

  return React.useMemo(() => {
    return (
      <Typography
        variant="body2"
        align="left"
        sx={{ display: { xs: "block", sm: "block", md: "none" } }}
      >
        {Records.map((row, index) => (
          <Card sx={{ minWidth: 275, borderRadius: 2, mb: 2 }}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography variant="h5">Name:</Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="h6" sx={{ pl: 2.5 }}>
                    {row.FullName}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography variant="h5">Email:</Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="h6" sx={{ pl: 2.5 }}>
                    {row.Email}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <Typography variant="h5">Enabled:</Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="h6" sx={{ pl: 2.5 }}>
                    {row.EnabledYesNo}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
            <CardActions>
              <Tooltip title="Edit">
                <IconButton
                  size="small"
                  name="details"
                  onClick={() =>
                    navigate(
                      enumRoutes.SystemUser_Edit.replace(":UserId", row.Id)
                    )
                  }
                >
                  <EditIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </CardActions>
          </Card>
        ))}
      </Typography>

    );
  }, [Records]);
};
