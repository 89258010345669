// @ts-check
import * as React from "react";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import MyAlert from "../../controls/MyAlert";
import {
  enumData, enumPage,
  enumPagination,
  enumRoutes
} from "../../helpers/common.constants";
import Button from "@mui/material/Button";
import {
  Card, CardContent,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow, Tooltip
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import MyDropDown from "../../controls/MyDropDown";
import { MessageHelper } from "../../helpers/MessageHelper";
import FinTimeActions from "../../actions/FinTimeActions";
import moment from "moment";

const RecordDefault = {
  PageSize: enumPagination.PageSize,
  StatusID: "2", //Submitted
  SuperiorID: 0,
  EmployeeID: 0,
  OfficeID: 0,
  ftsYear: 2024,
};

export default function MyTimeEntries() {
  const [Record, setRecord] = useState(RecordDefault);
  const [MessageData, setMessageData] = useState({});
  const [Records, setRecords] = useState([]);
  const [IsInitDone, setIsInitDone] = useState(false);

  
  //Za Refreshiranje na strana mu treba;
  const location = useLocation();

  //za poraki pri loadiranje na strana
  const msgHelper = new MessageHelper();

  //****************************************** */
  //HOOKS SECTION HERE
  //****************************************** */

  //1 --> Ke se povika ovoj HOOK samo dokolku se SMENI url-ot poradi toa na new dodavame Ticks() za da forcirame refresh na strana na sekoj click
  useEffect(() => {
    initData();
  }, [location.pathname + "/" + location.search]);

  //2 --> Od koga e sredena InitData() povikaj Search  (https://stackoverflow.com/questions/54069253/the-usestate-set-method-is-not-reflecting-a-change-immediately)
  useEffect(() => {
    if (IsInitDone) {
      searchData();
    }
  }, [IsInitDone]);

  //****************************************** */
  //METHOD  SECTION HERE
  //****************************************** */

  const initData = () => {
    async function fetchData() {
      //Clean all states
      setIsInitDone(false);
      setRecord(RecordDefault);

      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      setIsInitDone(true);

      //Load Finished
      result = msgHelper.setPhase(enumPage.LoadSuccess);
      setMessageData(result);

    }
    fetchData();
  };

  const searchData = () => {
    async function fetchData() {
      //Load Start
      setRecords([]);
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      let responses = await new FinTimeActions().MyTimeEntriesAsync(Record);
      if (!responses.error) {
        const records = responses[0].data;

        setRecords(records);

        //Load Finished
        result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);
      } else {
        //Load with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }
    }
    fetchData();
  };

 
  
  //****************************************** */
  //HANDLERS SECTION HERE
  //****************************************** */
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name + ":" + value);
    setRecord((prevState) => ({ ...prevState, [name]: value }));
  };

  //****************************************** */
  //GUI DISPLAY
  //****************************************** */
  const ToolbarButtons = () => {
    return (
      <React.Fragment>
        <Button
          sx={{ ml: 3, mr: 2, mt: 3.5 }}
          variant="contained"
          color="success"
          size="small"
          disabled={MessageData.Loading}
          onClick={() => searchData()}
        >
          Search
        </Button>
        
      </React.Fragment>
    );
  };

  return (
    <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
      <MyAlert {...MessageData} />

      <Card sx={{ minWidth: 275, borderRadius: 2, mb: 2 }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={2}>
              <MyDropDown
                Id="ftsYear"
                Record={Record}
                PlaceHolder="Year"
                HandleChange={handleChange}
                Options={enumData.YEARS}
              />
            </Grid> 
            <Grid>
              <ToolbarButtons />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="subtitle1" align="left">
                Total Records found: {Records.length}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>


      <RenderTable Records={Records} />
    </Typography>
  );
}

const RenderTable = ({ Records }) => {
  const navigate = useNavigate();

  return React.useMemo(() => {
    return (
      <TableContainer sx={{ display: { xs: "block", sm: "block", md: "block" } }}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="h4" align="center">
                  Employee
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h4" align="center">
                  Month/Year
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h4" align="center">
                  Status
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h4" align="center">
                  Modified
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h4" align="center">
                  Modified By
                </Typography>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Records.map((row, index) => (
              <TableRow
                key={row.rqRequestID}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  {index + 1}. {row.ftsStaffName}
                </TableCell>

                <TableCell>
                  {row.ftsMonth} / {row.ftsYear} 
                </TableCell>

                <TableCell>{row.ftsSubmittedFL ? "Submitted" : "Open"}</TableCell>

                <TableCell align="right">
                  {moment(row.ftsDateUpdates).format("DD/MM/YYYY")}
                </TableCell>

                <TableCell>
                  {row.ftsUpdatedBy}
                </TableCell>

                <TableCell align="center">
                  <Tooltip title={"Preview of the Timesheet for " + row.ftsMonth + "/" + row.ftsYear}>
                    <IconButton
                      size="small"
                      name="details"
                      onClick={() => 
                          navigate(
                                  !row.ftsSubmittedFL ?
                                  enumRoutes.Timesheet_FinTime_Edit.replace(":SummaryID", row.ftsTimeSummaryID)
                                  :
                                  enumRoutes.Timesheet_FinTime_Preview.replace(":SummaryID", row.ftsTimeSummaryID).replace(":showMsg", '0') 
                                  )
                                }
                    >
                      <EditIcon sx={{ fontSize: 15 }} />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }, [Records]);
};
