// @ts-check
import * as React from "react";
import Typography from "@mui/material/Typography";
import MyText from "../../controls/MyText";
import { useEffect, useState } from "react";
import MyAlert from "../../controls/MyAlert";
import {
  enumBreadcrumbPage,
  enumConfig,
  enumData,
  enumLocalStorageArgs,
  enumLookupTypes,
  enumPage,
  enumPagination,
  enumRoutes,
  enumSQLS,
  enumSharedMsgs,
} from "../../helpers/common.constants";
import EmployeeActions from "../../actions/EmployeeActions";
import Button from "@mui/material/Button";
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import LogHelper from "../../helpers/LogHelper";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import EmployeeBreadcrumbs from "./LeaveRequestBreadcrumbs";
import MyDropDown from "../../controls/MyDropDown";
import Link from "@mui/material/Link";
import { MessageHelper } from "../../helpers/MessageHelper";
import LeaveRequestBreadcrumbs from "./LeaveRequestBreadcrumbs";
import LeaveRequestActions from "../../actions/LeaveRequestActions";
import AuthenticateHelper from "../../helpers/AuthenticateHelper";
import moment from "moment";
import LocalStorageHelper from "../../helpers/LocalStorageHelper";
import MyDialogYesNo from "../../controls/MyDialogYesNo";
import DownloadActions from "../../actions/DownloadActions";
import MyAutoSuggest from "../../controls/MyAutoSuggest";

const RecordDefault = {
  StatusID: 0,
  SuperiorID: 0,
  EmployeeID: 0,
  OfficeID: 0,
  Year: 2024,
  TypeID: 0,

  //Pagination
  PageIndex: 0,
  PageSize: enumPagination.PageSizeLow,
  TotalRecords: 0,
  TotalPages: 0
};



export default function LeaveRequestSearch() {
  const [Record, setRecord] = useState(RecordDefault);
  const [RecordDelete, setRecordDelete] = useState(RecordDefault);
  const [MessageData, setMessageData] = useState({});
  const [Records, setRecords] = useState([]);
  const [IsInitDone, setIsInitDone] = useState(false);
  const [IsPaginationChange, setPaginationChange] = useState(false);
  const [IsDoSearch, setIsDoSearch] = useState(false);

  const [IsModalOpen, setIsModalOpen] = useState(false);
  const [EmployeesData, setEmployeesData] = useState([]);
  const [YearsData, setYearsData] = useState([]);
  //const [ApprovedSubmittedAllData, setApprovedSubmittedAllData] = useState([]);

  const [LookupData, setLookupData] = useState([]);

  //Za Refreshiranje na strana mu treba;
  const location = useLocation();

  //za poraki pri loadiranje na strana
  const msgHelper = new MessageHelper();

  //****************************************** */
  //HOOKS SECTION HERE
  //****************************************** */

  //1 --> Ke se povika ovoj HOOK samo dokolku se SMENI url-ot poradi toa na new dodavame Ticks() za da forcirame refresh na strana na sekoj click
  useEffect(() => { initData(); }, [location.pathname + "/" + location.search]);

  //2 --> Od koga e sredena InitData() povikaj Search  (https://stackoverflow.com/questions/54069253/the-usestate-set-method-is-not-reflecting-a-change-immediately)
  useEffect(() => { if (IsInitDone) { searchData(); } }, [IsInitDone]);

  // //3 --> Pagination Change 
  useEffect(() => { if (IsPaginationChange) { searchData(); } }, [IsPaginationChange]);

  //4 --> Search Click
  useEffect(() => { if (IsDoSearch) { searchData(); } }, [IsDoSearch]);


  //****************************************** */
  //METHOD  SECTION HERE
  //****************************************** */

  const initData = () => {
    async function fetchData() {
      //Clean all states
      setIsInitDone(false);
      setRecord(RecordDefault);

      //Proveri dali slucajno ima vo Local Storage definirani Search Arguments
      let RecordFromStorage = new LocalStorageHelper().GetJSON(enumLocalStorageArgs.LeaveRequestSearch);
      if (RecordFromStorage) {
        RecordFromStorage.PageIndex = 0; //sekogas prviot page pokazi go 
        setRecord(RecordFromStorage);
      }


      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      let responses = await new LeaveRequestActions().InitSearchAsync(); // await LookupActions.InitAsync();
      if (!responses.error) {
        let lookupData = responses[0].data;
        let countryData = responses[1].data;
        let employeesData = responses[2].data;
        let yearsData = responses[3].data;
        //let approvedSubmittedAllData = responses[4].data;

        setLookupData(lookupData);
        //setCountryData(countryData);
        setEmployeesData(employeesData);
        setYearsData(yearsData);
        //setApprovedSubmittedAllData(approvedSubmittedAllData);

        setIsInitDone(true);

        //Load Finished
        result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);
      } else {
        //Load with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }
    }
    fetchData();
  };

  const searchData = () => {
    async function fetchData() {
      //Load Start
      setRecords([]);
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      let responses = await new LeaveRequestActions().SearchAsync(Record);
      if (!responses.error) {
        const records = responses[0].data;


        //For Pagination purposes
        const pageIndex = responses[0].headers["content-pageindex"];
        const totalRecords = responses[0].headers["content-totalrecords"];
        const totalPages = responses[0].headers["content-totalpages"];
        setRecord((a) => ({ ...a, ["PageIndex"]: pageIndex, ["TotalRecords"]: totalRecords, ["TotalPages"]: totalPages }));
        setPaginationChange(false); //za da se pali na sleden page
        setIsDoSearch(false);       //za da se pali na Search Click

        //osvezi data
        setRecords(records);

        //Load Finished
        result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);

        //setiraj JSON vo Local Storage za da citas posle od tamu na sleden comeback 
        new LocalStorageHelper().SetJSON(enumLocalStorageArgs.LeaveRequestSearch, Record);

      } else {
        //Load with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }
    }
    fetchData();
  };

  const exportDetailsData = () => {
    async function fetchData() {
      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      //za export sekoga pokazi gi site podatoci 
      let responses = await new LeaveRequestActions().ExportDetailsAsync(Record);

      if (!responses.error) {
        //Load Finished
        let result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);

        //DOWNLOADING EXCEL FILE 
        const excelFileUrl = responses[0].data;
        let payload = "?inputFile=" + excelFileUrl;
        let downloadResponse = await new DownloadActions().DownloadFileAsync(payload);

        // create file link in browser's memory
        const href = URL.createObjectURL(downloadResponse.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', excelFileUrl); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        return;
      } else {
        //Load with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }
    }
    fetchData();
  };


  //****************************************** */
  //HANDLERS SECTION HERE
  //****************************************** */
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name + ":" + value);
    setRecord((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSearch = (e) => {
    setRecord((a) => ({ ...a, ["PageIndex"]: 0 }));
    setIsDoSearch(true);
  };


  const handleDelete = (record) => { //Povikuva onDelete(record);
    setRecordDelete(record);
    setIsModalOpen(true); //Otvori popup
  };


  const handlePageIndexChange = (value) => {
    if (value < 0) value = 0;
    if (value >= Record.TotalPages) value = Record.TotalPages - 1;
    setRecord((a) => ({ ...a, ["PageIndex"]: parseInt(value) }));
    setPaginationChange(true); //ke povika gore HOOK za da se refreshira Search-ot
  };


  const handleModalClose = (doDelete) => {
    if (doDelete) {
      async function fetchData() {

        let result = msgHelper.setPhase(enumPage.LoadStart);
        setMessageData(result);

        let payload = { rqRequestID: RecordDelete.rqRequestID };
        let responses = await new LeaveRequestActions().DeleteAsync(payload);
        if (!responses.error) {

          //Load Finished
          result = msgHelper.setPhase(enumPage.DeleteSuccess);
          setMessageData(result);

          //Refresh the Records
          searchData();
        } else {
          //Load with Error
          result = msgHelper.setPhase(enumPage.ErrorServer, responses);
          setMessageData(result);
        }

        setIsModalOpen(false);
        //Call Deletion 

      }
      fetchData();
    }
    setIsModalOpen(false);
  };


  //****************************************** */
  //GUI DISPLAY
  //****************************************** */
  const ToolbarButtons = () => {
    return (
      <React.Fragment>
        <Button
          sx={{ ml: 3, mr: 2, mt: 3.5 }}
          variant="contained"
          color="success"
          size="small"
          disabled={MessageData.Loading}
          onClick={() => handleSearch()}
        >
          Search
        </Button>
        <Button
          sx={{ mr: 2, mt: 3.5 }}
          variant="contained"
          color="secondary"
          size="small"
          disabled={MessageData.Loading}
          onClick={() => exportDetailsData()}
        >
          Export details
        </Button>
      </React.Fragment>
    );
  };

  return (
    <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
      <MyAlert {...MessageData} />

      <LeaveRequestBreadcrumbs
        page={enumBreadcrumbPage.Search}
        disabled={MessageData.Loading}
      />

      <Card sx={{ minWidth: 275, borderRadius: 2, mb: 2 }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={3}>
              <MyDropDown
                Id="Year"
                Record={Record}
                PlaceHolder="Years"
                HandleChange={handleChange}
                Options={YearsData}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <MyDropDown
                Id="StatusID"
                Record={Record}
                PlaceHolder="Status"
                HandleChange={handleChange}
                Options={enumData.LEAVEREQUEST_STATUS_FOR_APPROVAL}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <MyDropDown
                Id="TypeID"
                Record={Record}
                PlaceHolder="Leave Type"
                HandleChange={handleChange}
                Options={enumData.LEAVEREQUEST_TYPES}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <MyDropDown
                Id="OfficeID"
                Record={Record}
                PlaceHolder="Office"
                HandleChange={handleChange}
                Options={LookupData}
                LookupTypeId={enumLookupTypes.Offices}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <MyAutoSuggest Id='SuperiorID'
                Record={Record}
                PlaceHolder='Superior'
                HandleChange={handleChange}
                Required={true}
                SQLGetList={enumSQLS.KEY_LIST_EmployeeSearch}
                SQLGetRecord={enumSQLS.KEY_GET_Employee} /> 
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
            <MyAutoSuggest Id='EmployeeID'
                Record={Record}
                PlaceHolder='Staff'
                HandleChange={handleChange}
                Required={true}
                SQLGetList={enumSQLS.KEY_LIST_EmployeeSearch}
                SQLGetRecord={enumSQLS.KEY_GET_Employee} /> 
            </Grid>

            <Grid>
              <ToolbarButtons />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="subtitle1" align="left">
                Total Records found: {Record.TotalRecords}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <RenderTable Records={Records} Record={Record}
        onDelete={handleDelete}
        onPageIndexChange={handlePageIndexChange}
      />
      <MyDialogYesNo IsOpen={IsModalOpen} HandleModalClose={handleModalClose} Title={enumSharedMsgs.WariningMsg} Content={enumSharedMsgs.DeleteAreYouSure} />

    </Typography>
  );
}

const RenderTable = ({ Records, Record, onDelete, onPageIndexChange }) => { //Record = Pagination


  return (
    <TableContainer sx={{ display: { xs: "block", sm: "block", md: "block" } }}>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="h4" align="center">
                Employee
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h4" align="center">
                Office
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h4" align="center">
                Superior
              </Typography>
            </TableCell>
            <TableCell><Typography variant="h4" align="center">Leave Type</Typography></TableCell>
            <TableCell>
              <Typography variant="h4" align="center">
                Start Date
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h4" align="center">
                End Date
              </Typography>
            </TableCell>
            <TableCell><Typography variant="h4" align="center">Total Hours</Typography> </TableCell>
            <TableCell>
              <Typography variant="h4" align="center">
                Status
              </Typography>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Records.map((row, index) => (
            <TableRow
              key={row.rqRequestID}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell>
                {Record.PageIndex * Record.PageSize + index + 1}.{row.StaffName}

              </TableCell>

              <TableCell>{row.OfficeName}</TableCell>

              <TableCell>{row.SuperiorName}</TableCell>


              <TableCell>
                {row.TypeName}
              </TableCell>

              <TableCell>
                {moment(row.rqDatetimeStart).format("DD/MM/YYYY")}
              </TableCell>

              <TableCell>
                {moment(row.rqDatetimeEnd).format("DD/MM/YYYY")}
              </TableCell>

              <TableCell align="right">
                {row.rqTotalHours}
              </TableCell>

              <TableCell>{row.StatusName}</TableCell>

              <TableCell align="center">
                <Tooltip title={"Delete Record "}>
                  <IconButton
                    size="small"
                    name="details"
                    onClick={() => onDelete(row)}
                  >
                    <DeleteIcon sx={{ fontSize: 15 }} />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>


        {/* PAGINATION FOOTER HERE */}
        <TableFooter>
          <TableRow>

            <TableCell colSpan={10} align="center">

              <IconButton size="small" name="details"
                onClick={() => onPageIndexChange(parseInt(Record.PageIndex) - 1)}
              >
                <ArrowLeftIcon sx={{ fontSize: 20 }} />
              </IconButton>

              <IconButton
                size="small"
                name="details"
              >
                <Typography variant="h4" align="left">Page {parseInt(Record.PageIndex) + 1} of {Record.TotalPages == 0 ? 1 : Record.TotalPages}</Typography>
              </IconButton>

              <IconButton
                size="small"
                name="details"
                onClick={() => onPageIndexChange(parseInt(Record.PageIndex) + 1)}
              >
                <ArrowRightIcon sx={{ fontSize: 20 }} />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableFooter>


      </Table>
    </TableContainer>
  );


};
