import { IValidationMessage } from './common.interfaces';


export interface IErrorsInput {
  value: string | number,
  type: string,
  relatedValue?: string | number,
  relatedField?: string,
  message: string,
  ignoreValue?: string,
  element: string
};


export class ValidatorHelper {

  constructor() {
    //new ValidatorHelper().getError
  }

  /**
   * Vraka greska od arrErors, bara po Property ID od PropertyX kolekcija Record.PropertyX, 
   * @param arrErrors - NIza od Greski
   * @param controlId - Property
   * @returns ErrorMessage string
   */
  getError(arrErors: IValidationMessage[], controlId: string): string {
    let message: string = '';
    if (arrErors != null && arrErors.length > 0) {
      let currentOption = arrErors.find((x: IValidationMessage) => x.controlId === controlId);
      if (currentOption != null) {
        message = currentOption.message;
      }
    }
    return message;
  }

  /**
   * Vraka greska vo niza od interface: IValidationMessage
   * @param IErrorsInput - NIza od JSON struktura za validacija vidi vo Actions 
   * @returns IValidationMessage[]
   */
  validateClient(inputJSON: IErrorsInput[]): IValidationMessage[] {
    console.log(inputJSON);
    //proverka na greska
    let msgOutput: IValidationMessage[] = [];

    inputJSON.map((input: IErrorsInput) => {
      let invalidField = false;
      
      //valdiraj required fields
      if (input.type === "required") {
        if (input.value === "" || input.value === null) {
          invalidField = true;
        }
      } else if (input.type === "requiredBool") {
        
        if (input.value.toString() === "false") {
          invalidField = true;
        }
      }
      else if (input.type === "email") {
        if (input.value === "" || input.value === null) {
          invalidField = true;
        } else if (input.value.toString().indexOf("@") === -1) {
          invalidField = true;
        } else if (input.value.toString().indexOf(".") === -1) {
          invalidField = true;
        }
      } else if (input.type === "password") {
        if (input.value === "" || input.value === null) {
          invalidField = true;
        } else if (input.value.toString().length < 6) {
          invalidField = true;
        }
      } else if (input.type === "equal") {
        var currentValue = input.value;
        var relatedValue = input.relatedValue;

        if (currentValue !== relatedValue) {
          invalidField = true;
        }
      }

      //valdiraj select fields
      else if (input.type === "SelectEmpty") {
        if (parseInt(input.value.toString()) <= 0 || input.value === null || !input.value) {
          invalidField = true;
        }
      }

      //valdiraj select related fields
      else if (input.type === "SelectEmptyRelated") {
        console.error("SelectEmptyRelated");
        var relatedField = input.relatedField;
        console.error(relatedField);
        console.error(input.relatedValue);
        if (
          input.relatedValue !== "0" &&
          input.relatedValue !== 0 &&
          input.relatedValue !== null
        ) {
          console.error("RELATED VALUE:" + input.relatedValue);
          console.error("INPUT VALUE:" + input.value);

          if (input.value === "0" || input.value === 0 || input.value === null) {
            console.error(input);
            //alert("OK INVALID");
            invalidField = true;
          }
        }
      }
      //valdiraj select related fields
      else if (input.type === "SelectEmptyRelatedWithIgnoreValue") {
        console.error("SelectEmptyRelatedWithIgnoreValue");
        var relatedField = input.relatedField;
        console.error(relatedField);
        console.error(input.relatedValue);
        if (
          input.relatedValue !== "0" &&
          input.relatedValue !== 0 &&
          input.relatedValue !== null
        ) {
          //alert("PUKA" + input.relatedValue + ' - '+  input.ignoreValue.toString());
          if (input.relatedValue?.toString() !== input.ignoreValue?.toString()) {
            //alert("NAJDENO");
            console.error("RELATED VALUE:" + input.relatedValue);
            console.error("INPUT VALUE:" + input.value);

            if (
              input.value === "0" ||
              input.value === 0 ||
              input.value === null
            ) {
              console.error(input);
              //alert("OK INVALID");
              invalidField = true;
            }
          }
        }
      } else if (input.type === "Float") {
        if (input.value === "" || input.value === null) {
          invalidField = true;
        } else {
          let value: string = input.value.toString();
          const floatValue = parseFloat(value);
          if (isNaN(floatValue)) {
            invalidField = true;
          }
        }
      }

      //valdiraj datetime fields
      else if (input.type === "DateTimeEmpty") {
        if (input.value === "") {
          invalidField = true;
        } else {
          const inputDate = new Date(input.value);
          if (inputDate.getFullYear() < 1900) {
            invalidField = true;
          }
        }
      }

      if (invalidField) {
        msgOutput.push({
          message: input.message,
          controlId: input.element,
        });
      }
    });
    return msgOutput;
  }



  /**
   * Go validira SERVER responsite od NIZA  ako ima Error property  
   * @param Responses - NIZ OD RESPONSES
   * @returns True or False ako ima greska
   */
  validateServerResponses(responses: any): [] | {} {
    //proverka na greska
    let errorData = null;
    responses.map((currentResponse: any) => {
      if (currentResponse) { //mozno e currentResponse da bide NULL 
        if (!this.validateServerResponse(currentResponse)) {
          errorData = currentResponse;
          return true;
        }
      }
    });

    //ke napravis ovdeka CUSTOM object so error message
    if (errorData !== null) {
      return errorData;
    }
    return responses;
  }


  /**
   * Go validira SERVER response-ot ako ima Error property  
   * @param Response - Response Objekt 
   * @returns True or False ako ima greska
   */
  private validateServerResponse(response: any) {
    if (response.status) {
      if (response.status === 200) {
        return true;
      }
    }

    //najdi ja greskata po parametri
    response.error = true;
    if (response.message) {
      if (response.response) //ako stasal do server
      {
        if (response.response.status === 400) {
          response.error_description = response.response.data.title; //vo MYAlert ocekuva da ima property: error_description
        }
        else {
          //Ocekuva golem error pa poradi toa napisi mu go sama prviot text \r\n Object Reference not set to an instance of object \r\n File: itn...
          let errror = response.response.data.toString();
          if (errror === '') {
            response.error_description = response.message; //vo MYAlert ocekuva da ima property: error_description
          }
          else {
            let arrOfErrors = errror.split('\r');
            if (arrOfErrors) {
              response.error_description = arrOfErrors[0]; //vo MYAlert ocekuva da ima property: error_description
            }
            else {
              response.error_description = response.response.data;  //vo MYAlert ocekuva da ima property: error_description
            }
          }
        }
      }
      else {
        response.error_description = response.message;  //vo MYAlert ocekuva da ima property: error_description
      }
    }
    else {
      response.error_description = "Unknown Error";
    }
    response.error_description = response.error_description.replace("System.Exception: ", "");
    return false;
  }
}
