import { RestHelper } from "../helpers/RestHelper";

export class ExactonlineService {
  private _restHelper;

  constructor() {
    this._restHelper = new RestHelper();
  }

  async Save(payload: any) {
    const url: string = "Employee/Save";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async Search(payload: any) {
    console.log(payload);
    const url: string = "Exactonline/Search";
    let response = await this._restHelper.post(url, payload);

    return response;
  }

  async Migrate(payload: any) {
    console.log(payload);
    const url: string = "Exactonline/DoMigration";
    let response = await this._restHelper.post(url, payload);

    return response;
  }

  async GetProject(payload = {}) {
    const url: string = "Exactonline/GetProject";
    let response = await this._restHelper.post(url, payload);
    console.log(response);
    return response;
  }

  async GetDivision(payload = {}) {
    const url: string = "Exactonline/GetDivision";
    let response = await this._restHelper.post(url, payload);
    console.log(response);
    return response;
  }
  async GetFinancialYear(payload = {}) {
    const url: string = "Exactonline/GetFinancialYear";
    let response = await this._restHelper.post(url, payload);
    console.log(response);
    return response;
  }

  async GetMigrationStatus(payload = {}) {
    const url: string = "Exactonline/GetMigrationStatus";
    let response = await this._restHelper.post(url, payload);
    console.log(response);
    return response;
  }
  async GetExactToken(payload = {}) {
    const url: string = "Exactonline/GetExactToken";
    let response = await this._restHelper.post(url, payload);
    console.log(response);
    return response;
  }

  async Export(payload: any) {
    const url: string = "Employee/Export";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async Edit(payload: any) {
    const url: string = "Exactonline/Edit";
    let response = await this._restHelper.post(url, payload);
    return response;
  }
}
