import { RestHelper } from "../helpers/RestHelper";

export class DeclarationDiemService {
  private _restHelper;

  constructor() {
    this._restHelper = new RestHelper();
  }

  async SaveCost(payload: any) {
    const url: string = "DeclarationDiem/Save";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async DeleteCost(payload: any) {
    const url: string = "DeclarationDiem/Delete";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async UploadCost(payload: any) {
    const url: string = "DeclarationDiem/Upload";
    let response = await this._restHelper.postFile(url, payload);
    return response;
  }
 
  async DownloadCostUrl(payload: any) {
    const url: string = "DeclarationDiem/Download";
    let response = await this._restHelper.getUrl(url, payload);
    return response;
  }
  
  async GetEurRate(payload: any) {
    const url: string = "DeclarationDiem/GetEurRate";
    let response = await this._restHelper.post(url, payload);
    return response;
  }
  
  
  async GetByDeclarationID(payload: any) {
    const url: string = "DeclarationDiem/GetByDeclarationID";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  
  async DownloadDiem(payload: any) {
    const url: string = "DeclarationDiem/Download";
    let response = await this._restHelper.downloadFile(url, payload);
    return response;
  }
 
}
