import { RestHelper } from '../helpers/RestHelper';
             
export class SystemUserService {

  private _restHelper;

  constructor() {
    this._restHelper = new RestHelper();
  }

  async Save(payload : any)  {
    const url : string  = "SystemUser/Save";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async Search(payload : any)  {
    const url : string  = "SystemUser/Search";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async Export(payload : any)  {
    const url : string  = "SystemUser/Export";
    let response = await this._restHelper.post(url, payload);
    return response;
  }


  async Edit(payload : any)  {
    const url : string  = "SystemUser/Edit";
    let response = await this._restHelper.post(url, payload);
    return response;
  }
}
