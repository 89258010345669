import { ValidatorHelper } from "../helpers/ValidatorHelper";
import { LinkService } from "../services/LinkService";


export default class LinkActions {

  async InitAsync(Record : {}) {
    let responseData = await new LinkService().Search(Record);
       let responses = [responseData]

    //napravi validacija
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }
}