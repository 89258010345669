import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Navigator from "./Navigator";
import Header from "./Header";
import { Paper } from "@mui/material";
import AuthenticateHelper from "./helpers/AuthenticateHelper";
import NavigationHelper from "./helpers/NavigationHelper";
import { useLocation, useNavigate } from "react-router-dom";
import { enumConfig, enumRoutes } from "./helpers/common.constants";
import { lightBlue } from "@mui/material/colors";

import MENU_SysAdmin from "./json/MENU_SysAdmin.json";
import MENU_HRAdmin from "./json/MENU_HRAdmin.json";
import MENU_FinanceAdmin from "./json/MENU_FinanceAdmin.json";
import MENU_Staff from "./json/MENU_Staff.json";

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{" "}
      {new Date().getFullYear()}.
    </Typography>
  );
}

let theme = createTheme({
  palette: {
    primary: {
      light: "#AABBD2",
      main: "#1A2F80",
      dark: "#AABBD2",
    },
    // palette: {
    //   action: {
    //     active: lightBlue[200],
    //     //activeOpacity: 1,
    //     hover: lightBlue[100],
    //     hoverOpacity: 0.7,
    //     focus: lightBlue[600],
    //     focusOpacity: 1,
    //     selected: lightBlue[300],
    //     selectedOpacity: 1,
    //   },
  },
  typography: {
    //"fontFamily": "Futura",
    subtitle1: {
      fontWeight: 400,
      fontSize: "0.9rem",
      paddingTop: 10,
    },
    subtitle2: {
      fontWeight: 300,
      fontSize: "0.8rem",
      paddingTop: 10,
    },
    h2: {
      fontWeight: 100,
      fontSize: "2rem",
    },
    h3: {
      fontWeight: 100,
      fontSize: "1.5rem",
    },
    h4: {
      fontWeight: 750,
      fontSize: "0.9rem",
    },
    h5: {
      fontWeight: 750,
      fontSize: "0.9rem",
    },
    h6: {
      fontWeight: 750,
      fontSize: "0.8rem",
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#081627",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: "0.7rem",
          textTransform: "none",
        },
        contained: {
          boxShadow: "none",
          "&:active": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-root, & .MuiInputLabel-root": {
            fontSize: "0.8rem",
          },
          //height:40          //textTransform: "none"
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "& label": {
            fontSize: "0.8rem",
          },
        },
        input: {
          fontSize: "0.8rem",
        },
        listbox: {
          fontSize: "0.8rem",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: "0.8rem",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginLeft: theme.spacing(1),
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: theme.palette.common.white,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          margin: "0 16px",
          minWidth: 0,
          padding: 0,
          [theme.breakpoints.up("md")]: {
            padding: 0,
            minWidth: 0,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1),
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: "rgb(255,255,255,0.15)",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "#4fc3f7",
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: 14,
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "inherit",
          minWidth: "auto",
          marginRight: theme.spacing(2),
          "& svg": {
            fontSize: 20,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32,
        },
      },
    },
  },
};

const drawerWidth = 256;

//MyContent = AcquisitionManage.js
//recordData = {refreshFL: true, record: recordDb}; kade recordDb e samiot Acquisition record  (se koristi vo Header.js za promeni na title pri snimanje na nov record od New vo Edit)
export default function BackContainer({ MyContent, Section }) {
  //Za Refreshiranje na strana mu treba;
  const location = useLocation();

  const navigate = useNavigate();
  const isSigned = new AuthenticateHelper().IsSigned();
  const [menuByRole, setMenuByRole] = React.useState([]);

  //ako user-ot ne e najden odlogiraj go
  if (isSigned === false) {
    navigate(enumRoutes.Security_Logout);
  }

  const [mobileOpen, setMobileOpen] = React.useState(false);
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  React.useEffect(() => {
    let currentMenu = [];
    if (new AuthenticateHelper().IsStaff()) {
      currentMenu = MENU_Staff.find((x) => x.section === "Main") || [];
    }
    //HR ADMIN
    else if (new AuthenticateHelper().IsHrAdmin()) {
      if (Section) {
        currentMenu = MENU_HRAdmin.find((x) => x.section === Section) || [];
      } else {
        currentMenu = MENU_HRAdmin.find((x) => x.section === "Main") || [];
      }
    }
    //FINANCE ADMIN
    else if (new AuthenticateHelper().IsFinanceAdmin()) {
      if (Section) {
        currentMenu = MENU_FinanceAdmin.find((x) => x.section === Section) || []; //sega za sega isto
      } else {
        currentMenu = MENU_FinanceAdmin.find((x) => x.section === "Main") || [];
      }
    }
    //SYS ADMIN
    else if (new AuthenticateHelper().IsAdmin()) {
      if (Section) {
        currentMenu = MENU_SysAdmin.find((x) => x.section === Section) || [];
      } else {
        currentMenu = MENU_SysAdmin.find((x) => x.section === "Main") || [];
      }
    }
    else {
      //ne nasol niedna rolja odlogiraj go
      navigate(enumRoutes.Security_Logout);
    }
    setMenuByRole(currentMenu.data);
}, []); //default

return (
  <ThemeProvider theme={theme}>
    <Box sx={{ display: "flex", minHeight: "100vh" }}>
      <CssBaseline />

      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      >
        {isSmUp
          ? null
          : menuByRole.length > 0 && (
            <Navigator
              Menu={menuByRole}
              PaperProps={{ style: { width: drawerWidth } }}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
            />
          )}

        {menuByRole.length > 0 && (
          <Navigator
            Menu={menuByRole}
            PaperProps={{ style: { width: drawerWidth } }}
            sx={{ display: { sm: "block", xs: "none" } }}
          />
        )}
      </Box>

      <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
        <Header onDrawerToggle={handleDrawerToggle} Menu={menuByRole} />
        <Box
          component="main"
          sx={{ flex: 1, py: 2, px: 1, bgcolor: "#eaeff1" }}
        >
          {/* DA SE VIDI SO LEON */}
          <Paper sx={{ margin: "auto", overflow: "hidden" }}>
            {MyContent}
          </Paper>
        </Box>
        {/* <Box component="footer" sx={{ p: 2, bgcolor: '#eaeff1' }}>
            <Copyright />
          </Box> */}
      </Box>
    </Box>
  </ThemeProvider>
);
}
