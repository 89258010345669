import { IErrorsInput, ValidatorHelper } from "../helpers/ValidatorHelper";
import { LookupDataService } from "../services/LookupDataService";
import { CountryService } from "../services/CountryService";
import { EmployeeService } from "../services/EmployeeService";
import { StaffService } from "../services/StaffService";
import { ExactonlineService } from "../services/ExactonlineService";

export default class ExactonlineActions {
  async InitAsync() {
    let responseCostCenter = await new ExactonlineService().GetProject();
    let responseDivision = await new ExactonlineService().GetDivision();
    //let responseFinancialYearData = await new ExactonlineService().GetFinancialYear();
    let responseFinancialYearData =
      await new LookupDataService().GetExactOnlineYears();
    let responseMigrationConfigData =
      await new ExactonlineService().GetMigrationStatus();

    let responses = [
      responseCostCenter,
      responseDivision,
      responseFinancialYearData,
      responseMigrationConfigData,
    ];

    //napravi validacija
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async SearchAsync(Record: any) {
    console.log(Record);
    //napravi validacija
    let response = await new ExactonlineService().Search(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async EditAsync(Record: any) {
    //napravi validacija
    let response = await new ExactonlineService().Edit(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async InitMigrationAsync() {
    let responseConfig = await new ExactonlineService().GetMigrationStatus();
    let responses = [responseConfig];
    //napravi validacija
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async MigrateAsync(Record: any) {
    //napravi validacija
    console.log("OD ACTIOOONSS");
    console.log(Record);
    let response = await new ExactonlineService().Migrate(Record);

    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }
}
