// @ts-check
import { useEffect } from "react";
import Typography from "@mui/material/Typography";
import MyText from "../../controls/MyText";
import { useState } from "react";
import MyAlert from "../../controls/MyAlert";
import {
  enumBreadcrumbPage,
  enumLookupTypes,
  enumPage,
  enumRoutes,
  enumSQLS,
  enumSharedMsgs,
} from "../../helpers/common.constants";
import Button from "@mui/material/Button";
import { Breadcrumbs, Grid, IconButton } from "@mui/material";
import { useParams, useLocation, useNavigate, Link } from "react-router-dom";
import NotesIcon from "@mui/icons-material/Notes";
import EmployeeActions from "../../actions/EmployeeActions";
import MyDate from "../../controls/MyDate";
import moment from "moment";
import { MessageHelper } from "../../helpers/MessageHelper";
import VacancyBreadcrumbs from "./VacancyBreadcrumbs";
import AuthenticateHelper from "../../helpers/AuthenticateHelper";
import MyAutoSuggest from "../../controls/MyAutoSuggest";
import MyEmployeeActions from "../../actions/MyEmployeeActions";
import DownloadActions from "../../actions/DownloadActions";
import VacancyActions from "../../actions/VacancyActions";
import MyDropDown from "../../controls/MyDropDown";

import MyCheckList from "../../controls/MyCheckList";
import MyCheckbox from "../../controls/MyCheckbox";

const RecordDefault = {
  vaID: 0,
  vaName: "",
  ItemGUID: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  // vaDescription: "",
  // vaCode: "",
  vaCountryID: 0,
  vaFrom: moment(new Date(1900, 0, 1)).format("YYYY-MM-DD"),
  vaTo: moment(new Date(1900, 0, 1)).format("YYYY-MM-DD"),
  // vaTag: "",
  // vaCreated: moment(Date.now()).format("YYYY-MM-DD"),
  vapVacancyID: 0,
  vapFirstName: "",
  vapLastName: "",
  vapEmail: "",
  vapReside: "",
  vapLanguages: "",
  vapDoc1: "",
  vapDoc2: "",
  vapDoc3: "",
};

export default function VacancyApplicant(args) {
  //Page MAIN Hooks
  const [IsNewRecord, setIsNewRecord] = useState(true);
  const [IsProfileLocked, setIsProfileLocked] = useState(true);
  const [IsReadOnly, setReadOnly] = useState(false);
  const [MessageData, setMessageData] = useState({});
  const [Record, setRecord] = useState(RecordDefault);
  const [IsProfileRegistration, setProfileRegistration] = useState(false); //ako doaga od

  const [LookupData, setLookupData] = useState([]);

  const [YesNoData, setYesNoData] = useState([]);

  const [VacancyData, setVacancyData] = useState([]);

  const [VacancyApplicantData, setVacancyApplicantData] =
    useState(RecordDefault);

  const [uploadedFile, setUploadedFile] = useState(null);

  const { AplicantID } = useParams();

  //Da proveri dali e nov zapis ili postoecki
  const { VacancyID } = useParams();

  //za Navigacija
  const navigate = useNavigate();

  //Za Refreshiranje na strana mu treba;
  const location = useLocation();

  //za poraki pri loadiranje na strana
  const msgHelper = new MessageHelper();

  //****************************************** */
  //HOOKS SECTION HERE
  //****************************************** */

  //1 --> Ke se povika ovoj HOOK samo dokolku se SMENI url-ot poradi toa na new dodavame Ticks() za da forcirame refresh na strana
  useEffect(() => {
    initData();
  }, [location.pathname + "/" + location.search]);

  //****************************************** */
  //METHOD  SECTION HERE
  //****************************************** */

  const initData = () => {
    async function fetchData() {
      setIsNewRecord(true); //DEFAULT
      setRecord(RecordDefault);
      setIsProfileLocked(false);
      setReadOnly(false);

      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      let responses = await new VacancyActions().InitAsync(); // await LookupActions.InitAsync();
      if (!responses.error) {
        let lookupData = responses[0].data;
        let yesOrNoData = responses[2].data;
        //let countryData = responses[1].data;
        //let SuperiorsData = responses[2].data;
        let vacancyData = responses[5].data;

        setLookupData(lookupData);
        setVacancyData(vacancyData);
        setYesNoData(yesOrNoData);
        //setCountryData(countryData);

        if (AplicantID) {
          await GetApplicantProfile();
        } else {
          let isMyProfileLink =
            location.pathname.toString().indexOf(enumRoutes.Main_MyProfile) >
            -1;
          if (isMyProfileLink) {
            await GetApplicantProfile();
          }
        }

        //Load Finished
        result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);

        //Finished  with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }
    }

    fetchData();
  };

  async function GetApplicantProfile() {
    //setIsProfileLocked(false);
    setReadOnly(false);

    let result = msgHelper.setPhase(enumPage.LoadStart);
    setMessageData(result);

    let responses;
    let payload = { vapAplicantID: AplicantID };
    responses = await new VacancyActions().EditApplicantAsync(payload); // await LookupActions.InitAsync();
    setReadOnly(true);

    if (!responses.error) {
      let recordDb = responses[0].data;
      setRecord(recordDb);
      result = msgHelper.setPhase(enumPage.LoadSuccess);
      setMessageData(result);
    }
  }

  const saveData = () => {
    async function fetchData() {
      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      let response = null;

      //Ako e nov profil doaga od REGISTER tag-ot togas dozvoli da kreira nov EMPLOYEE
      // if (IsProfileRegistration) {
      //   response = await new MyEmployeeActions().RegisterAsync(Record);
      // } else {
      //   response = await new EmployeeActions().SaveAsync(Record);
      // }

      response = await new VacancyActions().SaveApplicantAsync(Record);

      if (!response.error) {
        let recordDb = response[0].data;

        console.log(uploadedFile);
        uploadFile(uploadedFile, recordDb.vapAplicantID);
        setRecord(recordDb);
        if (recordDb.eyRemoved) {
          result = msgHelper.setPhase(
            enumPage.LoadSuccessWithWarningMsg,
            null,
            "Profile is set as not active!"
          );
          setMessageData(result);
          setIsProfileLocked(true);
          setIsNewRecord(false);
        } else if (IsProfileRegistration) {
          result = msgHelper.setPhase(
            enumPage.LoadSuccessWithMsg,
            null,
            "Thank you for your registration. Email is sent to your account and  HR Department, now they will create Contract record for you and you can further use the system!"
          );
          setMessageData(result);
        } else {
          //Load Finished
          result = msgHelper.setPhase(enumPage.SaveSuccess);
          setMessageData(result);
          setRecord((a) => ({
            ...a,
            ValidationMessages: result.ValidationMessages,
          })); //Ako ima Validation Messages da gi izbrise
          setIsNewRecord(false);
        }
      } else {
        //Finished  with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, response);
        setMessageData(result);
        setRecord((a) => ({
          ...a,
          ValidationMessages: result.ValidationMessages,
        })); //Samo ovdeka mu treba ako ima Validacioni greski da dodade
      }
    }

    fetchData();
  };

  async function uploadFile(file, vapAplicantID) {
    let result = msgHelper.setPhase(enumPage.LoadStart);
    setMessageData(result);

    let payload = {
      FileData: file,
      FileName: file.name,
      ID: vapAplicantID,
    };

    // Perform the file upload
    let responses = await new VacancyActions().UploadAsync(payload);

    if (!responses.error) {
      // Refresh the data or update as needed after a successful upload
      // let payload = { vapApplicantID: vapAplicantID };
      // responses = await new VacancyActions().EditApplicantAsync(payload);

      if (!responses.error) {
        // let recordDb = responses[0].data;
        // setRecord(recordDb);

        //Load Finished
        result = msgHelper.setPhase(enumPage.SaveSuccess);
        setMessageData(result);
      } else {
        let result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }
    }
  }

  //****************************************** */
  //HANDLERS SECTION HERE
  //****************************************** */
  const handleChange = (e) => {
    const { name, value } = e.target;

    console.log(name + ":" + value);
    setRecord((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleFileUpload = (e) => {
    const { files, name } = e.target;

    const file = files[0];

    if (file) {
      //uploadData();
      setUploadedFile(file);
      setRecord((prevState) => ({ ...prevState, [name]: file.name }));
    }

    // async function uploadData() {
    //   //Load Start
    //   let result = msgHelper.setPhase(enumPage.LoadStart);
    //   setMessageData(result);

    //   let payload = {
    //     FileData: file,
    //     FileName: file.name,
    //     // ID: vapAplicantID,
    //   };
    //   let responses = await new VacancyActions().UploadAsync(payload);

    //   if (!responses.error) {
    //     //Refreshiraj samo delot za Declaration
    //     let payload = { vapApplicantID: 2 };
    //     responses = await new VacancyActions().EditApplicantAsync(payload); // await LookupActions.InitAsync();
    //     if (!responses.error) {
    //       let recordDb = responses[0].data;
    //       setVacancyApplicantData(recordDb);

    //       //Load Finished
    //       result = msgHelper.setPhase(
    //         enumPage.LoadSuccessWithMsg,
    //         null,
    //         "Supporting document successfully uploaded, you can preview & submit now if you are ready with all your costs/diems."
    //       );
    //       setMessageData(result);
    //     } else {
    //       let result = msgHelper.setPhase(enumPage.ErrorServer, responses);
    //       setMessageData(result);
    //     }
    //   } else {
    //     //Finished  with Error
    //     result = msgHelper.setPhase(enumPage.ErrorServer, responses);
    //     setMessageData(result);
    //     setRecord((a) => ({
    //       ...a,
    //       ValidationMessages: result.ValidationMessages,
    //     })); //Ako ima Validation Messages
    //   }
    // }
  };

  const downloadData = () => {
    async function fetchData() {
      let payload = "?ID=" + AplicantID;
      let response = await new VacancyActions().DownloadCostAsync(payload);

      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", VacancyApplicantData.vapDoc1); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    }
    fetchData();
  };

  //****************************************** */
  //GUI DISPLAY
  //****************************************** */
  const ActionButtons = () => {
    return (
      <Grid container align="right" padding={1}>
        <Grid item xs={12}>
          {IsProfileRegistration ||
            (!IsReadOnly && (
              <Button
                name="btnCancel"
                variant="contained"
                sx={{ mr: 3 }}
                color="warning"
                size="small"
                disabled={MessageData.Loading}
                onClick={() => navigate(enumRoutes.Security_Logout)}
              >
                Cancel
              </Button>
            ))}
          {!IsProfileLocked && !IsReadOnly && (
            <Button
              name="btnSave"
              variant="contained"
              sx={{ mr: 1 }}
              color="success"
              size="small"
              disabled={MessageData.Loading}
              onClick={() => saveData()}
            >
              Save
            </Button>
          )}
        </Grid>
      </Grid>
    );
  };

  return (
    <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
      {!IsReadOnly && !IsProfileRegistration && (
        <VacancyBreadcrumbs page={enumBreadcrumbPage.Master} Id={Record.vaID} />
      )}

      <MyAlert {...MessageData} />

      {/* {!IsReadOnly && !IsProfileRegistration && <HeaderButtons />} */}

      <ActionButtons />

      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5">General Info</Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <MyDropDown
            Id="vapVacancyID"
            Record={Record}
            PlaceHolder="You are applying for vacancy"
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            Options={VacancyData}
            //LookupTypeId={enumLookupTypes.Offices}
            Required={true}
            Disabled={IsProfileLocked || IsReadOnly}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <MyText
            Id="vapFirstName"
            PlaceHolder="First Name"
            Record={Record}
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            Required={true}
            Disabled={
              IsProfileLocked ||
              !IsNewRecord ||
              IsProfileRegistration ||
              IsReadOnly
            }
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <MyText
            Id="vapLastName"
            PlaceHolder="Last Name"
            Record={Record}
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            Required={true}
            Disabled={
              IsProfileLocked ||
              !IsNewRecord ||
              IsProfileRegistration ||
              IsReadOnly
            }
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <MyText
            Id="vapEmail"
            PlaceHolder="Email"
            Record={Record}
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            Required={true}
            Disabled={
              IsProfileLocked ||
              !IsNewRecord ||
              IsProfileRegistration ||
              IsReadOnly
            }
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <MyText
            Id="vapReside"
            PlaceHolder="In which country do you currently reside?"
            Record={Record}
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            Required={true}
            Disabled={
              IsProfileLocked ||
              !IsNewRecord ||
              IsProfileRegistration ||
              IsReadOnly
            }
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <MyDropDown
            Id="vapSPARKCollectPersonalDataID"
            Record={Record}
            PlaceHolder="Do you have a working permit"
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            Options={YesNoData}
            //LookupTypeId={enumLookupTypes.Offices}
            Required={true}
            Disabled={IsProfileLocked || IsReadOnly}
          />
        </Grid>
      </Grid>

      <Grid container sx={{ mt: 2 }}>
        <Grid item xs={12}>
          <Typography variant="h6">
            In which languages do you have professional working proficiency?
          </Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <MyCheckList
            Id="vapLanguages"
            Record={Record}
            PlaceHolder="Languages"
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            Options={LookupData}
            LookupTypeId={enumLookupTypes.Languages}
            Required={true}
            Disabled={IsProfileLocked || IsReadOnly}
          />
        </Grid>
      </Grid>

      {/* dokumenti */}
      <Grid container spacing={2} sx={{ my: 0, mx: 2 }} color="text.secondary">
        <Grid item xs={12} md={12} align="left">
          <Typography variant="subtitle" display="block">
            <span>
              {Record.vapDoc1 === ""
                ? "Please upload your motivation letter    "
                : "Uploaded file:" + Record.vapDoc1}
            </span>

            <Button
              variant="contained"
              component="label"
              sx={{ ml: 2, mr: 2 }}
              disabled={MessageData.Loading}
              disableFocusRipple={true}
              disableTouchRipple={true}
            >
              Upload
              <input
                hidden
                accept="*"
                multiple
                type="file"
                name="vapDoc1"
                onChange={handleFileUpload}
              />
            </Button>

            {VacancyApplicantData.vapDoc1 !== "" && (
              <Button
                variant="contained"
                color="success"
                onClick={() => downloadData()}
                disabled={MessageData.Loading}
                disableFocusRipple={true}
                disableTouchRipple={true}
              >
                Download
              </Button>
            )}
          </Typography>
        </Grid>
      </Grid>

      {/* <Grid container spacing={2} sx={{ my: 0, mx: 2 }} color="text.secondary">
        <Grid item xs={12} md={12} align="left">
          <Typography variant="subtitle" display="block">
            <span>
              {VacancyApplicantData.vapDoc2 === ""
                ? "Please upload your CV    "
                : "Uploaded file:" + VacancyApplicantData.vapDoc2}
            </span>

            <Button
              variant="contained"
              component="label"
              sx={{ ml: 2, mr: 2 }}
              disabled={MessageData.Loading}
              disableFocusRipple={true}
              disableTouchRipple={true}
            >
              Upload
              <input
                hidden
                accept="*"
                multiple
                type="file"
                name="vapDoc2"
                onChange={handleFileUpload}
              />
            </Button>

            {VacancyApplicantData.vapDoc2 !== "" && (
              <Button
                variant="contained"
                color="success"
                onClick={() => downloadData()}
                disabled={MessageData.Loading}
                disableFocusRipple={true}
                disableTouchRipple={true}
              >
                Download
              </Button>
            )}
          </Typography>
        </Grid>
      </Grid> */}

      {/* <Grid container spacing={2} sx={{ my: 0, mx: 2 }} color="text.secondary">
        <Grid item xs={12} md={12} align="left">
          <Typography variant="subtitle" display="block">
            <span>
              {VacancyApplicantData.vapDoc3 === ""
                ? "Please upload other documents    "
                : "Uploaded file:" + VacancyApplicantData.vapDoc3}
            </span>

            <Button
              variant="contained"
              component="label"
              sx={{ ml: 2, mr: 2 }}
              disabled={MessageData.Loading}
              disableFocusRipple={true}
              disableTouchRipple={true}
            >
              Upload
              <input
                hidden
                accept="*"
                multiple
                type="file"
                name="vapDoc3"
                onChange={handleFileUpload}
              />
            </Button>

            {VacancyApplicantData.vapDoc3 !== "" && (
              <Button
                variant="contained"
                color="success"
                onClick={() => downloadData()}
                disabled={MessageData.Loading}
                disableFocusRipple={true}
                disableTouchRipple={true}
              >
                Download
              </Button>
            )}
          </Typography>
        </Grid>
      </Grid> */}

      <ActionButtons />

      <MyAlert {...MessageData} />
    </Typography>
  );
}
