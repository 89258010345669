import * as React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import EmployeeActions from '../actions/EmployeeActions';
import GeneralActions from '../actions/GeneralActions';
import { enumSQLS } from '../helpers/common.constants';




export default function ZAutoSuggestField(args) {

  const defalutLabelText = "";
  const [options, setOptions] = React.useState([]);
  const [SelectedValueText, setSelectedValueText] = React.useState(defalutLabelText);

  let Record = args.Record;
  let required = args.Required ?? false;
  let hasError = false;
  let message = '';
  let disabled = args.Disabled ?? false;
  let lengthForSearch = args.LengthForSearch ?? 2; //najmalku 3 characteri za da prebaruva
  let variant = args.Variant ?? "outlined"
  let primaryKey = args.PrimaryKey;
  let sqlExtraValue = args.SQLExtraValue;


  //default ocekuva Text i Value za Select dokolku nema izbrano user-ot args.
  let optionDisplayText = ""; //ako slucajno ima custom text a selected text e razlicen pa poradi toa se zima optionDisplayText
  let optionText = "Text";
  let optionValue = "Value";
  let placeHolder = args.PlaceHolder;
  let hideTriangle = args.HideTriangle ?? false
  let margin = args.Margin ?? "dense";

  if (!args.SkipPlaceHolderSuffix) {
    placeHolder += " (search)";
  }


  //proveri dali e za Lookup togas znaci deka optionText = ldValue ; optionValue = ldLookupDataID
  //slucajno ako vo arguments ima custom Value i Text togas zemi gi od tamu konkretno args.OptionsText i  args.OptionsValue
  if (args.OptionsText) {
    optionText = args.OptionsText;
  }
  if (args.OptionsValue) {
    optionValue = args.OptionsValue;
  }

  //ako e specificiran togas ke go zeme ako ne ke go zeme OptionText
  if (args.OptionsDisplayText) {
    optionDisplayText = args.OptionsDisplayText;
  }
  else {
    optionDisplayText = optionText;
  }


  //Proveri dali ima greska
  if (required === true) {
    let errorCollection = args.ValidationMessages ? args.ValidationMessages : [];
    if (errorCollection != null && errorCollection.length > 0) {
      let currentOption = errorCollection.find((x) => x.controlId === args.Id);
      if (currentOption != null) {
        hasError = true;
        message = currentOption.message;
      }
    }
  }

  //Vo zavisnot od Value i HasError se refreshira komponentata
  //let value | number = args.SelectedValue 
  let value  = args.Value ?  args.Value : "";

  //Ke go selektira ID-to vo Drop Down so pomos na GetRecord
  React.useEffect(() => {
    //let currentValue  = value.toString();
    if (value === undefined || value === null) {
      value = "";
    }

    //vo ovoj mmoment resetiraj text
    //setSelectedValueText('');

    if (value !== "") {
      //dokolku nema definirano args.SQLGetRecord togas nema sto da vika GetRecord a toa e vo Header.js
      if (args.SQLGetRecord) {
        GetRecord(value);
      }
    }
  }, [value]); //default



  const GetList = (inputValue) => {

    async function fetchData() {

      //KEY_LIST_EmployeeSearch	EXEC pr_AS_EmployeeSearch {0}
      //SqlID: 1531
      //Params: {0}
      let params = "";
      if (sqlExtraValue)
        { 
        params = sqlExtraValue + "," + inputValue; 
        }
      else {
        params = inputValue;
      }

      let payload = { SqlID: args.SQLGetList, Params: params }
      let responses = await new GeneralActions().GetList(payload);
      if (!responses.error) {
        let generalSearchData = responses[0].data;

        if (generalSearchData.length > 0) {
          setOptions(generalSearchData);
        }
        else {
          setOptions([]);
          setSelectedValueText(defalutLabelText);
        }
      }
      else {
        alert("General Error\n" + responses);
      }
    }

    if (inputValue) {
      //najmalku 3 charactera za da prebaruva
      if (inputValue.length >= lengthForSearch) {
        fetchData();
      }
    } else {
      setOptions([]);
    }
  };

  const GetRecord = (inputValue) => {

    async function fetchData() {

      //KEY_GET_Employee	SELECT eyFirstName + ' ' + eyLastName AS Text From Employee WHERE eyEmployeeID = '{0}'
      //SqlID: 1532
      //Params: {0}
      let payload = { SqlID: args.SQLGetRecord, Params: inputValue.toString() }

      let responses = await new GeneralActions().GetRecord(payload);
      if (!responses.error) {
        let generalSearchData = responses[0].data;
        setSelectedValueText(generalSearchData.Text);
      }
      else {
        alert("General Error\n" + responses);
      }
    }

    if (inputValue != "") {
      fetchData();
    }
  };


  const handleInputChange = (event, inputValue, reason) => {
    console.error("handleInputChange")
    //reason --> input || reset (koga stiska enter)
    if (reason === 'reset') return; //stisnal na enter ne pravi nisto
    GetList(inputValue);
  };



  //useCallback samo ednas da se mountira: https://everyday.codes/javascript/react-usememo-and-when-you-should-use-it/
  const handleSelectedValue = (event, value) => {

    
    let option = value;
    if (option !== null) {
      let customerArgs = { target: { name: args.Id, 
                                    value: option[optionValue], 
                                    text: option[optionText], 
                                    primaryKey: primaryKey } };
      
      setSelectedValueText(option[optionText].toString())

      args.HandleChange(customerArgs);
    } else {
      let customerArgs = { target: { name: args.Id, value: 0, primaryKey: primaryKey, text:"" } };
      setSelectedValueText(defalutLabelText);
      args.HandleChange(customerArgs);
    }
  };

  //useCallback samo ednas da se mountira: https://everyday.codes/javascript/react-usememo-and-when-you-should-use-it/
  const handleOnOpen = (event) => {

    //vo store procedurata ocekuva da ima "**" check i da gi prikaze site
    if (event.type === "click")
      { 
        GetList("**");
      }
  };


  
  return React.useMemo(() => {


    return (
      <Autocomplete
        //freeSolo 
        size='small'
        freeSolo={hideTriangle} //za da se krie triagolnikot
        id={args.Id}
        disabled={disabled}
        getOptionLabel={(option) => typeof option === "string" ? option : option[optionDisplayText]}
        onChange={(event, value) => { handleSelectedValue(event, value); }} //ako e MULTIPLE 
        onInputChange={handleInputChange}
        onOpen={(event) => { handleOnOpen(event); }} //ako e MULTIPLE 
        options={options}
        autoComplete
        value={SelectedValueText}
        renderInput={(params) => (
          <TextField {...params} label={placeHolder}  variant={variant} size='small' fullWidth margin={margin} autoFocus={args.AutoFocus} helperText={message} error={hasError} 
            
          />
        )}
      />
    );
  }, [options, value, args.Disabled, SelectedValueText, args.SQLExtraValue, hasError, message]);
};

