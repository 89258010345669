import { RestHelper } from "../helpers/RestHelper";

export class FinReportsService {
  private _restHelper;

  constructor() {
    this._restHelper = new RestHelper();
  }

  async ExportEmployeeAllocation(payload: any) {
    const url: string = "FinReports/ExportEmployeeAllocation";
    let response = await this._restHelper.post(url, payload);
    return response;
  }
}
