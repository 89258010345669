import axios from 'axios';
import LogHelper from './LogHelper';
import AuthenticateHelper from './AuthenticateHelper';
import { enumRoutes } from './common.constants';

export interface IErrorsInput {
  value: string | number,
  type: string,
  relatedValue: string | number,
  relatedField: string,
  message: string,
  ignoreValue: string,
  element: string
};


export class RestHelper {

  private _logHelper;

  constructor() {
    this._logHelper = new LogHelper();
  }

  post(apiEndpoint: string, payload: {}): any {
    return axios.post(this._logHelper.GetBaseUrl() + apiEndpoint, payload, this.getOptions()).then((response: any) => {
      return response;
    }).catch((err) => {
      if (err.response) {
        let response = err.response;
        //401 Unauthorized is the status code to return when the client provides no credentials or invalid credentials. 
        //403 Forbidden is the status code to return when a client has valid credentials but not enough privileges to perform an action on a resource.Dec
        if (response.status === 401 || response.status === 403) {
          // console.log(payload);
          alert(apiEndpoint);
          window.open(enumRoutes.Security_Logout, '_self');
          return;
        }
      }
      return err;
    })
  }

  postWithHeader(apiEndpoint: string, payload: {}, headers: {}): any {

    let options: any = { headers: {} };
    options.headers = { 'Content-Type': "application/json" }
    options.headers["Authorization"] = new AuthenticateHelper().GetTokenWithBarrier();


    //gi dodava custom headers vo Headers
    //https://bobbyhadz.com/blog/typescript-convert-object-to-array
    //ocekuva za headers {Key: Value}
    if (headers) {
      //const obj = { name: 'Bobby', country: 'Chile' };
      const keys = Object.keys(headers) as (keyof typeof headers)[];
      //console.log(keys); // 👉️ ['name', 'country']

      keys.forEach((key) => {
        // 👇️ name Bobby, country Chile
        //console.log(key, headers[key]);
        options.headers[key] = headers[key];
      });
    }
    return axios.post(this._logHelper.GetBaseUrl() + apiEndpoint, payload, options).then((response: any) => {
      return response;
    }).catch((err) => {
      if (err.response) {
        let response = err.response;
        //401 Unauthorized is the status code to return when the client provides no credentials or invalid credentials. 
        //403 Forbidden is the status code to return when a client has valid credentials but not enough privileges to perform an action on a resource.Dec
        if (response.status === 401 || response.status === 403) {
          // console.log(payload);
          alert(apiEndpoint);
          window.open(enumRoutes.Security_Logout, '_self');
          return;
        }
      }
      return err;
    })
  }
  get(apiEndpoint: string, payload: {}): any {
    return axios.get(this._logHelper.GetBaseUrl() + apiEndpoint + payload, this.getOptions()).then((response: any) => {
      return response;
    }).catch((err) => {
      if (err.response) {
        let response = err.response;
        //401 Unauthorized is the status code to return when the client provides no credentials or invalid credentials. 
        //403 Forbidden is the status code to return when a client has valid credentials but not enough privileges to perform an action on a resource.Dec
        if (response.status === 401 || response.status === 403) {
          window.open(enumRoutes.Security_Logout, '_self');
          return;
        }
      }
      return err;
    })
  }

  getUrl(apiEndpoint: string, payload: {}): any {
    let url = "{0}?Token={1}&{2}"
    url = url.replace('{0}', this._logHelper.GetBaseUrl() + apiEndpoint);
    url = url.replace('{1}', new AuthenticateHelper().GetToken());
    url = url.replace('{2}', payload.toString());
    return url;
  }


  postFile(apiEndpoint: string, payload: any): any {

    var formData = new FormData();
    formData.append("FileName", payload.FileName);
    formData.append("FileData", payload.FileData);
    formData.append("ID", payload.ID);              //ova e ako se povrzuva so HR Portal-ot
    //formData.append("ItemGUID", payload.ItemGUID ? payload.ItemGUID : "");    //ova e ako se povrzuva so drug SYSTEM 
    formData.append("GoogleFolderId", payload.GoogleFolderId ? payload.GoogleFolderId : ""); //samo za GOOGLE treba ako se pravi upload

    return axios.post(this._logHelper.GetBaseUrl() + apiEndpoint, formData, this.getFormFileOptions()).then((response: any) => {
      return response;
    }).catch((err) => {
      if (err.response) {
        let response = err.response;
        //401 Unauthorized is the status code to return when the client provides no credentials or invalid credentials. 
        //403 Forbidden is the status code to return when a client has valid credentials but not enough privileges to perform an action on a resource.Dec
        if (response.status === 401 || response.status === 403) {
          window.open(enumRoutes.Security_Logout, '_self');
          return;
        }
      }
      return err;
    })
  }

  postFile2(apiEndpoint: string, payload: any): any {

    var formData = new FormData();
    formData.append("FileData1", payload.FileData1);
    formData.append("FileData2", payload.FileData2);
    formData.append("FileData3", payload.FileData3);
    formData.append("etCheckID", payload.etCheckID);
    
    // formData.append("ID", payload.ID);              //ova e ako se povrzuva so HR Portal-ot
    // //formData.append("ItemGUID", payload.ItemGUID ? payload.ItemGUID : "");    //ova e ako se povrzuva so drug SYSTEM 
    // formData.append("GoogleFolderId", payload.GoogleFolderId ? payload.GoogleFolderId : ""); //samo za GOOGLE treba ako se pravi upload

    return axios.post(this._logHelper.GetBaseUrl() + apiEndpoint, formData, this.getFormFileOptions()).then((response: any) => {
      return response;
    }).catch((err) => {
      if (err.response) {
        let response = err.response;
        //401 Unauthorized is the status code to return when the client provides no credentials or invalid credentials. 
        //403 Forbidden is the status code to return when a client has valid credentials but not enough privileges to perform an action on a resource.Dec
        if (response.status === 401 || response.status === 403) {
          window.open(enumRoutes.Security_Logout, '_self');
          return;
        }
      }
      return err;
    })
  }


  downloadFile(apiEndpoint: string, payload: {}): any {
    return axios.get(this._logHelper.GetBaseUrl() + apiEndpoint + payload, this.getDownloadOptions()).then((response: any) => {
      return response;
    }).catch((err) => {
      if (err.response) {
        let response = err.response;
        //401 Unauthorized is the status code to return when the client provides no credentials or invalid credentials. 
        //403 Forbidden is the status code to return when a client has valid credentials but not enough privileges to perform an action on a resource.Dec
        if (response.status === 401 || response.status === 403) {
          window.open(enumRoutes.Security_Logout, '_self');
          return;
        }
      }
      return err;
    })
  }


  login(apiEndpoint: string, payload: {}): any {
    return axios.post(this._logHelper.GetBaseUrl() + apiEndpoint, payload, {}).then((response: any) => {
      return response;
    }).catch((err) => {
      return err;
    })
  }


  getOptions(): any {
    let options: any = { headers: {} };
    options.headers = { 'Content-Type': "application/json" }
    options.headers["Authorization"] = new AuthenticateHelper().GetTokenWithBarrier();
    return options;
  }

  getDownloadOptions(): any {
    let options: any = { headers: {} };
    //options.headers = { 'Content-Type': "application/json" }
    options['responseType'] = 'blob';
    options.headers["Authorization"] = new AuthenticateHelper().GetTokenWithBarrier();
    return options;
  }

  getFormFileOptions(): any {
    let options: any = { headers: {} };
    options.headers = { 'Content-Type': 'application/x-www-form-urlencoded' }
    options.headers["Authorization"] = new AuthenticateHelper().GetTokenWithBarrier();
    return options;
  }

}
