// @ts-check
import { useEffect } from "react";
import Typography from "@mui/material/Typography";
import MyText from "../../controls/MyText";
import MyDropDown from "../../controls/MyDropDown";

import { useState } from "react";
import MyAlert from "../../controls/MyAlert";
import {
  enumBreadcrumbPage,
  enumLookupTypes,
  enumPage,
  enumRoutes,
  enumSQLS
} from "../../helpers/common.constants";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material";
import { useParams, useLocation, useNavigate, Link } from "react-router-dom";
import EmployeeActions from "../../actions/EmployeeActions";
import MyDate from "../../controls/MyDate";
import moment from "moment";
import { MessageHelper } from "../../helpers/MessageHelper";
import MyCheckbox from "../../controls/MyCheckbox";
import EmployeeBreadcrumbs from "./EmployeeBreadcrumbs";
import AuthenticateHelper from "../../helpers/AuthenticateHelper";
import MyAutoSuggest from "../../controls/MyAutoSuggest";
import MyEmployeeActions from "../../actions/MyEmployeeActions";
import EmployeeHeaderButtons from "./EmployeeHeaderButtons";
import MyAutoSuggestMulti from "../../controls/MyAutoSuggestMulti";
import MyCheckboxConsentForm from "../../controls/MyCheckboxConsentForm";

const RecordDefault = {
  eyEmployeeID: 0,
  eyUserName: "",
  eyFirstName: "",
  eyLastName: "",
  eyEmail: "", //new Date().getTime() + "@spark-online.org",
  eyDepartmentID: 0,
  eyType: true,
  eyFunctionName: "",
  eyOfficeName: "",
  eyRemoved: false,
  eyPassword: "",
  eyBirthDate: moment(new Date(1900, 0, 1)).format("YYYY-MM-DD"),
  ReceiveNotifications: true,
  eyAddress: "",
  eyCity: "",
  eyCountry: "",
  eyMobPhone: "",
  eySkype: "",
  eyPrivateEmail: "",
  eyWorkPermit: moment(new Date(1900, 0, 1)).format("YYYY-MM-DD"),
  eyWorkPermit2: moment(new Date(1900, 0, 1)).format("YYYY-MM-DD"),
  eyDeclarationSupperiorID: 0,
  eyCountryID: 0,
  eyCountryOriginID: 0,
  eyPassportID: "",
  eyPlaceOfBirth: "",
  eyNationalityID: 0,
  eyDomicileID: 0,
  eyResidenceID: 0,
  eyHRPosition: 0,
  eyHRLevelOfPosition: 0,
  eyHRPosition2: "",
  eyOfficePayrollName: "",
  eyTerminateDate: moment(new Date(1900, 0, 1)).format("YYYY-MM-DD"),
  eyTerminateReason: "",
  eyFunctionID: 0,
  eyOfficeID: 0,
  eyItemGUID: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  eyCreatedBy: "",
  eyCreatedDate: moment(Date.now()).format("YYYY-MM-DD"),
  eySuperiorID: 0,
  eyActiveProfile: 0,
  SkipValidation: false,
  eyEmergencyName: "",
  eyEmergencyPhone: "",
  eyEmergencyRelationID: 0,
  eyEmergencyEmail: "",
  eyGoogleFolderID: "",
  eyConsentFL: false
  // ValidationMessages: []
};

export default function EmployeeManage(args) {
  //Page MAIN Hooks
  const [IsNewRecord, setIsNewRecord] = useState(true);
  const [IsProfileLocked, setIsProfileLocked] = useState(true);
  const [IsReadOnly, setReadOnly] = useState(false);
  const [MessageData, setMessageData] = useState({});
  const [Record, setRecord] = useState(RecordDefault);
  const [IsProfileRegistration, setIsProfileRegistration] = useState(false); //ako doaga od
  const [IsMyProfile, setIsMyProfile] = useState(false); //ako doaga od

  const [LookupData, setLookupData] = useState([]);
  const [SuperiorData, setSuperiorData] = useState([]);
  const [CountryData, setCountryData] = useState([]);
  const [YesNoData, setYesNo] = useState([]);

  //Da proveri dali e nov zapis ili postoecki
  const { EmployeeID } = useParams();

  //za Navigacija
  const navigate = useNavigate();

  //Za Refreshiranje na strana mu treba;
  const location = useLocation();

  //za poraki pri loadiranje na strana
  const msgHelper = new MessageHelper();

  //****************************************** */
  //HOOKS SECTION HERE
  //****************************************** */

  //1 --> Ke se povika ovoj HOOK samo dokolku se SMENI url-ot poradi toa na new dodavame Ticks() za da forcirame refresh na strana
  useEffect(() => {
    initData();
  }, [location.pathname + "/" + location.search]);

  //****************************************** */
  //METHOD  SECTION HERE
  //****************************************** */

  const initData = () => {
    async function fetchData() {
      setIsNewRecord(true); //DEFAULT
      setRecord(RecordDefault);
      setIsProfileLocked(false);
      setReadOnly(false);

      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      let responses = await new EmployeeActions().InitAsync(); // await LookupActions.InitAsync();
      if (!responses.error) {
        let lookupData = responses[0].data;
        //let countryData = responses[1].data;
        //let SuperiorsData = responses[2].data;

        setLookupData(lookupData);
        //setCountryData(countryData);

        //setSuperiorData(SuperiorsData);

        //Load Finished
        result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);

        //ako postoi Employee Loadiraj go
        if (EmployeeID) {
          await GetEmployeeProfile();
        } else {
          let isMyProfileLink = location.pathname.toLowerCase().toString().indexOf(enumRoutes.Main_MyProfile.toLowerCase()) > -1;
          if (isMyProfileLink) {
            await GetEmployeeProfile();
          }
        }

        //INDIKACIJA ZA NOV USER
        if (location.pathname === enumRoutes.Employee_Register) {
          //alert(location.pathname);
          setIsProfileRegistration(true);

          let authenticateHelper = new AuthenticateHelper();
          Record.eyFirstName = authenticateHelper.GetFirstName();
          Record.eyLastName = authenticateHelper.GetLastName();
          Record.eyUserName = authenticateHelper.GetUserName();
          Record.eyEmail = authenticateHelper.GetUserName();
          setRecord(Record);

          result = msgHelper.setPhase(
            enumPage.LoadSuccessWithMsg,
            null,
            "Please fullfill all your personal details and press SAVE, upon saving email will be sent to HR department!"
          );
          setMessageData(result);
        }
      } else {
        //Finished  with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }
    }

    fetchData();
  };

  async function GetEmployeeProfile() {
    setIsProfileLocked(false);
    setReadOnly(false);

    let result = msgHelper.setPhase(enumPage.LoadStart);
    setMessageData(result);

    let isMyProfileLink = location.pathname.toLowerCase().toString().indexOf(enumRoutes.Main_MyProfile.toLowerCase()) > -1;
    setIsMyProfile(isMyProfileLink);

    let responses;
    let payload = { eyEmployeeID: EmployeeID };
    if (!isMyProfileLink) {
      responses = await new EmployeeActions().EditAsync(payload); // await LookupActions.InitAsync();
    } else {
      responses = await new MyEmployeeActions().GetMyProfileAsync(); // await LookupActions.InitAsync();
      //setReadOnly(true);
    }

    if (!responses.error) {
      let recordDb = responses[0].data;
      setRecord(recordDb);

      if (recordDb.eyRemoved) {
        result = msgHelper.setPhase(
          enumPage.LoadSuccessWithWarningMsg,
          null,
          "Profile is not active!"
        );
        setMessageData(result);
        setIsProfileLocked(true);
      } else {
        //Load Finished
        result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);
      }

      setIsNewRecord(false);
    } else {
      //Finished  with Error
      result = msgHelper.setPhase(enumPage.ErrorServer, responses);
      setMessageData(result);
    }
  }

  const saveData = () => {
    async function fetchData() {
      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      let response = null;

      //Ako e nov profil doaga od REGISTER tag-ot togas dozvoli da kreira nov EMPLOYEE
      if (IsProfileRegistration) {
        response = await new MyEmployeeActions().RegisterAsync(Record);
      }
      else if (IsMyProfile) {
        response = await new MyEmployeeActions().UpdateAsync(Record);
      }
       else {
        response = await new EmployeeActions().SaveAsync(Record);
      }

      if (!response.error) {
        let recordDb = response[0].data;
        setRecord(recordDb);

        if (recordDb.eyRemoved) {
          result = msgHelper.setPhase(
            enumPage.LoadSuccessWithWarningMsg,
            null,
            "Profile is set as not active!"
          );
          setMessageData(result);
          setIsProfileLocked(true);
          setIsNewRecord(false);
        } else if (IsProfileRegistration) {
          result = msgHelper.setPhase(
            enumPage.LoadSuccessWithMsg,
            null,
            "Thank you for your registration. Email is sent to your account and  HR Department, now they will create Contract record for you and you can further use the system!"
          );
          setMessageData(result);
        } else {
          //Load Finished
          result = msgHelper.setPhase(enumPage.SaveSuccess);
          setMessageData(result);
          setRecord((a) => ({
            ...a,
            ValidationMessages: result.ValidationMessages,
          })); //Ako ima Validation Messages da gi izbrise
          setIsNewRecord(false);
        }
      } else {
        //Finished  with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, response);
        setMessageData(result);
        setRecord((a) => ({
          ...a,
          ValidationMessages: result.ValidationMessages,
        })); //Samo ovdeka mu treba ako ima Validacioni greski da dodade
      }
    }

    fetchData();
  };
 

  const activateData = () => {
    async function fetchData() {
      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      Record.eyRemoved = false;
      Record.SkipValidation = true;

      let response = await new EmployeeActions().SaveAsync(Record);
      if (!response.error) {
        let recordDb = response[0].data;
        setRecord(recordDb);

        //Load Finished
        result = msgHelper.setPhase(
          enumPage.LoadSuccessWithWarningMsg,
          null,
          "Profile is actived again and email is sent to HR team!"
        );
        setMessageData(result);

        setIsProfileLocked(false);
        setIsNewRecord(false);
      } else {
        //Finished  with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, response);
        setMessageData(result);
        setRecord((a) => ({
          ...a,
          ValidationMessages: result.ValidationMessages,
        })); //Samo ovdeka mu treba ako ima Validacioni greski da dodade
      }
    }

    fetchData();
  };

  //****************************************** */
  //HANDLERS SECTION HERE
  //****************************************** */
  const handleChange = (e) => {
    const { name, value } = e.target;

    console.log(name + ":" + value);
    setRecord((prevState) => ({ ...prevState, [name]: value }));
  };

  //****************************************** */
  //GUI DISPLAY
  //****************************************** */
  const ActionButtons = () => {
    return (
      <Grid container align="right" padding={1}>
        <Grid item xs={12}>
          {IsProfileRegistration && !IsMyProfile && (
            <Button
              name="btnCancel"
              variant="contained"
              sx={{ mr: 3 }}
              color="warning"
              size="small"
              disabled={MessageData.Loading}
              onClick={() => navigate(enumRoutes.Security_Logout)}
            >
              Cancel
            </Button>
          )}
          {!IsProfileLocked && (
            <Button
              name="btnSave"
              variant="contained"
              sx={{ mr: 1 }}
              color="success"
              size="small"
              disabled={MessageData.Loading}
              onClick={() => saveData()}
            >
              Save
            </Button>
          )}
          {IsProfileLocked && (
            <Button
              name="btnSave"
              variant="contained"
              sx={{ mr: 1 }}
              color="warning"
              size="small"
              disabled={MessageData.Loading}
              onClick={() => activateData()}
            >
              Activate
            </Button>
          )}
        </Grid>
      </Grid>
    );
  };

  // const HeaderButtons = () => {
  //   return (
  //     <Grid
  //       container
  //       align="left"
  //       sx={{ borderBottom: 1, borderColor: "grey.500", pb: 1 }}
  //       align="center"
  //     >
  //       {!IsNewRecord && (
  //         <Grid item xs={12} spacing={1}>
  //           <Button
  //             sx={{ mr: 2 }}
  //             variant="contained"
  //             startIcon={<NotesIcon />}
  //             size="small"
  //             disabled={MessageData.Loading || IsNewRecord}
  //             onClick={() =>
  //               navigate(
  //                 enumRoutes.Employee_Contract_Get.replace(
  //                   ":EmployeeID",
  //                   Record.eyEmployeeID
  //                 )
  //               )
  //             }
  //           >
  //             Manage Contracts
  //           </Button>

  //           <Button
  //             sx={{ mr: 2 }}
  //             variant="contained"
  //             startIcon={<NotesIcon />}
  //             size="small"
  //             disabled={MessageData.Loading || IsNewRecord}
  //             onClick={() =>
  //               navigate(
  //                 enumRoutes.Employee_Documents.replace(
  //                   ":EmployeeID",
  //                   Record.eyEmployeeID
  //                 ).replace(":InputFolderID", Record.eyGoogleFolderID)
  //               )
  //             }
  //           >
  //             Manage Documents
  //           </Button>
  //           <Button
  //             sx={{ mr: 2 }}
  //             variant="contained"
  //             startIcon={<NotesIcon />}
  //             size="small"
  //             disabled={MessageData.Loading || IsNewRecord}
  //             onClick={() => exportEmployeeLeaveRequestForAllYears()}
  //           >
  //             Download Leave Request Overview
  //           </Button>
            
  //           <Button
  //             sx={{ mr: 2 }}
  //             variant="contained"
  //             startIcon={<NotesIcon />}
  //             size="small"
  //             disabled={MessageData.Loading || IsNewRecord}
  //             onClick={() =>
  //               navigate(
  //                 enumRoutes.Employee_CheckList.replace(
  //                   ":EmployeeID",
  //                   Record.eyEmployeeID
  //                 )
  //               )
  //             }
  //           >
  //             Employee check list
  //           </Button>
  //         </Grid>
  //       )}
  //     </Grid>
  //   );
  // };

  return (
    <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
      {!IsReadOnly && !IsProfileRegistration && !IsMyProfile && (
        <EmployeeBreadcrumbs
          page={enumBreadcrumbPage.Master}
          Id={Record.eyEmployeeID}
        />
      )}

      <MyAlert {...MessageData} />

      {!IsProfileRegistration && !IsMyProfile && <EmployeeHeaderButtons />}

      <ActionButtons />

      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5">General Info</Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <MyText
            Id="eyFirstName"
            PlaceHolder="First Name"
            Record={Record}
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            Required={true}
            Disabled={
              IsProfileLocked ||
              !IsNewRecord ||
              IsProfileRegistration ||
              IsReadOnly
            }
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <MyText
            Id="eyLastName"
            PlaceHolder="Last  Name"
            Record={Record}
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            Required={true}
            Disabled={
              IsProfileLocked ||
              !IsNewRecord ||
              IsProfileRegistration ||
              IsReadOnly
            }
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <MyText
            Id="eyEmail"
            PlaceHolder="Email (with @spark-online.org)"
            Record={Record}
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            Required={true}
            Disabled={
              IsProfileLocked ||
              !IsNewRecord ||
              IsProfileRegistration ||
              IsReadOnly
            }
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <MyText
            Id="eyPrivateEmail"
            PlaceHolder="Private Email"
            Record={Record}
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            Required={true}
            Disabled={IsProfileLocked || IsReadOnly}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <MyText
            Id="eyMobPhone"
            PlaceHolder="Mobile Number"
            Record={Record}
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            Required={true}
            
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <MyDate
            Id="eyBirthDate"
            PlaceHolder="Birth Date"
            Record={Record}
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            Required={true}
            Disabled={IsProfileLocked || IsReadOnly}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <MyText
            Id="eyAddress"
            PlaceHolder="Address"
            Record={Record}
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            Required={true}
            Disabled={IsProfileLocked || IsReadOnly}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <MyText
            Id="eyPassword"
            PlaceHolder="Zip Code"
            Record={Record}
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            Required={true}
            Disabled={IsProfileLocked || IsReadOnly}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <MyText
            Id="eyCity"
            PlaceHolder="City"
            Record={Record}
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            Required={true}
            Disabled={IsProfileLocked || IsReadOnly}
          />
        </Grid>
      </Grid>


{/* 
      <Grid container>
        <Grid item xs={12}>
          <MyAutoSuggestMulti
            Id="eyCountryID"
            Record={Record}
            PlaceHolder="ZONE"
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            Required={true}
            SQLGetList={enumSQLS.KEY_LIST_CountrySearch}
            SQLGetRecord={enumSQLS.KEY_GET_Country}
            Disabled={IsProfileLocked || IsReadOnly}
          />
        </Grid>
      </Grid> */}

      <Grid container>
        <Grid item xs={12}>
          <MyAutoSuggest
            Id="eyCountryID"
            Record={Record}
            PlaceHolder="Country"
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            Required={true}
            SQLGetList={enumSQLS.KEY_LIST_CountrySearch}
            SQLGetRecord={enumSQLS.KEY_GET_Country}
            Disabled={IsProfileLocked || IsReadOnly}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5">HR Info</Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <MyAutoSuggest
            Id="eyNationalityID"
            Record={Record}
            PlaceHolder="Nationality"
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            Required={true}
            SQLGetList={enumSQLS.KEY_LIST_CountrySearch}
            SQLGetRecord={enumSQLS.KEY_GET_Country}
            Disabled={IsProfileLocked || IsReadOnly}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <MyAutoSuggest
            Id="eyCountryOriginID"
            Record={Record}
            PlaceHolder="Second Nationality"
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            Required={true}
            SQLGetList={enumSQLS.KEY_LIST_CountrySearch}
            SQLGetRecord={enumSQLS.KEY_GET_Country}
            Disabled={IsProfileLocked || IsReadOnly}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <MyDate
            Id="eyWorkPermit"
            PlaceHolder="Expire Date Visa"
            Record={Record}
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            Required={true}
            Disabled={IsProfileLocked || IsReadOnly}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <MyDate
            Id="eyWorkPermit2"
            PlaceHolder="Expire Date Work Permit"
            Record={Record}
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            Required={true}
            Disabled={IsProfileLocked || IsReadOnly}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <MyAutoSuggest
            Id="eyDomicileID"
            Record={Record}
            PlaceHolder="Domicile"
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            Required={true}
            SQLGetList={enumSQLS.KEY_LIST_CountrySearch}
            SQLGetRecord={enumSQLS.KEY_GET_Country}
            Required={true}
            Disabled={IsProfileLocked || IsReadOnly}
          />
        </Grid>
      </Grid>

      {!IsMyProfile && (
        <Grid container>
          <Grid item xs={12}>
            <MyText
              Id="eyHRPosition2"
              PlaceHolder="Function Title"
              Record={Record}
              HandleChange={handleChange}
              ValidationMessages={Record.ValidationMessages}
              Required={true}
              Disabled={IsProfileLocked || IsReadOnly}
            />
          </Grid>
        </Grid>
      )}

      {!IsMyProfile && (
        <Grid container>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h5">Organizational Info</Typography>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <MyDropDown
                Id="eyOfficeID"
                Record={Record}
                PlaceHolder="Office"
                HandleChange={handleChange}
                ValidationMessages={Record.ValidationMessages}
                Options={LookupData}
                LookupTypeId={enumLookupTypes.Offices}
                Required={true}
                Disabled={IsProfileLocked || IsReadOnly}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <MyAutoSuggest
                Id="eySuperiorID"
                Record={Record}
                PlaceHolder="Superior"
                HandleChange={handleChange}
                ValidationMessages={Record.ValidationMessages}
                Required={true}
                SQLGetList={enumSQLS.KEY_LIST_EmployeeSearch}
                SQLGetRecord={enumSQLS.KEY_GET_Employee}
                Disabled={IsProfileLocked || IsReadOnly}
              />
            </Grid>
          </Grid> 
        </Grid>
      )}

      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5">Emergency Info</Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <MyText
            Id="eyEmergencyName"
            PlaceHolder="Emergency Name"
            Record={Record}
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            Required={true}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <MyText
            Id="eyEmergencyPhone"
            PlaceHolder="Emergency Phone Number"
            Record={Record}
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            Required={true}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <MyDropDown
            Id="eyEmergencyRelationID"
            Record={Record}
            PlaceHolder="Relation to employee"
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            Options={LookupData}
            LookupTypeId={enumLookupTypes.EmergencyRelation}
            Required={true}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <MyText
            Id="eyEmergencyEmail"
            PlaceHolder="Emergency Email "
            Record={Record}
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            Required={true}
          />
        </Grid>

        <Grid container>
            <Grid item xs={12} align="left">
              <MyCheckboxConsentForm
                Id="eyConsentFL"
                Record={Record}
                PlaceHolder="I agree to the collection and processing of my personal data by SPARK"
                HandleChange={handleChange}
                Required={true}
                Disabled={IsProfileLocked || IsReadOnly}
                ValidationMessages={Record.ValidationMessages}
                
              />

            </Grid>
          </Grid>
      </Grid>

      {!IsNewRecord && !IsMyProfile && (
        <Grid container>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h5">Termination Info</Typography>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12} align="left">
              <MyCheckbox
                Id="eyRemoved"
                Record={Record}
                PlaceHolder="Deactivate"
                HandleChange={handleChange}
                Required={true}
                Disabled={IsProfileLocked || IsReadOnly}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <MyDate
                Id="eyTerminateDate"
                PlaceHolder="Terminate Date"
                Record={Record}
                HandleChange={handleChange}
                ValidationMessages={Record.ValidationMessages}
                Required={true}
                Disabled={IsProfileLocked || IsReadOnly}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={12}>
              <MyText
                Id="eyTerminateReason"
                MultiLine={true}
                Rows={3}
                PlaceHolder="Terminate Reason"
                Record={Record}
                HandleChange={handleChange}
                ValidationMessages={Record.ValidationMessages}
                Required={true}
                Disabled={IsProfileLocked || IsReadOnly}
              />
            </Grid>
          </Grid>
        </Grid>
      )}

      <ActionButtons />

      <MyAlert {...MessageData} />
    </Typography>
  );
}
