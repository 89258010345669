// @ts-check
import { useEffect } from "react";
import Typography from "@mui/material/Typography";
import MyText from "../../controls/MyText";

import { useState } from "react";
import MyAlert from "../../controls/MyAlert";
import { enumRoutes } from "../../helpers/common.constants";
import Button from "@mui/material/Button";
import { Avatar, Grid } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AuthenticateActions from "../../actions/AuthenticateActions";
import AuthenticateHelper from "../../helpers/AuthenticateHelper";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import MicrosoftLogin from "react-microsoft-login";

const RecordDefault = {
  Username: "hr@spark-online.org",
  Password: "Acquisition22@@@22!",
};

export default function ExactonlineTokenReply(args) {
  const [Record, setRecord] = useState(RecordDefault);
  const [MessageData, setMessageData] = useState({});
  const [msalInstance, onMsalInstanceChange] = useState();
  const navigate = useNavigate();

  //Za Refreshiranje na strana mu treba;
  const location = useLocation();

  
  useEffect(() => {

    alert(location.search);
  }, [location.pathname + '/' + location.search]);

  
  return (
    <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="center">
      <MyAlert {...MessageData} />

      <Grid container align="center">
        <Grid item xs={12}>
          <Typography variant="h6" display="block">
            Token Received '{location.search.replace("?code=", "")}', please check your Email!
          </Typography>
        </Grid>
      </Grid>
    </Typography>
  );
}
