// @ts-check
import { useEffect } from "react";
import Typography from "@mui/material/Typography";

import { useState } from "react";
import MyAlert from "../../controls/MyAlert";
import {
  enumBreadcrumbPage, enumPage
} from "../../helpers/common.constants";
import { Grid } from "@mui/material";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import EmployeeActions from "../../actions/EmployeeActions";
import moment from "moment";
import { MessageHelper } from "../../helpers/MessageHelper";
import EmployeeBreadcrumbs from "./EmployeeBreadcrumbs";
import DocumentSearch from "../Document/DocumentSearch";
import EmployeeHeaderButtons from "./EmployeeHeaderButtons";

const RecordDefault = {
  eyEmployeeID: 0,
  eyUserName: "",
  eyFirstName: "",
  eyLastName: "",
  eyEmail: new Date().getTime() + "@spark-online.org",
  eyDepartmentID: 0,
  eyType: true,
  eyFunctionName: "1183",
  eyOfficeName: "",
  eyRemoved: false,
  eyPassword: "",
  eyBirthDate: moment(new Date(1900, 0, 1)).format("YYYY-MM-DD"),
  ReceiveNotifications: true,
  eyAddress: "",
  eyCity: "",
  eyCountry: "",
  eyMobPhone: "",
  eySkype: "",
  eyPrivateEmail: "",
  eyWorkPermit: moment(new Date(1900, 0, 1)).format("YYYY-MM-DD"),
  eyWorkPermit2: moment(new Date(1900, 0, 1)).format("YYYY-MM-DD"),
  eyDeclarationSupperiorID: 2,
  eyCountryID: 0,
  eyCountryOriginID: 0,
  eyPassportID: "",
  eyPlaceOfBirth: "",
  eyNationalityID: 0,
  eyDomicileID: 0,
  eyResidenceID: 0,
  eyHRPosition: 0,
  eyHRLevelOfPosition: 0,
  eyHRPosition2: "",
  eyOfficePayrollName: "",
  eyTerminateDate: moment(new Date(1900, 0, 1)).format("YYYY-MM-DD"),
  eyTerminateReason: "",
  eyFunctionID: 0,
  eyOfficeID: 0,
  eyItemGUID: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  eyCreatedBy: "",
  eyCreatedDate: moment(Date.now()).format("YYYY-MM-DD"),
  eySuperiorID: 0,
  eyActiveProfile: 0,
  SkipValidation: false,
  eyEmergencyName: "",
  eyEmergencyPhone: "",
  eyEmergencyRelationID: 0,
  eyEmergencyEmail: "",
  eyGoogleFolderID: ""
  // ValidationMessages: []
};

export default function EmployeeDocuments(args) {
  //Page MAIN Hooks
  const [MessageData, setMessageData] = useState({});
  const [Record, setRecord] = useState(RecordDefault);

  //Da proveri dali e nov zapis ili postoecki
  const { EmployeeID } = useParams();

  //za Navigacija
  const navigate = useNavigate();

  //Za Refreshiranje na strana mu treba;
  const location = useLocation();

  //za poraki pri loadiranje na strana
  const msgHelper = new MessageHelper();

  //****************************************** */
  //HOOKS SECTION HERE
  //****************************************** */

  //1 --> Ke se povika ovoj HOOK samo dokolku se SMENI url-ot poradi toa na new dodavame Ticks() za da forcirame refresh na strana
  useEffect(() => {
    initData();
  }, [location.pathname + "/" + location.search]);

  //****************************************** */
  //METHOD  SECTION HERE
  //****************************************** */

  const initData = () => {
    async function fetchData() {
      setRecord(RecordDefault);

      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      if (EmployeeID) {
        await GetEmployeeProfile();
      }
    }

    fetchData();
  };

  async function GetEmployeeProfile() {

    let result = msgHelper.setPhase(enumPage.LoadStart);
    setMessageData(result);


    let payload = { eyEmployeeID: EmployeeID };
    let responses = await new EmployeeActions().EditAsync(payload);

    if (!responses.error) {
      let recordDb = responses[0].data;
      setRecord(recordDb);

      //Load Finished
      result = msgHelper.setPhase(enumPage.LoadSuccess);
      setMessageData(result);
    } else {
      //Finished  with Error
      result = msgHelper.setPhase(enumPage.ErrorServer, responses);
      setMessageData(result);
    }
  }





  //****************************************** */
  //HANDLERS SECTION HERE
  //****************************************** */
  const handleChange = (e) => {
    const { name, value } = e.target;

    console.log(name + ":" + value);
    setRecord((prevState) => ({ ...prevState, [name]: value }));
  };


  return (
    <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">

      <Grid container align="center">
        <Grid item xs={12}>
          <Typography variant="h3" display="block">{Record.eyFullName}</Typography>
        </Grid>
      </Grid>

      <EmployeeBreadcrumbs
        page={enumBreadcrumbPage.Documents}
        Id={Record.eyEmployeeID}
      />

      <MyAlert {...MessageData} />

      <EmployeeHeaderButtons />

      {Record.eyGoogleFolderID !== "" && <DocumentSearch InputFolderID={Record.eyGoogleFolderID} />}


      <MyAlert {...MessageData} />
    </Typography>
  );
}
