// @ts-check
import * as React from "react";
import Typography from "@mui/material/Typography";
import MyText from "../../controls/MyText";
import { useEffect, useState } from "react";
import MyAlert from "../../controls/MyAlert";
import {
  enumBreadcrumbPage,
  enumData,
  enumLocalStorageArgs,
  enumLookupTypes,
  enumPage,
  enumPagination,
  enumRoutes,
  enumSharedMsgs
} from "../../helpers/common.constants";
import EmployeeActions from "../../actions/EmployeeActions";
import Button from "@mui/material/Button";
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow, TextField, Tooltip
} from "@mui/material";
import LogHelper from "../../helpers/LogHelper";
import { useLocation, useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import EmployeeBreadcrumbs from "./EmployeeBreadcrumbs";
import MyDropDown from "../../controls/MyDropDown";
import Link from "@mui/material/Link";
import { MessageHelper } from "../../helpers/MessageHelper";
import LocalStorageHelper from "../../helpers/LocalStorageHelper";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import DownloadActions from "../../actions/DownloadActions";
import MyDialogYesNo from "../../controls/MyDialogYesNo";
import DeleteIcon from "@mui/icons-material/Delete";

const RecordDefault = {
  OfficeID: 0,
  CountryIDs: "",
  Name: "",
  PhaseIDs: "",
  ActiveFL: "1",
  TypeOfContractID: 0,
  ExpirationContractDays: 0,

  //Pagination
  PageIndex: 0,
  PageSize: enumPagination.PageSizeLow,
  TotalRecords: 0,
  TotalPages: 0,
  Year: 2023
};

export default function TransferHoursSearch() {
  const [Record, setRecord] = useState(RecordDefault);
  const [MessageData, setMessageData] = useState({});
  const [Records, setRecords] = useState([]);
  const [IsInitDone, setIsInitDone] = useState(false);
  const [IsPaginationChange, setPaginationChange] = useState(false);
  const [IsDoSearch, setIsDoSearch] = useState(false);
  const [YearsData, setYearsData] = useState(enumData.YEARS);

  const [IsModalOpen, setIsModalOpen] = useState(false);
  const [LookupData, setLookupData] = useState([]);
  const [RecordDelete, setRecordDelete] = useState(RecordDefault);

  //Za Refreshiranje na strana mu treba;
  const location = useLocation();

  //za poraki pri loadiranje na strana
  const msgHelper = new MessageHelper();

  //****************************************** */
  //HOOKS SECTION HERE
  //****************************************** */

  //1 --> Ke se povika ovoj HOOK samo dokolku se SMENI url-ot poradi toa na new dodavame Ticks() za da forcirame refresh na strana na sekoj click
  useEffect(() => { initData(); }, [location.pathname + "/" + location.search]);

  //2 --> Od koga e sredena InitData() povikaj Search  (https://stackoverflow.com/questions/54069253/the-usestate-set-method-is-not-reflecting-a-change-immediately)
  useEffect(() => { if (IsInitDone) { searchData(); } }, [IsInitDone]);

  // //3 --> Pagination Change 
  useEffect(() => { if (IsPaginationChange) { searchData(); } }, [IsPaginationChange]);

  //4 --> Search Click
  useEffect(() => { if (IsDoSearch) { searchData(); } }, [IsDoSearch]);


  //****************************************** */
  //METHOD  SECTION HERE
  //****************************************** */

  const initData = () => {
    async function fetchData() {
      //Clean all states
      setIsInitDone(false);
      setRecord(RecordDefault);

      //Proveri dali slucajno ima vo Local Storage definirani Search Arguments
      let RecordFromStorage = new LocalStorageHelper().GetJSON(enumLocalStorageArgs.TransferHoursSearch);
      if (RecordFromStorage) {
        RecordFromStorage.PageIndex = 0; //sekogas prviot page pokazi go 
        setRecord(RecordFromStorage);
      }

      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      let responses = await new EmployeeActions().InitAsync(); // await LookupActions.InitAsync();
      if (!responses.error) {
        let lookupData = responses[0].data;
        let yesNoAllData = responses[1].data;
        let contractMonthData = responses[3].data;
        //let yearsData = responses[4].data;

        setLookupData(lookupData);

        //setYearsData(yearsData);
        setIsInitDone(true);

        //Load Finished
        result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);
      } else {
        //Load with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }
    }
    fetchData();
  };

  const searchData = () => {
    searchDataAsync();
  };

  async function searchDataAsync() {
    //Load Start
    setRecords([]);
    let result = msgHelper.setPhase(enumPage.LoadStart);
    setMessageData(result);

    let responses = await new EmployeeActions().TransferHoursSearchAsync(Record);
    if (!responses.error) {
      const records = responses[0].data;

      //For Pagination purposes
      const pageIndex = responses[0].headers["content-pageindex"];
      const totalRecords = responses[0].headers["content-totalrecords"];
      const totalPages = responses[0].headers["content-totalpages"];
      setRecord((a) => ({ ...a, ["PageIndex"]: pageIndex, ["TotalRecords"]: totalRecords, ["TotalPages"]: totalPages }));
      setPaginationChange(false); //za da se pali na sleden page
      setIsDoSearch(false);       //za da se pali na Search Click


      setRecords(records);

      //setiraj JSON vo Local Storage za da citas posle od tamu na sleden comeback 
      new LocalStorageHelper().SetJSON(enumLocalStorageArgs.TransferHoursSearch, Record);


      //Load Finished
      result = msgHelper.setPhase(enumPage.LoadSuccess);
      setMessageData(result);
    } else {
      //Load with Error
      result = msgHelper.setPhase(enumPage.ErrorServer, responses);
      setMessageData(result);
    }
  }


  const saveData = () => {
    async function fetchData() {
      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStartWithMsg, null, "This process could take a while, please wait!");
      setMessageData(result);

      let response = await new EmployeeActions().TransferHoursSaveAsync(Records);
      if (!response.error) {

        await searchDataAsync();

        result = msgHelper.setPhase(enumPage.LoadSuccessWithMsg, null, "Transfer hours finished!");
        setMessageData(result);

      } else {
        //Finished  with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, response);
        setMessageData(result);
        setRecord((a) => ({
          ...a,
          ValidationMessages: result.ValidationMessages,
        })); //Samo ovdeka mu treba ako ima Validacioni greski da dodade
      }
    }

    fetchData();
  };



  const handleDelete = (record) => { //Povikuva onDelete(record);
    setRecordDelete(record);
    setIsModalOpen(true); //Otvori popup
  };


  const handleModalClose = (doDelete) => {
    if (doDelete) {
      async function fetchData() {
        //Load Start
        let result = msgHelper.setPhase(enumPage.LoadStartWithMsg, null, "This process could take a while, please wait!");
        setMessageData(result);


        let response = await new EmployeeActions().TransferHoursDeleteAsync(RecordDelete);
        if (!response.error) {

          await searchDataAsync();

          result = msgHelper.setPhase(enumPage.LoadSuccessWithMsg, null, "Transfer hours deleted!");
          setMessageData(result);

        } else {
          //Finished  with Error
          result = msgHelper.setPhase(enumPage.ErrorServer, response);
          setMessageData(result);
          setRecord((a) => ({
            ...a,
            ValidationMessages: result.ValidationMessages,
          })); //Samo ovdeka mu treba ako ima Validacioni greski da dodade
        }
      }
      fetchData();
    }
    setIsModalOpen(false);
  };

  const handleSearch = (e) => {
    setRecord((a) => ({ ...a, ["PageIndex"]: 0 }));
    setIsDoSearch(true);
  };




  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name + ":" + value);
    setRecord((prevState) => ({ ...prevState, [name]: value }));
  };


  const handleChangeTransfer = (e) => {


    const { name, value } = e.target; //vo name-ot go imame proektot
    console.error(name + ":" + value);
    //setRecord((prevState) => ({ ...prevState, [name]: value }));

    let EmployeeId = name.replace("txt_", ""); //za da moze da menuvam
    let propertyName = "eyTransferedHoursInNY";

    //najdi go od nizata samiot record i updatetiraj go
    let newRecords = [...Records]; //Records.map( x => ({...x}) )
    let currentCheck = newRecords.find((a) => a.eyEmployeeID.toString() === EmployeeId.toString());

    currentCheck[propertyName] = value;
    currentCheck["IsChanged"] = true;


    //za vo koj red od nizata da go setira so findInex
    let propertyIndex = newRecords.findIndex((x) => x.eyEmployeeID.toString() === EmployeeId.toString());
    newRecords[propertyIndex] = currentCheck;
    setRecords(newRecords);
  };


  return (
    <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
      <MyAlert {...MessageData} />

      <EmployeeBreadcrumbs
        page={enumBreadcrumbPage.Search}
        disabled={MessageData.Loading}
      />

      <Card sx={{ minWidth: 275, borderRadius: 2, mb: 2 }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={2}>
              <MyDropDown
                Id="Year"
                Record={Record}
                PlaceHolder="Select Year for Transfer"
                HandleChange={handleChange}
                Options={YearsData}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={2}>

              <MyDropDown
                Id="OfficeID"
                Record={Record}
                PlaceHolder="Office"
                HandleChange={handleChange}
                Options={LookupData}
                LookupTypeId={enumLookupTypes.Offices}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <MyText
                Id="Name"
                PlaceHolder="Name or Email"
                Record={Record}
                HandleChange={handleChange}
                ValidationMessages={Record.ValidationMessages}
                Required={true}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <Button
                sx={{ mt: 1 }}
                variant="contained"
                color="success"
                size="small"
                disabled={MessageData.Loading}
                onClick={() => handleSearch()}
              >
                Search
              </Button>

            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="subtitle1" align="left">
                Total Records found: {Record.TotalRecords}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <RenderTable Records={Records} Record={Record} onHandleChange={handleChangeTransfer} onHandleSave={saveData} onHandleDelete={handleDelete} MessageData={MessageData} />
      <MyDialogYesNo IsOpen={IsModalOpen} HandleModalClose={handleModalClose} Title={enumSharedMsgs.WariningMsg} Content={enumSharedMsgs.DeleteAreYouSure}
      />
    </Typography>
  );
}

const RenderTable = ({ Records, Record, onHandleChange, onHandleSave, onHandleDelete, MessageData }) => { //Record = Pagination
  const navigate = useNavigate();

  return React.useMemo(() => {
    return (
      <TableContainer sx={{ display: { xs: "block", sm: "block", md: "block" } }}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="h4" align="center">
                  Employee Name
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h4" align="center">
                  Email
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h4" align="center">
                  Office Name
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h4" align="center">
                  Total Current Hours for {Record.Year}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h4" align="center">
                  WriteOff Hours
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h4" align="center">
                  Transfered Hours in for {Record.Year + 1}
                </Typography>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>

            {Records.length > 0 &&
              <TableRow>
                <TableCell colSpan={6} align="right">
                  <Button
                    sx={{ mt: 1 }}
                    variant="contained"
                    color="success"
                    size="small"
                    disabled={MessageData.Loading}
                    onClick={() => onHandleSave()}
                  >
                    Save All
                  </Button>

                </TableCell>
              </TableRow>
            }
          </TableHead>
          <TableBody>
            {Records.map((row, index) => (
              <RenderRow row={row} index={index} onHandleChange={onHandleChange} onHandleDelete={onHandleDelete} MessageData={MessageData} />
            ))}
          </TableBody>


          {/* PAGINATION FOOTER HERE */}
          {Records.length > 0 &&
            <TableFooter>
              <TableRow>

                <TableCell colSpan={6} align="right">
                  <Button
                    sx={{ mt: 1 }}
                    variant="contained"
                    color="success"
                    size="small"
                    disabled={MessageData.Loading}
                    onClick={() => onHandleSave()}
                  >
                    Save All
                  </Button>

                </TableCell>
                <TableCell>

                </TableCell>
                <TableCell>

                </TableCell>
              </TableRow>
            </TableFooter>
          }

        </Table>
      </TableContainer>
    );
  }, [Records]);
};

const RenderRow = ({ row, index, onHandleChange, onHandleDelete, MessageData }) => { //Record = Pagination

  return React.useMemo(() => {
    return (
      <TableRow
        key={row.eyEmployeeID}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell>
          <Link
            href={enumRoutes.Employee_Edit.replace(
              ":EmployeeID",
              row.eyEmployeeID
            )}
            underline="none"
            rel="noopener noreferrer"
          >
            {row.eyFirstName} {row.eyLastName}
          </Link>
        </TableCell>
        <TableCell>{row.eyEmail}</TableCell>

        <TableCell>{row.eyOfficeName}</TableCell>

        <TableCell align="right">{row.eyBalanceHours}</TableCell>
        <TableCell align="right">{row.eyWriteOffHours}</TableCell>
        <TableCell align="right">
          <TextField
            type='text'
            //size={size = 'small' ? 'small' : 'medium'}
            size='small'
            variant={"outlined"}
            margin="dense" //normal
            fullWidth
            id={"txt_" + row.eyEmployeeID}
            name={"txt_" + row.eyEmployeeID}
            //helperText={args.Help}
            onChange={onHandleChange}
            value={row.eyTransferedHoursInNY}
            sx={{ input: { textAlign: 'right' } }}
          />
        </TableCell>

        <TableCell align="center">
          <Tooltip title={"Delete Transfered Hours for " + row.eyFirstName + " " + row.eyLastName}>
            <IconButton
              size="small"
              name="details"
              disabled={MessageData.Loading}
              onClick={() => onHandleDelete(row)}
            >
              <DeleteIcon sx={{ fontSize: 15 }} color="error" />
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
    );
  }, [row.eyTransferedHoursInNY]);
};