import { ValidatorHelper } from "../helpers/ValidatorHelper";
import { FinReportsService } from "../services/FinReportsService";

export default class FinReportsActions {
  
  async ExportEmployeeAllocation(Record: any) {
    //napravi validacija
    let response = await new FinReportsService().ExportEmployeeAllocation(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }
}
