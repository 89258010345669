// @ts-check
import { useEffect } from "react";
import Typography from "@mui/material/Typography";
import MyText from "../../controls/MyText";
import { useState } from "react";
import MyAlert from "../../controls/MyAlert";
import {
  enumBreadcrumbPage,
  enumPage,
  enumSQLS,
} from "../../helpers/common.constants";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import MyDate from "../../controls/MyDate";
import moment from "moment";
import { MessageHelper } from "../../helpers/MessageHelper";
import VacancyBreadcrumbs from "./VacancyBreadcrumbs";
import MyAutoSuggest from "../../controls/MyAutoSuggest";
import VacancyActions from "../../actions/VacancyActions";
import MyDateInput from "../../controls/MyDateInput";
import MyDate2 from "../../controls/MyDate2";
import MyCheckList from "../../controls/MyCheckList";

import { EditorState, ContentState, convertFromHTML } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertToHTML } from "draft-convert";
import DOMPurify from "dompurify";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import MyEditor from "../../controls/MyEditor";

const RecordDefault = {
  vaID: 0,
  vaName: "",
  vaDescription: "",
  //vaItemGUID: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  vaCountryIDs: "",
  vaFrom: moment(new Date()).format("YYYY-MM-DD"),
  vaTo: moment(new Date()).format("YYYY-MM-DD"),
  vaText:
    "<p>Ova e bold <strong>boldirano</strong>, a ova e <i>italizirano</i>.</p></br> <p>A ova e nov red<p/>",
};

export default function VacancyManage(args) {
  //Page MAIN Hooks
  const [IsNewRecord, setIsNewRecord] = useState(true);
  const [IsProfileLocked, setIsProfileLocked] = useState(true);
  const [IsReadOnly, setReadOnly] = useState(false);
  const [MessageData, setMessageData] = useState({});
  const [Record, setRecord] = useState(RecordDefault);
  const [IsProfileRegistration, setProfileRegistration] = useState(false); //ako doaga od

  const [LookupData, setLookupData] = useState([]);
  const [SuperiorData, setSuperiorData] = useState([]);
  const [CountryData, setCountryData] = useState([]);
  const [YesNoData, setYesNo] = useState([]);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  //Da proveri dali e nov zapis ili postoecki
  const { VacancyID } = useParams();

  //za Navigacija
  const navigate = useNavigate();

  //Za Refreshiranje na strana mu treba;
  const location = useLocation();

  //za poraki pri loadiranje na strana
  const msgHelper = new MessageHelper();

  //****************************************** */
  //HOOKS SECTION HERE
  //****************************************** */

  //1 --> Ke se povika ovoj HOOK samo dokolku se SMENI url-ot poradi toa na new dodavame Ticks() za da forcirame refresh na strana
  useEffect(() => {
    initData();
  }, [location.pathname + "/" + location.search]);

  //****************************************** */
  //METHOD  SECTION HERE
  //****************************************** */

  const initData = () => {
    async function fetchData() {
      setIsNewRecord(true); //DEFAULT
      setRecord(RecordDefault);
      setIsProfileLocked(false);
      setReadOnly(false);

      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      let responses = await new VacancyActions().InitAsync(); // await LookupActions.InitAsync();
      if (!responses.error) {
        let lookupData = responses[0].data;
        let countryData = responses[1].data;
        //let SuperiorsData = responses[2].data;

        setLookupData(lookupData);
        setCountryData(countryData);

        //Load Finished
        result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);

        //ako postoi Employee Loadiraj go
        if (VacancyID) {
          await GetVacancy();
        }
      } else {
        //Finished  with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }
    }
    fetchData();
  };

  async function GetVacancy() {
    setIsProfileLocked(false);
    setReadOnly(false);

    let result = msgHelper.setPhase(enumPage.LoadStart);
    setMessageData(result);

    let payload = { vaID: VacancyID };
    let responses = await new VacancyActions().EditAsync(payload); // await LookupActions.InitAsync();

    if (!responses.error) {
      let recordDb = responses[0].data;
      setRecord(recordDb);

      result = msgHelper.setPhase(enumPage.LoadSuccess);
      setMessageData(result);

      setIsNewRecord(false);
    } else {
      //Finished  with Error
      result = msgHelper.setPhase(enumPage.ErrorServer, responses);
      setMessageData(result);
    }
  }

  const saveData = () => {
    async function fetchData() {
      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      let response = await new VacancyActions().SaveAsync(Record);

      if (!response.error) {
        let recordDb = response[0].data;
        setRecord(recordDb);

        //Load Finished
        result = msgHelper.setPhase(enumPage.SaveSuccess);
        setMessageData(result);
        setRecord((a) => ({
          ...a,
          ValidationMessages: result.ValidationMessages,
        })); //Ako ima Validation Messages da gi izbrise
        setIsNewRecord(false);
      } else {
        //Finished  with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, response);
        setMessageData(result);
        setRecord((a) => ({
          ...a,
          ValidationMessages: result.ValidationMessages,
        })); //Samo ovdeka mu treba ako ima Validacioni greski da dodade
      }
    }

    fetchData();
  };

  const [convertedContent, setConvertedContent] = useState(null);

  function createMarkup(html) {
    return {
      __html: DOMPurify.sanitize(html),
    };
  }

  //****************************************** */
  //HANDLERS SECTION HERE
  //****************************************** */
  const handleChange = (e) => {
    const { name, value } = e.target;

    console.error(name + ":" + value);
    setRecord((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleChangeDate = (e) => {
    // const { name, value } = e.target;
    // console.log(name + ":" + value);
    // setRecord((prevState) => ({ ...prevState, [name]: value }));
  };

  //****************************************** */
  //GUI DISPLAY
  //****************************************** */
  const ActionButtons = () => {
    return (
      <Grid container align="right" padding={1}>
        <Grid item xs={12}>
          <Button
            name="btnSave"
            variant="contained"
            sx={{ mr: 1 }}
            color="success"
            size="small"
            disabled={MessageData.Loading}
            onClick={() => saveData()}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
      <VacancyBreadcrumbs page={enumBreadcrumbPage.Master} Id={Record.vaID} />

      <MyAlert {...MessageData} />

      <ActionButtons />

      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5">General Info</Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <MyText
            Id="vaName"
            PlaceHolder="Vacancy Name"
            Record={Record}
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            Required={true}
            Disabled={false}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <MyText
            Id="vaDescription"
            PlaceHolder="Description"
            Record={Record}
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            //Required={true}
            //Disabled={false}
            MultiLine={true}
            Rows={5}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <MyCheckList
            Id="vaCountryIDs"
            Record={Record}
            PlaceHolder="Countries"
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            Options={CountryData}
            Required={true}
            // OptionsText="coName"
            // OptionsValue="coCountryID"
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} sx={{ mt: 3 }}>
          <MyDate2
            Id="vaFrom"
            PlaceHolder="From"
            Record={Record}
            HandleChange={handleChange}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sx={{ mt: 3 }}>
          <MyDate2
            Id="vaTo"
            PlaceHolder="To"
            Record={Record}
            HandleChange={handleChange}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} sx={{ mt: 3 }}>
          <MyEditor
            editorState={editorState}
            onEditorStateChange={setEditorState}
            setConvertedContent={setConvertedContent}
            vaText={Record.vaText}
            //Record={Record}
          />
        </Grid>
      </Grid>

      {Record.vaVacancyID > 0 && (
        <Grid container>
          <Grid item xs={12}>
            <Link href={Record.vaGoogleFullPath} target="_blank">
              {Record.vaGoogleFullPath}
            </Link>
          </Grid>
        </Grid>
      )}

      <ActionButtons />

      <MyAlert {...MessageData} />
    </Typography>
  );
}
