import { IErrorsInput, ValidatorHelper } from "../helpers/ValidatorHelper";
import { LookupDataService } from "../services/LookupDataService";
import { CountryService } from "../services/CountryService";
import { EmployeeService } from "../services/EmployeeService";
import { StaffService } from "../services/StaffService";
import { enumSharedMsgs } from "../helpers/common.constants";
import { DownloadService } from "../services/DownloadService";

export default class DownloadActions {
  
  async DownloadFileAsync(Record: any) {
    let response = await new DownloadService().DownloadFile(Record);
    return response;
  }
}
