// @ts-check
import React, { useState, useRef, useEffect } from "react";
import { Typography, Button, Grid } from "@mui/material";
import MyAlert from "../../controls/MyAlert";
import {
  enumPage,
  enumLookupTypes,
  enumRoutes,
} from "../../helpers/common.constants";
import DocumentActions from "../../actions/DocumentActions";
import { ImageConfig } from "../Document/imageConfig";
// import "../drop-file-input/drop-file-input.css";
import { MessageHelper } from "../../helpers/MessageHelper";
import MyDropDown from "../../controls/MyDropDown";
import moment from "moment";
import EmployeeActions from "../../actions/EmployeeActions";
import { useParams, useLocation, useNavigate, Link } from "react-router-dom";
import MyEmployeeActions from "../../actions/MyEmployeeActions";

const RecordDefault = {
  etTodoID: 0,
  etEmployeeID: 0,
  etYear: 0,
  etCheckID: 0,
  etCreatedDate: moment(new Date(1900, 0, 1)).format("YYYY-MM-DD"),
  etGoogleFileIDs: "",
  etGoogleFolderID: "",
};

const initialRecordState = {
  folderName: "",
  folderId: "",
  parentFolderId: "",
  IsNew: false,
  HasChanges: false,
};

export default function MyEmployeeToDoManage({ props, OnModalClose }) {
  const [messageData, setMessageData] = useState({});
  const [record, setInitialRecord] = useState(initialRecordState);
  const [fileList, setFileList] = useState([]);
  const wrapperRef = useRef(null);

  const [LookupData, setLookupData] = useState([]);

  const [Record, setRecord] = useState(RecordDefault);

  const documentActions = new DocumentActions();

  //const { FolderId, FolderName, IsNew, //onFileChange } = props;
  const FolderId = "1wbLQdtwVamIE9q_LSQl99oYrvAoBdx1E";
  const IsNew = true;
  const FolderName = "LEONID KODOVSKI";

  const msgHelper = new MessageHelper();
  const location = useLocation();

  useEffect(() => {
    setInitialRecord({
      ...record,
      folderId: FolderId,
      IsNew: IsNew,
      folderName: FolderName,
    });
  }, [FolderId, IsNew, FolderName]);

  useEffect(() => {
    initData();
  }, [location.pathname + "/" + location.search]);

  const initData = () => {
    async function fetchData() {
      // setIsNewRecord(true); //DEFAULT
      setRecord(RecordDefault);
      // setIsProfileLocked(false);
      // setReadOnly(false);

      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      let responses = await new EmployeeActions().InitAsync(); // await LookupActions.InitAsync();
      if (!responses.error) {
        let lookupData = responses[0].data;
        setLookupData(lookupData);

        //Load Finished
        result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);
      } else {
        //Finished  with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }
    }

    fetchData();
  };

  const handleFileUpload = async () => {

    setMessageData(
      msgHelper.setPhase(
        enumPage.LoadStartWithMsg,
        null,
        "Please wait until upload finish..."
      )
    );

    let fileCounter = 1;
    let filesTotal = fileList.length;
    let hasError = false;


    const payload = {
      FileData1: null,
      FileData2: null,
      FileData3: null,
      etEmployeeID: 999999999,
      etCheckID: Record.etCheckID,
    };


    for (const file of fileList) {

      var messageDataWait = msgHelper.setPhase(
                                              enumPage.LoadStartWithMsg,
                                              null,
                                              "Please wait it might take a while, uploading " +
                                              fileCounter +
                                              " file of " +
                                              filesTotal +
                                              " files");

      if (fileCounter === 1) {
        payload.FileData1 = file;
      }
      else if (fileCounter === 2) {
        payload.FileData2 = file;
      }
      else if (fileCounter === 3) {
        payload.FileData3 = file;
      }

      messageDataWait.Timing = 100000; //kazuva kolku da ceka
      setMessageData(messageDataWait);

      fileCounter = fileCounter + 1;
    }

    const response = await new EmployeeActions().SaveTODOsAsync(payload);

    if (response.error) {
      var errrMessageData = msgHelper.setPhase(
        enumPage.ErrorServer,
        response
      );
      errrMessageData.Timing = 100000; //kazuva kolku da ceka
      setMessageData(errrMessageData);
      hasError = true;
    }


    if (!hasError) {
      setInitialRecord((prevState) => ({ ...prevState, HasChanges: true }));

      var messageDataOK = msgHelper.setPhase(
        enumPage.LoadSuccessWithMsg,
        null,
        "All files were uploaded!"
      );
      messageDataOK.Timing = 100000; //kazuva kolku da ceka
      setMessageData(messageDataOK);
    }
  };


  const handleDrag = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.type === "dragenter" || event.type === "dragover") {
      wrapperRef.current.classList.add("dragover");
    } else {
      wrapperRef.current.classList.remove("dragover");
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    wrapperRef.current.classList.remove("dragover");
    const files = [...event.dataTransfer.files];
    if (files.length) {
      const updatedList = [...fileList, ...files];
      setFileList(updatedList);
      //onFileChange(updatedList);
    }
  };

  const handleFileSelect = (event) => {
    const files = [...event.target.files];
    if (files.length) {
      const updatedList = [...fileList, ...files];
      setFileList(updatedList);
      //onFileChange(updatedList);
    }
  };

  const removeFile = (file) => {
    const updatedList = fileList.filter((f) => f !== file);
    setFileList(updatedList);
    //onFileChange(updatedList);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    console.log(name + ":" + value);
    setRecord((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item xs={12}>
        <MyAlert {...messageData} />
      </Grid>

      {/* <Grid item xs={12}>
        <Typography variant="h5">Upload Multiple File</Typography>
      </Grid> */}
      <Grid item xs={12} md={3}>
        <MyDropDown
          Id="etCheckID"
          Record={Record}
          PlaceHolder="Document Type"
          HandleChange={handleChange}
          ValidationMessages={Record.ValidationMessages}
          Options={LookupData}
          LookupTypeId={enumLookupTypes.DocumentType}
          Required={true}
        //Disabled={IsProfileLocked || IsReadOnly}
        />
      </Grid>

      <Grid item xs={12}>
        <div
          ref={wrapperRef}
          className="drop-file-input"
          onDragEnter={handleDrag}
          onDragOver={handleDrag}
          onDragLeave={handleDrag}
          onDrop={handleDrop}
        >
          <div className="drop-file-input__label">
            <p>Drag & Drop your files here</p>
          </div>
          <input type="file" multiple onChange={handleFileSelect} />
        </div>
        {fileList.length > 0 && (
          <div className="drop-file-preview">
            {fileList.map((item, index) => (
              <div key={index} className="drop-file-preview__item">
                <img
                  src={
                    ImageConfig[item.type.split("/")[1]] ||
                    ImageConfig["default"]
                  }
                  alt=""
                />
                <div className="drop-file-preview__item__info">
                  <p>
                    {item.name} - {(item.size / 1024).toFixed(2)} KB
                  </p>
                  {/* <p>{item.name} </p>
                  <p>{(item.size / 1024).toFixed(2)} KB</p> */}
                </div>
                {!messageData.Loading && (
                  <span
                    className="drop-file-preview__item__del"
                    onClick={() => removeFile(item)}
                  >
                    x
                  </span>
                )}
              </div>
            ))}
          </div>
        )}
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          gap: "100px",
        }}
      >
        <Button
          variant="contained"
          color="success"
          disabled={messageData.Loading || fileList.length === 0}
          onClick={handleFileUpload}
        >
          Upload & Save
        </Button>
      </Grid>
      <Grid item xs={12}>
        <MyAlert {...messageData} />
      </Grid>
    </Grid>
  );
}
