import { RestHelper } from "../helpers/RestHelper";

export class LeaveRequestService {
  private _restHelper;

  constructor() {
    this._restHelper = new RestHelper();
  }

  async Save(payload: any) {
    const url: string = "LeaveRequest/Save";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async Approval(payload: any) {
    const url: string = "LeaveRequest/Approval";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async Search(payload: any) {
    const url: string = "LeaveRequest/Search";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  
  async MyApprovals(payload: any) {
    const url: string = "LeaveRequest/MyApprovals";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async MyLeaveRequests(payload: any) {
    const url: string = "LeaveRequest/MyLeaveRequests";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async ExportDetails(payload: any) {
    const url: string = "LeaveRequest/ExportDetails";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async ExportSummary(payload: any) {
    const url: string = "LeaveRequest/ExportSummary";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async Edit(payload: any) {
    const url: string = "LeaveRequest/Edit";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async Delete(payload: any) {
    const url: string = "LeaveRequest/Delete";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async Calendar(payload: any) {
    const url: string = "LeaveRequest/Calendar";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async GetLeaveRequestBalance(payload: any) {
    const url: string = "LeaveRequest/GetLeaveRequestBalance";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async GetStaffLeaveRequestBalance(payload: any) {
    const url: string = "LeaveRequest/GetStaffLeaveRequestBalance";
    let response = await this._restHelper.post(url, payload);
    return response;
  }
}
