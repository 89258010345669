import { RestHelper } from '../helpers/RestHelper';

export class LinkService {

  private _restHelper;

  constructor() {
    this._restHelper = new RestHelper();
  }

  async Search(payload = {}) {
    const url: string = "Link/Search";
    let response = await this._restHelper.post(url, payload);
    return response;
  }
}
