import LocalStorageHelper from "../helpers/LocalStorageHelper";
import { RestHelper } from "../helpers/RestHelper";
import { enumLocalStorageArgs } from "../helpers/common.constants";

export class OfficeService {
  private _restHelper;

  constructor() {
    this._restHelper = new RestHelper();
  }


  async Search(payload: any) {
    const url: string = "Office/Search";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async GetActiveOffices(payload: any) {
    const url: string = "Office/GetActiveOffices";
    let response = await this._restHelper.post(url, payload);
    return response;
  }
  

  async Save(payload: any) {
    const url: string = "Office/Save";
    let response = await this._restHelper.post(url, payload);
    return response;
  }
  
}
