import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Navigator from './Navigator';
import Content from './Content';
import Header from './Header';
import { Paper } from '@mui/material';
import AuthenticateHelper from './helpers/AuthenticateHelper';
import { Outlet } from 'react-router-dom';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}.
    </Typography>
  );
}
let theme = createTheme({
  palette: {
    primary: {
      light: "#63ccff",
      main: "#009be5",
      dark: "#006db3",
    },
    // palette: {
    //   action: {
    //     active: lightBlue[200],
    //     //activeOpacity: 1,
    //     hover: lightBlue[100],
    //     hoverOpacity: 0.7,
    //     focus: lightBlue[600],
    //     focusOpacity: 1,
    //     selected: lightBlue[300],
    //     selectedOpacity: 1,
    //   },
  },
  typography: {
    subtitle1: {
      fontWeight: 400,
      fontSize: '0.9rem',
      paddingTop: 10
    },
    subtitle2: {
      fontWeight: 300,
      fontSize: '0.8rem',
      paddingTop: 10
    },
    h2: {
      fontWeight: 100,
      fontSize: '2rem',
    },
    h3: {
      fontWeight: 100,
      fontSize: '1.5rem',
    },
    h4: {
      fontWeight: 750,
      fontSize: '0.9rem',
    },
    h5: {
      fontWeight: 750,
      fontSize: '0.9rem',
    },
    h6: {
      fontSize: '0.9rem',
    }
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },

});

theme = {
  ...theme,
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#081627",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '0.7rem',
          textTransform: "none",
        },
        contained: {
          boxShadow: "none",
          "&:active": {
            boxShadow: "none",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-root, & .MuiInputLabel-root": {
            fontSize: "0.8rem"
          }
          //height:40          //textTransform: "none"
        }
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          '& label': {
            fontSize: "0.8rem"
          },
        },
        input: {
          fontSize: "0.8rem"
        },
        listbox: {
          fontSize: "0.8rem"
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: "0.8rem"
        }
      }
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginLeft: theme.spacing(1),
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: theme.palette.common.white,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          margin: "0 16px",
          minWidth: 0,
          padding: 0,
          [theme.breakpoints.up("md")]: {
            padding: 0,
            minWidth: 0,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1),
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: "rgb(255,255,255,0.15)",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "#4fc3f7",
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: 14,
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "inherit",
          minWidth: "auto",
          marginRight: theme.spacing(2),
          "& svg": {
            fontSize: 20,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32,
        },
      },
    },
  },
};

const drawerWidth = 256;


export default function PublicContainer({ MyContent, needRefresh }) {
  console.error("PublicContainer");
  console.error(MyContent);
  const [mobileOpen, setMobileOpen] = React.useState(false);

  React.useEffect(() => {
    if (needRefresh) {
      alert("CALLED")
    }
  }, [needRefresh]); //default

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', minHeight: '100vh' }}>
        <CssBaseline />

        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          
          <Box component="main" sx={{ flex: 1, py: 6, px: 4, bgcolor: '#eaeff1' }}>

            {/* DA SE VIDI SO LEON */}
            <Paper sx={{ margin: 'auto', overflow: 'hidden' }}>
              {MyContent}
            </Paper>
          </Box>
          {/* <Box component="footer" sx={{ p: 2, bgcolor: '#eaeff1' }}>
            <Copyright />
          </Box> */}
        </Box>
      </Box>
    </ThemeProvider>
  );
}
