import { IErrorsInput, ValidatorHelper } from "../helpers/ValidatorHelper";
import { LookupDataService } from "../services/LookupDataService";
import { CountryService } from "../services/CountryService";
import { EmployeeService } from "../services/EmployeeService";
import { StaffService } from "../services/StaffService";
import { enumSharedMsgs } from "../helpers/common.constants";
import { FinProjectService } from "../services/FinProjectService";
import { OfficeService } from "../services/OfficeService";

export default class FinProjectActions {
  async InitAsync() {
    let responseLookup = await new LookupDataService().GetAll();

    let responses = [responseLookup];

    //napravi validacija
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async EditAsync(Record: any) {
    //napravi validacija
    let response = await new FinProjectService().Edit(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async SearchAsync(Record: any) {
    //napravi validacija
    let response = await new FinProjectService().Search(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  
  async SubmitAsync(Record: any) {
    //napravi validacija
    let response = await new FinProjectService().Submit(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  
  async VerifyAsync(Record: any) {
    //napravi validacija
    let response = await new FinProjectService().Verify(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }


  async RevisionAsync(Record: any) {
    //napravi validacija
    let response = await new FinProjectService().Revision(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async GetVersionsAsync(Record: any) {
    //napravi validacija
    let response = await new FinProjectService().GetVersions(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async ExportAsync(Record: any) {
    //napravi validacija
    let response = await new FinProjectService().Export(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async SaveAsync(Record: any) {
    let jsonInputData: IErrorsInput[] = [
      {
        element: "fpName",
        type: "required",
        value: Record.fpName,
        message: "Project Name is required",
      },
      {
        element: "fpDescription",
        type: "required",
        value: Record.fpDescription,
        message: "Description is required",
      },
      {
        element: "fpStartDate",
        type: "DateTimeEmpty",
        value: Record.fpStartDate,
        message: "Start Date is required",
      },
      {
        element: "fpEndDate",
        type: "DateTimeEmpty",
        value: Record.fpEndDate,
        message: "End Date is required",
      },
      {
        element: "fpOwnerID",
        type: "SelectEmpty",
        value: Record.fpOwnerID,
        message: "Holder Name is required",
      },
      {
        element: "fpHolderID",
        type: "SelectEmpty",
        value: Record.fpHolderID,
        message: "Holder Name is required",
      },
      {
        element: "fpHolderID2",
        type: "SelectEmpty",
        value: Record.fpHolderID2,
        message: "Verifier Name #2 is required",
      },
      {
        element: "fpHolderID3",
        type: "SelectEmpty",
        value: Record.fpHolderID3,
        message: "Verifier Name #3 is required",
      },
      {
        element: "fpAmount",
        type: "required",
        value: Record.fpAmount,
        message: "Amount is required",
      },
      {
        element: "fpYear",
        type: "required",
        value: Record.fpYear,
        message: "Year is required",
      },
    ];

    if (!Record.SkipValidation) {
      //validacija
      let validationMessages = new ValidatorHelper().validateClient(
        jsonInputData
      );
      if (validationMessages.length > 0) {
        let response = {
          error: true, //oznaci deka ima greska
          error_description: enumSharedMsgs.SaveClientError,
          ValidationMessages: validationMessages, //vrati gi greskite za GUI
        };
        return response;
      }
    }
    //napravi validacija
    let response = await new FinProjectService().Save(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }
}
