import { Button, Grid, Tooltip, Typography } from "@mui/material";

import { Table, TableCell, TableContainer, TableRow } from "@mui/material";

import { useEffect, useState } from "react";

import { enumDocumentsSource, enumPage } from "../../helpers/common.constants";
import Link from "@mui/material/Link";
import MyAlert from "../../controls/MyAlert";
import DocumentActions from "../../actions/DocumentActions";
import FolderIcon from "@mui/icons-material/Folder";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import CloudCircleIcon from "@mui/icons-material/CloudCircle";
import LogHelper from "../../helpers/LogHelper";
import { MessageHelper } from "../../helpers/MessageHelper";
import ManageFolder from "./ManageFolder";
import MyDialog from "../../controls/MyDialog";
import UploadFile from "./UploadFile";
import DeleteFileFolder from "./DeleteFileFolder";
import { useParams } from "react-router-dom";
import { DocumentService } from "../../services/DocumentService";
import UploadMultipleFile from "./UploadMultipleFile";

export default function DocumentSearch(args) {
  const [MessageData, setMessageData] = useState({});
  const [RecordsAll, setRecordsAll] = useState([]);
  const [RecordsParentFolderNames, setRecordsParentFolderNames] = useState([]);
  const [RecordsParentFolderIDs, setRecordsParentFolderIDs] = useState([]);
  const [ParentFolderId, setParentFolderId] = useState([]);
  const [ParentFolderName, setParentFolderName] = useState([]);
  const [RootFolderId, setRootFolderId] = useState([]);
  const [FolderId, setFolderId] = useState([]);
  const [FolderName, setFolderName] = useState([]);
  const [ModalParams, setModalParams] = useState({
    ShowModal: false,
    Content: "",
    OnModalClose: null,
    OnModalHasChanges: null,
    Title: "",
  }); //ModalParams e objekt koj sto IsOpen i IsContent ima struktura

  //za poraki pri loadiranje na strana
  const msgHelper = new MessageHelper();

  //Da proveri dali e nov zapis ili postoecki
  const { InputFolderID, TypeOfSource } = useParams();

  useEffect(() => {
    initData();
    return () => {};
  }, []);

  const openNewFolderDialog = () => {
    let args = { ParentFolderId: FolderId, IsNew: true };
    let control = <ManageFolder {...args} />;
    setModalParams({
      ShowModal: true,
      Content: control,
      OnModalClose: onModalCloseNewFolder,
    });
  };

  const openRenameFolderDialog = () => {
    let args = { FolderId: FolderId, FolderName: FolderName, IsNew: false };
    let control = <ManageFolder {...args} />;
    setModalParams({
      ShowModal: true,
      Content: control,
      OnModalClose: onModalCloseNewFolder,
    });
  };

  const openUploadFileDialog = () => {
    let args = { FolderId: FolderId, FolderName: FolderName, IsFolder: true };
    let control = <UploadFile {...args} />;
    setModalParams({
      ShowModal: true,
      Content: control,
      OnModalClose: onModalCloseNewFolder,
    });
  };

  
  const openUploadMultipleFileDialog = () => {
    let args = { FolderId: FolderId, FolderName: FolderName, IsFolder: true };
    let control = (
      <UploadMultipleFile {...args}/>
    );

    setModalParams({
      ShowModal: true,
      Content: control,
      OnModalClose: onModalCloseNewFolder,
    });
  };

  const openDeleteFileDialog = ({ fileId, fileName }) => {
    let args = {
      FolderId: FolderId,
      FileId: fileId,
      FileName: fileName,
      IsFile: true,
    };
    let control = <DeleteFileFolder {...args} />;
    setModalParams({
      ShowModal: true,
      Content: control,
      OnModalClose: onModalCloseNewFolder,
    });
  };

  const openDeleteFolderDialog = () => {
    let args = {
      ParentFolderId: ParentFolderId,
      FolderId: FolderId,
      FolderName: FolderName,
      IsFolder: true,
    };
    let control = <DeleteFileFolder {...args} />;
    setModalParams({
      ShowModal: true,
      Content: control,
      OnModalClose: onModalCloseNewFolder,
    });
  };

  const openGoogleDriveDialog = () => {
    let url = "https://drive.google.com/drive/u/2/folders/" + FolderId;
    window.open(url, "_blank");
  };

  function onModalCloseNewFolder(args) {
    setModalParams({ ShowModal: false, Content: null });
    if (args.HasChanges) {
      GetContentData(RootFolderId, args.FolderId);
    }
  }

  const initData = () => {
    async function fetchData() {
      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      //Od EmployeeHeaderButtons se praka kako url: null primerno:
      //http://localhost:3000/Employee/Documents/750/null/2
      //750 --> EmployeeiD
      //InputFolderId: null
      //TypeOfSource: 1 ili 2 ()
      if (TypeOfSource === enumDocumentsSource.GoogleDrive_FromURL.toString()) {
        //Inicijalno RootFolderId == FolderID
        setRootFolderId(InputFolderID);
        GetContentData(InputFolderID, InputFolderID);
      } else if (
        TypeOfSource === enumDocumentsSource.GoogleDrive_FromArg.toString()
      ) {
        //Inicijalno RootFolderId == FolderID
        setRootFolderId(args.InputFolderID);
        GetContentData(args.InputFolderID, args.InputFolderID);
      }
      // else {
      //   let rootFolderId = "17wo1xqgiUVIXgN04j6EMnbC61fkI8DI0";
      //   setRootFolderId(rootFolderId);
      //   GetContentData(rootFolderId,rootFolderId);
      // }
    }

    fetchData();
  };

  const GetContentData = (rootFolderId, folderId) => {
    //alert("CALLED");
    async function fetchData() {
      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      setRecordsAll([]);
      setRecordsParentFolderIDs([]);
      setRecordsParentFolderNames([]);
      setRecordsParentFolderIDs([]);

      let responses =
        await new DocumentActions().GetFolderContentByFolderIdAsync(
          rootFolderId,
          folderId
        );
      if (!responses.error) {
        const rootRecord = responses[0].data;
        setRecordsAll(rootRecord.files);

        let pathParentFolderNames = rootRecord.pathParentFolderNames.split(",");
        let pathParentFolderIDs = rootRecord.pathParentFolderIDs.split(",");
        setRecordsParentFolderNames(pathParentFolderNames);
        setRecordsParentFolderIDs(pathParentFolderIDs);

        //najvazno za MODAL da raboti so toj FolderId i FolderName
        setFolderId(rootRecord.folderId);
        setFolderName(rootRecord.folderName);

        //Ovoj del mu treba pri brisenje na Folder da moze da se vrati na eden folder prethodno
        if (rootRecord.parent) {
          setParentFolderId(rootRecord.parent.folderId);
          setParentFolderName(rootRecord.parent.folderName);
        }

        //Load Finished
        result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);
      } else {
        setMessageData({
          HasError: true,
          Message: responses.error_description,
        });
      }
    }

    fetchData();
  };

  // const downloadFile = (fileId) => {

  //   var url = new LogHelper().GetBaseUrl() + 'GoogleDrive/DownloadFile/' + fileId;
  //   window.open(url, "_blank");
  // };

  const downloadFile = (fileName, fileId) => {
    async function fetchData() {
      let payload = "/" + fileId;
      let response = await new DocumentActions().DownloadFileAsync(payload);

      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", fileName); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    }
    fetchData();
  };

  const getFolderFileName = (row) => {
    if (row.isFolder)
      return (
        <div>
          <FolderIcon sx={{ fontSize: 15 }} /> &nbsp;{row.folderName}
        </div>
      );
    else return <div>&nbsp;{row.fileName}</div>;
  };

  const HeaderButtons = () => {
    return (
      <Grid
        container
        align="left"
        sx={{ borderBottom: 1, borderColor: "grey.500", pb: 1 }}
        align="center"
      >
        <Grid item xs={12} spacing={1}>
          <Button
            sx={{ mr: 2 }}
            variant="contained"
            startIcon={<FolderIcon />}
            size="small"
            onClick={() => openNewFolderDialog()}
          >
            New Folder
          </Button>

          <Button
            sx={{ mr: 2 }}
            variant="contained"
            startIcon={<FolderIcon />}
            size="small"
            color="secondary"
            disabled={MessageData.Loading || FolderId === RootFolderId}
            onClick={() => openRenameFolderDialog()}
          >
            Rename Folder
          </Button>

          <Button
            sx={{ mr: 2 }}
            variant="contained"
            startIcon={<DeleteForeverIcon />}
            size="small"
            color="error"
            disabled={MessageData.Loading || FolderId === RootFolderId}
            onClick={() => openDeleteFolderDialog()}
          >
            Delete Folder
          </Button>

          <Button
            sx={{ mr: 2 }}
            variant="contained"
            startIcon={<UploadFileIcon />}
            size="small"
            color="success"
            onClick={() => openUploadFileDialog()}
          >
            Upload File
          </Button>

          <Button
            sx={{ mr: 2 }}
            variant="contained"
            startIcon={<UploadFileIcon />}
            size="small"
            color="success"
            onClick={() => openUploadMultipleFileDialog()}
          >
            Upload Multiple File
          </Button> 

          <Button
            sx={{ mr: 2 }}
            variant="contained"
            startIcon={<CloudCircleIcon />}
            size="small"
            onClick={() => openGoogleDriveDialog()}
          >
            Go To Drive
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
      <MyAlert {...MessageData} />

      <HeaderButtons />

      <br />
      <TableContainer
        sx={{ display: { xs: "block", sm: "block", md: "block" } }}
      >
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableRow>
            <TableCell colSpan={2}>
              {RecordsParentFolderNames.map((folderName, index) => (
                <Link
                  href="#"
                  onClick={() =>
                    GetContentData(RootFolderId, RecordsParentFolderIDs[index])
                  }
                  underline="none"
                  rel="noopener noreferrer"
                >
                  {folderName}
                  {index + 1 !== RecordsParentFolderIDs.length && " > "}
                </Link>
              ))}
            </TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>

          <TableRow>
            <TableCell style={{ width: "40%" }}>
              <b> Name</b>
            </TableCell>
            <TableCell style={{ width: "30%" }}>
              <b> Owner</b>
            </TableCell>
            <TableCell>
              <b> File Size</b>
            </TableCell>
            <TableCell></TableCell>
          </TableRow>

          {RecordsAll.map((row, index) => (
            <TableRow>
              <TableCell>
                <Link
                  onClick={() =>
                    row.isFolder
                      ? GetContentData(RootFolderId, row.folderId)
                      : downloadFile(row.fileName, row.fileId)
                  }
                  href="#"
                  underline="none"
                  rel="noopener noreferrer"
                >
                  {getFolderFileName(row)}
                  <br />
                </Link>
              </TableCell>
              <TableCell>{row.writers}</TableCell>
              <TableCell>{row.size}</TableCell>
              <TableCell>
                {!row.isFolder && (
                  <Tooltip
                    title={
                      "Delete " +
                      (row.isFolder
                        ? "folder " + row.folderName
                        : " file " + row.fileName)
                    }
                  >
                    <Button
                      sx={{ mr: 2 }}
                      variant="contained"
                      size="small"
                      color="error"
                      onClick={() =>
                        openDeleteFileDialog({
                          fileId: row.fileId,
                          fileName: row.fileName,
                        })
                      }
                    >
                      <DeleteForeverIcon fontSize="small" />
                    </Button>
                  </Tooltip>
                )}
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </TableContainer>

      <MyDialog
        IsOpen={ModalParams.ShowModal}
        HandleModalClose={ModalParams.OnModalClose}
        Title={ModalParams.Title}
        Content={ModalParams.Content}
      />
    </Typography>
  );
}
