import * as React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import  {createFilterOptions}  from '@mui/material/Autocomplete';
import { IValidationMessage } from '../helpers/common.interfaces';

export interface Props {
  Record: {},
  Id: string,
  Required?: boolean,
  Disabled?: boolean,
  PlaceHolder: string,
  Label?: string,
  AutoFocus?: boolean,
  HandleChange(e: { target: { name: string, value: number } }): void,
  ValidationMessages?: IValidationMessage[],
  LookupTypeId?: number,
  Options: any[],
  OptionsText?: string,
  OptionsValue?: string
  //SelectedValue: string | number
};


export default function MyDropDown(args: Props) {


  let Record = args.Record;
  let required: boolean = args.Required ?? false;
  let hasError: boolean = false;
  let message: string = '';


  //Proveri dali ima greska
  if (required === true) {
    let errorCollection = args.ValidationMessages ? args.ValidationMessages : [];
    if (errorCollection != null && errorCollection.length > 0) {
      let currentOption = errorCollection.find((x) => x.controlId === args.Id);
      if (currentOption != null) {
        hasError = true;
        message = currentOption.message;
      }

      //sekogas resetiraj ja EMPTY bidejki ne ni treba a istata e povrzana za drugi komponenti poradi toa napravi nov set 
      //args.ValidationMessages = [];
    }
  }


    // const filterOptions = createFilterOptions({
    //   matchFrom: 'start',
    //   stringify: (option : any) => option.Text,
    // });
  

  return React.useMemo(() => {

    // console.error("REFRESHED");
    // console.error("AAAAAAA");
    // console.error(args);


    let disabled: boolean = args.Disabled ?? false;
    // let controlId: string = args.Id;



    //Vo zavisnot od Value i HasError se refreshira komponentata
    //let value: string | number = args.SelectedValue 
    let value : string | number = Record ? Record[args.Id as keyof {}] : "";


    
    //let currentValue : string = value.toString();
    if (value === undefined || value === null)
    {
      value = "";
    }
    //if (!value) value = "";


    //default ocekuva Text i Value za Select dokolku nema izbrano user-ot args.
    let optionText: string = "Text";
    let optionValue: string = "Value";
    let LookupTypeId: number | undefined = args.LookupTypeId; //ako e praten ke go setira ako ne UNDEFINED
    let placeHolder: string = args.PlaceHolder;

    let options: any[] = args.Options ? args.Options : [];

    //proveri dali e za Lookup togas znaci deka optionText = ldValue ; optionValue = ldLookupDataID
    if (LookupTypeId) {
      options = options.filter((x: any) => x.ldTypeID === LookupTypeId) || [];
      optionValue = "ldLookupDataID";
      optionText = "ldValue";
    }
    else {
      //slucajno ako vo arguments ima custom Value i Text togas zemi gi od tamu konkretno args.OptionsText i  args.OptionsValue
      if (args.OptionsText) {
        optionText = args.OptionsText;
      }
      if (args.OptionsValue) {
        optionValue = args.OptionsValue;
      }
    }


    //useCallback samo ednas da se mountira: https://everyday.codes/javascript/react-usememo-and-when-you-should-use-it/
    const handleSelectedValue = (event: React.SyntheticEvent, value: any) => {

      let option: any = value;
      if (option !== null) {
        let customerArgs = { target: { name: args.Id, value: option[optionValue], text: option[optionText] } };
        args.HandleChange(customerArgs);
      } else {
        let customerArgs = { target: { name: args.Id, value: 0, text: '' } };
        args.HandleChange(customerArgs);
      }
    };

    //ovie se vrednostite sto ke se setiraat
    let selectedValueId = value; //gore "value" nikogas nema da bide NULL
    //if (!selectedValueId) selectedValueId = "";
    let selectedValueText = "";

    if (options && options.length > 0) {
      // console.error("INFO");
      // console.error(args.Id);
      // console.error(options);
      // console.error(selectedValueId);
      // console.error("value:" + value);
      

      //if (selectedValueId !== "0") {
        //moze i da nema potreba od filter konkretno za Periods
        let currentOption = options.filter((x) => x[optionValue].toString() === selectedValueId.toString()) || [];
        if (currentOption.length > 0) {
          selectedValueText = currentOption[0][optionText];
        }
      //}
    }

    return (
      <Autocomplete
        //style={{'width':'200px'}}
        size='small'
        id={args.Id}
        disabled={disabled}
        getOptionLabel={(option) => typeof option === "string" ? option : option[optionText]}
        onChange={(event: React.SyntheticEvent, value: any) => { handleSelectedValue(event, value); }} //ako e MULTIPLE 
        options={options}
        //filterOptions={filterOptions}
        autoComplete
        value={selectedValueText}
        renderInput={(params) => (
          <TextField {...params} label={placeHolder} variant="outlined" fullWidth margin="dense" autoFocus={args.AutoFocus} helperText={message} error={hasError}
          />
        )}
      />
    );
  }, [args, hasError, message]);
};

