import { IErrorsInput, ValidatorHelper } from "../helpers/ValidatorHelper";
import { LookupDataService } from "../services/LookupDataService";
import { CountryService } from "../services/CountryService";
import { EmployeeService } from "../services/EmployeeService";
import { StaffService } from "../services/StaffService";
import { enumSharedMsgs } from "../helpers/common.constants";
import { FinAllocationService } from "../services/FinAllocationService";
import { OfficeService } from "../services/OfficeService";
import { FinProjectService } from "../services/FinProjectService";

export default class FinAllocationActions {
  async InitAsync(Record: any) {
    let responseLookup = await new LookupDataService().GetAll();

    let responses = [responseLookup];

    //napravi validacija
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async SearchAsync(Record: any) {
    //napravi validacija
    let response = await new FinAllocationService().Search(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async GetByProjectIDAsync(Record: any) {
    //napravi validacija
    let response = await new FinAllocationService().GetByProjectID(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async GetByEmployeeAsync(Record: any) {
    console.log("Record:", Record);
    //napravi validacija
    let response = await new FinAllocationService().GetByEmployee(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async GetEmployeeFinDetailsAsync(Record: any) {
    //napravi validacija
    let response = await new FinAllocationService().GetEmployeeFinDetails(
      Record
    );
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async GetFinBudgetDetailsAsync(Record: any) {
    //napravi validacija
    let response = await new FinAllocationService().GetFinBudgetDetails(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async DeleteAsync(Record: any) {
    //napravi validacija
    let response = await new FinAllocationService().Delete(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async SaveAsync(Record: any) {
    //napravi validacija
    let response = await new FinAllocationService().Save(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  // async ExportAsync(Record: any) {
  //   //napravi validacija
  //   let response = await new FinAllocationService().Export(Record);
  //   let responses = [response];
  //   let output = new ValidatorHelper().validateServerResponses(responses);
  //   return output;
  // }

  async ExportByEmployeeAsync(Record: any) {
    //napravi validacija
    let response = await new FinAllocationService().ExportByEmployee(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async ExportSearchAsync(Record: any) {
    //napravi validacija
    let response = await new FinAllocationService().ExportBySearch(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async ExportAllocationSearchAsync(Record: any) {
    //napravi validacija
    let response = await new FinAllocationService().ExportAllocationBySearch(
      Record
    );
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async ExportByProjectAsync(Record: any) {
    //napravi validacija
    let response = await new FinAllocationService().ExportByProject(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }
}
