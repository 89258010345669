import { IErrorsInput, ValidatorHelper } from "../helpers/ValidatorHelper";
import { LookupDataService } from "../services/LookupDataService";
import { CountryService } from "../services/CountryService";
import { StaffService } from "../services/StaffService";
import { DeclarationService } from "../services/DeclarationService";
import DeclarationCostActions from "./DeclarationCostActions";
import DeclarationDiemActions from "./DeclarationDiemActions";
import { DeclarationDiemService } from "../services/DeclarationDiemService";
import { DeclarationCostService } from "../services/DeclarationCostService";

export default class DeclarationActions {
  async InitAsync(Record: any) {
    let responseProjectsByYear =
      await new LookupDataService().GetProjectsByYear(Record);
    let responses = [responseProjectsByYear];

    //napravi validacija
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async InitSearchAsync(Record: any) {
    let responseLookup = await new LookupDataService().GetAll();
    //let responseCountry = await new CountryService().GetAll();
    //let responseEmployees = await new LookupDataService().GetAllEmployees();
    let responseAllYears = await new LookupDataService().GetAllYears();
    let responseApprovedSubmittedAll = await new LookupDataService().GetApprovedFinanceApprovedSubmittedAll();
    let responseProjectYears = await new LookupDataService().GetProjectYears();
    let responseProjects = await new LookupDataService().GetProjects();

    let responses = [
      responseLookup,
      //responseCountry,
      //responseEmployees,
      responseAllYears,
      responseApprovedSubmittedAll,
      responseProjectYears,
      responseProjects,
    ];

    //napravi validacija
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async InitProjectBudgetChangeAsync(Record: any) {
    let responseLookup = await new LookupDataService().GetAll();
    //let responseCountry = await new CountryService().GetAll();
    let responseEmployees = await new LookupDataService().GetAllEmployees();
    //let responseAllYears = await new LookupDataService().GetAllYears();
    //let responseApprovedSubmittedAll = await new LookupDataService().GetApprovedFinanceApprovedSubmittedAll();
    let responseProjectYears = await new LookupDataService().GetProjectYears();
    let responseProjects = await new LookupDataService().GetProjects();

    let responses = [
      responseLookup,
      //responseCountry,
      responseEmployees,
      ///responseAllYears,
      ///responseApprovedSubmittedAll,
      responseProjectYears,
      responseProjects,
    ];

    //napravi validacija
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async SaveAsync(Record: any) {
    let jsonInputData: IErrorsInput[] = [
      {
        element: "deName",
        type: "required",
        value: Record.deName,
        message: "Name is required",
      },
      {
        element: "deProjectName",
        type: "SelectEmpty",
        value: Record.deProjectName,
        message: "Project is required",
      },
    ];

    //validacija
    let validationMessages = new ValidatorHelper().validateClient(
      jsonInputData
    );
    if (validationMessages.length > 0) {
      let response = {
        error: true, //oznaci deka ima greska
        error_description: "Unable to save",
        ValidationMessages: validationMessages, //vrati gi greskite za GUI
      };
      return response;
    }

    //napravi validacija
    let response = await new DeclarationService().Save(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async SaveProjectAsync(Record: any) {
    // let jsonInputData: IErrorsInput[] = [
    //   {
    //     element: "prName",
    //     type: "required",
    //     value: Record.prName,
    //     message: "Name is required",
    //   },
    //   {
    //     element: "BudgetHolderID",
    //     type: "SelectEmpty",
    //     value: Record.BudgetHolderID,
    //     message: "Project is required",
    //   },
    //   {
    //     element: "ProjectID",
    //     type: "SelectEmpty",
    //     value: Record.ProjectID,
    //     message: "Project is required",
    //   },
    // ];

    // //validacija
    // let validationMessages = new ValidatorHelper().validateClient(
    //   jsonInputData
    // );
    // if (validationMessages.length > 0) {
    //   let response = {
    //     error: true, //oznaci deka ima greska
    //     error_description: "Unable to save",
    //     ValidationMessages: validationMessages, //vrati gi greskite za GUI
    //   };
    //   return response;
    // }

    //napravi validacija
    let response = await new DeclarationService().SaveProject(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async FinanceApprovalAsync(Record: any) {
    //napravi validacija
    let response = await new DeclarationService().FinanceApproval(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async BudgetHolderApprovalAsync(Record: any) {
    //napravi validacija
    let response = await new DeclarationService().BudgetHolderApproval(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async SearchAsync(Record: any) {
    //napravi validacija
    let response = await new DeclarationService().Search(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async SearchProjectAsync(Record: any) {
    //napravi validacija
    let response = await new DeclarationService().SearchProject(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async MyDeclarationsAsync(Record: any) {
    //napravi validacija
    let response = await new DeclarationService().MyDeclarations(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async MyDeclarationApprovalsAsync(Record: any) {
    //napravi validacija
    let response = await new DeclarationService().MyDeclarationApprovals(
      Record
    );
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async ExportDetailsAsync(Record: any) {
    //napravi validacija
    let response = await new DeclarationService().ExportDetails(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async ExportSummaryAsync(Record: any) {
    //napravi validacija
    let response = await new DeclarationService().ExportSummary(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async EditAsync(Record: any) {
    //napravi validacija
    let response = await new DeclarationService().Edit(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async GetDeclarationDetails(Record: any) {
    //napravi validacija
    let responseCost = await new DeclarationCostService().GetByDeclarationID(
      Record
    );
    let responseDiems = await new DeclarationDiemService().GetByDeclarationID(
      Record
    );
    let responses = [responseCost, responseDiems];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async SubmitAsync(Record: any) {
    //napravi validacija
    let response = await new DeclarationService().Submit(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async ClaimForBudgetApprovalAsync(Record: any) {
    //napravi validacija
    let response = await new DeclarationService().ClaimForBudgetApproval(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }
  // async DownloadDeclrationUrlAsync(Record: any) {
  //   //napravi validacija
  //   let response = await new DeclarationService().DownloadDeclarationUrl(
  //     Record
  //   );
  //   return response;
  // }

  async DownloadAsync(Record: any) {
    //napravi validacija
    let response = await new DeclarationService().Download(
      Record
    );
    return response;
  }
}
