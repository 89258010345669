import { enumConfig } from "./common.constants";

import { AES, enc } from 'crypto-js';

export default class LocalStorageHelper {

  private _secretPass: string = "XkhZG4fW2t2W";

  constructor() { }

  ExistByKey(keyName: string) {
    return localStorage.getItem(keyName) != null ? true : false;
  }

  GetValueByKey(keyName: string): string | null {
    //return  localStorage.getItem(keyName);
    let valueEnc: any = localStorage.getItem(keyName);
    if (!valueEnc) return null;
    
    let value = this.Decrypt(valueEnc);
    return value;
  }

  GetBoolValueByKey(keyName: string): boolean | null {
    const value = localStorage.getItem(keyName);
    if (value) {
      return (value === "true");
    }
    return false;
  }

  GetBoolValueByKeyWithDefValue(keyName: string, defValue: boolean): boolean | null {
    const value = localStorage.getItem(keyName);
    if (value) {
      return (value === "true");
    }
    return defValue;
  }

  GetJSONResponse(keyName: string) {
    if (this.ExistByKey(keyName)) {
      //alert("POSTOI VO CACHE");
      let responseCache: string | null = this.GetValueByKey(keyName);
      if (responseCache) {
        responseCache = JSON.parse(responseCache);
        let response = { data: responseCache }; //mora vaka za da moze da odi posle niz validacija na nivo na Actions
        return response;
      }
    }
    return null;
  }

  GetJSON(keyName: string) : {}  | null {
    if (this.ExistByKey(keyName)) {
      let responseCache: string | null = this.GetValueByKey(keyName);
      if (responseCache) {
        responseCache = JSON.parse(responseCache);
      }
      return responseCache as {};
    }
    return null;
  }


  SetJSON(keyName: string, keyValue: string) {
    this.SetValueByKey(keyName, JSON.stringify(keyValue));
  }



  SetValueByKey(keyName: string, keyValue: string) {
    //return localStorage.setItem(keyName, keyValue)
    let keyValueEnc = this.Encrypt(keyValue);
    return localStorage.setItem(keyName, keyValueEnc)
  }

  RemoveValueByKey(keyName: string) {
    return localStorage.removeItem(keyName)
  }

  RemoveAllItems() {
    localStorage.clear();
  }

  Encrypt(input: string): any {
    return AES.encrypt(input, this._secretPass);
    //return CryptoJS.AES.encrypt(input, this._secretPass)
  };

  Decrypt(input: string): any {
    
    try {

      let bytes = AES.decrypt(input, this._secretPass);
      const decrypted = bytes.toString(enc.Utf8);
      return decrypted;
    }
    catch {
      return "N/A";
    }

  };

}
