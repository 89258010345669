import { IErrorsInput, ValidatorHelper } from "../helpers/ValidatorHelper";
import { LookupDataService } from "../services/LookupDataService";
import { CountryService } from "../services/CountryService";
import { EmployeeService } from "../services/EmployeeService";
import { StaffService } from "../services/StaffService";
import { enumSharedMsgs } from "../helpers/common.constants";
import { FinBudgetService } from "../services/FinBudgetService";
import { OfficeService } from "../services/OfficeService";
import { FinProjectService } from "../services/FinProjectService";

export default class FinBudgetActions {
  async InitAsync(Record: any) {
    let responseProject = await new FinProjectService().Edit(Record);
    let responseLookup = await new LookupDataService().GetAll();

    let responses = [responseLookup, responseProject];

    //napravi validacija
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async SearchAsync(Record: any) {
    //napravi validacija
    let response = await new FinBudgetService().Search(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  
  async GetByProjectIDAsync(Record: any) {
    //napravi validacija
    let response = await new FinBudgetService().GetByProjectID(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }
  
  async SaveAsync(Record: any) {
    //napravi validacija
    
    let response = await new FinBudgetService().Save(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async ExportAsync(Record: any) {
    //napravi validacija
    let response = await new FinBudgetService().Export(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  
  async DeleteAsync(Record: any) {
    //napravi validacija
    let response = await new FinBudgetService().Delete(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }


}
