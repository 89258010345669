import { ValidatorHelper } from "../helpers/ValidatorHelper";
import { StaffService } from "../services/StaffService";
import { SystemUserService } from "../services/SystemUserService";
import { LogTypeService } from "../services/LogTypeService";
import { SystemLogService } from "../services/SystemLogService";


export default class SystemLogActions {

  async InitAsync() {
    let responseStaff  = await new StaffService().GetAll();
    let responseLogTypes  = await new LogTypeService().GetAllActive();
    let responses  = [responseStaff, responseLogTypes]

    //napravi validacija
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }
 
  async SearchAsync(Record : any) {
   
    //napravi validacija
    let response = await new SystemLogService().Search(Record);
    let responses  = [response]
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async SearchByRecordAsync(Record : any) {
   
    //napravi validacija
    let response = await new SystemLogService().SearchByRecord(Record);
    let responses  = [response]
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async PrepareAsync(Record : any) {
   
    //napravi validacija
    let response = await new SystemLogService().Prepare(Record);
    let responses  = [response]
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  
}