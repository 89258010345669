import * as React from 'react';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import PeopleIcon from '@mui/icons-material/People';
import DnsRoundedIcon from '@mui/icons-material/DnsRounded';
import { createBrowserHistory } from 'history';
import { useNavigate } from 'react-router-dom';
import AuthenticateHelper from './helpers/AuthenticateHelper';
import NavigationHelper from './helpers/NavigationHelper';
import { enumSQLS, enumRoutes } from './helpers/common.constants';
import MyAutoSuggest from './controls/MyAutoSuggest';

const item = {
  py: '2px',
  px: 3,
  color: 'rgba(255, 255, 255, 0.7)',
  '&:hover, &:focus': {
    bgcolor: 'rgba(255, 255, 255, 0.08)',
  },
};

const itemCategory = {
  boxShadow: '0 -1px 0 rgb(255,255,255,0.1) inset',
  py: 1.5,
  px: 3,
};

export default function Navigator(props) {
  const { ...other } = props;
  //console.error("YEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEEE");
  //console.error(props);

  const [menu, setMenu] = React.useState(props.Menu);
  const [currentMenu, setCurrentMenu] = React.useState({});
  const navigate = useNavigate();

  const goToUrl = (url) => {
    let currentUrl = url;

    if (currentUrl.toString().indexOf("https") > -1) {
      window.open(currentUrl, "_blank");
    }
    else {
      currentUrl = currentUrl + '?' + new Date().getTime(); //za da forsira REFRESH
      navigate(currentUrl);
    }
    
  };

  React.useEffect(() => {
    setMenu(props.Menu);
  }, [new Date().getTime()]); //default


  function getIconForName(iconName) {

    switch (iconName) {

      case 'EditIcon': {
        return <PeopleIcon />;
      }

      case 'SearchIcon': {
        return <DnsRoundedIcon />;
      }

      default: {
        return <PeopleIcon />;
      }
    }
  }


  return (
    <Drawer variant="permanent" {...other}>
      <List disablePadding>
        <ListItem underline="none" rel="noopener noreferrer" sx={{ ...item, ...itemCategory, fontSize: 22, color: '#fff' }}>
          <ListItemButton sx={item} onClick={() => goToUrl(enumRoutes.Main_Dashboard)} alignItems='center'>
            <img src="/images/logo_white.png" onClick={() => goToUrl(enumRoutes.Main_Dashboard)} />
          </ListItemButton>
        </ListItem>
        {menu && menu.map(({ name, url, data, index }) => (
          <Box key={index} sx={{ bgcolor: '#101F33' }} padding={0}>
            <ListItem sx={{ py: 0, px: 3 }}>
              <ListItemText sx={{ color: '#fff' }}>
                {
                  data ? name : //ako ima submenus togas ke gi prikaze samo imeto vo sprotivno ke se prikaze GoToUrl
                    <ListItemButton sx={{ pl: 0, pr: 0, pt: 0, pb: 0 }} onClick={() => goToUrl(url)} >
                      {name}
                    </ListItemButton>
                }
              </ListItemText>
            </ListItem>
            {data && data.filter((x) => x.visibleInMenu === true).map(({ title, url, index, icon }) => (
              <ListItem disablePadding key={index}>
                <ListItemButton sx={item} onClick={() => goToUrl(url)}>
                  {<ListItemIcon>{getIconForName(icon)}</ListItemIcon>}
                  <ListItemText>{title}</ListItemText>
                </ListItemButton>
              </ListItem>
            ))}

            <Divider sx={{ mt: 1 }} />
          </Box>
        ))}
      </List>
    </Drawer>
  );
}
