import { RestHelper } from '../helpers/RestHelper';

export class UserService {

  private _restHelper;

  constructor() {
    this._restHelper = new RestHelper();
  }

  async GetUserDetails(payload: any = null) {
    const url: string = "User/GetUserDetails";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  
  async ChangePassword(payload : any)  {
    const url : string  = "User/ChangePassword";
    let response = await this._restHelper.post(url, payload);
    return response;
  }
}
