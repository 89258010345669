// @ts-check
import { useEffect } from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import MyText from "../../controls/MyText";
import MyDropDown from "../../controls/MyDropDown";
import MySearchFilter from "../../controls/MySearchFilter";
import MySearchDialog from "../../controls/MySearchDialog";

import { useState } from "react";
import MyAlert from "../../controls/MyAlert";
import {
  enumBreadcrumbPage,
  enumLookupTypes,
  enumPage,
  enumRoutes,
  enumSharedMsgs,
} from "../../helpers/common.constants";
import EmployeeActions from "../../actions/EmployeeActions";
import Button from "@mui/material/Button";
import MyCheckList from "../../controls/MyCheckList";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Breadcrumbs,
  Card,
  CardActions,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useParams, useLocation, useNavigate, Link } from "react-router-dom";
import CurrencyHelper from "../../helpers/CurrencyHelper";
import MyMoney from "../../controls/MyMoney";
import MyNumber from "../../controls/MyNumber";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";
import React from "react";
// import AcquisitionBreadcrumbs from "./EmployeeBreadcrumbs";
import NotesIcon from "@mui/icons-material/Notes";
import BrightnessAutoIcon from "@mui/icons-material/BrightnessAuto";
import EmployeeBreadcrumbs from "../Employee/EmployeeBreadcrumbs";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ExactonlineActions from "../../actions/ExactonlineActions";
import ExactonlineBreadcrumbs from "./ExactonlineBreadcrumbs";
import { MessageHelper } from "../../helpers/MessageHelper";

const RecordDefault = {
  ID: "",
  AmountDC: 0,
  AmountFC: 0,
  FinancialYear: 0,
  CostCenter: "",
  AccountName: "",
  Description: "",
  Created: moment(Date.now()).format("YYYY-MM-DD"),
  GLAccount: "",
  GLAccountDescription: "",
  Date: moment(Date.now()).format("YYYY-MM-DD"),
  EntryID: "",
  JournalCode: "",
  JournalDescription: "",
};
export default function EmployeeOverview(args) {
  // console.error("AcquisitionManage - ARGS");
  // console.error(args);

  //Page MAIN Hooks
  const [IsNewRecord, setIsNewRecord] = useState(true);
  const [IsInitDone, setIsInitDone] = useState(true);
  const [MessageData, setMessageData] = useState({});
  const [Record, setRecord] = useState(RecordDefault);

  const [Records, setRecords] = useState([]);

  //Da proveri dali e nov zapis ili postoecki
  const { Code, Year } = useParams();

  //za Navigacija
  const navigate = useNavigate();

  //Za Refreshiranje na strana mu treba;
  const location = useLocation();

  //za convertiranje na Pari
  let currencyHelper = new CurrencyHelper();

  //za poraki pri loadiranje na strana
  const msgHelper = new MessageHelper();


  //****************************************** */
  //HOOKS SECTION HERE
  //****************************************** */

  //1 --> Ke se povika ovoj HOOK samo dokolku se SMENI url-ot poradi toa na new dodavame Ticks() za da forcirame refresh na strana
  useEffect(() => {
    initData();
  }, [location.pathname + "/" + location.search]);

  //****************************************** */
  //METHOD  SECTION HERE
  //****************************************** */

  const initData = () => {
    async function fetchData() {
      setIsNewRecord(true);

      //Load Start
      setRecords([]);

      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      let payload = { Costcenter: Code, LoadRelations: true, ReportingYear: Year };
      console.log(payload);
      let responses = await new ExactonlineActions().EditAsync(payload); // await LookupActions.InitAsync();

      if (!responses.error) {
        let records = responses[0].data;

        setRecords(records);

        //Load Finished
        result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);
      } else {
        //Load with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }
    }
    fetchData();
  };

  //****************************************** */
  //HANDLERS SECTION HERE
  //****************************************** */

  //****************************************** */
  //GUI DISPLAY
  //****************************************** */

  return (
    <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
      <ExactonlineBreadcrumbs page={enumBreadcrumbPage.Overview} Id={Record.ID} />

      <MyAlert {...MessageData} />
      <RenderTable Records={Records} />
    </Typography>
  );
}
const RenderTable = ({ Records }) => {
  const navigate = useNavigate();

  return React.useMemo(() => {
    return (
      <TableContainer sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="h4" align="left">
                  Date
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h4" align="left">
                  Journal
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h4" align="left">
                  G/L Account
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h4" align="left">
                  Description
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h4" align="left"></Typography>
                Debit
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Records.map((row, index) => (
              <TableRow
                key={row.eyEmployeeID}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {/* <TableCell>{index + 1}.</TableCell> */}
                <TableCell sx={{ textAlign: "left" }}>
                  {index + 1}. {moment(row.Date).format("D/MM/YYYY")}
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row.JournalDescription}
                </TableCell>

                <TableCell sx={{ textAlign: "left" }}>
                  {row.GLAccountDescription}
                </TableCell>

                <TableCell sx={{ textAlign: "left" }}>
                  {row.Description}
                </TableCell>
                <TableCell sx={{ textAlign: "right" }}>
                  {new CurrencyHelper().ToEuro(row.AmountDC)}
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>Total</TableCell>
              <TableCell sx={{ textAlign: "left" }}></TableCell>
              <TableCell sx={{ textAlign: "left" }}></TableCell>
              <TableCell sx={{ textAlign: "left" }}></TableCell>
              <TableCell sx={{ textAlign: "right", fontWeight: "bold" }}>
                {new CurrencyHelper().ToEuro(
                  Records.reduce((sum, row) => sum + (row.AmountDC || 0), 0)
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }, [Records]);
};
