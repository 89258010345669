// @ts-check
import * as React from "react";
import Typography from "@mui/material/Typography";
import MyText from "../../controls/MyText";
import { useEffect, useState } from "react";
import MyAlert from "../../controls/MyAlert";
import {
  enumBreadcrumbPage,
  enumLocalStorageArgs,
  enumLookupTypes,
  enumPage,
  enumPagination,
  enumRoutes,
  enumSQLS,
  enumSharedMsgs,
} from "../../helpers/common.constants";
import EmployeeActions from "../../actions/EmployeeActions";
import Button from "@mui/material/Button";
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import LogHelper from "../../helpers/LogHelper";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import EmployeeBreadcrumbs from "./DeclarationBreadcrumbs";
import MyDropDown from "../../controls/MyDropDown";
import Link from "@mui/material/Link";
import { MessageHelper } from "../../helpers/MessageHelper";
import DeclarationBreadcrumbs from "./DeclarationBreadcrumbs";
import DeclarationActions from "../../actions/DeclarationActions";
import AuthenticateHelper from "../../helpers/AuthenticateHelper";
import moment from "moment";
import LocalStorageHelper from "../../helpers/LocalStorageHelper";
import MyDropDownTable from "../../controls/MyDropDownTable";
import MyAutoSuggest from "../../controls/MyAutoSuggest";

const RecordDefault = {
  PageSize: enumPagination.PageSize,
  prBudgetHolderID: 0,
  prYear: 2024,
  prProjectID: 0
};

export default function DeclarationProjectBudgetHolderChange() {
  const [Record, setRecord] = useState(RecordDefault);
  const [MessageData, setMessageData] = useState({});
  const [Records, setRecords] = useState([]);
  const [IsInitDone, setIsInitDone] = useState(false);
  const [EmployeesData, setEmployeesData] = useState([]);
  const [ProjectYearsData, setProjectYearsData] = useState([]);

  const [ProjectsData, setProjectsData] = useState([]);

  const [IsProfileRegistration, setProfileRegistration] = useState(false);
  const [IsNewRecord, setIsNewRecord] = useState(true);

  //Za Refreshiranje na strana mu treba;
  const location = useLocation();

  //za poraki pri loadiranje na strana
  const msgHelper = new MessageHelper();

  //****************************************** */
  //HOOKS SECTION HERE
  //****************************************** */

  //1 --> Ke se povika ovoj HOOK samo dokolku se SMENI url-ot poradi toa na new dodavame Ticks() za da forcirame refresh na strana na sekoj click
  useEffect(() => {
    initData();
  }, [location.pathname + "/" + location.search]);

  //2 --> Od koga e sredena InitData() povikaj Search  (https://stackoverflow.com/questions/54069253/the-usestate-set-method-is-not-reflecting-a-change-immediately)
  useEffect(() => {
    if (IsInitDone) {
      searchData();
    }
  }, [IsInitDone]);

  //****************************************** */
  //METHOD  SECTION HERE
  //****************************************** */

  const initData = () => {
    async function fetchData() {
      //Clean all states
      setIsInitDone(false);
      setRecord(RecordDefault);

      //Proveri dali slucajno ima vo Local Storage definirani Search Arguments
      let RecordFromStorage = new LocalStorageHelper().GetJSON(
        enumLocalStorageArgs.DeclarationSearch
      );
      if (RecordFromStorage) {
        setRecord(RecordFromStorage);
      }

      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      let responses = await new DeclarationActions().InitProjectBudgetChangeAsync(); // await LookupActions.InitAsync();
      if (!responses.error) {
        let employeesData = responses[1].data;
        let projectYearsData = responses[2].data;
        let projectsData = responses[3].data;

        setEmployeesData(employeesData);
        setProjectYearsData(projectYearsData);
        setProjectsData(projectsData);

        setIsInitDone(true);

        //Load Finished
        result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);
      } else {
        //Load with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }
    }
    fetchData();
  };

  const saveData = (Record) => {
    async function fetchData() {
      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);
      console.log("REKORDOOOTTT od Save");
      console.log(Record);
      let response = await new DeclarationActions().SaveProjectAsync(Record);
      if (!response.error) {
        let recordDb = response[0].data;
        setRecord(recordDb);

        if (recordDb.eyRemoved) {
          result = msgHelper.setPhase(
            enumPage.LoadSuccessWithWarningMsg,
            null,
            "Profile is set as not active!"
          );
          setMessageData(result);
          //setIsProfileLocked(true);
          //setIsNewRecord(false);
        } else if (IsProfileRegistration) {
          result = msgHelper.setPhase(
            enumPage.LoadSuccessWithMsg,
            null,
            "Thank you for your registration. Email is sent to your account and  HR Department, now they will create Contract record for you and you can further use the system!"
          );
          setMessageData(result);
        } else {
          //Load Finished
          result = msgHelper.setPhase(enumPage.SaveSuccess);
          setMessageData(result);
          setRecord((a) => ({
            ...a,
            ValidationMessages: result.ValidationMessages,
          })); //Ako ima Validation Messages da gi izbrise
          setIsNewRecord(false);
        }
      } else {
        //Finished  with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, response);
        setMessageData(result);
        setRecord((a) => ({
          ...a,
          ValidationMessages: result.ValidationMessages,
        })); //Samo ovdeka mu treba ako ima Validacioni greski da dodade
      }
    }

    fetchData();
  };

  const searchData = () => {
    async function fetchData() {
      //Load Start
      setRecords([]);
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      let responses = await new DeclarationActions().SearchProjectAsync(Record);
      if (!responses.error) {
        const records = responses[0].data;

        setRecords(records);

        //Load Finished
        result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);

        //setiraj JSON vo Local Storage za da citas posle od tamu na sleden comeback
        new LocalStorageHelper().SetJSON(
          enumLocalStorageArgs.DeclarationSearch,
          Record
        );
      } else {
        //Load with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }
    }
    fetchData();
  };


  //****************************************** */
  //HANDLERS SECTION HERE
  //****************************************** */
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name + ":" + value);
    setRecord((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleChangeBudgetHolder = (e) => {

    //Ova logika raboti so MyDropDownTable

    //Name ==> PrBudgetHolderID_63 kade 63 e ProjectId 
    //ProjectId se naoga so replace-> PrBudgetHolderID_63 vo 63
    //PropertyName se naoga so replace-> PrBudgetHolderID_63 vo PrBudgetHolderID za da mapira vo kolekcijata

    //ke go najde tekovniot red vo kolekcijata po ProjectId
    //let currentProject = Records.find((a) => a.prProjectID.toString() === projectID);

    //ke go smeni po property name vo ovoj slucaj prBudgetHolderID
    //currentProject[propertyName] = value;

    //ke ja osvezi kolekcija prvo ke go najde INDEX_ot na red-ot
    //let propertyIndex = Records.findIndex((x) => x.prProjectID == projectID);

    //posle ke osvezi 
    //Records[propertyIndex] = currentProject;

    //na kraj state-ot ke go osvezi
    //setRecords(Records);



    const { name, value } = e.target; //vo name-ot go imame proektot
    console.log(name + ":" + value);
    setRecord((prevState) => ({ ...prevState, [name]: value }));

    let projectID = name.replace("prBudgetHolderID_", ""); //za da moze da menuvam
    let propertyName = "prBudgetHolderID";

    //Treba da zakacas RECORDS a ne Record 

    //let NewRecords = Records;
    let currentProject = Records.find((a) => a.prProjectID.toString() === projectID);
    currentProject[propertyName] = value;
    let propertyIndex = Records.findIndex((x) => x.prProjectID == projectID);
    Records[propertyIndex] = currentProject;
    setRecords(Records);
  };

  //****************************************** */
  //GUI DISPLAY
  //****************************************** */
  const ToolbarButtons = () => {
    return (
      <React.Fragment>
        <Button
          sx={{ ml: 3, mr: 2, mt: 3.5 }}
          variant="contained"
          color="success"
          size="small"
          disabled={MessageData.Loading}
          onClick={() => searchData()}
        >
          Search
        </Button>
      </React.Fragment>
    );
  };

  return (
    <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
      <MyAlert {...MessageData} />

      <DeclarationBreadcrumbs
        page={enumBreadcrumbPage.Search}
        disabled={MessageData.Loading}
      />

      <Card sx={{ minWidth: 275, borderRadius: 2, mb: 2 }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={2}>
              <MyDropDown
                Id="prYear"
                Record={Record}
                PlaceHolder="Years"
                HandleChange={handleChange}
                Options={ProjectYearsData}
              />
            </Grid>
            <Grid>
              <ToolbarButtons />
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <TableContainer sx={{ display: { xs: "block", sm: "block", md: "block" } }}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="h4" align="center">
                  Project Name
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h4" align="center">
                  Budget Holder
                </Typography>
              </TableCell>

              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Records.map((row, index) => (
              <TableRow
                key={row.prProjectID}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  {index + 1}. {row.ProjectName}
                </TableCell>

                {/* <TableCell>{row.BudgetHolderName}</TableCell> */}

                <TableCell>
                  <MyDropDownTable
                    Id={"prBudgetHolderID_" + row.prProjectID}
                    Record={row}
                    PlaceHolder="Employees"
                    HandleChange={handleChangeBudgetHolder} // Passing the handleChange function
                    Options={EmployeesData} // Passing the EmployeesData array
                  />
                </TableCell>

                <TableCell align="center">
                  <Button
                    sx={{ ml: 3, mr: 2, mt: 3.5 }}
                    variant="contained"
                    color="success"
                    size="small"
                    disabled={MessageData.Loading}
                    onClick={() => saveData(row)}
                  >
                    Save
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>


      {/* <RenderTable
        Records={Records}
        handleChange={handleChange}
        EmployeesData={EmployeesData}
        MessageData={MessageData}
        saveData={saveData}
        handleChangeBudgetHolder={handleChangeBudgetHolder}
      /> */}
    </Typography>
  );
}

