// @ts-check
import { useEffect } from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import MyText from "../../controls/MyText";
import MyDropDown from "../../controls/MyDropDown";
import MySearchFilter from "../../controls/MySearchFilter";
import MySearchDialog from "../../controls/MySearchDialog";

import { useState } from "react";
import MyAlert from "../../controls/MyAlert";
import {
  enumBreadcrumbPage,
  enumData,
  enumLookupTypes,
  enumPage,
  enumRoutes,
  enumSharedMsgs,
  enumSQLS,
} from "../../helpers/common.constants";
import AcquisitionActions from "../../actions/AcquisitionActions";
import Button from "@mui/material/Button";
import MyCheckList from "../../controls/MyCheckList";
import { Grid } from "@mui/material";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import CurrencyHelper from "../../helpers/CurrencyHelper";
import MyMoney from "../../controls/MyMoney";
import MyNumber from "../../controls/MyNumber";
import SystemUserActions from "../../actions/SystemUserActions";
import MyCheckbox from "../../controls/MyCheckbox";
import SystemUserBreadcrumbs from "./FinProjectBreadcrumbs";
import VisibilityIcon from "@mui/icons-material/Visibility";
import moment from "moment";
import FinProjectActions from "../../actions/FinProjectActions";
import MyDate from "../../controls/MyDate";
import { MessageHelper } from "../../helpers/MessageHelper";
import MyAutoSuggest from "../../controls/MyAutoSuggest";
import FinHeaderButtons from "./FinHeaderButtons";
import MyCurrency from "../../controls/MyCurrency";
import FinProjectHeader from "./FinProjectHeader";

const RecordDefault = {
  fpProjectID: 0,
  fpYear: moment(Date.now()).format("YYYY"),
  fpCode: "",
  fpName: "",
  fpDescription: "",
  fpStartDate: moment().startOf('year').format('YYYY-MM-DD'),
  fpEndDate: moment().endOf('year').format('YYYY-MM-DD'),
  fpVersionNo: 1,
  fpVersionDate: '1900-01-01',
  fpHolderCount: 0,
  fpOwnerID: -1,
  fpOwnerName: "",
  fpOwnerEmail: "",

  fpHolderID: -1,
  fpHolderName: "",
  fpHolderEmail: "",
  fpHolderID2: -1,
  fpHolderName2: "",
  fpHolderEmail2: "",
  fpHolderID3: -1,
  fpHolderName3: "",
  fpHolderEmail3: "",
  fpActiveFL: false,
  fpAmount: "",
  fpVerifiedFL: false,
  IsLocked: false
};

export default function FinProjectManage(args) {
  // console.error("AcquisitionManage - ARGS");
  // console.error(args);

  //Page MAIN Hooks
  const [IsNewRecord, setIsNewRecord] = useState(true); 
  const [IsInitDone, setIsInitDone] = useState(true);
  const [MessageData, setMessageData] = useState({});
  const [Record, setRecord] = useState(RecordDefault);

  //Da proveri dali e nov zapis ili postoecki
  const { ProjectID, showMsg } = useParams();

  //za Navigacija
  const navigate = useNavigate();

  //Za Refreshiranje na strana mu treba;
  const location = useLocation();

  const msgHelper = new MessageHelper();

  //****************************************** */
  //HOOKS SECTION HERE
  //****************************************** */

  //1 --> Ke se povika ovoj HOOK samo dokolku se SMENI url-ot poradi toa na new dodavame Ticks() za da forcirame refresh na strana
  useEffect(() => {
    initData();
  }, [location.pathname + "/" + location.search]);


  //****************************************** */
  //METHOD  SECTION HERE
  //****************************************** */

  const initData = () => {
    async function fetchData() {

      setRecord(RecordDefault);
      setIsNewRecord(true);

      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      if (ProjectID) {
        let payload = { fpProjectId: ProjectID };
        let responses = await new FinProjectActions().EditAsync(payload); // await LookupActions.InitAsync();

        if (!responses.error) {
          let recordDb = responses[0].data;
          setRecord(recordDb);
          setIsNewRecord(false);

          //Load Finished
          result = msgHelper.setPhase(enumPage.LoadSuccess);
          setMessageData(result);

          if (showMsg) {
            result = msgHelper.setPhase(enumPage.LoadSuccessWithMsg, null, "Project has been created, please use the buttons for Budget Lines and Allocations, please note once allocation is added project is locked for further changes");
            setMessageData(result);
          }

          // if (recordDb.IsLocked) {
          //   result = msgHelper.setPhase(enumPage.LoadSuccessWithWarningMsg, null, "Project can't be changed, allocation were detected.");
          //   setMessageData(result);
          // }

        } else {
          //Finished  with Error
          result = msgHelper.setPhase(enumPage.ErrorServer, responses);
          setMessageData(result);
        }
      }
      else {
        result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);
      }

    }
    fetchData();
  };

  const saveData = () => {
    async function fetchData() {

      //Load Start
      let isNew = Record.fpProjectID === 0;
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);
      let responses = await new FinProjectActions().SaveAsync(Record);

      if (!responses.error) {
        let recordDb = responses[0].data;
        setRecord(recordDb);

        result = msgHelper.setPhase(enumPage.SaveSuccess);
        setMessageData(result);

        if (isNew) {
          navigate(enumRoutes.Timesheet_FinProject_Edit_WithMsg.replace(":ProjectID", recordDb.fpProjectID).replace(":showMsg", true));
        }
        else {
          navigate(enumRoutes.Timesheet_FinProject_Edit.replace(":ProjectID", recordDb.fpProjectID));

        }

      } else {
        //Finished  with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);

        setRecord((a) => ({
          ...a,
          ValidationMessages: result.ValidationMessages,
        }));
      }
    }

    fetchData();
  };

  //****************************************** */
  //HANDLERS SECTION HERE
  //****************************************** */
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name + ":" + value);
    setRecord((prevState) => ({ ...prevState, [name]: value }));
  };

  //****************************************** */
  //GUI DISPLAY
  //****************************************** */
  const ActionButtons = () => {
    return (
      <Grid container align="right" padding={1}>
        <Grid item xs={12}>
          <Button
            name="btnSave"
            variant="contained"
            sx={{ mr: 1 }}
            color="success"
            size="small"
            disabled={MessageData.Loading || Record.IsLocked}
            onClick={() => saveData()}
          >
            Save
          </Button>
        </Grid>
      </Grid>
    );
  };


  return (
    <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
      
      <MyAlert {...MessageData} />



      {Record.fpProjectID > 0 &&
      <FinProjectHeader/>
      }

      <FinHeaderButtons />

      <ActionButtons />


      {!IsNewRecord && (
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6" display="block" align="center">
              {Record.fpName}
            </Typography>
          </Grid>
        </Grid>
      )}

      <Grid container>
        <Grid item xs={12}>
          <MyText
            Id="fpName"
            PlaceHolder="Project Name"
            Record={Record}
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            Required={true}
            Disabled={Record.IsLocked}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <MyText
            Id="fpDescription"
            PlaceHolder="Description"
            Record={Record}
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            Required={true}
            Disabled={Record.IsLocked}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} sm={12} md={2}>
        <MyDropDown
          Id="fpYear"
          Record={Record}
          PlaceHolder="Year"
          HandleChange={handleChange}
          Options={enumData.YEARS}
          Disabled={Record.IsLocked}
        />
      </Grid>



      <Grid container>
        <Grid item xs={12}>
          <MyDate
            Id="fpStartDate"
            PlaceHolder="Start Date"
            Record={Record}
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            Required={true}
            Disabled={Record.IsLocked}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <MyDate
            Id="fpEndDate"
            PlaceHolder="End Date"
            Record={Record}
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            Required={true}
            Disabled={Record.IsLocked}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <MyAutoSuggest
            Id="fpOwnerID"
            Record={Record}
            PlaceHolder="Budget Holder Name"
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            Required={true}
            SQLGetList={enumSQLS.KEY_LIST_EmployeeSearch}
            SQLGetRecord={enumSQLS.KEY_GET_Employee}
            Disabled={Record.IsLocked}
          />
        </Grid>
      </Grid>


      <Grid container>
        <Grid item xs={12}>
          <MyAutoSuggest
            Id="fpHolderID"
            Record={Record}
            PlaceHolder="Verifier Name #1"
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            Required={true}
            SQLGetList={enumSQLS.KEY_LIST_EmployeeSearch}
            SQLGetRecord={enumSQLS.KEY_GET_Employee}
            Disabled={Record.IsLocked}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <MyAutoSuggest
            Id="fpHolderID2"
            Record={Record}
            PlaceHolder="Verifier Name  #2"
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            Required={true}
            SQLGetList={enumSQLS.KEY_LIST_EmployeeSearch}
            SQLGetRecord={enumSQLS.KEY_GET_Employee}
            Disabled={Record.IsLocked}
          />
        </Grid>
      </Grid>      

      <Grid container>
        <Grid item xs={12}>
          <MyAutoSuggest
            Id="fpHolderID3"
            Record={Record}
            PlaceHolder="Verifier Name #3"
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            Required={true}
            SQLGetList={enumSQLS.KEY_LIST_EmployeeSearch}
            SQLGetRecord={enumSQLS.KEY_GET_Employee}
            Disabled={Record.IsLocked}
          />
        </Grid>
      </Grid>            

      <Grid container>
        <Grid item xs={12}>
          <MyCurrency
            Id="fpAmount"
            PlaceHolder="Amount"
            Record={Record}
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            Required={true}
            Disabled={Record.IsLocked}
          />
        </Grid>
      </Grid>

      <Grid container align="right" padding={1}>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="success"
            size="small"
            disabled={Record.IsLocked}
            onClick={() => saveData()}
          >
            Save
          </Button>
        </Grid>
      </Grid>

      <MyAlert {...MessageData} />
    </Typography>
  );
}
