// @ts-check
import { useEffect } from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import MyText from "../../controls/MyText";
import MyDropDown from "../../controls/MyDropDown";
import MySearchFilter from "../../controls/MySearchFilter";
import MySearchDialog from "../../controls/MySearchDialog";

import { useState } from "react";
import MyAlert from "../../controls/MyAlert";
import {
  enumBreadcrumbPage,
  enumLookupTypes,
  enumPage,
  enumRoutes,
  enumSharedMsgs,
} from "../../helpers/common.constants";
import EmployeeActions from "../../actions/EmployeeActions";
import Button from "@mui/material/Button";
import MyCheckList from "../../controls/MyCheckList";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Breadcrumbs,
  Card,
  CardActions,
  CardContent,
  Grid,
} from "@mui/material";
import { useParams, useLocation, useNavigate, Link } from "react-router-dom";
import CurrencyHelper from "../../helpers/CurrencyHelper";
import MyMoney from "../../controls/MyMoney";
import MyNumber from "../../controls/MyNumber";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";
import React from "react";
import AcquisitionBreadcrumbs from "./EmployeeBreadcrumbs";
import NotesIcon from "@mui/icons-material/Notes";
import BrightnessAutoIcon from "@mui/icons-material/BrightnessAuto";
import EmployeeBreadcrumbs from "./EmployeeBreadcrumbs";
import VisibilityIcon from "@mui/icons-material/Visibility";

const RecordDefault = {
  eyEmployeeID: 0,
  eyUserName: "string",
  eyFirstName: "Some information",
  eyLastName: "string",
  eyEmail: "spark@spark-online" + new Date().getTime() + ".com",
  eyDepartmentID: 0,
  eyType: true,
  eyFunctionName: "1183",
  eyOfficeName: "string",
  eyRemoved: true,
  eyPassword: "string",
  eyBirthDate: moment(Date.now()).format("YYYY-MM-DD"),
  ReceiveNotifications: true,
  eyAddress: "string",
  eyCity: "string",
  eyCountry: "",
  eyMobPhone: "string",
  eySkype: "string",
  eyPrivateEmail: "privateemail@" + new Date().getTime() + ".com",
  eyWorkPermit: moment(Date.now()).format("YYYY-MM-DD"),
  eyWorkPermit2: moment(Date.now()).format("YYYY-MM-DD"),
  eyDeclarationSupperiorID: 2,
  eyCountryID: 2,
  eyCountryOriginID: 2,
  eyPassportID: "string",
  eyPlaceOfBirth: "string",
  eyNationalityID: 2,
  eyDomicileID: 2,
  eyResidenceID: 2,
  eyHRPosition: 2,
  eyHRLevelOfPosition: 0,
  eyHRPosition2: "string",
  eyOfficePayrollName: "string",
  eyTerminateDate: moment(Date.now()).format("YYYY-MM-DD"),
  eyTerminateReason: "string",
  eyFunctionID: 1183,
  eyOfficeID: 1455,
  eyItemGUID: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  eyCreatedBy: "string",
  eyCreatedDate: moment(Date.now()).format("YYYY-MM-DD"),
  eySuperiorID: 2,
};

export default function EmployeeOverview(args) {
  // console.error("AcquisitionManage - ARGS");
  // console.error(args);

  //Page MAIN Hooks
  const [IsNewRecord, setIsNewRecord] = useState(true);
  const [IsInitDone, setIsInitDone] = useState(true);
  const [MessageData, setMessageData] = useState({});
  const [Record, setRecord] = useState(RecordDefault);

  const [LookupData, setLookupData] = useState([]);
  const [StaffData, setStaffData] = useState([]);
  const [YearsData, setYearsData] = useState([]);

  const [DoCalculation, setDoCalculation] = useState(false);
  const [CountryData, setCountryData] = useState([]);

  //Da proveri dali e nov zapis ili postoecki
  const { EmployeeID } = useParams();

  //za Navigacija
  const navigate = useNavigate();

  //Za Refreshiranje na strana mu treba;
  const location = useLocation();

  //za convertiranje na Pari
  let currencyHelper = new CurrencyHelper();

  //****************************************** */
  //HOOKS SECTION HERE
  //****************************************** */

  //1 --> Ke se povika ovoj HOOK samo dokolku se SMENI url-ot poradi toa na new dodavame Ticks() za da forcirame refresh na strana
  useEffect(() => {
    initData();
  }, [location.pathname + "/" + location.search]);

  //****************************************** */
  //METHOD  SECTION HERE
  //****************************************** */
  const setPageMsg = (state, response) => {
    if (state == enumPage.LoadStart) {
      setMessageData((a) => ({ ...a, Message: "", Loading: true }));
    } else if (state == enumPage.LoadSuccess) {
      setMessageData((a) => ({ ...a, Loading: false }));
    } else if (state == enumPage.ErrorServer) {
      if (response.ValidationMessages) {
        setRecord((prevState) => ({
          ...prevState,
          ValidationMessages: response.ValidationMessages,
        })); //za da se reflekgiraat po ZSNText,
      }
      setMessageData((a) => ({
        ...a,
        HasError: true,
        Message: response.error_description,
        Loading: false,
      }));
    } else if (state == enumPage.SaveSuccess) {
      setMessageData((a) => ({
        ...a,
        Loading: false,
        HasError: false,
        Message: enumSharedMsgs.Save,
      }));
    }
  };

  const initData = () => {
    async function fetchData() {
      setIsNewRecord(true);
      setPageMsg(enumPage.LoadStart);

      let payload = { eyEmployeeID: EmployeeID, LoadRelations: true };
      let responses = await new EmployeeActions().EditAsync(payload); // await LookupActions.InitAsync();

      if (!responses.error) {
        let recordDb = responses[0].data;
        setRecord(recordDb);
        setIsNewRecord(false);

        setPageMsg(enumPage.LoadSuccess);
      } else {
        setPageMsg(enumPage.ErrorServer, responses);
      }
    }
    fetchData();
  };

  //****************************************** */
  //HANDLERS SECTION HERE
  //****************************************** */

  //****************************************** */
  //GUI DISPLAY
  //****************************************** */
  const HeaderButtons = () => {
    return (
      <Grid
        container
        align="left"
        sx={{ borderBottom: 1, borderColor: "grey.500", pb: 1 }}
        align="center"
      >
        <Grid item xs={12} spacing={1}>
          <Button
            sx={{ mr: 2 }}
            variant="contained"
            startIcon={<VisibilityIcon />}
            size="small"
            disabled={MessageData.Loading || IsNewRecord}
            onClick={() =>
              navigate(
                enumRoutes.Employee_Edit.replace(
                  ":EmployeeID",
                  Record.eyEmployeeID
                )
              )
            }
          >
            Manage Employee
          </Button>

          <Button
            sx={{ mr: 2 }}
            variant="contained"
            startIcon={<NotesIcon />}
            size="small"
            disabled={MessageData.Loading || IsNewRecord}
            onClick={() =>
              navigate(
                enumRoutes.Employee_Contract_Get.replace(
                  ":EmployeeID",
                  Record.eyEmployeeID
                )
              )
            }
          >
            Manage Contracts
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
      <EmployeeBreadcrumbs
        page={enumBreadcrumbPage.Overview}
        Id={Record.eyEmployeeID}
      />

      <MyAlert {...MessageData} />
      <HeaderButtons />

      <Card sx={{ minWidth: 275, borderRadius: 2, mb: 2 }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Typography variant="h5">Employee:</Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="h6" sx={{ pl: 2.5 }}>
                {Record.eyFirstName} {Record.eyLastName}
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Typography variant="h5">Email:</Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="h6" sx={{ pl: 2.5 }}>
                {Record.eyEmail}
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Typography variant="h5">Office:</Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="h6" sx={{ pl: 2.5 }}>
                {Record.eyOfficeName}
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={2}>
              <Typography variant="h5">Active:</Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="h6" sx={{ pl: 2.5 }}>
                {Record.eyRemoved == 1 ? "No" : "Yes"}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Typography>
  );
}
