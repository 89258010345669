// @ts-check 
import { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import MyText from '../../controls/MyText';

import { useState } from 'react';
import MyAlert from '../../controls/MyAlert';
import { enumBreadcrumbPage, enumLeaveRequest, enumPage, enumRoutes, enumSharedMsgs } from '../../helpers/common.constants';
import NotificationActions from '../../actions/NotificationActions';
import Button from '@mui/material/Button';
import { Grid } from '@mui/material';
import { useParams, useLocation, useNavigate } from "react-router-dom";
import CurrencyHelper from '../../helpers/CurrencyHelper';
import moment from 'moment';
import MyDateTime from '../../controls/MyDateTime';
import DeclarationBreadcrumbs from './DeclarationBreadcrumbs';
import { MessageHelper } from '../../helpers/MessageHelper';
import MyDate from '../../controls/MyDate';
import NotesIcon from '@mui/icons-material/Notes';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeclarationActions from '../../actions/DeclarationActions';
import MyDropDown from '../../controls/MyDropDown';

const RecordDefault =
{
  "deDeclarationID": 0,
  "deYear": 0,
  "deName": "",
  "deEmployeeID": 0,
  "deEmployeeName": "string",
  "deEmployeeEmail": "string",
  "deItemGUID": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  "deCreatedDate": "2023-08-03T09:26:05.570Z",
  "deCreatedBy": "string",
  "deFinanceID": 0,
  "deFinanceFullName": "string",
  "deFinanceFullEmail": "string",
  "deFinanceDate": "2023-08-03T09:26:05.570Z",
  "deBudgetHolderID": 0,
  "deBudgetHolderFullName": "string",
  "deBudgetHolderEmail": "string",
  "deBudgetDate": "2023-08-03T09:26:05.570Z",
  "deSubmittedFL": false,
  "deFinanceApprovedFL": false,
  "deBudgetApprovedFL": false,
  "deCostTotal": 0,
  "dePerDiemTotal": 0,
  "deNote": "string",
  "AllowChangeProjectDeclaration": true
}


const RecordDefaultHourBalance =
{
  "TakenHours": 0,
  "BalanceHours": 0,
  "SuperiorID": 0,
  "SuperiorName": "",
  "ContractedHours": 0
}

export default function DeclarationManage(args) {

  //Page MAIN Hooks
  const [EnableButtons, setEnableButtons] = useState(true);
  const [IsNewRecord, setIsNewRecord] = useState(true);
  const [IsInitDone, setIsInitDone] = useState(true);
  const [MessageData, setMessageData] = useState({});
  const [ProjectData, setProjectData] = useState({});

  const [Record, setRecord] = useState(RecordDefault);
  const [RecordHourBalance, setRecordHourBalance] = useState(RecordDefaultHourBalance);

  //Da proveri dali e nov zapis ili postoecki
  const { DeclarationID } = useParams();

  //za Navigacija 
  const navigate = useNavigate();

  //Za Refreshiranje na strana mu treba;
  const location = useLocation();


  //za poraki pri loadiranje na strana 
  const msgHelper = new MessageHelper();

  //****************************************** */
  //HOOKS SECTION HERE 
  //****************************************** */

  //1 --> Ke se povika ovoj HOOK samo dokolku se SMENI url-ot poradi toa na new dodavame Ticks() za da forcirame refresh na strana
  useEffect(() => {
    initData();
  }, [location.pathname + '/' + location.search]);

  //2 --> Ako e postoecki zapis povikaj editData  (https://stackoverflow.com/questions/54069253/the-usestate-set-method-is-not-reflecting-a-change-immediately)
  useEffect(() => {
    if (IsInitDone) {

      //Check if is existing record and make EditData
      if (DeclarationID) {
        setIsNewRecord(false);

        editData();
      }
    }
  }, [IsInitDone]);



  //****************************************** */
  //METHOD  SECTION HERE 
  //****************************************** */

  const initData = () => {
    async function fetchData() {


      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);
      setIsInitDone(false);
      setEnableButtons(false);
      setRecord(RecordDefault);

      let payload = {}
      let responses = await new DeclarationActions().InitAsync(payload); // await LookupActions.InitAsync();
      if (!responses.error) {

        let responseProjects = responses[0].data;

        setProjectData(responseProjects);


        //Load Finished
        result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);

        setIsInitDone(true);
        setEnableButtons(true);
      }
      else {
        //Finished  with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }
    }

    fetchData();
  };


  const editData = () => {
    async function fetchData() {

      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);
      setEnableButtons(false);
      //setMessageData((a) => ({...a, Loading: true }));

      let payload = { deDeclarationID: DeclarationID };
      let responses = await new DeclarationActions().EditAsync(payload); // await LookupActions.InitAsync();
      if (!responses.error) {

        let recordDb = responses[0].data;
        setRecord(recordDb);

        if (recordDb.deSubmittedFL)
        {
          navigate(enumRoutes.Declaration_Submit.replace(":DeclarationID", recordDb.deDeclarationID));
          return
        }

        let result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);

        if (recordDb.deSubmittedFL) {
          result = msgHelper.setPhase(enumPage.LoadSuccessWithMsg, null, "Declaration is submitted and cant' be changed!");
          setMessageData(result);
        }
      }
      else {
        let result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
        setEnableButtons(true);
      }

    }
    fetchData();
  };

  const saveData = () => {
    console.error("saveData");
    async function fetchData() {

      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      let response = await new DeclarationActions().SaveAsync(Record);
      if (!response.error) {

        let recordDb = response[0].data;
        setRecord(recordDb);

        //Load Finished
        result = msgHelper.setPhase(enumPage.LoadSuccessWithMsg, null, "Declaration saved, please  add your costs and diems and after preview & submit them");
        setMessageData(result);
        setRecord((a) => ({ ...a, ValidationMessages: result.ValidationMessages }));  //Ako ima Validation Messages da gi izbrise
        setIsNewRecord(false);
      }
      else {
        //Finished  with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, response);
        setMessageData(result);
        setRecord((a) => ({ ...a, ValidationMessages: result.ValidationMessages }));  //Samo ovdeka mu treba ako ima Validacioni greski da dodade
      }
    }

    fetchData();
  };

  const submitData = () => {

    async function fetchData() {

      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      let response = await new DeclarationActions().SubmitAsync(Record);
      if (!response.error) {

        let recordDb = response[0].data;
        setRecord(recordDb);

        //Load Finished
        result = msgHelper.setPhase(enumPage.LoadSuccessWithMsg, null, "Declaration submitted, please wait for Finance and Budget Holder approval");
        setMessageData(result);
        setRecord((a) => ({ ...a, ValidationMessages: result.ValidationMessages }));  //Ako ima Validation Messages da gi izbrise
        setIsNewRecord(false);
      }
      else {
        //Finished  with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, response);
        setMessageData(result);
        setRecord((a) => ({ ...a, ValidationMessages: result.ValidationMessages }));  //Samo ovdeka mu treba ako ima Validacioni greski da dodade
      }
    }

    fetchData();
  };


  const goToCosts = () => {
    navigate(enumRoutes.Declaration_Cost_Edit.replace(":DeclarationID", Record.deDeclarationID));
  };

  const goToDiems = () => {
    navigate(enumRoutes.Declaration_Diem_Edit.replace(":DeclarationID", Record.deDeclarationID));
  };



  //****************************************** */
  //HANDLERS SECTION HERE 
  //****************************************** */
  const handleChange = (e) => {
    const { name, value } = e.target;

    console.log(name + ":" + value);
    setRecord((prevState) => ({ ...prevState, [name]: value }));
  };


  //****************************************** */
  //GUI DISPLAY
  //****************************************** */
  const ActionButtons = () => {
    return (
      <Grid container align="right" padding={0}>
        <Grid item xs={12}>
          <Button name="btnSave" variant="contained" sx={{ mr: 1 }} color="success" size="small" disabled={MessageData.Loading || Record.deSubmittedFL ||  !Record.AllowChangeProjectDeclaration} onClick={() => saveData()}>Save</Button>
          {/* <Button name="btnAddCosts" variant="contained" sx={{ mr: 1 }} color="primary" size="small" disabled={MessageData.Loading || Record.deSubmittedFL || (Record.deDeclarationID === 0)} onClick={() => goToCosts()}>Add Costs</Button>
          <Button name="btnAddDiems" variant="contained" sx={{ mr: 5 }} color="primary" size="small" disabled={MessageData.Loading || Record.deSubmittedFL || (Record.deDeclarationID === 0)} onClick={() => goToDiems()}>Add Diems</Button>
          <Button name="btnSubmit" variant="contained" sx={{ mr: 1 }} color="info" size="small" disabled={MessageData.Loading || Record.deSubmittedFL || (Record.deDeclarationID === 0)} onClick={() => submitData()}>Submit</Button> */}
        </Grid>
      </Grid>
    )
  };


  const HeaderButtons = () => {
    return (
      <Grid container sx={{ borderBottom: 1, borderColor: 'grey.500', pb: 1 }} align="center">
        <Grid item xs={12} spacing={1} >
          <Button sx={{ mr: 2 }} variant="contained" startIcon={<VisibilityIcon />} size="small" disabled={MessageData.Loading || IsNewRecord} onClick={() => navigate(enumRoutes.Declaration_Cost_Edit.replace(":DeclarationID", Record.deDeclarationID))}>
            Costs
          </Button>

          <Button sx={{ mr: 2 }} variant="contained" startIcon={<VisibilityIcon />} size="small" disabled={MessageData.Loading || IsNewRecord} onClick={() => navigate(enumRoutes.Declaration_Diem_Edit.replace(":DeclarationID", Record.deDeclarationID))}>
            Diems
          </Button>

          <Button sx={{ mr: 2 }} variant="contained" startIcon={<VisibilityIcon />} size="small" disabled={MessageData.Loading || IsNewRecord} onClick={() => navigate(enumRoutes.Declaration_Submit.replace(":DeclarationID", Record.deDeclarationID))}>
            Preview & Submit
          </Button>
        </Grid>
      </Grid>
    )
  };

  return (
    <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="center">

      <DeclarationBreadcrumbs page={enumBreadcrumbPage.Master} Id={Record.deDeclarationID} disabled={MessageData.Loading} />

      <MyAlert {...MessageData} />

      <HeaderButtons />

      <ActionButtons />

      <Grid container spacing={2} paddingBottom={0}>
        <Grid item xs={12} md={12}>
          <MyText Id='deName'
            PlaceHolder='Enter Declaration Name'
            Record={Record}
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            MultiLine={true}
            Rows={3}
            Required={true}
          />
        </Grid>
      </Grid>


      <Grid container>
        <Grid item xs={12} md={4}>
          <MyDropDown Id='deProjectName'
            Record={Record}
            PlaceHolder='-Select Project-'
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            Options={ProjectData}
            Required={true}
            OptionsText="Text"
            OptionsValue="Value"
          />
        </Grid>
      </Grid>


      <ActionButtons />

      <MyAlert {...MessageData} />


    </Typography>
  );
}

