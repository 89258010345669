// @ts-check
import * as React from "react";
import Typography from "@mui/material/Typography";
import MyText from "../../controls/MyText";
import { useEffect, useState } from "react";
import MyAlert from "../../controls/MyAlert";
import {
  enumBreadcrumbPage,
  enumConfig,
  enumData,
  enumLeaveRequestStatus,
  enumLocalStorageArgs,
  enumLookupTypes,
  enumPage,
  enumPagination,
  enumRoutes,
  enumSQLS,
  enumSharedMsgs,
} from "../../helpers/common.constants";
import EmployeeActions from "../../actions/EmployeeActions";
import Button from "@mui/material/Button";
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import LogHelper from "../../helpers/LogHelper";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import EmployeeBreadcrumbs from "./LeaveRequestBreadcrumbs";
import MyDropDown from "../../controls/MyDropDown";
import Link from "@mui/material/Link";
import { MessageHelper } from "../../helpers/MessageHelper";
import LeaveRequestBreadcrumbs from "./LeaveRequestBreadcrumbs";
import LeaveRequestActions from "../../actions/LeaveRequestActions";
import AuthenticateHelper from "../../helpers/AuthenticateHelper";
import moment from "moment";
import LocalStorageHelper from "../../helpers/LocalStorageHelper";
import DownloadActions from "../../actions/DownloadActions";
import MyAutoSuggest from "../../controls/MyAutoSuggest";

const RecordDefault = {
  PageSize: enumPagination.PageSize,
  StatusID: 0,
  SuperiorID: 0,
  EmployeeID: 0,
  OfficeID: 0,
  Year: 2024,
  TypeID: 0,
  ActiveEmployeesFL:1
};

export default function ReportLeaveRequestSummary() {
  const [Record, setRecord] = useState(RecordDefault);
  const [MessageData, setMessageData] = useState({});
  const [Records, setRecords] = useState([]);
  const [IsInitDone, setIsInitDone] = useState(false);
  const [YesNoAllData, setYesNoAllData] = useState([]);
  const [CountryData, setCountryData] = useState([]);
  const [EmployeesData, setEmployeesData] = useState([]);
  const [YearsData, setYearsData] = useState([]);
  //const [ApprovedSubmittedAllData, setApprovedSubmittedAllData] = useState([]);

  const [LookupData, setLookupData] = useState([]);

  //Za Refreshiranje na strana mu treba;
  const location = useLocation();

  //za poraki pri loadiranje na strana
  const msgHelper = new MessageHelper();

  //****************************************** */
  //HOOKS SECTION HERE
  //****************************************** */

  //1 --> Ke se povika ovoj HOOK samo dokolku se SMENI url-ot poradi toa na new dodavame Ticks() za da forcirame refresh na strana na sekoj click
  useEffect(() => {
    initData();
  }, [location.pathname + "/" + location.search]);


  //****************************************** */
  //METHOD  SECTION HERE
  //****************************************** */

  const initData = () => {
    async function fetchData() {
      //Clean all states
      setIsInitDone(false);
      setRecord(RecordDefault);

      //Proveri dali slucajno ima vo Local Storage definirani Search Arguments
      let RecordFromStorage = new LocalStorageHelper().GetJSON(enumLocalStorageArgs.LeaveRequestSearch);
      if (RecordFromStorage) {
        //setRecord(RecordFromStorage);
      }


      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      let responses = await new LeaveRequestActions().InitSearchAsync(); // await LookupActions.InitAsync();
      if (!responses.error) {
        let lookupData = responses[0].data;
        let countryData = responses[1].data;
        let employeesData = responses[2].data;
        let yearsData = responses[3].data;
        let yesNoAllData = responses[4].data;
        setYesNoAllData(yesNoAllData);
        //let approvedSubmittedAllData = responses[4].data;

        setLookupData(lookupData);
        setCountryData(countryData);
        setEmployeesData(employeesData);
        setYearsData(yearsData);
        //setApprovedSubmittedAllData(approvedSubmittedAllData);

        setIsInitDone(true);

        //Load Finished
        result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);
      } else {
        //Load with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }
    }
    fetchData();
  };



  const exportDetailsData = () => {
    async function fetchData() {
      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      Record.StatusID = enumLeaveRequestStatus.Approved;
      let responses = await new LeaveRequestActions().ExportDetailsAsync(
        Record
      );

      if (!responses.error) {
        //Load Finished
        let result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);

        //DOWNLOADING EXCEL FILE 
        const excelFileUrl = responses[0].data;
        let payload = "?inputFile=" + excelFileUrl;
        let downloadResponse = await new DownloadActions().DownloadFileAsync(payload);

        // create file link in browser's memory
        const href = URL.createObjectURL(downloadResponse.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', excelFileUrl); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        return;
      } else {
        //Load with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }
    }
    fetchData();
  };

  const exportSummaryData = () => {
    async function fetchData() {
      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      let responses = await new LeaveRequestActions().ExportSummaryAsync(
        Record
      );

      if (!responses.error) {
        //Load Finished
        let result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);

        //DOWNLOADING EXCEL FILE 
        const excelFileUrl = responses[0].data;
        let payload = "?inputFile=" + excelFileUrl;
        let downloadResponse = await new DownloadActions().DownloadFileAsync(payload);

        // create file link in browser's memory
        const href = URL.createObjectURL(downloadResponse.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', excelFileUrl); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        return;
      } else {
        //Load with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }
    }
    fetchData();
  };

  //****************************************** */
  //HANDLERS SECTION HERE
  //****************************************** */
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name + ":" + value);
    setRecord((prevState) => ({ ...prevState, [name]: value }));
  };

  //****************************************** */
  //GUI DISPLAY
  //****************************************** */
  const ToolbarButtons = () => {
    return (
      <React.Fragment>
        <Button
          sx={{ ml: 2, mr: 2, mt: 3.5 }}
          variant="contained"
          color="secondary"
          size="small"
          disabled={MessageData.Loading}
          onClick={() => exportSummaryData()}
        >
          Export summary
        </Button>

        <Button
          sx={{ mr: 2, mt: 3.5 }}
          variant="contained"
          color="secondary"
          size="small"
          disabled={MessageData.Loading}
          onClick={() => exportDetailsData()}
        >
          Export details
        </Button>

      </React.Fragment>
    );
  };

  return (
    <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">


      <MyAlert {...MessageData} />


      <Card sx={{ minWidth: 275, borderRadius: 2, mb: 2 }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={2}>
              <MyDropDown
                Id="Year"
                Record={Record}
                PlaceHolder="Year"
                HandleChange={handleChange}
                Options={YearsData}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={2}>
              <MyDropDown
                Id="OfficeID"
                Record={Record}
                PlaceHolder="Office"
                HandleChange={handleChange}
                Options={LookupData}
                LookupTypeId={enumLookupTypes.Offices}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <MyAutoSuggest Id='EmployeeID'
                Record={Record}
                PlaceHolder='Staff'
                HandleChange={handleChange}
                Required={true}
                SQLGetList={enumSQLS.KEY_LIST_EmployeeSearch}
                SQLGetRecord={enumSQLS.KEY_GET_Employee} />
            </Grid>

           
            <Grid item xs={12} sm={12} md={2}>
              <MyDropDown
                Id="ActiveEmployeesFL"
                Record={Record}
                PlaceHolder="Active"
                HandleChange={handleChange}
                Options={YesNoAllData}
              />
            </Grid>

            <Grid>
              <ToolbarButtons />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="subtitle2" align="left">
                * Leave Request Summary Report takes only the approved hours in calculation
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Typography>
  );
}

