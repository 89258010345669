// DateInput.tsx
import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";

interface DateInputProps {
  label: string;
  value: string;
  onChange: (dateValue: string, dateField: string) => void; // Modified onChange
  dateField: string; // New prop to specify which date field is being updated
}

const DateInput: React.FC<DateInputProps> = ({
  label,
  value,
  onChange,
  dateField, // Accept the dateField prop
}) => {
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(
    value ? dayjs(value, "YYYY-MM-DD") : null
  );

  const handleDateChange = (date: Dayjs | null) => {
    setSelectedDate(date);
    if (date) {
      const formattedDate = date.format("YYYY-MM-DD");

      onChange(formattedDate, dateField); // Pass dateField along with date value
    } else {
      onChange("", dateField); // Handle clearing the date if needed
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDatePicker
        label={label}
        format="DD-MM-YYYY"
        value={selectedDate}
        onChange={handleDateChange}
        slotProps={{ textField: { variant: "outlined" } }}
      />
    </LocalizationProvider>
  );
};

export default DateInput;
