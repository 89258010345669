import { IErrorsInput, ValidatorHelper } from "../helpers/ValidatorHelper";
import { LookupDataService } from "../services/LookupDataService";
import { CountryService } from "../services/CountryService";
import { EmployeeService } from "../services/EmployeeService";
import { StaffService } from "../services/StaffService";
import { enumSharedMsgs } from "../helpers/common.constants";
import { EmployeeCheckListService } from "../services/EmployeeCheckListService";

export default class EmployeeCheckListActions {
  
  async InitAsync(Record:any) {
    
    let responseCheckList = await new EmployeeCheckListService().GetByEmployeeID(Record);
    let responseEmployee = await new EmployeeService().Edit(Record);

    let responses = [
      responseCheckList,
      responseEmployee
    ];

    //napravi validacija
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async SaveAsync(payload : any, headers: any) {
    let response  = await new EmployeeCheckListService().Save(payload, headers);
    let responses = [response]

    //napravi validacija
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }
}
