import { RestHelper } from '../helpers/RestHelper';

export class AuthenticateService {

  private _restHelper;

  constructor() {
    this._restHelper = new RestHelper();
  }

  async Login(payload: any) {
    const url: string = "Authenticate/Login";
    let response = await this._restHelper.login(url, payload);
    return response;
  }

  // async LoginExternal(payload: any) {
  //   const url: string = "Authenticate/LoginExternal";
  //   let response = await this._restHelper.login(url, payload);
  //   return response;
  // }
  
  
  async LoginGoogle(payload: any) {
    const url: string = "Authenticate/LoginGoogle";
    let response = await this._restHelper.login(url, payload);
    return response;
  }


  async LoginMicrosoft(payload: any) {
    const url: string = "Authenticate/LoginMicrosoft";
    let response = await this._restHelper.login(url, payload);
    return response;
  }

  async GetUserDetails(payload: any = null) {
    const url: string = "Authenticate/GetUserDetails";
    let response = await this._restHelper.post(url, payload);
    return response;
  }
}
