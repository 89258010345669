export default class CurrencyHelper {

  constructor() { }

  ToEuro(amount: string): string {
    
    let parsedAmount = parseFloat(amount);
    if (isNaN(parsedAmount))
    {
      parsedAmount = 0;
    }
    return Intl.NumberFormat('en-DE', { style: 'currency', currency: 'EUR',minimumFractionDigits: 0 }).format(parsedAmount);
  }

  ToMoney(amount: string): string {
    
    let parsedAmount = parseFloat(amount);
    if (isNaN(parsedAmount))
    {
      parsedAmount = 0;
    }
    return Intl.NumberFormat('en-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: 0}).format(parsedAmount).replace("€","");
  }

  ToMoneyFormat(amount: string): string {
    
    let parsedAmount = parseFloat(amount);
    if (isNaN(parsedAmount))
    {
      parsedAmount = 0;
    }
    return Intl.NumberFormat().format(parsedAmount);
  }

  ToMoneyFormatDigits(amount: string, digits : number): string {
    
    let parsedAmount = parseFloat(amount);
    if (isNaN(parsedAmount))
    {
      parsedAmount = 0;
    }
    //maximumFractionDigits odreduva kolku ke ima DECIMALI
    return Intl.NumberFormat('en-DE', { style: 'currency', currency: 'EUR', minimumFractionDigits: digits,
    maximumFractionDigits: digits}).format(parsedAmount).replace("€","");
  }

  FromMoneyFormatDigits(amount: string, digits : number): string {
    amount = amount.replace(",","");
    amount = amount.replace(",","");
    amount = amount.replace(",","");
    let newValue  = parseFloat(amount).toFixed(digits)
    return newValue;
  }


  ToFloat2Decimals(amount: string): string
  {
      return this.ToFloat(amount).toFixed(2);
  }
  ToFloat(amount: string): number {
    
    let parsedAmount = parseFloat(amount);
    if (!isNaN(parsedAmount))
    {
      return parsedAmount;
    }

    let value = amount.replace(',', '');
    value = value.replace(',', ''); // so /g ke treba
    value = value.replace(',', '');
    value = value.replace('€', '');
    return parseFloat(value);
  }

  ToInt(amount: string): number {
    
    let parsedAmount = parseInt(amount);
    if (!isNaN(parsedAmount))
    {
      return parsedAmount;
    }
    return 0;
  }

  isInt(n : any) {
    return n != "" && !isNaN(n) && Math.round(n) == n;
  }
  isFloat(n : any) {
    return n != "" && !isNaN(n) && Math.round(n) != n;
  }

}
