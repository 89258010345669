import LocalStorageHelper from "../helpers/LocalStorageHelper";
import { RestHelper } from "../helpers/RestHelper";
import { enumLocalStorageArgs } from "../helpers/common.constants";

export class LookupDataService {
  private _restHelper;

  constructor() {
    this._restHelper = new RestHelper();
  }

  async GetAll(payload = {}) {
    const url: string = "LookupData/GetAll";
    const storageKey = enumLocalStorageArgs.UrlKeys + url.replace("/", "_");

    //Check if is stored in Local Storages to not reload many many times
    let responseFromStorage = new LocalStorageHelper().GetJSON(storageKey);
    if (responseFromStorage) {
      return responseFromStorage;
    }


    let response = await this._restHelper.post(url, payload);

    //Storage in the Storage
    new LocalStorageHelper().SetJSON(storageKey, response);

    return response;
  }

  async GetAllByTypeID(payload = {}) {
    const url: string = "LookupData/GetAllByTypeID";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async GetYears(payload = {}) {
    const url: string = "LookupData/GetYears";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async GetAllYears(payload = {}) {
    const url: string = "LookupData/GetAllYears";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async GetProjectYears(payload = {}) {
    const url: string = "LookupData/GetProjectYears";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async GetYesOrNoOrAll(payload = {}) {
    const url: string = "LookupData/GetYesOrNoOrAll";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async GetYesNo(payload = {}) {
    const url: string = "LookupData/GetYesNo";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async GetOpenClose(payload = {}) {
    const url: string = "LookupData/GetOpenClose";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async GetContractMonths(payload = {}) {
    const url: string = "LookupData/GetContractMonths";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async GetApprovedSubmittedAll(payload = {}) {
    const url: string = "LookupData/GetApprovedSubmittedAll";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async GetApprovedFinanceApprovedSubmittedAll(payload = {}) {
    const url: string = "LookupData/GetApprovedFinanceApprovedSubmittedAll";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async GetSuperiors(payload = {}) {
    const url: string = "LookupData/GetSuperiors";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async GetAllEmployees(payload = {}) {
    const url: string = "LookupData/GetAllEmployees";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async GetProjects(payload = {}) {
    const url: string = "LookupData/GetProjects";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async GetActiveEmployees(payload = {}) {
    const url: string = "LookupData/GetActiveEmployees";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async GetExactOnlineYears(payload = {}) {
    const url: string = "LookupData/GetExactOnlineYears";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async GetProjectsByYear(payload = {}) {
    const url: string = "LookupData/GetProjectsByYear";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async GetBudgetPerProjectAndYear(payload = {}) {
    const url: string = "LookupData/GetBudgetPerProjectAndYear";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async GetVacancies(payload = {}) {
    const url: string = "LookupData/GetVacancies";
    let response = await this._restHelper.post(url, payload);
    return response;
  }
}
