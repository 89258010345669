// @ts-check
import { useEffect } from "react";
import Typography from "@mui/material/Typography";
import MyText from "../../controls/MyText";

import { useState } from "react";
import MyAlert from "../../controls/MyAlert";
import { enumPage } from "../../helpers/common.constants";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { MessageHelper } from "../../helpers/MessageHelper";
import DocumentActions from "../../actions/DocumentActions";

const RecordDefault = {
  folderName: "",
  folderId: "",
  parentFolderId: "",
  IsNew: false,
  HasChanges: false,
};

export default function UploadFile(args) {
  //Page MAIN Hooks
  const [MessageData, setMessageData] = useState({});
  const [Record, setRecord] = useState(RecordDefault);

  //za poraki pri loadiranje na strana
  const msgHelper = new MessageHelper();

  //****************************************** */
  //HOOKS SECTION HERE
  //****************************************** */

  useEffect(() => {
    initData();
  }, []);

  //****************************************** */
  //METHOD  SECTION HERE
  //****************************************** */

  const initData = () => {
    //go asajnira folder-ot
    setRecord((a) => ({
      ...a,
      folderId: args.props.FolderId, //ova e kaj ke go stavi fajlot
      IsNew: args.props.IsNew,
      folderName: args.props.FolderName,
    }));
  };

  const closeWindow = () => {
    if (Record.HasChanges) {
      args.OnModalClose({ FolderId: Record.folderId, HasChanges: true });
    } else {
      args.OnModalClose({ FolderId: Record.folderId, HasChanges: false });
    }
  };

  const handleFileUpload = (e) => {
    const { files, name } = e.target;

    const file = files[0];

    if (file) {
      uploadData();
    }

    async function uploadData() {
      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      let payload = {
        GoogleFolderId: Record.folderId,
        FileData: file,
        ID: 99,
        ItemGuid: null,
      };
      let responses = await new DocumentActions().UploadFileAsync(payload);

      if (!responses.error) {
        let newlyFileId = responses[0].data;

        //Load Finished
        result = msgHelper.setPhase(
          enumPage.LoadSuccessWithMsg,
          null,
          "File uploaded"
        );
        setMessageData(result);

        setRecord((prevState) => ({ ...prevState, HasChanges: true }));
      } else {
        //Finished  with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
        setRecord((a) => ({
          ...a,
          ValidationMessages: result.ValidationMessages,
        })); //Ako ima Validation Messages
      }
    }
  };

  return (
    <Grid container>
      <Grid container>
        <Grid item xs={12} align="left">
          <MyAlert {...MessageData} />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5">Upload File</Typography>
        </Grid>
      </Grid>

      <Grid container align="left">
        <Grid item xs={6} align="left">
          <Button
            variant="contained"
            component="label"
            sx={{ ml: 2, mr: 2 }}
            disabled={MessageData.Loading}
            disableFocusRipple={true}
            disableTouchRipple={true}
          >
            Upload
            <input
              hidden
              accept="*"
              type="file"
              name="FileNameXXX"
              onChange={handleFileUpload}
            />
          </Button>
        </Grid>
        <Grid item xs={2}>
          <Button
            name="btnClose"
            variant="contained"
            sx={{ mr: 1 }}
            color="warning"
            size="small"
            disabled={MessageData.Loading}
            onClick={() => closeWindow()}
          >
            Close
          </Button>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} align="left">
          <MyAlert {...MessageData} />
        </Grid>
      </Grid>
    </Grid>
  );
}
