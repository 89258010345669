import { RestHelper } from "../helpers/RestHelper";

export class EmployeeService {
  private _restHelper;

  constructor() {
    this._restHelper = new RestHelper();
  }

  async Save(payload: any) {
    const url: string = "Employee/Save";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async SaveCheckList(payload: any) {
    console.log(payload);
    const url: string = "Employee/SaveCheckList";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async Search(payload: any) {
    const url: string = "Employee/Search";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async TransferHoursSearch(payload: any) {
    const url: string = "Employee/TransferHoursSearch";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async TransferHoursSave(payload: any) {
    const url: string = "Employee/TransferHoursSave";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async SaveTODOs(payload: any) {
    //let response = await this._restHelper.post(url, payload);
    const url: string = "MyEmployee/SaveTODOs";
    let response = await this._restHelper.postFile2(url, payload);
    return response;

    return response;
  }

  async TransferHoursDelete(payload: any) {
    const url: string = "Employee/TransferHoursDelete";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async Export(payload: any) {
    const url: string = "Employee/Export";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async ExportEmployeeLeaveRequestAllYears(payload: any) {
    const url: string = "Employee/ExportEmployeeLeaveRequestAllYears";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async Edit(payload: any) {
    console.log(payload);
    const url: string = "Employee/Edit";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async GeneralSearch(payload: any) {
    const url: string = "Employee/GeneralSearch";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async GeneralFindTextById(payload: any) {
    //     @TableName nvarchar(100),
    // @ColumnToReturn nvarchar(100),
    // @ColumnToReturn2 nvarchar(100),
    // @ColumnToSearch nvarchar(100),
    // @ValueToFind nvarchar(100)
    const url: string = "Employee/GeneralFindTextById";
    let response = await this._restHelper.post(url, payload);
    return response;
  }
}
