// @ts-check
import React, { useState, useRef, useEffect } from "react";
import { Typography, Button, Grid } from "@mui/material";
import MyAlert from "../../controls/MyAlert";
import { enumPage } from "../../helpers/common.constants";
import DocumentActions from "../../actions/DocumentActions";
import { ImageConfig } from "./imageConfig";
import uploadImg from "./assets/cloud-upload-regular-240.png";
import "./drop-file-input/drop-file-input.css";
import { MessageHelper } from "../../helpers/MessageHelper";

const initialRecordState = {
  folderName: "",
  folderId: "",
  parentFolderId: "",
  IsNew: false,
  HasChanges: false,
};

export default function UploadMultipleFile({ props, OnModalClose }) {
  const [messageData, setMessageData] = useState({});
  const [record, setRecord] = useState(initialRecordState);
  const [fileList, setFileList] = useState([]);
  const wrapperRef = useRef(null);
  const documentActions = new DocumentActions();

  const { FolderId, FolderName, IsNew, onFileChange } = props;

  const msgHelper = new MessageHelper();

  useEffect(() => {
    setRecord({
      ...record,
      folderId: FolderId,
      IsNew: IsNew,
      folderName: FolderName,
    });
  }, [FolderId, IsNew, FolderName]);

  const handleFileUpload = async () => {
    
    setMessageData(msgHelper.setPhase(enumPage.LoadStartWithMsg, null, "Please wait until upload finish..."));

    let fileCounter = 1;
    let filesTotal = fileList.length;
    let hasError = false;
    for (const file of fileList) {

      var messageDataWait = msgHelper.setPhase(enumPage.LoadStartWithMsg, null,  "Please wait it might take a while, uploading " + fileCounter + ' file of ' + filesTotal + ' files');
      messageDataWait.Timing = 100000; //kazuva kolku da ceka
      setMessageData(messageDataWait);

      const payload = { GoogleFolderId: record.folderId, FileData: file, ID: 99, ItemGuid: null, };
      const response = await documentActions.UploadFileAsync(payload);

      if (response.error) {
        var errrMessageData  =  msgHelper.setPhase(enumPage.ErrorServer, response)
        errrMessageData.Timing = 100000; //kazuva kolku da ceka
        setMessageData(errrMessageData);
        hasError = true;
        break;
      }

      fileCounter = fileCounter+1;
    }

    if (!hasError) {
      setRecord((prevState) => ({ ...prevState, HasChanges: true }));

      var messageDataOK = msgHelper.setPhase(enumPage.LoadSuccessWithMsg, null, "All files were uploaded!");
      messageDataOK.Timing = 100000; //kazuva kolku da ceka
      setMessageData(messageDataOK);
    }
  };

  const handleDrag = (event) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.type === "dragenter" || event.type === "dragover") {
      wrapperRef.current.classList.add("dragover");
    } else {
      wrapperRef.current.classList.remove("dragover");
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    wrapperRef.current.classList.remove("dragover");
    const files = [...event.dataTransfer.files];
    if (files.length) {
      const updatedList = [...fileList, ...files];
      setFileList(updatedList);
      onFileChange(updatedList);
    }
  };

  const handleFileSelect = (event) => {
    const files = [...event.target.files];
    if (files.length) {
      const updatedList = [...fileList, ...files];
      setFileList(updatedList);
      onFileChange(updatedList);
    }
  };

  const removeFile = (file) => {
    const updatedList = fileList.filter((f) => f !== file);
    setFileList(updatedList);
    onFileChange(updatedList);
  };

  const closeWindow = () => {
    OnModalClose({ FolderId: record.folderId, HasChanges: record.HasChanges });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <MyAlert {...messageData} />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5">Upload Multiple File</Typography>
      </Grid>

      <Grid item xs={12}>
        <div
          ref={wrapperRef}
          className="drop-file-input"
          onDragEnter={handleDrag}
          onDragOver={handleDrag}
          onDragLeave={handleDrag}
          onDrop={handleDrop}
        >
          <div className="drop-file-input__label">
            <img src={uploadImg} alt="" />
            <p>Drag & Drop your files here</p>
          </div>
          <input type="file" multiple onChange={handleFileSelect} />
        </div>
        {fileList.length > 0 && (
          <div className="drop-file-preview">
            {fileList.map((item, index) => (
              <div key={index} className="drop-file-preview__item">
                <img
                  src={
                    ImageConfig[item.type.split("/")[1]] ||
                    ImageConfig["default"]
                  }
                  alt=""
                />
                <div className="drop-file-preview__item__info">
                  <p>
                    {item.name} - {(item.size / 1024).toFixed(2)} KB
                  </p>
                  {/* <p>{item.name} </p>
                  <p>{(item.size / 1024).toFixed(2)} KB</p> */}
                </div>
                {!messageData.Loading && (
                  <span
                    className="drop-file-preview__item__del"
                    onClick={() => removeFile(item)}
                  >
                    x
                  </span>
                )}
              </div>
            ))}
          </div>
        )}
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "100%",
          gap: "100px",
        }}
      >
        <Button
          variant="contained"
          disabled={messageData.Loading || fileList.length === 0}
          onClick={handleFileUpload}
        >
          Upload
        </Button>
        <Button
          variant="contained"
          color="warning"
          size="small"
          disabled={messageData.Loading}
          onClick={closeWindow}
        >
          Close
        </Button>
      </Grid>
      <Grid item xs={12}>
        <MyAlert {...messageData} />
      </Grid>
    </Grid>
  );
}
