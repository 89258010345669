import { RestHelper } from '../helpers/RestHelper';
             
export class MyEmployeeService {

  private _restHelper;

  constructor() {
    this._restHelper = new RestHelper();
  }


  async Search(payload : any)  {
    const url : string  = "MyEmployee/Search";
    let response = await this._restHelper.post(url, payload);
    return response;
  }


  async Register(payload : any)  {
    const url : string  = "MyEmployee/Register";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async Update(payload : any)  {
    const url : string  = "MyEmployee/Update";
    let response = await this._restHelper.post(url, payload);
    return response;
  }


  async Export(payload : any)  {
    const url : string  = "MyEmployee/Export";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async GetMyProfile(payload : any)  {
    const url : string  = "MyEmployee/GetMyProfile";
    let response = await this._restHelper.post(url, payload);
    return response;
  }
  
  async GetOrgChart(payload : any)  {
    const url : string  = "MyEmployee/GetOrgChart";
    let response = await this._restHelper.post(url, payload);
    return response;
  }


  
}
