import { RestHelper } from '../helpers/RestHelper';

export class LogTypeService {

  private _restHelper;

  constructor() {
    this._restHelper = new RestHelper();
  }

  async GetAllActive(payload = {}) {
    const url: string = "LogType/GetAllActive";
    let response = await this._restHelper.post(url, payload);
    return response;
  }
}
