import { RestHelper } from "../helpers/RestHelper";

export class EmployeeCheckListService {
  private _restHelper;

  constructor() {
    this._restHelper = new RestHelper();
  }




  async GetByEmployeeID(payload: any) {
    const url: string = "EmployeeCheckList/GetByEmployeeID";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async Save(payload: any, headers: any) {
    const url: string = "EmployeeCheckList/Save";
    let response = await this._restHelper.postWithHeader(url, payload, headers);
    return response;
  }
}
