// @ts-check
import * as React from "react";
import Typography from "@mui/material/Typography";
import MyText from "../../controls/MyText";
import { useEffect, useState } from "react";
import MyAlert from "../../controls/MyAlert";
import {
  enumBreadcrumbPage,
  enumLocalStorageArgs,
  enumLookupTypes,
  enumPage,
  enumPagination,
  enumRoutes,
} from "../../helpers/common.constants";
import EmployeeActions from "../../actions/EmployeeActions";
import Button from "@mui/material/Button";
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import LogHelper from "../../helpers/LogHelper";
import { useLocation, useNavigate } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import EmployeeBreadcrumbs from "../Employee/EmployeeBreadcrumbs";
import MyDropDown from "../../controls/MyDropDown";
import Link from "@mui/material/Link";
import { MessageHelper } from "../../helpers/MessageHelper";
import LocalStorageHelper from "../../helpers/LocalStorageHelper";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import DownloadActions from "../../actions/DownloadActions";
import VacancyActions from "../../actions/VacancyActions";
import moment from "moment";
import VacancyBreadcrumbs from "./VacancyBreadcrumbs";
import PersistentDrawerRight from "../../controls/PersistentDrawerRight";

const RecordDefault = {
  vaCountryID: 0,
  VacancyName: "",
  VacancyStatus: 1,

  //Pagination
  PageIndex: 0,
  PageSize: enumPagination.PageSizeLow,
  TotalRecords: 0,
  TotalPages: 0,
};

export default function VacancySearch() {
  const [Record, setRecord] = useState(RecordDefault);
  const [MessageData, setMessageData] = useState({});
  const [Records, setRecords] = useState([]);
  const [IsInitDone, setIsInitDone] = useState(false);
  const [IsPaginationChange, setPaginationChange] = useState(false);
  const [IsDoSearch, setIsDoSearch] = useState(false);

  const [OpenCloseData, setOpenCloseData] = useState([]);
  const [CountryData, setCountryData] = useState([]);

  const [LookupData, setLookupData] = useState([]);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  //Za Refreshiranje na strana mu treba;
  const location = useLocation();

  //za poraki pri loadiranje na strana
  const msgHelper = new MessageHelper();

  //****************************************** */
  //HOOKS SECTION HERE
  //****************************************** */

  //1 --> Ke se povika ovoj HOOK samo dokolku se SMENI url-ot poradi toa na new dodavame Ticks() za da forcirame refresh na strana na sekoj click
  useEffect(() => {
    initData();
  }, [location.pathname + "/" + location.search]);

  //2 --> Od koga e sredena InitData() povikaj Search  (https://stackoverflow.com/questions/54069253/the-usestate-set-method-is-not-reflecting-a-change-immediately)
  useEffect(() => {
    if (IsInitDone) {
      searchData();
    }
  }, [IsInitDone]);

  // //3 --> Pagination Change
  useEffect(() => {
    if (IsPaginationChange) {
      searchData();
    }
  }, [IsPaginationChange]);

  //4 --> Search Click
  useEffect(() => {
    if (IsDoSearch) {
      searchData();
    }
  }, [IsDoSearch]);

  useEffect(() => {
    if (isDrawerOpen) {
      searchData();
    }
  }, [isDrawerOpen]);

  // useEffect(() => {
  //   alert(isDrawerOpen);
  // }, [isDrawerOpen]);

  //****************************************** */
  //METHOD  SECTION HERE
  //****************************************** */

  const initData = () => {
    async function fetchData() {
      //Clean all states
      setIsInitDone(false);
      setRecord(RecordDefault);

      //Proveri dali slucajno ima vo Local Storage definirani Search Arguments
      let RecordFromStorage = new LocalStorageHelper().GetJSON(
        enumLocalStorageArgs.EmployeeSearch
      );
      if (RecordFromStorage) {
        RecordFromStorage.PageIndex = 0; //sekogas prviot page pokazi go
        setRecord(RecordFromStorage);
      }

      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      let responses = await new VacancyActions().InitAsync(); // await LookupActions.InitAsync();
      if (!responses.error) {
        let lookupData = responses[0].data;
        let countryData = responses[1].data;
        let openCloseData = responses[3].data;

        setLookupData(lookupData);
        setOpenCloseData(openCloseData);
        setCountryData(countryData);

        setIsInitDone(true);

        //Load Finished
        result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);
      } else {
        //Load with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }
    }
    fetchData();
  };

  const searchData = () => {
    async function fetchData() {
      //Load Start
      setRecords([]);
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      let responses = await new VacancyActions().SearchAsync(Record);
      if (!responses.error) {
        const records = responses[0].data;

        //For Pagination purposes
        const pageIndex = responses[0].headers["content-pageindex"];
        const totalRecords = responses[0].headers["content-totalrecords"];
        const totalPages = responses[0].headers["content-totalpages"];
        setRecord((a) => ({
          ...a,
          ["PageIndex"]: pageIndex,
          ["TotalRecords"]: totalRecords,
          ["TotalPages"]: totalPages,
        }));
        setPaginationChange(false); //za da se pali na sleden page
        setIsDoSearch(false); //za da se pali na Search Click

        setRecords(records);

        //setiraj JSON vo Local Storage za da citas posle od tamu na sleden comeback
        new LocalStorageHelper().SetJSON(
          enumLocalStorageArgs.EmployeeSearch,
          Record
        );

        //Load Finished
        result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);
      } else {
        //Load with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }
    }
    fetchData();
  };

  //****************************************** */
  //HANDLERS SECTION HERE
  //****************************************** */
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name + ":" + value);
    setRecord((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSearch = (e) => {
    setRecord((a) => ({ ...a, ["PageIndex"]: 0 }));
    setIsDoSearch(true);
  };

  const handlePageIndexChange = (value) => {
    if (value < 0) value = 0;
    if (value >= Record.TotalPages) value = Record.TotalPages - 1;
    setRecord((a) => ({ ...a, ["PageIndex"]: parseInt(value) }));
    setPaginationChange(true); //ke povika gore HOOK za da se refreshira Search-ot
  };

  const handleToggleDrawer = () => {
    setIsDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    console.log(isDrawerOpen);
    setIsDrawerOpen(false);
    alert(isDrawerOpen);
  };
  //****************************************** */
  //GUI DISPLAY
  //****************************************** */
  const ToolbarButtons = () => {
    return (
      <React.Fragment>
        <Button
          sx={{ ml: 3, mr: 2, mt: 3.5 }}
          variant="contained"
          color="success"
          size="small"
          disabled={MessageData.Loading}
          onClick={() => handleSearch()}
        >
          Search
        </Button>
        <Button
          sx={{ ml: 3, mr: 2, mt: 3.5 }}
          variant="contained"
          color="success"
          size="small"
          disabled={MessageData.Loading}
          onClick={handleToggleDrawer}
        >
          Side bar
        </Button>
      </React.Fragment>
    );
  };

  return (
    <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
      <MyAlert {...MessageData} />

      <VacancyBreadcrumbs
        page={enumBreadcrumbPage.Search}
        disabled={MessageData.Loading}
      />

      <Card sx={{ minWidth: 275, borderRadius: 2, mb: 2 }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={3}>
              <MyDropDown
                Id="vaCountryID"
                Record={Record}
                PlaceHolder="Country"
                HandleChange={handleChange}
                Options={CountryData}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={3}>
              <MyText
                Id="VacancyName"
                PlaceHolder="Vacancy Name"
                Record={Record}
                HandleChange={handleChange}
                ValidationMessages={Record.ValidationMessages}
                Required={true}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={2}>
              <MyDropDown
                Id="VacancyStatus"
                Record={Record}
                PlaceHolder="Vacancy Status"
                HandleChange={handleChange}
                Options={OpenCloseData}
              />
            </Grid>

            <Grid container justifyContent="flex-end">
              <ToolbarButtons />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="subtitle1" align="left">
                Total Records found: {Record.TotalRecords}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <RenderTable
        Records={Records}
        Record={Record}
        onPageIndexChange={handlePageIndexChange}
        isDrawerOpen={isDrawerOpen}
        handleDrawerClose={handleDrawerClose}
      />
    </Typography>
  );
}

const RenderTable = ({
  Records,
  Record,
  onPageIndexChange,
  isDrawerOpen,
  handleDrawerClose,
}) => {
  //Record = Pagination

  const navigate = useNavigate();

  return React.useMemo(() => {
    return (
      <>
        <TableContainer
          sx={{ display: { xs: "none", sm: "none", md: "block" } }}
        >
          <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="h4" align="center">
                    Vacancy Name
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h4" align="center">
                    Valid from
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h4" align="center">
                    Valid to
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h4" align="center">
                    Number of Applicants
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h4" align="center">
                    Country
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography variant="h4" align="center">
                    Created
                  </Typography>
                </TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Records.map((row, index) => (
                <TableRow
                  key={row.vaID}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>
                    {Record.PageIndex * Record.PageSize + index + 1}.
                    <Link
                      href={enumRoutes.Employee_Edit.replace(
                        ":VacancyID",
                        row.vaID
                      )}
                      underline="none"
                      rel="noopener noreferrer"
                    >
                      {row.vaName}
                    </Link>
                  </TableCell>
                  <TableCell>
                    {moment(row.vaFrom).format("D/MM/YYYY")}
                  </TableCell>

                  <TableCell>{moment(row.vaTo).format("D/MM/YYYY")}</TableCell>
                  <TableCell>{row.NumberOfApp}</TableCell>
                  <TableCell>{row.Countries}</TableCell>

                  <TableCell>
                    {moment(row.vaCreated).format("D/MM/YYYY")}
                  </TableCell>

                  <TableCell align="center">
                    <Tooltip title={"Edit  Vacancy " + row.vaName}>
                      <IconButton
                        size="small"
                        name="details"
                        // onClick={handleOpenDrawer}
                      >
                        <EditIcon sx={{ fontSize: 15 }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={"Edit  Vacancy " + row.vaName}>
                      <IconButton
                        size="small"
                        name="details"
                        onClick={() =>
                          navigate(
                            enumRoutes.Vacancy_Edit.replace(
                              ":VacancyID",
                              row.vaID
                            )
                          )
                        }
                      >
                        <EditIcon sx={{ fontSize: 15 }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip
                      title={
                        "Edit Contract " +
                        row.eyFirstName +
                        " " +
                        row.eyLastName
                      }
                    >
                      <IconButton
                        size="small"
                        name="Profile"
                        onClick={() =>
                          navigate(
                            enumRoutes.Employee_Contract_Get.replace(
                              ":EmployeeID",
                              row.eyEmployeeID
                            )
                          )
                        }
                      >
                        <TextSnippetIcon sx={{ fontSize: 15 }} />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>

            {/* PAGINATION FOOTER HERE */}
            <TableFooter>
              <TableRow>
                <TableCell colSpan={10} align="center">
                  <IconButton
                    size="small"
                    name="details"
                    onClick={() =>
                      onPageIndexChange(parseInt(Record.PageIndex) - 1)
                    }
                  >
                    <ArrowLeftIcon sx={{ fontSize: 20 }} />
                  </IconButton>

                  <IconButton size="small" name="details">
                    <Typography variant="h4" align="left">
                      Page {parseInt(Record.PageIndex) + 1} of{" "}
                      {Record.TotalPages == 0 ? 1 : Record.TotalPages}
                    </Typography>
                  </IconButton>

                  <IconButton
                    size="small"
                    name="details"
                    onClick={() =>
                      onPageIndexChange(parseInt(Record.PageIndex) + 1)
                    }
                  >
                    <ArrowRightIcon sx={{ fontSize: 20 }} />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>

        <PersistentDrawerRight
          open={isDrawerOpen}
          handleDrawerClose={handleDrawerClose}
        />
      </>
    );
  }, [Records]);
};
