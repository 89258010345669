// @ts-check 
import { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import MyText from '../../controls/MyText';

import { useState } from 'react';
import MyAlert from '../../controls/MyAlert';
import { enumPage } from '../../helpers/common.constants';
import Button from '@mui/material/Button';
import { Grid } from '@mui/material';
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { MessageHelper } from '../../helpers/MessageHelper';
import DocumentActions from '../../actions/DocumentActions';

const RecordDefault =
{
  folderName: "",
  fileName: "",
  folderId: "",
  fileId: "",
  parentFolderId: "",
  IsFile: false,
  IsFolder: false,
  HasChanges: false,
  DisabledProceedButton: false
}



export default function DeleteFileFolder(args) {


  //Page MAIN Hooks
  const [MessageData, setMessageData] = useState({});
  const [Record, setRecord] = useState(RecordDefault);

  //za poraki pri loadiranje na strana 
  const msgHelper = new MessageHelper();

  //****************************************** */
  //HOOKS SECTION HERE 
  //****************************************** */

  useEffect(() => {
    initData();
  }, []);



  //****************************************** */
  //METHOD  SECTION HERE 
  //****************************************** */

  const initData = () => {
    //go asajnira PARENT folder-ot
    setRecord((a) => ({
      ...a,
      parentFolderId: args.props.ParentFolderId, //ova e za NOV Folder
      folderId: args.props.FolderId,  //ova e za RENAME
      fileId: args.props.FileId,  //ova e za RENAME
      IsNew: args.props.IsNew,
      folderName: args.props.FolderName,
      fileName: args.props.FileName,
      IsFolder: args.props.IsFolder,
      IsFile: args.props.IsFile
    }));
  };



  const saveData = () => {
    async function fetchData() {

      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);


      let response = null;

      if (Record.IsFile) {
        
        let payload = {fileId: Record.fileId}
        response = await new DocumentActions().DeleteFileAsync(payload);
      }
      else {
        let payload = {folderId: Record.folderId}
        response = await new DocumentActions().DeleteFolderAsync(payload);
      }

      if (!response.error) {

        //Load Finished
        if (Record.IsFolder) {
          result = msgHelper.setPhase(enumPage.LoadSuccessWithMsg, null, "Folder deleted.");
          let newFolderId = response[0].data;

          setRecord((prevState) => ({ ...prevState, HasChanges: true})); //dont' go to the newer folder
        }
        else {
          result = msgHelper.setPhase(enumPage.LoadSuccessWithMsg, null, "File deleted.");
          setRecord((prevState) => ({ ...prevState, HasChanges: true}));
        }
        
        setMessageData(result);
        setRecord((a) => ({ ...a, ValidationMessages: result.ValidationMessages, DisabledProceedButton: true }));  //Ako ima Validation Messages da gi izbrise 
      }
      else {
        //Finished  with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, response);
        setMessageData(result);
        setRecord((a) => ({ ...a, ValidationMessages: result.ValidationMessages }));  //Samo ovdeka mu treba ako ima Validacioni greski da dodade
      }
    }

    fetchData();
  };



  const closeWindow = () => {
    if (Record.HasChanges){
      if (Record.IsFolder)
      {
        args.OnModalClose({ FolderId: Record.parentFolderId, HasChanges: true });
      }
      else {
        args.OnModalClose({ FolderId: Record.folderId, HasChanges: true });
      }
    }
    else {
      args.OnModalClose({ FolderId: Record.folderId, HasChanges: false });
    }
  };




  //****************************************** */
  //HANDLERS SECTION HERE 
  //****************************************** */
  const handleChange = (e) => {
    const { name, value } = e.target;

    console.log(name + ":" + value);
    setRecord((prevState) => ({ ...prevState, [name]: value }));
  };



  return (
    <Grid container>

      <Grid container>
        <Grid item xs={12} align="left">
          <MyAlert {...MessageData} />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5">Are you share you want to delete {Record.IsFolder ? "folder:" +  Record.folderName : "file:" + Record.fileName}</Typography>
        </Grid>
      </Grid>


      <Grid container align="left" padding={1}>
        <Grid item xs={6} align="right">
          <Button name="btnSave" variant="contained" sx={{ mr: 1 }} color="warning" size="small" disabled={MessageData.Loading ||  Record.DisabledProceedButton} onClick={() => saveData()}>Proceed</Button>
        </Grid>

        <Grid item xs={6}>
          <Button name="btnClose" variant="contained" sx={{ mr: 1 }} color="warning" size="small" disabled={MessageData.Loading} onClick={() => closeWindow()}>Close</Button>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} align="left">
          <MyAlert {...MessageData} />
        </Grid>
      </Grid>

    </Grid>
  );
}

