import { RestHelper } from '../helpers/RestHelper';
             
export class NotificationService {

  private _restHelper;

  constructor() {
    this._restHelper = new RestHelper();
  }

  async Search(payload : any)  {
    const url : string  = "Notification/Search";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async Prepare(payload : any)  {
    const url : string  = "Notification/Prepare";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async Save(payload : any)  {
    const url : string  = "Notification/Save";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  
  async Edit(payload : any)  {
    const url : string  = "Notification/Edit";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

}
