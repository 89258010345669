// @ts-check 
import { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import MyText from '../../controls/MyText';

import { useState } from 'react';
import MyAlert from '../../controls/MyAlert';
import { enumBreadcrumbPage, enumLeaveRequest, enumPage, enumRoutes, enumSharedMsgs } from '../../helpers/common.constants';
import NotificationActions from '../../actions/NotificationActions';
import Button from '@mui/material/Button';
import { Grid } from '@mui/material';
import { useParams, useLocation, useNavigate } from "react-router-dom";
import CurrencyHelper from '../../helpers/CurrencyHelper';
import moment from 'moment';
import MyDateTime from '../../controls/MyDateTime';
import LeaveRequestBreadcrumbs from './LeaveRequestBreadcrumbs';
import { MessageHelper } from '../../helpers/MessageHelper';
import LeaveRequestActions from '../../actions/LeaveRequestActions';
import MyDate from '../../controls/MyDate';
import NotesIcon from '@mui/icons-material/Notes';
import VisibilityIcon from '@mui/icons-material/Visibility';

const RecordDefault =
{
  "rqRequestID": 0,
  "rqEmployeeID": 0,
  "rqDatetimeStart": moment(Date.now()).format("YYYY-MM-DD HH:mm"),
  "rqDatetimeEnd": moment(Date.now()).format("YYYY-MM-DD HH:mm"),
  "rqTotalHours": 8,
  "rqApproved": true,
  "rqSubmited": true,
  "rqReviewed": true,
  "rqSuperiorID": 0,
  "rqYear": 0,
  "rqLeftHours": 0,
  "rqNote": "",
  "ContractedHours": 0,
  "TakenHours": 0,
  "BalanceHours": 0,
  "SuperiorID": 0,
  "SuperiorName": ""
}


const RecordDefaultHourBalance =
{
  "TakenHours": 0,
  "BalanceHours": 0,
  "SuperiorID": 0,
  "SuperiorName": "",
  "ContractedHours": 0
}

export default function LeaveRequestPreview(args) {

  //Page MAIN Hooks
  const [EnableButtons, setEnableButtons] = useState(true);
  const [IsInitDone, setIsInitDone] = useState(true);
  const [MessageData, setMessageData] = useState({});
  const [Record, setRecord] = useState(RecordDefault);
  const [RecordHourBalance, setRecordHourBalance] = useState(RecordDefaultHourBalance);

  //Da proveri dali e nov zapis ili postoecki
  const { RequestID } = useParams();

  //za Navigacija 
  const navigate = useNavigate();

  //Za Refreshiranje na strana mu treba;
  const location = useLocation();


  //za poraki pri loadiranje na strana 
  const msgHelper = new MessageHelper();

  //****************************************** */
  //HOOKS SECTION HERE 
  //****************************************** */

  //1 --> Ke se povika ovoj HOOK samo dokolku se SMENI url-ot poradi toa na new dodavame Ticks() za da forcirame refresh na strana
  useEffect(() => {
    initData();
  }, [location.pathname + '/' + location.search]);

  //2 --> Ako e postoecki zapis povikaj editData  (https://stackoverflow.com/questions/54069253/the-usestate-set-method-is-not-reflecting-a-change-immediately)
  useEffect(() => {
    if (IsInitDone) {

      //Check if is existing record and make EditData
      if (RequestID) {
        editData();
      }
    }
  }, [IsInitDone]);



  //****************************************** */
  //METHOD  SECTION HERE 
  //****************************************** */

  const initData = () => {
    async function fetchData() {

      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);
      setIsInitDone(false);
      setEnableButtons(false);
      setRecord(RecordDefault);

      let payload = { TypeID: enumLeaveRequest.Leave }
      let responses = await new LeaveRequestActions().InitAsync(payload); // await LookupActions.InitAsync();
      if (!responses.error) {

        let leaveRequestBalanceHours = responses[0].data;

        setRecordHourBalance(leaveRequestBalanceHours);

        //alert(RecordHourBalance.ContractedHours);
        if (leaveRequestBalanceHours.ContractedHours === 0) {
          result = msgHelper.setPhase(enumPage.LoadSuccessWithWarningMsg, null, "Please contact HR department to fullfill your contract hours");
          setMessageData(result);
        }
        else {

          //Load Finished
          result = msgHelper.setPhase(enumPage.LoadSuccess);
          setMessageData(result);
        }
        setIsInitDone(true);
        setEnableButtons(true);
      }
      else {
        //Finished  with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }
    }

    fetchData();
  };


  const editData = () => {
    async function fetchData() {

      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);
      setEnableButtons(false);
      //setMessageData((a) => ({...a, Loading: true }));
      //alert(RequestID)
      let payload = { rqRequestID: RequestID };
      let responses = await new LeaveRequestActions().EditAsync(payload); // await LookupActions.InitAsync();
      if (!responses.error) {

        let recordDb = responses[0].data;
        setRecord(recordDb);

        let result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);

        //let isPreviewOnly = false; (location.pathname.toString().toLowerCase().indexOf("preview") > -1); //tuka ne e vo state pa radi toa ni treba uste edna

      }
      else {
        let result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
        setEnableButtons(true);
      }

    }
    fetchData();
  };

  
  
  
  return (
    <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="center">

      <LeaveRequestBreadcrumbs page={enumBreadcrumbPage.Master} Id={Record.rqRequestID} disabled={MessageData.Loading} />

      <MyAlert {...MessageData} />

  
      <Grid container align="center">
        <Grid item xs={12}>
          <Typography variant="h5">
            Total Hours : {RecordHourBalance.ContractedHours}, Taken Approved Hours : {RecordHourBalance.TakenHours}, Balance Hours : {RecordHourBalance.BalanceHours}, Superior: {RecordHourBalance.SuperiorName}
          </Typography>
        </Grid>
      </Grid>


      <Grid container>
        <Grid item xs={12} align="left">
          <MyDate Id='rqDatetimeStart' PlaceHolder='Start Date' Record={Record}
          />
        </Grid>
      </Grid>


      <Grid container>
        <Grid item xs={12} align="left">
          <MyDate Id='rqDatetimeEnd' PlaceHolder='End Date' Record={Record}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <MyText Id='rqTotalHours'
            PlaceHolder='Total Hours'
            Record={Record}
            ValidationMessages={Record.ValidationMessages}
          //Disabled={true}
          />
        </Grid>
      </Grid>


      <MyAlert {...MessageData} />


    </Typography>
  );
}

