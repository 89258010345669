import * as React from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
import { IValidationMessage } from '../helpers/common.interfaces';
import EmployeeActions from '../actions/EmployeeActions';
import GeneralActions from '../actions/GeneralActions';
import { enumSQLS } from '../helpers/common.constants';

export interface Props {
  Record: {},
  Id: string,
  Required?: boolean,
  Disabled?: boolean,
  PlaceHolder: string,
  Label?: string,
  AutoFocus?: boolean,
  HandleChange(e: { target: { name: string, value: number } }): void,
  ValidationMessages?: IValidationMessage[],
  LookupTypeId?: number,
  Options: any[],
  OptionsText?: string,
  OptionsValue?: string,
  Url: string,
  LengthForSearch: number,
  SQLGetList: number, //cita od enumSQLS
  SQLGetRecord: number,  //cita od enumSQLS
  SkipPlaceHolderSuffix: boolean,
  Variant: string,
  HideTriangle: boolean
  Margin: string

  //SelectedValue: string | number
};


export default function MyAutoSuggest(args: Props) {

  const defalutLabelText = "";
  const [options, setOptions] = React.useState([]);
  const [SelectedValueText, setSelectedValueText] = React.useState(defalutLabelText);

  let Record = args.Record;
  let required: boolean = args.Required ?? false;
  let hasError: boolean = false;
  let message: string = '';
  let disabled: boolean = args.Disabled ?? false;
  let lengthForSearch = args.LengthForSearch ?? 2; //najmalku 3 characteri za da prebaruva
  let variant = args.Variant ?? "outlined"

  //default ocekuva Text i Value za Select dokolku nema izbrano user-ot args.
  let optionText: string = "Text";
  let optionValue: string = "Value";
  let placeHolder: string = args.PlaceHolder;
  let hideTriangle: boolean = args.HideTriangle ?? false
  let margin: string = args.Margin ?? "dense";

  if (!args.SkipPlaceHolderSuffix) {
    placeHolder += " (search)";
  }


  //proveri dali e za Lookup togas znaci deka optionText = ldValue ; optionValue = ldLookupDataID
  //slucajno ako vo arguments ima custom Value i Text togas zemi gi od tamu konkretno args.OptionsText i  args.OptionsValue
  if (args.OptionsText) {
    optionText = args.OptionsText;
  }
  if (args.OptionsValue) {
    optionValue = args.OptionsValue;
  }

  //Proveri dali ima greska
  if (required === true) {
    let errorCollection = args.ValidationMessages ? args.ValidationMessages : [];
    if (errorCollection != null && errorCollection.length > 0) {
      let currentOption = errorCollection.find((x) => x.controlId === args.Id);
      if (currentOption != null) {
        hasError = true;
        message = currentOption.message;
      }
    }
  }

  //Vo zavisnot od Value i HasError se refreshira komponentata
  //let value: string | number = args.SelectedValue 
  let value: string | number = Record ? Record[args.Id as keyof {}] : "";

  //Ke go selektira ID-to vo Drop Down so pomos na GetRecord
  React.useEffect(() => {
    //let currentValue : string = value.toString();
    if (value === undefined || value === null) {
      value = "";
    }

    //vo ovoj mmoment resetiraj text
    //setSelectedValueText('');

    if (value !== "") {
      //dokolku nema definirano args.SQLGetRecord togas nema sto da vika GetRecord a toa e vo Header.js
      if (args.SQLGetRecord) {
        GetRecord(value);
      }
    }
  }, [value]); //default



  const GetList = (inputValue: any) => {

    async function fetchData() {

      //KEY_LIST_EmployeeSearch	EXEC pr_AS_EmployeeSearch {0}
      //SqlID: 1531
      //Params: {0}
      let payload: any = { SqlID: args.SQLGetList, Params: inputValue }
      let responses: any = await new GeneralActions().GetList(payload);
      if (!responses.error) {
        let generalSearchData = responses[0].data;

        if (generalSearchData.length > 0) {
          setOptions(generalSearchData);
        }
        else {
          setOptions([]);
          setSelectedValueText(defalutLabelText);
        }
      }
      else {
        alert("General Error\n" + responses);
      }
    }

    if (inputValue) {
      //najmalku 3 charactera za da prebaruva
      if (inputValue.length >= lengthForSearch) {
        fetchData();
      }
    } else {
      setOptions([]);
    }
  };

  const GetRecord = (inputValue: any) => {

    async function fetchData() {

      //KEY_GET_Employee	SELECT eyFirstName + ' ' + eyLastName AS Text From Employee WHERE eyEmployeeID = '{0}'
      //SqlID: 1532
      //Params: {0}
      let payload: any = { SqlID: args.SQLGetRecord, Params: inputValue.toString() }

      let responses: any = await new GeneralActions().GetRecord(payload);
      if (!responses.error) {
        let generalSearchData = responses[0].data;
        setSelectedValueText(generalSearchData.Text);
      }
      else {
        alert("General Error\n" + responses);
      }
    }

    if (inputValue != "") {
      fetchData();
    }
  };


  const handleInputChange = (event: any, inputValue: any, reason: any) => {
    GetList(inputValue);
  };



  //useCallback samo ednas da se mountira: https://everyday.codes/javascript/react-usememo-and-when-you-should-use-it/
  const handleSelectedValue = (event: React.SyntheticEvent, value: any) => {

    let option: any = value;
    if (option !== null) {
      let customerArgs = { target: { name: args.Id, value: option[optionValue] } };
      setSelectedValueText(option[optionText])
      args.HandleChange(customerArgs);
    } else {
      let customerArgs = { target: { name: args.Id, value: 0 } };
      setSelectedValueText(defalutLabelText);
      args.HandleChange(customerArgs);
    }
  };

  
  return React.useMemo(() => {


    return (
      <Autocomplete
        size='small'
        freeSolo={hideTriangle as any} //za da se krie triagolnikot
        id={args.Id}
        disabled={disabled}
        getOptionLabel={(option) => typeof option === "string" ? option : option[optionText]}
        onChange={(event: React.SyntheticEvent, value: any) => { handleSelectedValue(event, value); }} //ako e MULTIPLE 
        onInputChange={handleInputChange}
        options={options}
        autoComplete
        value={SelectedValueText}
        renderInput={(params) => (
          <TextField {...params} label={placeHolder} variant={variant as any} size='small' fullWidth margin={margin as any} autoFocus={args.AutoFocus} helperText={message} error={hasError} 
            
          />
        )}
      />
    );
  }, [options, value, args.Disabled, SelectedValueText, hasError, message]);
};

