import { RestHelper } from "../helpers/RestHelper";

export class DeclarationService {
  private _restHelper;

  constructor() {
    this._restHelper = new RestHelper();
  }

  async Save(payload: any) {
    const url: string = "Declaration/Save";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async SaveProject(payload: any) {
    const url: string = "Declaration/SaveProject";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async FinanceApproval(payload: any) {
    const url: string = "Declaration/FinanceApproval";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async BudgetHolderApproval(payload: any) {
    const url: string = "Declaration/BudgetHolderApproval";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async MyDeclarations(payload: any) {
    const url: string = "Declaration/MyDeclarations";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async MyDeclarationApprovals(payload: any) {
    const url: string = "Declaration/MyDeclarationApprovals";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async Search(payload: any) {
    const url: string = "Declaration/Search";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async SearchProject(payload: any) {
    const url: string = "Declaration/SearchProject";
    let response = await this._restHelper.post(url, payload);
    return response;
  }
  async ExportDetails(payload: any) {
    const url: string = "Declaration/ExportDetails";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async ExportSummary(payload: any) {
    const url: string = "Declaration/ExportSummary";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async Edit(payload: any) {
    const url: string = "Declaration/Edit";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async Submit(payload: any) {
    const url: string = "Declaration/Submit";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async ClaimForBudgetApproval(payload: any) {
    const url: string = "Declaration/ClaimForBudgetApproval";
    let response = await this._restHelper.post(url, payload);
    return response;
  }


  async GetBudgetsPerDeclaration(payload: any) {
    const url: string = "Declaration/GetBudgetsPerDeclaration";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async Download(payload: any) {
    const url: string = "Declaration/Download";
    let response = await this._restHelper.downloadFile(url, payload);
    return response;
  }
}
