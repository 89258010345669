// @ts-check
import * as React from "react";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import MyAlert from "../../controls/MyAlert";
import {
  enumBreadcrumbPage,
  enumLocalStorageArgs,
  enumPage,
  enumPagination,
  enumRoutes,
} from "../../helpers/common.constants";
import EmployeeActions from "../../actions/EmployeeActions";
import Button from "@mui/material/Button";
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import LogHelper from "../../helpers/LogHelper";
import { useLocation, useNavigate } from "react-router-dom";
import EmployeeBreadcrumbs from "../Employee/EmployeeBreadcrumbs";
import MyDropDown from "../../controls/MyDropDown";
import Link from "@mui/material/Link";
import { MessageHelper } from "../../helpers/MessageHelper";
import ExactonlineActions from "../../actions/ExactonlineActions";
import CurrencyHelper from "../../helpers/CurrencyHelper";
import InfoIcon from '@mui/icons-material/InfoOutlined';
import moment from "moment";
import DownloadActions from "../../actions/DownloadActions";
import ExactonlineBreadcrumbs from "./ExactonlineBreadcrumbs";
import LocalStorageHelper from "../../helpers/LocalStorageHelper";

const RecordDefault = {
  PageSize: enumPagination.PageSize,
  // OfficeID: 0,
  // CountryIDs: "",
  // Name: "",
  // PhaseIDs: "",
  // ActiveFL: "1",
  Division: "",
  Year: "2023",
  Code: "",
};

export default function ExactonlineSearch() {
  const [Record, setRecord] = useState(RecordDefault);
  const [MessageData, setMessageData] = useState({});
  const [Records, setRecords] = useState([]);
  const [IsInitDone, setIsInitDone] = useState(false);

  const [DivisionData, setDivisionData] = useState([]);
  const [CostCenterData, setCostCenterData] = useState([]);
  const [CostCenterDataAll, setCostCenterDataAll] = useState([]);
  const [FinancialYearData, setFinancialYearData] = useState([]);

  const [MigrationConfigData, setMigrationConfigData] = useState([]);

  //Za Refreshiranje na strana mu treba;
  const location = useLocation();

  //za poraki pri loadiranje na strana
  const msgHelper = new MessageHelper();

  //****************************************** */
  //HOOKS SECTION HERE
  //****************************************** */

  //1 --> Ke se povika ovoj HOOK samo dokolku se SMENI url-ot poradi toa na new dodavame Ticks() za da forcirame refresh na strana na sekoj click
  useEffect(() => {
    initData();
  }, [location.pathname + "/" + location.search]);

  //2 --> Od koga e sredena InitData() povikaj Search  (https://stackoverflow.com/questions/54069253/the-usestate-set-method-is-not-reflecting-a-change-immediately)
  useEffect(() => {
    if (IsInitDone) {
      searchData();
    }
  }, [IsInitDone]);

  //****************************************** */
  //METHOD  SECTION HERE
  //****************************************** */

  const initData = () => {
    async function fetchData() {
      //Clean all states
      setIsInitDone(false);
      setRecord(RecordDefault);

      //Proveri dali slucajno ima vo Local Storage definirani Search Arguments
      let RecordFromStorage = new LocalStorageHelper().GetJSON(enumLocalStorageArgs.ExactOnlineSearch);
      if (RecordFromStorage) {
        RecordFromStorage.PageIndex = 0; //sekogas prviot page pokazi go 
        setRecord(RecordFromStorage);
      }

      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      let responses = await new ExactonlineActions().InitAsync(); // await LookupActions.InitAsync();
      if (!responses.error) {
        // let lookupData = responses[0].data;
        // let countryData = responses[1].data;
        // let SuperiorsData = responses[2].data;
        // let yesNoAllData = responses[3].data;

        let costCenterData = responses[0].data;
        let divisionData = responses[1].data;
        let financialYearData = responses[2].data;
        let migrationConfigData = responses[3].data;
        console.log(costCenterData);

        //setCostCenterData(costCenterData);
        setCostCenterDataAll(costCenterData);
        
        
        setCostCenterData(costCenterData);
        setDivisionData(divisionData);

        setFinancialYearData(financialYearData);
        setMigrationConfigData(migrationConfigData);

        setIsInitDone(true);

        //Load Finished
        result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);
      } else {
        //Load with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }
    }
    fetchData();
  };

  const searchData = () => {
    async function fetchData() {
      //Load Start
      setRecords([]);
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      let responses = await new ExactonlineActions().SearchAsync(Record);
      if (!responses.error) {
        const records = responses[0].data;
        
        console.log(records);
        setRecords(records);

        //setiraj JSON vo Local Storage za da citas posle od tamu na sleden comeback 
        new LocalStorageHelper().SetJSON(enumLocalStorageArgs.ExactOnlineSearch, Record);


        //Load Finished
        result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);
      } else {
        //Load with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }
    }
    fetchData();
  };

  const exportData = () => {
    async function fetchData() {
      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      let responses = await new EmployeeActions().ExportAsync(Record);

      if (!responses.error) {
        //Load Finished
        let result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);

        //DOWNLOADING EXCEL FILE 
        const excelFileUrl = responses[0].data;
        let payload = "?inputFile=" + excelFileUrl;
        let downloadResponse = await new DownloadActions().DownloadFileAsync(payload);

        // create file link in browser's memory
        const href = URL.createObjectURL(downloadResponse.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement('a');
        link.href = href;
        link.setAttribute('download', excelFileUrl); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        return;
      } else {
        //Load with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }
    }
    fetchData();
  };

  //****************************************** */
  //HANDLERS SECTION HERE
  //****************************************** */
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name + ":" + value);
    setRecord((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleChangeDivision = (e) => {
    const { name, value } = e.target; //value ==> CountryId

    const filterDivisionCostCenter = CostCenterDataAll.filter(
      (a) => a.Division === value.toString()
    );
    console.error(value);
    console.log(filterDivisionCostCenter);
    setCostCenterData(filterDivisionCostCenter);

    setRecord((prevState) => ({ ...prevState, [name]: value }));
  };

  //****************************************** */
  //GUI DISPLAY
  //****************************************** */
  const ToolbarButtons = () => {
    return (
      <React.Fragment>
        <Button
          sx={{ ml: 3, mr: 2, mt: 3.5 }}
          variant="contained"
          color="success"
          size="small"
          //disabled={MessageData.Loading}
          onClick={() => searchData()}
        >
          Search
        </Button>
        <Button
          sx={{ mr: 0, mt: 3.5 }}
          variant="contained"
          color="secondary"
          size="small"
          disabled={MessageData.Loading}
          onClick={() => exportData()}
        >
          Export
        </Button>
        {/* <Button
          sx={{ ml: 3, mr: 2, mt: 3.5 }}
          variant="contained"
          color="primary"
          size="small"
          target="_blank"
          href="https://508c-62-162-133-229.ngrok-free.app/ExactAuthorize/Authenticate"
        >
          Do migration
        </Button> */}
      </React.Fragment>
    );
  };

  return (
    <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
      <MyAlert {...MessageData} />

      <ExactonlineBreadcrumbs
        page={enumBreadcrumbPage.Search}
        disabled={MessageData.Loading}
      />

      <Card sx={{ minWidth: 275, borderRadius: 2, mb: 2 }}>
        <CardContent>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" fontSize={12}>
                <i><InfoIcon sx={{ fontSize: 14 }} /> Exact Online data from: {moment(MigrationConfigData.coRefDate).format("DD/MM/YYYY")}</i>
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={3}>
              <MyDropDown
                Id="Division"
                Record={Record}
                PlaceHolder="Division"
                HandleChange={handleChangeDivision}
                Options={DivisionData}
                OptionsText="Description"
                OptionsValue="Code"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <MyDropDown
                Id="Code"
                Record={Record}
                PlaceHolder="Cost center"
                HandleChange={handleChange}
                Options={CostCenterData}
                OptionsText="ProjectName"
                OptionsValue="ProjectName"
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <MyDropDown
                Id="Year"
                Record={Record}
                PlaceHolder="Financial Year"
                HandleChange={handleChange}
                Options={FinancialYearData}
              />
            </Grid>
            <Grid>
              <ToolbarButtons />
            </Grid>


          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="subtitle1" align="left">
                Total Records found: {Records.length}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <RenderTable Records={Records} />
    </Typography>
  );
}

const RenderTable = ({ Records }) => {
  const navigate = useNavigate();

  return React.useMemo(() => {
    return (
      <TableContainer sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="h4" align="left">
                  Cost center
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h4" align="left">
                  Financial year
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h4" align="left">
                  Description
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h4" align="left">
                  Budget
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h4" align="left">
                  Costs
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h4" align="left">
                  Balance
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Records.map((row, index) => (
              <TableRow
                key={row.eyEmployeeID}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {/* <TableCell>{index + 1}.</TableCell> */}
                <TableCell sx={{ textAlign: "left" }}>
                  {" "}
                  <Link
                    href={enumRoutes.Exactonline_Overview.replace(":Code", row.Code).replace(":Year", row.ReportingYear)}
                    underline="none"
                    rel="noopener noreferrer"
                  >
                    {row.Code}
                  </Link>
                </TableCell>
                <TableCell sx={{ textAlign: "left" }}>
                  {row.ReportingYear}
                </TableCell>

                <TableCell sx={{ textAlign: "left" }}>
                  {row.Description}
                </TableCell>
                <TableCell sx={{ textAlign: "right" }}>
                  {new CurrencyHelper().ToEuro(row.BudgetEUR)}
                </TableCell>

                <TableCell sx={{ textAlign: "right" }}>
                  {new CurrencyHelper().ToEuro(row.CostEUR)}
                </TableCell>
                <TableCell sx={{ textAlign: "right" }}>
                  {new CurrencyHelper().ToEuro(row.BudgetEUR - row.CostEUR)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }, [Records]);
};
