import { IErrorsInput, ValidatorHelper } from "../helpers/ValidatorHelper";
import { LookupDataService } from "../services/LookupDataService";
import { CountryService } from "../services/CountryService";
import { EmployeeService } from "../services/EmployeeService";
import { StaffService } from "../services/StaffService";
import { enumSharedMsgs } from "../helpers/common.constants";

export default class EmployeeActions {
  async InitAsync() {
    let responseLookup = await new LookupDataService().GetAll();
    //let responseCountry = await new CountryService().GetAll();
    //let responseSuperiors = await new LookupDataService().GetSuperiors();
    let responseYesOrNoOrAll = await new LookupDataService().GetYesOrNoOrAll();
    let responseYesNo = await new LookupDataService().GetYesNo();
    let contractMonthData = await new LookupDataService().GetContractMonths();
    //let responseAllYears = await new LookupDataService().GetAllYears();

    let responses = [
      responseLookup,
      //responseCountry,
      //responseSuperiors,
      responseYesOrNoOrAll,
      responseYesNo,
      contractMonthData,
      //responseAllYears
    ];

    //napravi validacija
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async SaveAsync(Record: any) {
    let jsonInputData: IErrorsInput[] = [
      {
        element: "eyFirstName",
        type: "required",
        value: Record.eyFirstName,
        message: "Fist Name is required",
      },
      {
        element: "eyLastName",
        type: "required",
        value: Record.eyLastName,
        message: "Last Name is required",
      },
      {
        element: "eyEmail",
        type: "required",
        value: Record.eyEmail,
        message: "Email is required",
      },
      {
        element: "eyEmail",
        type: "email",
        value: Record.eyEmail,
        message: "Valid Email is required",
      },
      {
        element: "eyPrivateEmail",
        type: "required",
        value: Record.eyPrivateEmail,
        message: "Email is required",
      },
      {
        element: "eyEmergencyName",
        type: "required",
        value: Record.eyEmergencyName,
        message: "Emergency Name is required",
      },
      {
        element: "eyEmergencyPhone",
        type: "required",
        value: Record.eyEmergencyPhone,
        message: "Emergency Phone is required",
      },

      {
        element: "eyEmergencyEmail",
        type: "required",
        value: Record.eyEmergencyEmail,
        message: "Emergency Email is required",
      },
      {
        element: "eyBirthDate",
        type: "DateTimeEmpty",
        value: Record.eyBirthDate,
        message: "Birth Date is required",
      },
      {
        element: "eyAddress",
        type: "required",
        value: Record.eyAddress,
        message: "Address is required",
      },
      {
        element: "eyCity",
        type: "required",
        value: Record.eyCity,
        message: "City is required",
      },
      {
        element: "eyCountryID",
        type: "SelectEmpty",
        value: Record.eyCountryID,
        message: "Country is required",
      },

      {
        element: "eyEmergencyRelationID",
        type: "SelectEmpty",
        value: Record.eyEmergencyRelationID,
        message: "Relation to employee is required",
      },

      {
        element: "eyOfficeID",
        type: "SelectEmpty",
        value: Record.eyOfficeID,
        message: "Office is required",
      },
      {
        element: "eySuperiorID",
        type: "SelectEmpty",
        value: Record.eySuperiorID,
        message: "Superior is required",
      }
    ];

    if (!Record.SkipValidation) {
      //validacija
      let validationMessages = new ValidatorHelper().validateClient(
        jsonInputData
      );
      if (validationMessages.length > 0) {
        let response = {
          error: true, //oznaci deka ima greska
          error_description: enumSharedMsgs.SaveClientError,
          ValidationMessages: validationMessages, //vrati gi greskite za GUI
        };
        return response;
      }
    }

    //napravi validacija
    let response = await new EmployeeService().Save(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async SearchAsync(Record: any) {
    
    //napravi validacija
    let response = await new EmployeeService().Search(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async TransferHoursSearchAsync(Record: any) {
    
    //napravi validacija
    let response = await new EmployeeService().TransferHoursSearch(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }


  async SaveTODOsAsync(Record: any) {
    
    //napravi validacija
    let response = await new EmployeeService().SaveTODOs(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }
  
  
  async TransferHoursSaveAsync(Record: any) {
    
    //napravi validacija
    let response = await new EmployeeService().TransferHoursSave(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }
  

  async TransferHoursDeleteAsync(Record: any) {
    
    //napravi validacija
    let response = await new EmployeeService().TransferHoursDelete(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }
  

  async ExportAsync(Record: any) {
    //napravi validacija
    let response = await new EmployeeService().Export(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async ExportEmployeeLeaveRequestAllYearsAsync(Record: any) {
    //napravi validacija
    let response =
      await new EmployeeService().ExportEmployeeLeaveRequestAllYears(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async EditAsync(Record: any) {
    //napravi validacija
    let response = await new EmployeeService().Edit(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async GeneralSearchAsync(Record: any) {
    //napravi validacija
    let response = await new EmployeeService().GeneralSearch(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async GeneralFindTextByIdAsync(Record: any) {
    //napravi validacija
    let response = await new EmployeeService().GeneralFindTextById(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  // async SaveCheckListAsync(Record: any) {
  //   
  //   let jsonInputData: IErrorsInput[] = [
  //     {
  //       element: "ecCheckID",
  //       type: "required",
  //       value: Record.ecCheckID,
  //       message: "Fist Name is required",
  //     },
  //   ];

  //   if (!Record.SkipValidation) {
  //     //validacija
  //     let validationMessages = new ValidatorHelper().validateClient(
  //       jsonInputData
  //     );
  //     if (validationMessages.length > 0) {
  //       let response = {
  //         error: true, //oznaci deka ima greska
  //         error_description: enumSharedMsgs.SaveClientError,
  //         ValidationMessages: validationMessages, //vrati gi greskite za GUI
  //       };
  //       return response;
  //     }
  //   }

  //   //napravi validacija
  //   let response = await new EmployeeService().SaveCheckList(Record);
  //   let responses = [response];
  //   let output = new ValidatorHelper().validateServerResponses(responses);
  //   return output;
  // }
}
