
import * as React from 'react';
import { Grid, TextField, TextFieldPropsSizeOverrides, Typography } from '@mui/material';
import { IValidationMessage } from '../helpers/common.interfaces';
import { OverridableStringUnion } from '@mui/types';
import CurrencyHelper from '../helpers/CurrencyHelper';
import moment from 'moment';
import { useEffect, useState } from 'react';

//export interface IValidationMessage {controlId: string, message: string}

export interface Props {
  Record: {},
  Id: string,
  Required?: boolean,
  Disabled?: boolean,
  PlaceHolder: string,
  Type?: string,
  Label?: string,
  AutoFocus?: boolean,
  HandleChange(e: { target: { name: string, value: string } }): void,
  HandleBlur(e: React.FocusEvent<HTMLInputElement>): void,
  HandleFocus(e: React.FocusEvent<HTMLInputElement>): void,
  ValidationMessages?: IValidationMessage[],
  MultiLine?: boolean
  Rows?: number
  TextAlign?: string
  Size?: string,
  IsMoney?: boolean
};


export default function MyDate2(args: Props) {
  let hasError = false;
  let message = "";
  let Record = args.Record;
  let controlId = args.Id;
  let controlIdY = args.Id + "_Y";
  let controlIdM = args.Id + "_M";
  let controlIdD = args.Id + "_D";
  let required = args.Required;
  let disabled = args.Disabled;
  let placeHolder = args.PlaceHolder;
  let placeHolderY = args.PlaceHolder;
  let placeHolderM = args.PlaceHolder;
  let placeHolderD = args.PlaceHolder;
  let type = "text";
  let textAllignment = args.TextAlign;

  if (args.Type) type = args.Type;

  placeHolderY += " (yyyy)";
  placeHolderM += " (mm)";
  placeHolderD += " (dd)";

  //Vo zavisnot od Value i HasError se refreshira komponentata
  let outputValue = "";
  let value = Record ? Record[args.Id as keyof {}] : "";

  const [isInit, setIsInit] = useState(true);
  const [ValueY, setValueY] = useState("");
  const [ValueM, setValueM] = useState("");
  const [ValueD, setValueD] = useState("");
  const [ValueOutput, setValueOutput] = useState("");

  useEffect(() => {
    if (value !== "Invalid date") {

      if (!value)
      {
        value = "1900-01-01"; 
      }
      //alert("HERE");
      //let value = moment(value).format("YYYY-MM-DD");
      let valueY = moment.utc(value).format("YYYY");
      let valueM = moment.utc(value).format("MM");
      let valueD = moment.utc(value).format("DD");

      setValueY(valueY);
      setValueM(valueM);
      setValueD(valueD);
      setIsInit(false);
    } 
  }, [value]);


  outputValue = value;

  //Proveri dali ima greska
  if (required === true) {
    let errorCollection = args.ValidationMessages;
    if (errorCollection != null && errorCollection.length > 0) {
      let currentOption = errorCollection.find((x: IValidationMessage) => x.controlId === controlId); //vidi vo validation.helper.js
      if (currentOption != null) {
        hasError = true;
        message = currentOption.message;
      }
    }
  }

  const handleChangeD = (e: any) => {
    const { name, value } = e.target;
    setValueD(value);
  };

  const handleChangeM = (e: any) => {
    const { name, value } = e.target;
    setValueM(value);
  };

  const handleChangeY = (e: any) => {
    const { name, value } = e.target;
    setValueY(value);
  };

  const handleBlurD = (e: any) => {
    const { name, value } = e.target;
    let dayD = value;
    let validationDate = ValueY + '-' + ValueM + '-' + dayD;

    //proveri dali e validen datum
    if (!moment(validationDate).isValid()) {
      dayD = '01';
      setValueD(dayD); //initial valid value
    }
    //ako e samo 1 brojka togas dodaj 0;
    else if (dayD.length == 1) {
      dayD = '0' + dayD;
      setValueD(dayD);
    }

    //konceno se e vo red so ogled deka moze da e dodadena brojka uste ednas setiraj se i prakaj go na Parent-ot
    validationDate = ValueY + '-' + ValueM + '-' + dayD;
    let customerArgs = { target: { name: args.Id, value: validationDate }, relatedTarget: e.relatedTarget }; //relatedTarget za btnSave
    args.HandleChange(customerArgs);
  };

  const handleBlurM = (e: any) => {
    const { name, value } = e.target;
    let monthM = value;
    let validationDate = ValueY + '-' + monthM + '-' + ValueD;

    //proveri dali e validen datum
    if (!moment(validationDate).isValid()) {
      monthM = '01';
      setValueM(monthM); //initial valid value
    }
    //ako e samo 1 brojka togas dodaj 0;
    else if (monthM.length == 1) {
      monthM = '0' + monthM;
      setValueM(monthM);
    }

    //konceno se e vo red so ogled deka moze da e dodadena brojka uste ednas setiraj se i prakaj go na Parent-ot
    validationDate = ValueY + '-' + monthM + '-' + ValueD;
    let customerArgs = { target: { name: args.Id, value: validationDate }, relatedTarget: e.relatedTarget }; //relatedTarget za btnSave
    args.HandleChange(customerArgs);
  };

  const handleBlurY = (e: any) => {
    const { name, value } = e.target;
    let yearY = value;
    let validationDate = yearY + '-' + ValueM + '-' + ValueD;

    //proveri dali e validen datum
    if (!moment(validationDate, 'YYYY-MM-DD', true).isValid()) {
      yearY = '2023';
      setValueY(yearY); //initial valid value
    }



    //konceno se e vo red so ogled deka moze da e dodadena brojka uste ednas setiraj se i prakaj go na Parent-ot
    validationDate = yearY + '-' + ValueM + '-' + ValueD;
    let customerArgs = { target: { name: args.Id, value: validationDate }, relatedTarget: e.relatedTarget }; //relatedTarget za btnSave
    args.HandleChange(customerArgs);
  };


  return (
    <Grid container>
      <Grid item xs={12} sx={{ mr: 1 }}>
        <Typography variant="h6" color="text.secondary" align="left">
          {placeHolder}
        </Typography>
      </Grid>
      <Grid item xs={3} sm={3} sx={{ mr: 1 }}>
        <TextField
          type='text'
          size='small'
          variant={"outlined"}
          margin="dense" //normal
          fullWidth
          disabled={disabled}
          id={controlIdD}
          name={controlIdD}
          //helperText={args.Help}
          label="Day"
          onChange={handleChangeD}
          onBlur={handleBlurD}
          value={ValueD}
          //defaultValue=" "
          helperText={message}
          error={hasError}
          placeholder={placeHolderD}
          sx={{ input: { textAlign: textAllignment ? textAllignment : "left", maxLength: 2 } }}
        />
      </Grid>
      <Grid item xs={3} sm={3} sx={{ mr: 1 }}>
        <TextField
          type='text'
          //size={size = 'small' ? 'small' : 'medium'}
          size='small'
          variant={"outlined"}
          margin="dense" //normal
          fullWidth
          disabled={disabled}
          id={controlIdM}
          name={controlIdM}
          //helperText={args.Help}
          label="Month"
          onChange={handleChangeM}
          onBlur={handleBlurM}
          //onChange={e => dispatch({ type: 'UserName_Change', payload: e.target.value })}
          value={ValueM}
          //defaultValue=" "
          helperText={message}
          error={hasError}
          placeholder={placeHolderM}
          sx={{ input: { textAlign: textAllignment ? textAllignment : "left" } }}
        />
      </Grid>
      <Grid item xs={3} sm={3}>
        <TextField
          type='text'
          //size={size = 'small' ? 'small' : 'medium'}
          size='small'
          variant={"outlined"}
          margin="dense" //normal
          fullWidth
          disabled={disabled}
          id={controlIdY}
          name={controlIdY}
          //helperText={args.Help}
          label="Year"
          onChange={handleChangeY}
          onBlur={handleBlurY}
          //onChange={e => dispatch({ type: 'UserName_Change', payload: e.target.value })}
          value={ValueY}
          //defaultValue=" "
          helperText={message}
          error={hasError}
          placeholder={placeHolder}
          sx={{ input: { textAlign: textAllignment ? textAllignment : "left" } }}
        />
      </Grid>
    </Grid>
  );
};

