import { IErrorsInput, ValidatorHelper } from "../helpers/ValidatorHelper";
import { LookupDataService } from "../services/LookupDataService";
import { CountryService } from "../services/CountryService";
import { EmployeeService } from "../services/EmployeeService";
import { StaffService } from "../services/StaffService";
import { enumSharedMsgs } from "../helpers/common.constants";
import { ActionPointsService } from "../services/ActionPointsService";


export default class ActionPointsActions {

  async InitAsync(Record : {}) {
    let responseData = await new ActionPointsService().Search(Record);
       let responses = [responseData]

    //napravi validacija
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async GetTotalActionPointsAsync(Record : {}) {
    let responseData = await new ActionPointsService().GetTotalActionPoints(Record);
       let responses = [responseData]

    //napravi validacija
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

}