import { enumConfig } from "./common.constants";


export default class ExactOnlineHelper {

  constructor() {}

  isLocalEnvironment() : boolean {
    const url = String(window.location.href);
    return url.includes("localhost");
  }

  GetBaseUrl() {
    if (this.isLocalEnvironment()) {
      return enumConfig.exactAuthUrlLOCAL;

    }
    return enumConfig.exactAuthUrlLIVE;
  }
}
