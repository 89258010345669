import AuthenticateHelper from "./AuthenticateHelper";
import { enumConfig } from "./common.constants";


export default class LogHelper {

  constructor() {}

  isLocalEnvironment() : boolean {
    const url = String(window.location.href);
    return url.includes("localhost");
  }

  GetBaseUrl() {
    if (this.isLocalEnvironment()) {
      return enumConfig.baseUrlDevelopment;
    }
    return enumConfig.baseUrl;
  }

  GetDownloadFileUrl(fileName : string) {
    let url : string = enumConfig.baseUrl;
    if (this.isLocalEnvironment()) {
      url =  enumConfig.baseUrlDevelopment;
    }

    let token = new AuthenticateHelper().GetToken();
    let ouputUrl  =  url + "Download/DownloadFile?token=" + token + "&inputFile=" + fileName;

    return ouputUrl;
  }
}
