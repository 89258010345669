// DateInput.tsx
import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import moment from "moment";
import { TextField } from "@mui/material";
import { IValidationMessage } from "../helpers/common.interfaces";


  export interface Props  {
    Record: {},
    Id: string,
    Required?: boolean,
    Disabled?: boolean,
    PlaceHolder: string,
    Type?: string,
    Label?: string,
    AutoFocus?: boolean,
    HandleChange(e: { target: { name: string, value: string } }): void,
    HandleBlur(e: React.FocusEvent<HTMLInputElement>) : void,
    HandleFocus(e: React.FocusEvent<HTMLInputElement>) : void,
    ValidationMessages?: IValidationMessage[],
    MultiLine?: boolean
    Rows?: number
    TextAlign? : string
    Size? : string,
    IsMoney? : boolean
  };


  export default function MyDate (args : Props) {
    let hasError = false;
    let message = "";
    let Record = args.Record;
    let controlId = args.Id;
    let required = args.Required;
    let disabled = args.Disabled;
    let placeHolder = args.PlaceHolder;
    

    
    //Vo zavisnot od Value i HasError se refreshira komponentata
    let value : any = Record ? Record[args.Id as keyof {}] : new Date();

    //Vo zavisnot od Value i HasError se refreshira komponentata
    //let value : any  =   dayjs(new Date(), "DD-MM-YYYY");
    value = dayjs(value, "YYYY-MM-DD");

    
    //Proveri dali ima greska
    if (required === true) {
      let errorCollection = args.ValidationMessages;
      if (errorCollection != null && errorCollection.length > 0) {
        let currentOption = errorCollection.find((x : IValidationMessage) => x.controlId === controlId); //vidi vo validation.helper.js
        if (currentOption != null) {
          hasError = true;
          message = currentOption.message;
        }
      }
    }


    //console.error("ALLOO OUT :" + new Date().getTime());
      

    const handleDateChange = (date: Dayjs | null) => {
      //setSelectedDate(date);
      if (date && dayjs(date).isValid()) {
        const formattedDate = date.format("YYYY-MM-DD");
        let customerArgs = { target: { name: args.Id, value: formattedDate} };
        args.HandleChange(customerArgs); // Pass dateField along with date value
      } else {
        let customerArgs = { target: { name: args.Id, value: ""} };
        args.HandleChange(customerArgs); // Pass dateField along with date value

        //args.HandleChange("", dateField); // Handle clearing the date if needed
      }
    };
    
    return React.useMemo(() => { 

      return (
        
        <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DesktopDatePicker sx={{ mt: 2, mb:1 }}
          label={placeHolder}
          format="DD-MM-YYYY"
          value={value}
          onChange={handleDateChange}
          slotProps={{ textField: { variant: "outlined", helperText:message, error:hasError } }}
          disabled={disabled}
        />
      </LocalizationProvider>
      );
    }, [controlId, value, message, disabled, hasError]);  
  };
