import LocalStorageHelper from "../helpers/LocalStorageHelper";
import { RestHelper } from "../helpers/RestHelper";
import { enumLocalStorageArgs } from "../helpers/common.constants";

export class FinAllocationService {
  private _restHelper;

  constructor() {
    this._restHelper = new RestHelper();
  }

  async Search(payload: any) {
    const url: string = "FinAllocation/Search";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async GetByProjectID(payload: any) {
    const url: string = "FinAllocation/GetByProjectID";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async GetByEmployee(payload: any) {
    const url: string = "FinAllocation/GetByEmployee";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async GetEmployeeFinDetails(payload: any) {
    const url: string = "FinAllocation/GetEmployeeFinDetails";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async GetFinBudgetDetails(payload: any) {
    const url: string = "FinAllocation/GetFinBudgetDetails";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async Save(payload: any) {
    const url: string = "FinAllocation/Save";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async Delete(payload: any) {
    const url: string = "FinAllocation/Delete";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  // async Export(payload: any) {
  //   const url: string = "FinAllocation/Export";
  //   let response = await this._restHelper.post(url, payload);
  //   return response;
  // }

  async ExportByEmployee(payload: any) {
    const url: string = "FinAllocation/ExportByEmployee";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async ExportBySearch(payload: any) {
    const url: string = "FinAllocation/ExportBySearch";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async ExportAllocationBySearch(payload: any) {
    const url: string = "FinAllocation/ExportAllocationBySearch";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async ExportByProject(payload: any) {
    const url: string = "FinAllocation/ExportByProject";
    let response = await this._restHelper.post(url, payload);
    return response;
  }
}
