// @ts-check
import { useEffect } from "react";

import { useState } from "react";
import {
  enumDocumentsSource,
  enumLookupTypes,
  enumPage,
  enumRoutes
} from "../../helpers/common.constants";
import Button from "@mui/material/Button";
import { Grid } from "@mui/material";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import NotesIcon from "@mui/icons-material/Notes";
import EmployeeActions from "../../actions/EmployeeActions";
import moment from "moment";
import { MessageHelper } from "../../helpers/MessageHelper";
import DownloadActions from "../../actions/DownloadActions";
import SystemLogDetail from "../SystemLog/SystemLogDetail";

export default function EmployeeHeaderButtons(args) {
  //Page MAIN Hooks
  const [IsNewRecord, setIsNewRecord] = useState(true);
  const [MessageData, setMessageData] = useState({});
  const [ModalParams, setModalParams] = useState({ ShowModal: false, Content: "", OnModalClose: null, OnModalHasChanges: null, Arguments: null }); //ModalParams e objekt koj sto IsOpen i IsContent ima struktura

  //Da proveri dali e nov zapis ili postoecki
  const { EmployeeID } = useParams();

  //za Navigacija
  const navigate = useNavigate();

  //Za Refreshiranje na strana mu treba;
  const location = useLocation();

  //za poraki pri loadiranje na strana
  const msgHelper = new MessageHelper();

  //****************************************** */
  //HOOKS SECTION HERE
  //****************************************** */

  //1 --> Ke se povika ovoj HOOK samo dokolku se SMENI url-ot poradi toa na new dodavame Ticks() za da forcirame refresh na strana
  useEffect(() => {
    if (EmployeeID) {
      if (parseInt(EmployeeID) > 0) {
        setIsNewRecord(false);
      }
    }
    else {
      setIsNewRecord(true);
    }

  }, [location.pathname + "/" + location.search]);

  //****************************************** */
  //METHOD  SECTION HERE
  //****************************************** */

  async function openSystemLogDialog() {

    let result = msgHelper.setPhase(enumPage.LoadStart);
    setMessageData(result);


    let payload = { eyEmployeeID: EmployeeID };
    let responses = await new EmployeeActions().EditAsync(payload); // await LookupActions.InitAsync();
    if (!responses.error) {

      let employeeDb = responses[0].data;

      let args = { ReferenceNumber: employeeDb.eyItemGUID }
      setModalParams({ ShowModal: true, OnModalClose: closeSystemLogDialog, Arguments: args });

      let result = msgHelper.setPhase(enumPage.LoadSuccess);
      setMessageData(result);
      return true;
    }
    else {
      //Load with Error
      result = msgHelper.setPhase(enumPage.ErrorServer, responses);
      setMessageData(result);
    }

  };


  const closeSystemLogDialog = (args) => {
    setModalParams({ ShowModal: false });
  }

  const exportEmployeeLeaveRequestForAllYears = () => {
    async function fetchData() {
      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      let payload = { EmployeeID: EmployeeID };
      let responses =
        await new EmployeeActions().ExportEmployeeLeaveRequestAllYearsAsync(
          payload
        );

      if (!responses.error) {
        //Load Finished
        let result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);

        //DOWNLOADING EXCEL FILE
        const excelFileUrl = responses[0].data;
        let payload = "?inputFile=" + excelFileUrl;
        let downloadResponse = await new DownloadActions().DownloadFileAsync(
          payload
        );

        // create file link in browser's memory
        const href = URL.createObjectURL(downloadResponse.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", excelFileUrl); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        return;
      } else {
        //Load with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }
    }
    fetchData();
  };



  //****************************************** */
  //GUI DISPLAY
  //****************************************** */


  const HeaderButtons = () => {
    return (
      <Grid
        container
        align="left"
        sx={{ borderBottom: 1, borderColor: "grey.500", pb: 1 }}
        align="center"
      >

        <Grid item xs={12} spacing={1}>

          <Button
            sx={{ mr: 2, mb: 1 }}
            variant="contained"
            startIcon={<NotesIcon />}
            size="small"
            disabled={MessageData.Loading || IsNewRecord}
            onClick={() =>
              navigate(
                enumRoutes.Employee_Edit.replace(
                  ":EmployeeID",
                  EmployeeID
                )
              )
            }
          >
            Employee
          </Button>

          <Button
            sx={{ mr: 2, mb: 1 }}
            variant="contained"
            startIcon={<NotesIcon />}
            size="small"
            disabled={MessageData.Loading || IsNewRecord}
            onClick={() =>
              navigate(
                enumRoutes.Employee_Contract_Get.replace(
                  ":EmployeeID",
                  EmployeeID
                )
              )
            }
          >
            Contracts
          </Button>

          <Button
            sx={{ mr: 2, mb: 1 }}
            variant="contained"
            startIcon={<NotesIcon />}
            size="small"
            disabled={MessageData.Loading || IsNewRecord}
            onClick={() =>
              navigate(
                enumRoutes.Employee_Documents.replace(
                  ":EmployeeID",
                  EmployeeID
                ).replace(":InputFolderID", "null").replace(":TypeOfSource", enumDocumentsSource.GoogleDrive_FromArg) //ke go cita od args

              )
            }
          >
            Documents
          </Button>

          <Button
            sx={{ mr: 2, mb: 1 }}
            variant="contained"
            startIcon={<NotesIcon />}
            size="small"
            disabled={MessageData.Loading || IsNewRecord}
            onClick={() =>
              navigate(
                enumRoutes.Employee_CheckList_Onboarding.replace(
                  ":EmployeeID",
                  EmployeeID
                ).replace(":LookupTypeID", enumLookupTypes.Onboarding)
              )
            }
          >
            Onboarding
          </Button>

          <Button
            sx={{ mr: 2, mb: 1 }}
            variant="contained"
            startIcon={<NotesIcon />}
            size="small"
            disabled={MessageData.Loading || IsNewRecord}
            onClick={() =>
              navigate(
                enumRoutes.Employee_CheckList_Offboarding.replace(
                  ":EmployeeID",
                  EmployeeID
                ).replace(":LookupTypeID", enumLookupTypes.Offboarding)
              )
            }
          >
            Offboarding
          </Button>

          <Button
            sx={{ mr: 2, mb: 1 }}
            variant="contained"
            startIcon={<NotesIcon />}
            size="small"
            disabled={MessageData.Loading || IsNewRecord}
            onClick={() =>
              navigate(
                enumRoutes.Employee_CheckList_Competencies.replace(
                  ":EmployeeID",
                  EmployeeID
                ).replace(":LookupTypeID", enumLookupTypes.Competencies)
              )
            }
          >
            Competencies
          </Button>

          <Button
            sx={{ mr: 2, mb: 1 }}
            variant="contained"
            startIcon={<NotesIcon />}
            size="small"
            disabled={MessageData.Loading || IsNewRecord}
            onClick={() => exportEmployeeLeaveRequestForAllYears()}
          >
            Leave Request Overview
          </Button>

          <Button
            sx={{ mr: 2, mb: 1 }}
            variant="contained"
            startIcon={<NotesIcon />}
            size="small"
            disabled={MessageData.Loading || IsNewRecord}
            onClick={() => openSystemLogDialog()}
          >
            Log
          </Button>
        </Grid>

        <SystemLogDetail IsOpen={ModalParams.ShowModal} HandleModalClose={ModalParams.OnModalClose} Props={ModalParams.Arguments} />

      </Grid>
    );
  };

  return (
    <HeaderButtons />
  );
}
