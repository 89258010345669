import React, { useState, useEffect } from "react";
import { EditorState, ContentState, convertFromHTML } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { convertToHTML } from "draft-convert";
import DOMPurify from "dompurify";

interface Props {
  editorState: EditorState;
  onEditorStateChange: (editorState: EditorState) => void;
  setConvertedContent: (html: string | null) => void;
  vaText: string;
}

export default function MyEditor(props: Props) {
  const { vaText, editorState, onEditorStateChange } = props;

  useEffect(() => {
    const blocksFromHTML = convertFromHTML(vaText);
    const contentState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    const initialEditorState = EditorState.createWithContent(contentState);
    onEditorStateChange(initialEditorState);
  }, [vaText, onEditorStateChange]);

  // Update convertedContent based on editorState
  useEffect(() => {
    let html = convertToHTML(editorState.getCurrentContent());
    props.setConvertedContent(html);
  }, [editorState, props]);

  return (
    <div>
      <Editor
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarClassName="toolbar-class"
        toolbar={{
          options: ["inline", "blockType"],
        }}
      />
      {/* <button onClick={setTextInEditor}>Set Text</button> */}
    </div>
  );
}
