import LocalStorageHelper from "./LocalStorageHelper";
import { enumConfig } from "./common.constants";

const AUTH_TOKEN = "AUTH_TOKEN";
const AUTH_USERNAME = "AUTH_USERNAME";
const AUTH_FULLNAME = "AUTH_FULLNAME";
const AUTH_ROLEID = "AUTH_ROLEID";


const AUTH_FIRSTNAME = "AUTH_FIRSTNAME";
const AUTH_LASTNAME = "AUTH_LASTNAME";
const AUTH_NEXTURL  = "AUTH_NEXTURL";

export default class AuthenticateHelper {

  private _localStorageHelper: any;
  
  constructor() {
    this._localStorageHelper = new LocalStorageHelper();
  }

  IsSigned() : boolean {
    return this._localStorageHelper.GetValueByKey(AUTH_TOKEN) ? true : false;
  }

  IsAdmin() : boolean {
    const currentRole : string = this._localStorageHelper.GetValueByKey(AUTH_ROLEID);
    const currentRoleAdmin : string = enumConfig.ROLE_ADMIN;
    return currentRole.indexOf(currentRoleAdmin) > -1;
  }

  IsHrAdmin() : boolean {
    const currentRole : string = this._localStorageHelper.GetValueByKey(AUTH_ROLEID);
    const currentRoleAdmin : string = enumConfig.ROLE_HR_ADMIN;
    return currentRole.indexOf(currentRoleAdmin) > -1;
  }
  
  IsFinanceAdmin() : boolean {
    const currentRole : string = this._localStorageHelper.GetValueByKey(AUTH_ROLEID);
    const currentRoleAdmin : string = enumConfig.ROLE_FINANCE_ADMIN;
    return currentRole.indexOf(currentRoleAdmin) > -1;
  }

  IsAdminOrFinanceAdmin() : boolean {
    if (!this.IsAdmin())
    {
      return this.IsFinanceAdmin();
    }
    return true;
  }

  GetRole() : string  {
    return this._localStorageHelper.GetValueByKey(AUTH_ROLEID);
  }

  //ako e samo STAFF i nisto drugo
  IsStaff() : boolean {
    return this._localStorageHelper.GetValueByKey(AUTH_ROLEID) === enumConfig.ROLE_STAFF;
  }

  GetTokenWithBarrier() : string {
    return "bearer " + this._localStorageHelper.GetValueByKey(AUTH_TOKEN);
  }

  GetToken() : string { //za Download mu treba da go verifikuva racno token-ot
    return this._localStorageHelper.GetValueByKey(AUTH_TOKEN);
  }
  
  GetUserName() : string {
    return this._localStorageHelper.GetValueByKey(AUTH_USERNAME);
  }

  GetNextuRL() : string {
    return this._localStorageHelper.GetValueByKey(AUTH_NEXTURL);
  }

  GetFullName() : string {
    return this._localStorageHelper.GetValueByKey(AUTH_FULLNAME);
  }

  GetFirstName() : string {
    return this._localStorageHelper.GetValueByKey(AUTH_FIRSTNAME);
  }

  GetLastName() : string {
    return this._localStorageHelper.GetValueByKey(AUTH_LASTNAME);
  }

  SetNextUrl(url : string) {
    this._localStorageHelper.SetValueByKey(AUTH_NEXTURL, url);
  }

  SignIn(token : string, userName : string, fullName: string, role : string, expiresIn: string) {
    
    this._localStorageHelper.SetValueByKey(AUTH_TOKEN, token);
    this._localStorageHelper.SetValueByKey(AUTH_USERNAME, userName);
    this._localStorageHelper.SetValueByKey(AUTH_ROLEID, role);
  
    //ENCRIPTAJ TUKA
    this._localStorageHelper.SetValueByKey(AUTH_FULLNAME, fullName);
  }
  
  //ova funkcija se koristi samo i samo za novi useri i nisto poveke
  SignInLikeNewUser(token : string, userName : string, fullName: string, role : string, firstName:string, lastName:string) {
    
    this._localStorageHelper.SetValueByKey(AUTH_TOKEN, token);
    this._localStorageHelper.SetValueByKey(AUTH_USERNAME, userName);
    this._localStorageHelper.SetValueByKey(AUTH_ROLEID, role);

    this._localStorageHelper.SetValueByKey(AUTH_FIRSTNAME, firstName);
    this._localStorageHelper.SetValueByKey(AUTH_LASTNAME, lastName);
    
  
    //ENCRIPTAJ TUKA
    this._localStorageHelper.SetValueByKey(AUTH_FULLNAME, fullName);
  }
  

  SignOut() {
    // this._localStorageHelper.SetValueByKey(AUTH_TOKEN, null);
    // this._localStorageHelper.SetValueByKey(AUTH_USERNAME, null);
    // this._localStorageHelper.SetValueByKey(AUTH_ROLEID, null);
    // this._localStorageHelper.SetValueByKey(AUTH_FIRSTNAME, null);
    // this._localStorageHelper.SetValueByKey(AUTH_LASTNAME, null);
    // this._localStorageHelper.SetValueByKey(AUTH_FULLNAME, null);

    this._localStorageHelper.RemoveAllItems();
  }
  
}
