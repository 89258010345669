import { IErrorsInput, ValidatorHelper } from "../helpers/ValidatorHelper";
import { LookupDataService } from "../services/LookupDataService";
import { CountryService } from "../services/CountryService";
import { StaffService } from "../services/StaffService";
import { DeclarationService } from "../services/DeclarationService";
import { DeclarationDiemService } from "../services/DeclarationDiemService";

export default class DeclarationDiemActions {
  async InitAsync(Record: any) {

    let responsesDeclaration = await new DeclarationService().Edit(Record);
    let responsesDiemTypes = await new LookupDataService().GetAllByTypeID(Record);
    let responsesBudgets = await new DeclarationService().GetBudgetsPerDeclaration(Record);
    let responses = [responsesDiemTypes, responsesBudgets, responsesDeclaration];

    //napravi validacija
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  
  
  async SaveAsync(Record: any) {
    let jsonInputData: IErrorsInput[] = [
      {
        element: "dfBudgetName",
        type: "SelectEmpty",
        value: Record.dfBudgetName,
        message: "Budget is required",
      },
      {
        element: "dfName",
        type: "SelectEmpty",
        value: Record.dfName,
        message: "Diem Type is required",
      }
    ];


    //validacija
    let validationMessages = new ValidatorHelper().validateClient(jsonInputData);
    if (validationMessages.length > 0) {
      let response = {
        error: true, //oznaci deka ima greska
        error_description: "Unable to save",
        ValidationMessages: validationMessages, //vrati gi greskite za GUI
      };
      return response;
    }


    //napravi validacija
    let response = await new DeclarationDiemService().SaveCost(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  
  async DeleteAsync(Record: any) {
    //napravi validacija
    let response = await new DeclarationDiemService().DeleteCost(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async GetByDeclarationIDAsync(Record: any) {
    //napravi validacija
    let response = await new DeclarationDiemService().GetByDeclarationID(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  
  async UploadAsync(Record: any) {
    //napravi validacija
    let response = await new DeclarationDiemService().UploadCost(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  
  async DownloadCostUrlAsync(Record: any) {
    //napravi validacija
    let response = await new DeclarationDiemService().DownloadCostUrl(Record);
    return response;
  }

  
  async DownloadDiemAsync(Record: any) {
    //napravi validacija
    let response = await new DeclarationDiemService().DownloadDiem(Record);
    return response;
  }
}
