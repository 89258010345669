// @ts-check 
import * as React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import MyText from '../../controls/MyText';
import { useState } from 'react';
import MyAlert from '../../controls/MyAlert';
import { enumBreadcrumbPage, enumLookupTypes, enumPage, enumPagination, enumRoutes, enumSharedMsgs } from '../../helpers/common.constants';
import DeclarationCostActions from '../../actions/DeclarationCostActions';
import Button from '@mui/material/Button';
import MyCheckList from '../../controls/MyCheckList';
import { Box, Breadcrumbs, Card, CardActions, CardContent, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, useMediaQuery } from '@mui/material';
import CurrencyHelper from '../../helpers/CurrencyHelper';
import LogHelper from '../../helpers/LogHelper';
import MyDate from '../../controls/MyDate';
import { useParams, useLocation, useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import moment from 'moment';
import DeclarationBreadcrumbs from './DeclarationBreadcrumbs';
import NotesIcon from '@mui/icons-material/Notes';
import VisibilityIcon from '@mui/icons-material/Visibility';
import BrightnessAutoIcon from '@mui/icons-material/BrightnessAuto';
import { MessageHelper } from '../../helpers/MessageHelper';
import MyNumber from '../../controls/MyNumber';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from '@mui/icons-material/HighlightOff';
import MyDropDown from '../../controls/MyDropDown';
import MyMoney from '../../controls/MyMoney';
import DeclarationActions from '../../actions/DeclarationActions';
import DeclarationDiemActions from '../../actions/DeclarationDiemActions';

const RecordDefault =
{
  "dfDeclarationFeeID": 0,
  "dfDeclarationID": 0,
  "dfTypeID": 0,
  "dfName": "",
  "dfDescription": "string",
  "dfProjectName": "string",
  "dfBudgetName": "",
  "dfFee": 0,
  "dfCurrency": "EUR",
  "dfFeeRate": 1,
  "dfFeeEUR": 0,
  "dfStartDate": "2023-08-04T10:58:08.507Z",
  "dfEndDate": "2023-08-04T10:58:08.507Z",
  "deCostDoc1": "",
  "deNotes": "",
  "deDiemDoc1": ""
};


export default function DeclarationApproval() {


  const [DeclarationData, setDeclaration] = useState(RecordDefault);
  const [Record, setRecord] = useState(RecordDefault);
  const [EnableButtons, setEnableButtons] = useState(true);
  const [IsFinanceApproval, setFinanceApproval] = useState(false);
  const [IsBudgetHolderApproval, setBudgetHolderApproval] = useState(false);
  const [MessageData, setMessageData] = useState({});
  const [CostRecords, setCostRecords] = useState([]);
  const [PerDiemRecords, setPerDiemRecords] = useState([]);
  const { DeclarationID } = useParams();
  const [IsInitDone, setIsInitDone] = useState(false);
  const [ForceSave, setForceSave] = useState(false);
  const [SumRecord, setSumRecord] = useState([]);

  //za Navigacija 
  const navigate = useNavigate();

  //za poraki pri loadiranje na strana 
  const msgHelper = new MessageHelper();

  //Za Refreshiranje na strana mu treba;
  const location = useLocation();

  //****************************************** */
  //HOOKS SECTION HERE 
  //****************************************** */

  //1 --> Iniicijalno povikaj initData i ako zavrsi se kako sto treba ke go setira IsInitDone: true koj sto ke go zapali hook-ot IsInitDone
  React.useEffect(() => {
    initData();
  }, [location.pathname + '/' + location.search]);

  //****************************************** */
  //METHOD  SECTION HERE 
  //****************************************** */

  const initData = () => {
    async function fetchData() {

      //Kazuva deka e Finance Approval sprema samiot Link
      let isFinanceApproval = false; //tuka ne e vo state pa radi toa ni treba uste edna
      if (location.pathname.toString().toLowerCase().indexOf("financeapproval") > -1) {
        isFinanceApproval = true;
        setFinanceApproval(true);
      }
      else if (location.pathname.toString().toLowerCase().indexOf("budgetholderapproval") > -1) {
        setBudgetHolderApproval(true);
      }


      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);
      setEnableButtons(false);
      //setMessageData((a) => ({...a, Loading: true }));

      let payload = { deDeclarationID: DeclarationID };
      let responses = await new DeclarationActions().EditAsync(payload); // await LookupActions.InitAsync();
      if (!responses.error) {

        let recordDb = responses[0].data;
        setDeclaration(recordDb);

        await GetByDeclarationDetailsIDAsync();

        let result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);

        if (!recordDb.deSubmittedFL) {
          result = msgHelper.setPhase(enumPage.LoadSuccessWithMsg, null, "Declaration is not submitted by the Staff!");
          setMessageData(result);
        }
        else if (recordDb.deBudgetApprovedFL) {
          result = msgHelper.setPhase(enumPage.LoadSuccessWithMsg, null, "Declaration is already approved by Budget Holder and can't be changed!");
          setMessageData(result);
        }
        else if (isFinanceApproval && recordDb.deFinanceApprovedFL) {
          result = msgHelper.setPhase(enumPage.LoadSuccessWithMsg, null, "Declaration is already approved by Finance and can't be changed!");
          setMessageData(result);
        }
        else {
          setEnableButtons(true);
        }
      }
      else {
        let result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
        setEnableButtons(true);
      }

    }
    fetchData();
  };

  async function GetByDeclarationDetailsIDAsync() {


    //sega loadiraj Contracts by employee
    let payload = { deDeclarationID: DeclarationID };
    let responses = await new DeclarationActions().GetDeclarationDetails(payload);
    if (!responses.error) {
      //Load Finished
      let CostRecordsData = responses[0].data;
      setCostRecords(CostRecordsData);

      let PerDiemRecordsData = responses[1].data;
      setPerDiemRecords(PerDiemRecordsData);

      let sumItemAmount = 0;
      if (CostRecordsData.length > 0) {
        sumItemAmount = CostRecordsData.map((item) => item.dfFeeEUR).reduce((a, b) => a + b);
      }

      let sumItemAmount2 = 0;
      if (PerDiemRecordsData.length > 0) {
        sumItemAmount2 = PerDiemRecordsData.map((item) => item.dfFeeEUR).reduce((a, b) => a + b);
      }
      setSumRecord({ Amount: sumItemAmount, Amount2: sumItemAmount2 });

      let result = msgHelper.setPhase(enumPage.LoadSuccess);
      setMessageData(result);
      return true;
    }
    else {
      //Load with Error
      let result = msgHelper.setPhase(enumPage.ErrorServer, responses);
      setMessageData(result);
      return false;
    }
  }


  const approveData = () => {

    async function fetchData() {

      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      let response = null;

      if (IsFinanceApproval) {
        DeclarationData.deFinanceApprovedFL = true;
        DeclarationData.deNotes = Record.deNotes;
        response = await new DeclarationActions().FinanceApprovalAsync(DeclarationData);
      }

      else if (IsBudgetHolderApproval) {
        DeclarationData.deBudgetApprovedFL = true;
        DeclarationData.deNotes = Record.deNotes;
        response = await new DeclarationActions().BudgetHolderApprovalAsync(DeclarationData);
      }

      if (!response.error) {

        let recordDb = response[0].data;
        setRecord(recordDb);

        if (IsFinanceApproval) {
          result = msgHelper.setPhase(enumPage.LoadSuccessWithMsg, null, "Declaration verified by Finance,  email sent to Budget Holder for final approval");
        }

        if (IsBudgetHolderApproval) {
          result = msgHelper.setPhase(enumPage.LoadSuccessWithMsg, null, "Declaration approved,  email sent to Finance and Staff for payment");
        }

        setEnableButtons(false);

        setMessageData(result);
        setRecord((a) => ({ ...a, ValidationMessages: result.ValidationMessages }));  //Ako ima Validation Messages da gi izbrise
      }
      else {
        //Finished  with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, response);
        setMessageData(result);
        setRecord((a) => ({ ...a, ValidationMessages: result.ValidationMessages }));  //Samo ovdeka mu treba ako ima Validacioni greski da dodade
      }
    }

    fetchData();
  };


  const disapproveData = () => {

    async function fetchData() {

      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      let response = null;



      if (IsFinanceApproval) {
        DeclarationData.deFinanceApprovedFL = false;
        DeclarationData.deNotes = Record.deNotes;
        response = await new DeclarationActions().FinanceApprovalAsync(DeclarationData);
      }

      else if (IsBudgetHolderApproval) {
        DeclarationData.deBudgetApprovedFL = false;
        DeclarationData.deNotes = Record.deNotes;
        response = await new DeclarationActions().BudgetHolderApprovalAsync(DeclarationData);
      }

      if (!response.error) {

        let recordDb = response[0].data;
        setRecord(recordDb);

        if (IsFinanceApproval) {
          result = msgHelper.setPhase(enumPage.LoadSuccessWithMsg, null, "Declaration disapproved by Finance and email sent to Staff");
        }

        if (IsBudgetHolderApproval) {
          result = msgHelper.setPhase(enumPage.LoadSuccessWithMsg, null, "Declaration disapproved by Bydget Holder and email sent to Staff");
        }

        setEnableButtons(false);

        setMessageData(result);
        setRecord((a) => ({ ...a, ValidationMessages: result.ValidationMessages }));  //Ako ima Validation Messages da gi izbrise
      }
      else {
        //Finished  with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, response);
        setMessageData(result);
        setRecord((a) => ({ ...a, ValidationMessages: result.ValidationMessages }));  //Samo ovdeka mu treba ako ima Validacioni greski da dodade
      }
    }

    fetchData();
  };







  const downloadDiem = () => {
    async function fetchData() {

      let payload = "?ID=" + DeclarationID;
      let response = await new DeclarationDiemActions().DownloadDiemAsync(payload);

      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', DeclarationData.deDiemDoc1); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    }
    fetchData();
  };

  const downloadCost = () => {
    async function fetchData() {

      let payload = "?ID=" + DeclarationID;
      let response = await new DeclarationCostActions().DownloadCostAsync(payload);

      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', DeclarationData.deCostDoc1); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    }
    fetchData();
  };


  const downloadDeclaration = () => {
    async function fetchData() {

      let payload = "?ID=" + DeclarationID;
      let response = await new DeclarationActions().DownloadAsync(payload);

      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', "Declaration_" + new Date().getTime() + ".xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    }
    fetchData();
  };



  //****************************************** */
  //HANDLERS SECTION HERE 
  //****************************************** */
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name + ":" + value);
    setRecord((prevState) => ({ ...prevState, [name]: value }));
  };



  //****************************************** */
  //GUI DISPLAY
  //****************************************** */
  const ActionButtons = () => {
    return (
      <Grid container padding={1}>
        <Grid item xs={6} align="left">
          <Button name="btnSave" variant="contained" color="secondary" size="small" disabled={MessageData.Loading || !EnableButtons} onClick={() => disapproveData()}>Disapprove</Button>
        </Grid>

        <Grid item xs={6} align="right">
          <Button name="btnSave" variant="contained" color="success" size="small" disabled={MessageData.Loading || !EnableButtons} onClick={() => approveData()}>Approve</Button>
        </Grid>
      </Grid>
    )
  };

  const HeaderButtons = () => {
    return (
      <Grid container sx={{ borderBottom: 1, borderColor: 'grey.500', pb: 1 }}>
        <Grid item xs={12} align="right">
          <Button name="btnDownload" variant="contained" color="warning" size="small" disabled={MessageData.Loading} onClick={() => downloadDeclaration()}>Download</Button>
        </Grid>

      </Grid>
    )
  };


  return (
    <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="center">
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h3" display="block">{DeclarationData.deName}</Typography>
          <Typography variant="h4" display="block">Staff: {DeclarationData.deEmployeeFullName}, Finance Controller: {DeclarationData.deFinanceFullName}, Budget Holder: {DeclarationData.deBudgetHolderFullName} </Typography>
        </Grid>
      </Grid>

      <DeclarationBreadcrumbs page={enumBreadcrumbPage.Notes} Id={Record.ctEmployeeID} disabled={MessageData.Loading} />
      <MyAlert {...MessageData} />

      <HeaderButtons />


      <Grid container align="left" sx={{ borderBottom: 1, borderColor: 'grey.500', pb: 1 }}>
        <Grid item xs={12}>
          <Typography variant="h6">
            <b>List of Costs</b>
          </Typography>
        </Grid>
      </Grid>


      <TableContainer sx={{ display: { xs: "block", sm: "block", md: "block" } }}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="center"><b>#</b></TableCell>
              <TableCell align="center"><b>Date</b></TableCell>
              <TableCell align="center"><b>Name</b></TableCell>
              <TableCell align="center"><b>Budget</b></TableCell>
              <TableCell align="center"><b>Cost</b></TableCell>
              <TableCell align="center"><b>Cost (EUR)</b></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {CostRecords.map((row, index) => (
              <TableRow key={row.dfDeclarationFeeID} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>
                  {index + 1}.
                </TableCell>
                <TableCell align="left">{moment(row.dfStartDate).format("D/MM/YYYY")}</TableCell>
                <TableCell align="left">{row.dfName}</TableCell>
                <TableCell align="left">{row.dfBudgetName}</TableCell>
                <TableCell align="right">{new CurrencyHelper().ToMoney(row.dfFee)} {row.dfCurrency}</TableCell>
                <TableCell align="right">{new CurrencyHelper().ToEuro(row.dfFeeEUR)}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={5} align="right">
                <b>Total</b>
              </TableCell>
              <TableCell align="right">
                <b>{new CurrencyHelper().ToEuro(SumRecord.Amount)}</b>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>


      <Grid container align="left" sx={{ borderBottom: 1, borderColor: 'grey.500', pb: 1 }}>
        <Grid item xs={12}>
          <Typography variant="h6">
            <b>List of Diems</b>
          </Typography>
        </Grid>
      </Grid>

      <TableContainer sx={{ display: { xs: "block", sm: "block", md: "block" } }}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell align="center"><b>#</b></TableCell>
              <TableCell align="center"><b>Start Date</b></TableCell>
              <TableCell align="center"><b>End Date</b></TableCell>
              <TableCell align="center"><b>Budget</b></TableCell>
              <TableCell align="center"><b>Day Fee</b></TableCell>
              <TableCell align="center"><b>Days</b></TableCell>
              <TableCell align="center"><b>Total (EUR)</b></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {PerDiemRecords.map((row, index) => (
              <TableRow key={row.dfDeclarationFeeID} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>
                  {index + 1}.
                </TableCell>
                <TableCell align="left">{moment(row.dfStartDate).format("D/MM/YYYY")}</TableCell>
                <TableCell align="left">{moment(row.dfEndDate).format("D/MM/YYYY")}</TableCell>
                <TableCell align="left">{row.dfBudgetName}</TableCell>
                <TableCell align="right">{new CurrencyHelper().ToMoney(row.dfFee)}</TableCell>
                <TableCell align="right">{new CurrencyHelper().ToMoney(row.dfFeeRate)}</TableCell>
                <TableCell align="right">{new CurrencyHelper().ToEuro(row.dfFeeEUR)}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell colSpan={6} align="right">
                <b>Total</b>
              </TableCell>
              <TableCell align="right">
                <b>{new CurrencyHelper().ToEuro(SumRecord.Amount2)}</b>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Grid container align="left" sx={{ borderBottom: 1, borderColor: 'grey.500', pb: 1 }}>
        <Grid item xs={12}>
          <Typography variant="h6">
            <b>Supported Documents</b>
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={2} sx={{ my: 0, mx: 2 }} color="text.secondary" >
        <Grid item xs={12} md={6}>
          {DeclarationData.deCostDoc1 !== "" &&
            <Button variant="contained" size='small' color="warning" onClick={() => downloadCost()} disabled={MessageData.Loading}
              disableFocusRipple={true}
              disableTouchRipple={true}>
              Download Cost Document: {DeclarationData.deCostDoc1}
            </Button>
          }
        </Grid>
        <Grid item xs={12} md={6}>
          {DeclarationData.deDiemDoc1 !== "" &&
            <Button variant="contained" size='small' color="warning" onClick={() => downloadDiem()} disabled={MessageData.Loading}
              disableFocusRipple={true}
              disableTouchRipple={true}>
              Download Diem Document: {DeclarationData.deDiemDoc1}
            </Button>
          }
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <MyText Id='deNotes'
            PlaceHolder='Please enter note in case of disapproval'
            Record={Record}
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            MultiLine={true}
            Rows={3}
          />
        </Grid>
      </Grid>

      <ActionButtons />
      <MyAlert {...MessageData} />
    </Typography>
  );
}
