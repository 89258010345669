// @ts-check
import { useEffect } from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import MyText from "../../controls/MyText";
import MyDropDown from "../../controls/MyDropDown";
import MySearchFilter from "../../controls/MySearchFilter";
import MySearchDialog from "../../controls/MySearchDialog";

import { useState } from "react";
import MyAlert from "../../controls/MyAlert";
import {
  enumBreadcrumbPage,
  enumLookupTypes,
  enumPage,
  enumRoutes,
  enumSharedMsgs,
} from "../../helpers/common.constants";
import EmployeeActions from "../../actions/EmployeeActions";
import Button from "@mui/material/Button";
import MyCheckList from "../../controls/MyCheckList";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Breadcrumbs,
  Card,
  CardActions,
  CardContent,
  Grid,
} from "@mui/material";
import { useParams, useLocation, useNavigate, Link } from "react-router-dom";
import CurrencyHelper from "../../helpers/CurrencyHelper";
import MyMoney from "../../controls/MyMoney";
import MyNumber from "../../controls/MyNumber";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";
import React from "react";
import AcquisitionBreadcrumbs from "./EmployeeBreadcrumbs";
import NotesIcon from "@mui/icons-material/Notes";
import BrightnessAutoIcon from "@mui/icons-material/BrightnessAuto";
import EmployeeBreadcrumbs from "./EmployeeBreadcrumbs";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Chart from "react-google-charts";
import MyEmployeeActions from "../../actions/MyEmployeeActions";

const options = {
  allowHtml: true,
};

//const data = [["Todor Panovski","Nikolaos Koufos",""],["Yannick Du Pont","Michel Richter",""],["Michel Richter","Peter de Ruiter",""],["Valentina Qarri","Michel Richter",""],["Verica Koracevic","Peter de Ruiter",""],["Igor Stankovic","Todor Panovski",""],["Simon van Melick","Peter de Ruiter",""],["Oscar NZAYIRAMBAHO","Igor Kana",""],["Ivana Spasojevic","Bilal Amin",""],["Nikolaos Koufos","Michel Richter",""]]

const RecordDefault = {
  eyEmployeeID: 0,
  eyUserName: "string",
  eyFirstName: "Some information",
  eyLastName: "string",
  eyEmail: "spark@spark-online" + new Date().getTime() + ".com",
  eyDepartmentID: 0,
  eyType: true,
  eyFunctionName: "1183",
  eyOfficeName: "string",
  eyRemoved: true,
  eyPassword: "string",
  eyBirthDate: moment(Date.now()).format("YYYY-MM-DD"),
  ReceiveNotifications: true,
  eyAddress: "string",
  eyCity: "string",
  eyCountry: "",
  eyMobPhone: "string",
  eySkype: "string",
  eyPrivateEmail: "privateemail@" + new Date().getTime() + ".com",
  eyWorkPermit: moment(Date.now()).format("YYYY-MM-DD"),
  eyWorkPermit2: moment(Date.now()).format("YYYY-MM-DD"),
  eyDeclarationSupperiorID: 2,
  eyCountryID: 2,
  eyCountryOriginID: 2,
  eyPassportID: "string",
  eyPlaceOfBirth: "string",
  eyNationalityID: 2,
  eyDomicileID: 2,
  eyResidenceID: 2,
  eyHRPosition: 2,
  eyHRLevelOfPosition: 0,
  eyHRPosition2: "string",
  eyOfficePayrollName: "string",
  eyTerminateDate: moment(Date.now()).format("YYYY-MM-DD"),
  eyTerminateReason: "string",
  eyFunctionID: 1183,
  eyOfficeID: 1455,
  eyItemGUID: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
  eyCreatedBy: "string",
  eyCreatedDate: moment(Date.now()).format("YYYY-MM-DD"),
  eySuperiorID: 2,
};

export default function EmployeeOrgChart(args) {
  const [MessageData, setMessageData] = useState({});
  const [Record, setRecord] = useState(RecordDefault);
  const [OrgChartData, setOrgChartData] = useState([["", "", ""]]);

  //Za Refreshiranje na strana mu treba;
  const location = useLocation();
  //****************************************** */
  //HOOKS SECTION HERE
  //****************************************** */

  //1 --> Ke se povika ovoj HOOK samo dokolku se SMENI url-ot poradi toa na new dodavame Ticks() za da forcirame refresh na strana
  useEffect(() => {
    initData();
  }, [location.pathname + "/" + location.search]);

  //****************************************** */
  //METHOD  SECTION HERE
  //****************************************** */
  const setPageMsg = (state, response) => {
    if (state == enumPage.LoadStart) {
      setMessageData((a) => ({ ...a, Message: "", Loading: true }));
    } else if (state == enumPage.LoadSuccess) {
      setMessageData((a) => ({ ...a, Loading: false }));
    } else if (state == enumPage.ErrorServer) {
      if (response.ValidationMessages) {
        setRecord((prevState) => ({
          ...prevState,
          ValidationMessages: response.ValidationMessages,
        })); //za da se reflekgiraat po ZSNText,
      }
      setMessageData((a) => ({
        ...a,
        HasError: true,
        Message: response.error_description,
        Loading: false,
      }));
    } else if (state == enumPage.SaveSuccess) {
      setMessageData((a) => ({
        ...a,
        Loading: false,
        HasError: false,
        Message: enumSharedMsgs.Save,
      }));
    }
  };

  const initData = () => {
    async function fetchData() {
      setPageMsg(enumPage.LoadStart);

      let responses = await new MyEmployeeActions().GetOrgChartAsync(); // await LookupActions.InitAsync();

      let outputArray = [];

      if (!responses.error) {
        let data = responses[0].data;
        //setOrgChartData(responses[0]);

        //const data = [["Todor Panovski","Nikolaos Koufos",""],["Yannick Du Pont","Michel Richter",""],["Michel Richter","Peter de Ruiter",""],["Valentina Qarri","Michel Richter",""],["Verica Koracevic","Peter de Ruiter",""],["Igor Stankovic","Todor Panovski",""],["Simon van Melick","Peter de Ruiter",""],["Oscar NZAYIRAMBAHO","Igor Kana",""],["Ivana Spasojevic","Bilal Amin",""],["Nikolaos Koufos","Michel Richter",""]]

        const options = {
          allowHtml: true,
          size: "medium",
          color: "#001B2C",
          textColor: "#C5D5E0",
          fontName: "Century Gothic", // or 'Futura'
        };

        data.map((a) => {
          let content = `
    <div style="color:#FFF; font-family: 'Futura', 'Century Gothic'; border-style: hidden; background-color: #001B2C">
      <p style="font-size:14px; margin:0; padding:0; line-height:1.2;">${a.Staff}</p>
      <p style="color:#C5D5E0; font-size:12px; margin:0; padding:0;">${a.FunctionStaff}</p>
    </div>
  `;
          let rowArray = [{ v: a.Staff, f: content }, a.Superior, ""];
          outputArray.push(rowArray);
        });

        //       data.map((a) => {
        //         let rowArray = [];
        //         let content = `
        //   <div style="color:#FFF; font-family: 'Century Gothic', Futura; border:0">
        //     <p style="font-size:14px; margin:0; padding:0; line-height:1.2;">${a.Staff}</p>
        //     <p style="color:#C5D5E0; font-size:12px; margin:0; padding:0;">${a.FunctionStaff}</p>
        //   </div>
        // `;
        //         rowArray.push({ v: a.Staff, f: content });
        //         rowArray.push(a.Superior);
        //         rowArray.push(""); // Assuming you don't want to display the function of the superior in the box
        //         outputArray.push(rowArray);
        //       });

        // data.map((a) => {
        //   let rowArray = [];
        //   rowArray.push({
        //     v: a.Staff,
        //     f:
        //       a.Staff +
        //       '<div style="color:red; font-style:italic">' +
        //       a.FunctionStaff +
        //       "</div>",
        //   });
        //   rowArray.push(a.Superior);
        //   rowArray.push(a.FunctionSuperior);
        //   outputArray.push(rowArray);
        // });

        //console.error("ZZZZZZZZZZZZZZZZZZZZZZZZ");
        //console.error(outputArray);
        setOrgChartData(outputArray);

        setPageMsg(enumPage.LoadSuccess);
      } else {
        setPageMsg(enumPage.ErrorServer, responses);
      }
    }
    fetchData();
  };

  const handleChartReady = () => {
    const chartDivs = document.querySelectorAll(
      ".google-visualization-orgchart-node"
    );

    chartDivs.forEach((div) => {
      // Check if this div contains the text 'SPARK'
      if (div.innerHTML.includes("SPARK")) {
        div.style.color = "#FFF"; // Change the font color to white
        // Perform any other style changes you need here
      }
    });
    // Select all elements with the class that matches the nodes
    document
      .querySelectorAll(".google-visualization-orgchart-node")
      .forEach(function (node) {
        // This removes the inline border style
        node.style.borderColor = "transparent";
      });
  };

  return (
    <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
      <MyAlert {...MessageData} />
      <Typography
        sx={{
          my: 5,
          mx: 2,
          color: "#4267b2",
          fontFamily: '"Futura", "Century Gothic", sans-serif',
          fontWeight: "bold",
        }}
        align="left"
        //color="text.secondary"
      >
        Explainer: Org chart based on official line manager / supervisor. This
        chart does not show your technical (matrix) line manager.
      </Typography>
      <Card sx={{ minWidth: 275, borderRadius: 2, mb: 2 }}>
        <CardContent>
          {/* <Chart
            chartType="OrgChart"
            data={OrgChartData}
            options={options}
            width="100%"
            //height="400px"
          /> */}
          <Chart
            chartType="OrgChart"
            data={OrgChartData}
            options={{
              allowHtml: true,
              size: "medium",
              color: "#001B2C",
              textColor: "#FFF",
              fontName: "Futura",
            }}
            width="100%"
            //height="400px"
            chartEvents={[
              {
                eventName: "ready",
                callback: handleChartReady,
              },
            ]}
          />
        </CardContent>
      </Card>
    </Typography>
  );
}
