import { RestHelper } from '../helpers/RestHelper';
             
export class RoleService {

  private _restHelper;

  constructor() {
    this._restHelper = new RestHelper();
  }

  async GetAll(payload : any)  {
    const url : string  = "Role/GetAll";
    let response = await this._restHelper.post(url, payload);
    return response;
  }
}