// @ts-check
import { useEffect } from "react";
import Typography from "@mui/material/Typography";
import MyText from "../../controls/MyText";

import { useState } from "react";
import MyAlert from "../../controls/MyAlert";
import { enumRoutes } from "../../helpers/common.constants";
import Button from "@mui/material/Button";
import { Avatar, Grid } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import AuthenticateActions from "../../actions/AuthenticateActions";
import AuthenticateHelper from "../../helpers/AuthenticateHelper";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import MicrosoftLogin from "react-microsoft-login";
import MyCurrency from "../../controls/MyCurrency";

const RecordDefault = {
  Username: "",
  Password: ""
};

export default function Login(args) {
  const [Record, setRecord] = useState(RecordDefault);
  const [MessageData, setMessageData] = useState({});
  const [msalInstance, onMsalInstanceChange] = useState();
  const navigate = useNavigate();

  //Za Refreshiranje na strana mu treba;
  const location = useLocation();
  const isLocalhost = window.location.href.indexOf("http://localhost") !== -1;

  useEffect(() => {
    new AuthenticateHelper().SignOut();
    //initData();
  }, []); //default

  useEffect(() => {
    if (!isLocalhost) {
      Record.Username = "";
      Record.Password = "";
      setRecord(Record);
    }
  }, [location.pathname + '/' + location.search]);

  const DoLoginNormal = () => {
    async function fetchData() {
      setMessageData({ Loading: true });

      let response = await new AuthenticateActions().LoginAsync(Record);

      if (!response.error) {
        let result = response[0].data;
        new AuthenticateHelper().SignIn(
          result.token,
          result.userName,
          result.fullName,
          result.role,
          result.expiration
        );

        let nextUrl = location.search;
        //alert(nextUrl);
        if (nextUrl) {
          if (nextUrl.toLowerCase().indexOf("?returnurl") > -1) {
            nextUrl = nextUrl.toLowerCase().replace("?returnurl=", "");
            //nextUrl = nextUrl.substring(1);
            //alert(nextUrl);
            navigate(nextUrl);
            return;
          }
        }

        navigate(enumRoutes.Main_Dashboard);
        return;
      } else {
        if (response.ValidationMessages) {
          setRecord((prevState) => ({
            ...prevState,
            ValidationMessages: response.ValidationMessages,
          })); //za da se reflekgiraat po ZSNText,
        }

        setMessageData({
          HasError: true,
          Message: response.error_description,
          Loading: false,
        });
      }
    }

    fetchData();
  };

  const DoLoginGoogle = (payloadGoogle) => {
    async function fetchData() {
      setMessageData({ Loading: true });

      let response = await new AuthenticateActions().LoginGoogle(payloadGoogle);

      if (!response.error) {
        let result = response[0].data;

        if (result.isNewUser) {
          new AuthenticateHelper().SignInLikeNewUser(
            result.token,
            result.userName,
            result.fullName,
            result.role,
            result.firstName,
            result.lastName
          );
          navigate(enumRoutes.Employee_Register);
          return;
        } else {
          new AuthenticateHelper().SignIn(
            result.token,
            result.userName,
            result.fullName,
            result.role,
            result.expiration
          );

          let nextUrl = location.search;
          //alert(nextUrl);
          if (nextUrl) {
            if (nextUrl.toLowerCase().indexOf("?returnurl") > -1) {
              nextUrl = nextUrl.toLowerCase().replace("?returnurl=", "");
              //nextUrl = nextUrl.substring(1);
              //alert(nextUrl);
              navigate(nextUrl);
              return;
            }
          }

          navigate(enumRoutes.Main_Dashboard);
          return;
        }
      } else {
        if (response.ValidationMessages) {
          setRecord((prevState) => ({
            ...prevState,
            ValidationMessages: response.ValidationMessages,
          })); //za da se reflekgiraat po ZSNText,
        }

        setMessageData({
          HasError: true,
          Message: response.error_description,
          Loading: false,
        });
      }
    }

    fetchData();
  };

  const DoLoginMicrosoft = (payloadMicrosoft) => {
    async function fetchData() {
      setMessageData({ Loading: true });

      let response = await new AuthenticateActions().LoginMicrosoft(
        payloadMicrosoft
      );

      if (!response.error) {
        let result = response[0].data;

        if (result.isNewUser) {
          new AuthenticateHelper().SignInLikeNewUser(
            result.token,
            result.userName,
            result.fullName,
            result.role,
            result.firstName,
            result.lastName
          );
          navigate(enumRoutes.Employee_Register);
          return;
        } else {
          new AuthenticateHelper().SignIn(
            result.token,
            result.userName,
            result.fullName,
            result.role,
            result.expiration
          );

          let nextUrl = location.search;
          //alert(nextUrl);
          if (nextUrl) {
            if (nextUrl.toLowerCase().indexOf("?returnurl") > -1) {
              nextUrl = nextUrl.toLowerCase().replace("?returnurl=", "");
              //nextUrl = nextUrl.substring(1);
              //alert(nextUrl);
              navigate(nextUrl);
              return;
            }
          }

          navigate(enumRoutes.Main_Dashboard);
          return;
        }
      } else {
        if (response.ValidationMessages) {
          setRecord((prevState) => ({
            ...prevState,
            ValidationMessages: response.ValidationMessages,
          })); //za da se reflekgiraat po ZSNText,
        }

        setMessageData({
          HasError: true,
          Message: response.error_description,
          Loading: false,
        });
      }
    }

    fetchData();
  };
  //****************************************** */
  //HANDLERS SECTION HERE
  //****************************************** */
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name + ":" + value);
    setRecord((prevState) => ({ ...prevState, [name]: value }));
  };

  const loginHandler = (err, data, msal) => {
    console.log(err, data);
    // some actions
    if (!err && data) {
      //let payload = {IdToken : data.id_token, Provider: "Microsoft"}
      DoLoginMicrosoft(data);
      onMsalInstanceChange(msal);
    }
  };

  return (
    <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="center">
      <MyAlert {...MessageData} />

      <Grid container align="center">
        <Grid item xs={12}>
          <img src="logo.png" />
        </Grid>
      </Grid>

      <Grid container align="center">
        <Grid item xs={12}>
          <Typography variant="h6" display="block">
            Sign In
          </Typography>
        </Grid>
      </Grid>

      <Grid container align="center">
        <Grid item xs={12}>
          <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar>
        </Grid>
      </Grid>

      <Grid container sx={{ pt: 3 }}>
        <Grid item xs={12} align="center">
          <GoogleOAuthProvider clientId="821989582318-irklcu1i8l5r32r51vqbs2f2ihg28f8s.apps.googleusercontent.com">
            <GoogleLogin
              onSuccess={(credentialResponse) => {
                console.error("GOOOGLE RESPONSE");
                console.error(credentialResponse);
                let credential = credentialResponse.credential;
                let payload = {
                  IdToken: credential,
                  Provider: "Google",
                };
                DoLoginGoogle(payload);
              }}
              onError={() => {
                console.error("GOOOGLE RESPONSE");
                console.error("Login Failed");
              }}
            />
          </GoogleOAuthProvider>
        </Grid>
      </Grid>

      <Grid container sx={{ pt: 3 }}>
        <Grid item xs={12} align="center">
          <MicrosoftLogin
            clientId="425e9e22-f884-4681-b326-978e980d748f"
            authCallback={loginHandler}
          />
        </Grid>
      </Grid>

      <MyAlert {...MessageData} />
    </Typography>
  );
}
