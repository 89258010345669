import { RestHelper } from '../helpers/RestHelper';
             
export class GeneralService {

  private _restHelper;

  constructor() {
    this._restHelper = new RestHelper();
  }

  async GetList(payload : any)  {
    const url : string  = "General/GetList";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async GetRecord(payload : any)  {
//     @TableName nvarchar(100), 
// @ColumnToReturn nvarchar(100), 
// @ColumnToReturn2 nvarchar(100), 
// @ColumnToSearch nvarchar(100),
// @ValueToFind nvarchar(100)
    const url : string  = "General/GetRecord";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async GetRecords(payload : any)  {
    //     @TableName nvarchar(100), 
    // @ColumnToReturn nvarchar(100), 
    // @ColumnToReturn2 nvarchar(100), 
    // @ColumnToSearch nvarchar(100),
    // @ValueToFind nvarchar(100)
        const url : string  = "General/GetRecords";
        let response = await this._restHelper.post(url, payload);
        return response;
      }

}
