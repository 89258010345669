import LocalStorageHelper from '../helpers/LocalStorageHelper';
import { RestHelper } from '../helpers/RestHelper';
import { enumLocalStorageArgs } from '../helpers/common.constants';

export class CountryService {

  private _restHelper;

  constructor() {
    this._restHelper = new RestHelper();
  }

  async GetAll(payload = {}) {
    const url: string = "Country/GetAll";
    const storageKey = enumLocalStorageArgs.UrlKeys + url.replace("/", "_");

    //Check if is stored in Local Storages to not reload many many times
    let responseFromStorage = new LocalStorageHelper().GetJSON(storageKey);
    if (responseFromStorage) {
      return responseFromStorage;
    }

    let response = await this._restHelper.post(url, payload);

    //Storage in the Storage
    new LocalStorageHelper().SetJSON(storageKey, response);

    return response;
  }
}
