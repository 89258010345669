import { IErrorsInput, ValidatorHelper } from "../helpers/ValidatorHelper";
import { LookupDataService } from "../services/LookupDataService";
import { CountryService } from "../services/CountryService";
import { LeaveRequestService } from "../services/LeaveRequestService";
import { StaffService } from "../services/StaffService";

export default class LeaveRequestActions {
  async InitAsync(Record: any) {
    let responseLeaveRequestBalance =
      await new LeaveRequestService().GetLeaveRequestBalance(Record);
    let responses = [responseLeaveRequestBalance];

    //napravi validacija
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async InitSearchAsync(Record: any) {
    let responseLookup = await new LookupDataService().GetAll();
    let responseCountry = await new CountryService().GetAll();
    let responseEmployees = await new LookupDataService().GetAllEmployees();
    let responseAllYears = await new LookupDataService().GetAllYears();
    let responseYesOrNoOrAll = await new LookupDataService().GetYesOrNoOrAll();
    //let responseApprovedSubmittedAll = await new LookupDataService().GetApprovedSubmittedAll();

    let responses = [
      responseLookup,
      responseCountry,
      responseEmployees,
      responseAllYears,
      responseYesOrNoOrAll
      //responseApprovedSubmittedAll,
    ];

    //napravi validacija
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async SaveAsync(Record: any) {
    let jsonInputData: IErrorsInput[] = [
      {
        element: "rqDatetimeStart",
        type: "DateTimeEmpty",
        value: Record.rqDatetimeStart,
        message: "Start Date is required",
      },
      {
        element: "rqDateTimeEnd",
        type: "DateTimeEmpty",
        value: Record.rqDatetimeEnd,
        message: "End Date is required",
      },
    ];

    if (!Record.SkipValidation) {
      //validacija
      let validationMessages = new ValidatorHelper().validateClient(
        jsonInputData
      );
      if (validationMessages.length > 0) {
        let response = {
          error: true, //oznaci deka ima greska
          error_description: "Unable to save",
          ValidationMessages: validationMessages, //vrati gi greskite za GUI
        };
        return response;
      }
    }

    //napravi validacija
    let response = await new LeaveRequestService().Save(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async ApprovalAsync(Record: any) {
    //napravi validacija
    let response = await new LeaveRequestService().Approval(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async SearchAsync(Record: any) {
    //napravi validacija
    let response = await new LeaveRequestService().Search(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    
    return output;
  }

  async CalendarAsync(Record: any) {
    //napravi validacija
    let response = await new LeaveRequestService().Calendar(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    
    return output;
  }

  async MyApprovalsAsync(Record: any) {
    //napravi validacija
    let response = await new LeaveRequestService().MyApprovals(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async MyLeaveRequestsAsync(Record: any) {
    //napravi validacija
    let response = await new LeaveRequestService().MyLeaveRequests(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async ExportDetailsAsync(Record: any) {
    //napravi validacija
    let response = await new LeaveRequestService().ExportDetails(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async ExportSummaryAsync(Record: any) {
    //napravi validacija
    let response = await new LeaveRequestService().ExportSummary(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async EditAsync(Record: any) {
    //napravi validacija
    let response = await new LeaveRequestService().Edit(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  
  async DeleteAsync(Record: any) {
    //napravi validacija
    let response = await new LeaveRequestService().Delete(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async GetStaffLeaveRequestBalanceAsync(Record: any) {
    //napravi validacija
    let response = await new LeaveRequestService().GetStaffLeaveRequestBalance(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }
}
