// @ts-check
import * as React from "react";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import MyAlert from "../../controls/MyAlert";
import {
  enumBreadcrumbPage,
  enumConfig,
  enumPage,
  enumPagination,
  enumRoutes,
} from "../../helpers/common.constants";
import EmployeeActions from "../../actions/EmployeeActions";
import Button from "@mui/material/Button";
import {
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import LogHelper from "../../helpers/LogHelper";
import { useLocation, useNavigate } from "react-router-dom";
import EmployeeBreadcrumbs from "../Employee/EmployeeBreadcrumbs";
import MyDropDown from "../../controls/MyDropDown";
import Link from "@mui/material/Link";
import { MessageHelper } from "../../helpers/MessageHelper";
import ExactonlineActions from "../../actions/ExactonlineActions";
import CurrencyHelper from "../../helpers/CurrencyHelper";
import moment from "moment";
import MyText from "../../controls/MyText";
import ExactOnlineHelper from "../../helpers/ExactOnlineHelper";

const RecordDefault = {
  PageSize: enumPagination.PageSize,

  // OfficeID: 0,
  // CountryIDs: "",
  // Name: "",
  // PhaseIDs: "",
  // ActiveFL: "1",
  Division: "",
  Year: 2022,
  Code: "",
  coRefDate: "",
  coName: "",
};

export default function ExactonlineMigration() {
  const [Record, setRecord] = useState(RecordDefault);
  const [MessageData, setMessageData] = useState({});
  const [Records, setRecords] = useState([]);
  const [IsInitDone, setIsInitDone] = useState(false);

  const [MigrationConfigData, setMigrationConfigData] = useState([]);

  //Za Refreshiranje na strana mu treba;
  const location = useLocation();

  //za poraki pri loadiranje na strana
  const msgHelper = new MessageHelper();

  //****************************************** */
  //HOOKS SECTION HERE
  //****************************************** */

  //1 --> Ke se povika ovoj HOOK samo dokolku se SMENI url-ot poradi toa na new dodavame Ticks() za da forcirame refresh na strana na sekoj click
  useEffect(() => {
    initData();
  }, [location.pathname + "/" + location.search]);

  //****************************************** */
  //METHOD  SECTION HERE
  //****************************************** */

  const initData = () => {
    async function fetchData() {
      //Clean all states
      setIsInitDone(false);
      setRecord(RecordDefault);

      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      let responses = await new ExactonlineActions().InitMigrationAsync(); // await LookupActions.InitAsync();
      if (!responses.error) {
        let migrationConfigData = responses[0].data;

        console.log("TOKEEENNN");
        console.log(migrationConfigData);

        setMigrationConfigData(migrationConfigData);
        setIsInitDone(true);

        //Load Finished
        result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);
      } else {
        //Load with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }
    }
    fetchData();
  };


  const MigrateData = () => {
    async function fetchData() {
      //Clean all states
      setIsInitDone(false);
      setRecord(RecordDefault);

      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStartWithMsg, null, "Exact Online migration in progress, please check your email for logs!");
      setMessageData(result);

      // Make the HTTP request to your API with the 'token' as a parameter
      let response = await new ExactonlineActions().MigrateAsync(Record);
      if (!response.error) {

        //Load Finished
        result = msgHelper.setPhase(enumPage.LoadSuccessWithMsg, null, response[0].data);
        setMessageData(result);
      } else {
        //Load with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, response);
        setMessageData(result);
      }
    }
    fetchData();
  };


  // const MigrateData = async () => {
  //   try {
  //     // Assuming you have the 'token' available in your React component
  //     //const token = exactTokenData;
  //     //let result = msgHelper.setPhase(enumPage.LoadStart);
  //     let result = msgHelper.setPhase(enumPage.LoadStartWithMsg, null, "Exact Online migration in progress, please check your email for logs!");

  //     // Make the HTTP request to your API with the 'token' as a parameter
  //     let response = await new ExactonlineActions().MigrateAsync(Record);
  //     if (!response.error) {

  //       //Load Finished
  //       result = msgHelper.setPhase(enumPage.LoadSuccessWithMsg, null, response[0].data);
  //       setMessageData(result);
  //     } else {
  //       //Load with Error
  //       result = msgHelper.setPhase(enumPage.ErrorServer, response);
  //       setMessageData(result);
  //     }

  //     // Handle the response from the API if needed
  //     console.log("TOKENOOOOTTT");
  //     console.log(response);
  //   } catch (error) {
  //     // Handle any errors that occur during the API request
  //     console.error(error);
  //   }
  // };


  
  const handleChange = (e) => {
    const { name, value } = e.target;

    console.log(name + ":" + value);
    setRecord((prevState) => ({ ...prevState, [name]: value }));
  };



  return (
    <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
      <MyAlert {...MessageData} />

      <Card sx={{ minWidth: 275, borderRadius: 2, mb: 2 }}>
        <CardContent>
          {/* <Grid container spacing={2}>
            <Grid>
              <ToolbarButtons />
            </Grid>
          </Grid> */}
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography variant="h5">
                Last migration has been made:{" "}
                {moment(MigrationConfigData.coRefDate).format("D/MM/YYYY")}
              </Typography>
            </Grid>
          </Grid>


          <Grid container>
            <Grid item xs={6}>
              <Button
                sx={{ mt: 3.5 }}
                variant="contained"
                color="primary"
                size="small"
                target="_blank"
                href={ new ExactOnlineHelper().GetBaseUrl()}
              >
                Exact authentication 
              </Button>
            </Grid>
          
            <Grid item xs={6}>
              <br/>{new ExactOnlineHelper().GetBaseUrl()}
            </Grid>
          </Grid>
          <hr/>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <MyText Id='Year'
                PlaceHolder='Migration Year'
                Record={Record}
                HandleChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <Button
                sx={{ mt: 1 }}
                variant="contained"
                color="primary"
                size="small"
                onClick={() => MigrateData()}
              >
                Do migration
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {/* <RenderTable Records={Records} /> */}
    </Typography>
  );
}
