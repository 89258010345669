// @ts-check 
import Typography from '@mui/material/Typography';

import { Breadcrumbs } from '@mui/material';
import Link from "@mui/material/Link";
import { enumBreadcrumbPage, enumRoutes } from '../../helpers/common.constants';
import { useEffect, useState } from 'react';

export default function SystemUserBreadcrumbs({page, Id, disabled}) {

  
  const [showPage, setShowPage] = useState(false);

  //1 --> Go zapaluva pri sekoja promena na InputValue i go formatira vo EUR format 
  useEffect(() => {
      if (!disabled)
      {
        setShowPage(true);
      }
  }, [disabled]); //default


  const RenderPage = () => {
      if (!showPage) 
      {
        return <div/>
      }
      if (page === enumBreadcrumbPage.Search)
      {
        return <Search/>;
      }
      else if (page === enumBreadcrumbPage.Master)
      {
        return <Master/>;
      }
      else if (page === enumBreadcrumbPage.Overview)
      {
        return <Overview/>;
      }
      return <div/>
  }
 
  const Search = () => {
    return (
      <Breadcrumbs color="primary"  variant="body2"  aria-label="breadcrumb">
        <Link underline="none" rel="noopener noreferrer" href={enumRoutes.Acquisition_Dashboard}>Home</Link>
        <Typography variant="body2"  color="text.primary">Search</Typography>
      </Breadcrumbs>
    );
  }

  const Overview = () => {
    return (
      <Breadcrumbs color="primary"  variant="body2"  aria-label="breadcrumb">
        <Link underline="none" rel="noopener noreferrer"  href={enumRoutes.Acquisition_Dashboard}>Home</Link>
        <Link underline="none" rel="noopener noreferrer"  href={enumRoutes.SystemUser_Search}>Search</Link>
        <Typography variant="body2"  color="text.primary">Overview</Typography>
      </Breadcrumbs>
    );
    }

  const Master = () => {
    return (
      <Breadcrumbs color="primary"  variant="body2"  aria-label="breadcrumb">
        <Link underline="none" rel="noopener noreferrer"  href={enumRoutes.Acquisition_Dashboard}>Home</Link>
        <Link underline="none" rel="noopener noreferrer"  href={enumRoutes.SystemUser_Search}>Search</Link>
        <Typography variant="body2"  color="text.primary">User</Typography>
      </Breadcrumbs>
    );
  }


  return (
    <RenderPage/>
  )
}
