// @ts-check 
import { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import MyText from '../../controls/MyText';
import MyDropDown from '../../controls/MyDropDown';
import MySearchFilter from '../../controls/MySearchFilter';
import MySearchDialog from '../../controls/MySearchDialog';

import { useState } from 'react';
import MyAlert from '../../controls/MyAlert';
import { enumBreadcrumbPage, enumLookupTypes, enumPage, enumRoutes, enumSharedMsgs } from '../../helpers/common.constants';
import AcquisitionActions from '../../actions/AcquisitionActions';
import Button from '@mui/material/Button';
import MyCheckList from '../../controls/MyCheckList';
import { Grid } from '@mui/material';
import { useParams, useLocation, useNavigate } from "react-router-dom";
import CurrencyHelper from '../../helpers/CurrencyHelper';
import MyMoney from '../../controls/MyMoney';
import MyNumber from '../../controls/MyNumber';
import SystemUserActions from '../../actions/SystemUserActions';
import MyCheckbox from '../../controls/MyCheckbox';
import SystemUserBreadcrumbs from './SystemUserBreadcrumbs';
import VisibilityIcon from '@mui/icons-material/Visibility';

const RecordDefault = {
  Id: '',
  UserName: "",
  FullName: "",
  Email: "ENTERUSERNAME@spark-online.org",
  Password: "",
  ConfirmPassword: "",
  OldPassword: "",
  IsEnabled: true,
  Role: "",
  RowID: 0
};

export default function SystemUserManage(args) {

  // console.error("AcquisitionManage - ARGS");
  // console.error(args);

  //Page MAIN Hooks
  const [IsNewRecord, setIsNewRecord] = useState(true);
  const [IsInitDone, setIsInitDone] = useState(true);
  const [MessageData, setMessageData] = useState({});
  const [Record, setRecord] = useState(RecordDefault);

  const [RolesData, setRoles] = useState([]);
  const [OfficesData, setOffices] = useState([]);

  //Da proveri dali e nov zapis ili postoecki
  const { UserId } = useParams();

  //za Navigacija 
  const navigate = useNavigate();

  //Za Refreshiranje na strana mu treba;
  const location = useLocation();


  //****************************************** */
  //HOOKS SECTION HERE 
  //****************************************** */

  //1 --> Ke se povika ovoj HOOK samo dokolku se SMENI url-ot poradi toa na new dodavame Ticks() za da forcirame refresh na strana
  useEffect(() => {
    initData();
  }, [location.pathname + '/' + location.search]);

  //2 --> Ako e postoecki zapis povikaj editData  (https://stackoverflow.com/questions/54069253/the-usestate-set-method-is-not-reflecting-a-change-immediately)
  useEffect(() => {
    if (IsInitDone) {

      //Check if is existing record and make EditData
      if (UserId) {
        editData();
      }
    }
  }, [IsInitDone]);



  //****************************************** */
  //METHOD  SECTION HERE 
  //****************************************** */

  const setPageMsg = (state, response) => {

    if (state == enumPage.LoadStart) {
      setMessageData((a) => ({ ...a, Message: '', Loading: true }));
    }
    else if (state == enumPage.LoadSuccess) {
      setMessageData((a) => ({ ...a, Loading: false }));
    }
    else if (state == enumPage.ErrorServer) {
      if (response.ValidationMessages) {
        setRecord((prevState) => ({ ...prevState, ValidationMessages: response.ValidationMessages })); //za da se reflekgiraat po ZSNText,
      }
      setMessageData((a) => ({ ...a, HasError: true, Message: response.error_description, Loading: false }));
    }
    else if (state == enumPage.SaveSuccess) {
      setMessageData((a) => ({ ...a, Loading: false, HasError: false, Message: enumSharedMsgs.Save }));
    }
  };

  const initData = () => {
    async function fetchData() {

      setIsNewRecord(true); //DEFAULT 
      setRecord(RecordDefault);
      setPageMsg(enumPage.LoadStart);

      let responses = await new SystemUserActions().InitAsync(); // await LookupActions.InitAsync();
      if (!responses.error) {

        let rolesData = responses[0].data;
        setRoles(rolesData);

        let officesData = responses[1].data;
        setOffices(officesData);

        setPageMsg(enumPage.LoadSuccess);

        setIsInitDone(true);
      }
      else {
        setPageMsg(enumPage.ErrorServer, responses);
      }
    }


    fetchData();
  };


  const editData = () => {
    async function fetchData() {

      setPageMsg(enumPage.LoadStart);
      //setMessageData((a) => ({...a, Loading: true }));

      let payload = { Id: UserId };
      let responses = await new SystemUserActions().EditAsync(payload); // await LookupActions.InitAsync();
      if (!responses.error) {

        let recordDb = responses[0].data;
        setRecord(recordDb);

        setPageMsg(enumPage.LoadSuccess);
        setIsNewRecord(false);
      }
      else {
        setPageMsg(enumPage.ErrorServer, responses);
      }

    }
    fetchData();
  };

  const saveData = () => {
    async function fetchData() {
      setMessageData((a) => ({ Loading: true }));

      let response = await new SystemUserActions().SaveAsync(Record);

      if (!response.error) {

        let recordDb = response[0].data;
        setRecord(recordDb);

        //ke go prati do App.js na event handler: const handleRefresh = (argsOfRecordData) 
        //od tamu ke go prati vo backContainer  pa vo Header js kako recordData={recordData}
        //za da go promeni header-ot od New User vo Manage User
        if (IsNewRecord) {
          args.onRefresh({ refreshFL: true, record: recordDb });
        }

        setPageMsg(enumPage.SaveSuccess);
        setIsNewRecord(false);
      }
      else {
        setPageMsg(enumPage.ErrorServer, response);
      }
    }

    fetchData();
  };

  //****************************************** */
  //HANDLERS SECTION HERE 
  //****************************************** */
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name + ":" + value);
    setRecord((prevState) => ({ ...prevState, [name]: value }));
  };


  //****************************************** */
  //GUI DISPLAY
  //****************************************** */
  const ActionButtons = () => {
    return (
      <Grid container align="right" padding={1}>
        <Grid item xs={12}>
          <Button name="btnSave" variant="contained" sx={{ mr: 1 }} color="success" size="small" disabled={MessageData.Loading} onClick={() => saveData()}>Save</Button>
        </Grid>
      </Grid>
    )
  };

  const HeaderButtons = () => {
    return (
      <Grid container align="left" sx={{ borderBottom: 1, borderColor: 'grey.500', pb: 1 }} align="center">
        <Grid item xs={12} spacing={1} >
          <Button sx={{ mr: 2 }} variant="contained" startIcon={<VisibilityIcon />} size="small" disabled={MessageData.Loading || IsNewRecord} onClick={() => navigate(enumRoutes.SystemUser_Overview.replace(":UserId", Record.Id))}>
            Overview
          </Button>
        </Grid>
      </Grid>
    )
  };


  return (
    <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">

      <SystemUserBreadcrumbs page={enumBreadcrumbPage.Master} Id={Record.Id} disabled={MessageData.Loading} />

      <MyAlert {...MessageData} />

      <HeaderButtons />
      <ActionButtons />

      {!IsNewRecord &&
        <Grid container>
          <Grid item xs={12}>
            <Typography variant="h6" display="block" align="center">{Record.FullName}</Typography>
          </Grid>
        </Grid>
      }

      <Grid container>
        <Grid item xs={12}>
          <MyText Id='FullName'
            PlaceHolder='FullName'
            Record={Record}
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            Required={true}
            Disabled={!IsNewRecord}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <MyText Id='Email'
            PlaceHolder='Email (User Name)'
            Record={Record}
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            Required={true}
            Disabled={!IsNewRecord}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <MyText Id='Password'
            PlaceHolder='Password'
            Type='password'
            Record={Record}
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            Required={true}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <MyText Id='ConfirmPassword'
            PlaceHolder='Confirm Password'
            Type='password'
            Record={Record}
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            Required={true}
          />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <MyCheckList Id='Role'
            Record={Record}
            PlaceHolder='Role'
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            Options={RolesData}
            Required={true} />
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          <MyCheckList Id='OfficeIDs'
            Record={Record}
            PlaceHolder='OfficeIDs'
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            LookupTypeId={enumLookupTypes.Offices}
            Options={OfficesData}
            Required={true} />
        </Grid>
      </Grid>
      

      <Grid container>
        <Grid item xs={12}>
          <MyCheckbox Id='IsEnabled'
            Record={Record}
            PlaceHolder='Enabled'
            HandleChange={handleChange}
            Required={true} />
        </Grid>
      </Grid>

      <Grid container align="right" padding={1}>
        <Grid item xs={12}>
          <Button variant="contained" color="success" size="small" disabled={MessageData.Loading} onClick={() => saveData()}>Save</Button>
        </Grid>
      </Grid>


      <MyAlert {...MessageData} />


    </Typography>
  );
}
