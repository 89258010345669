// @ts-check
import * as React from "react";
import Typography from "@mui/material/Typography";
import MyText from "../../controls/MyText";
import { useEffect, useState } from "react";
import MyAlert from "../../controls/MyAlert";
import {
  enumData,
  enumPage,
  enumPagination,
  enumRoutes,
  enumSharedMsgs,
  enumSQLS,
} from "../../helpers/common.constants";
import Button from "@mui/material/Button";
import {
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import Link from "@mui/material/Link";
import MyDropDown from "../../controls/MyDropDown";
import { MessageHelper } from "../../helpers/MessageHelper";
import DownloadActions from "../../actions/DownloadActions";
import FinAllocationActions from "../../actions/FinAllocationActions";
import CurrencyHelper from "../../helpers/CurrencyHelper";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import MyAutoSuggestMulti from "../../controls/MyAutoSuggestMulti";
import ZCurrencyField from "../../controls/ZCurrencyField";
import moment from "moment";
import ZAutoSuggestField from "../../controls/ZAutoSuggestField";
import AddIcon from "@mui/icons-material/Add";
import MyDialogYesNo from "../../controls/MyDialogYesNo";
import FinReportsActions from "../../actions/FinReportsActions";

const RecordDefault = {
  EmployeeIDs: "",
  ProjectID: 0,
  BudgetID: 0,
  StaffID: 0,
  Year: 2024,
  PageIndex: 0,
  PageSize: enumPagination.PageSizeLow,
  TotalRecords: 0,
  TotalPages: 0,
  ProjectIDs: "",
  StaffIDs:"",
  StatusID:1
};

const styles = {
  TDSmall: { mt: 0, mb: 0, pt: 0, pb: 0 },
};

export default function FinRepEmployeeAllocation() {
  const [Record, setRecord] = useState(RecordDefault);
  const [RecordToDelete, setRecordToDelete] = useState(RecordDefault);
  const [MessageData, setMessageData] = useState({});
  const [Project, setProject] = useState({});
  const [Records, setRecords] = useState([]);
  const [RecordsSum, setRecordsSum] = useState([]);
  const [IsInitDone, setIsInitDone] = useState(false);
  const [IsPaginationChange, setPaginationChange] = useState(false);
  const [IsDoSearch, setIsDoSearch] = useState(false);
  const [IsLocked, setIsLocked] = useState(false);
  const [IsModalOpen, setIsModalOpen] = useState(false);
  const [ModalMessage, setModalMessage] = useState("");
  const [LookupData, setLookupData] = useState([]);
  const [OfficeData, setOfficeData] = useState([]);
  const [CurrencyExcRates, setCurrencyExcRates] = useState([]);
  const { ProjectID } = useParams();

  //Za Refreshiranje na strana mu treba;
  const location = useLocation();

  //za poraki pri loadiranje na strana
  const msgHelper = new MessageHelper();

  //****************************************** */
  //HOOKS SECTION HERE
  //****************************************** */

  //1 --> Ke se povika ovoj HOOK samo dokolku se SMENI url-ot poradi toa na new dodavame Ticks() za da forcirame refresh na strana na sekoj click
  useEffect(() => {
    initData();
  }, [location.pathname + "/" + location.search]);

  //2 --> Od koga e sredena InitData() povikaj Search  (https://stackoverflow.com/questions/54069253/the-usestate-set-method-is-not-reflecting-a-change-immediately)
  useEffect(() => {
    if (IsInitDone) {
      searchData();
    }
  }, [IsInitDone]);

  // //3 --> Pagination Change
  useEffect(() => {
    if (IsPaginationChange) {
      searchData();
    }
  }, [IsPaginationChange]);

  //4 --> Search Click
  useEffect(() => {
    if (IsDoSearch) {
      searchData();
    }
  }, [IsDoSearch]);

  //****************************************** */
  //METHOD  SECTION HERE
  //****************************************** */
  const initData = () => {
    async function fetchData() {
      //Clean all statesLock      setIsInitDone(false);

      //Najprvo vidi dali doaga od payroll_verify
      let isVerifyLink =
        location.pathname
          .toLowerCase()
          .toString()
          .indexOf("finbudget_verify".toLowerCase()) > -1;

      let record = RecordDefault;
      record.ProjectID = ProjectID;
      setRecord(record);

      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      let responses = await new FinAllocationActions().InitAsync(); // await LookupActions.InitAsync();
      if (!responses.error) {
        let lookupData = responses[0].data;

        setLookupData(lookupData);

        //await GetProjectByIdAsync();

        //setYearsData(yearsData);
        setIsInitDone(true);

        //Load Finished
        result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);
      } else {
        //Load with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }
    }
    fetchData();
  };

  const searchData = () => {
    searchDataAsync();
  };

  async function searchDataAsync() {
    //Load Start
    setRecords([]);
    let result = msgHelper.setPhase(enumPage.LoadStart);
    setMessageData(result);

    let responses = await new FinAllocationActions().SearchAsync(Record);
    if (!responses.error) {
      const records = responses[0].data;

      //For Pagination purposes
      const pageIndex = responses[0].headers["content-pageindex"];
      const totalRecords = responses[0].headers["content-totalrecords"];
      const totalPages = responses[0].headers["content-totalpages"];
      setRecord((a) => ({
        ...a,
        ["PageIndex"]: pageIndex,
        ["TotalRecords"]: totalRecords,
        ["TotalPages"]: totalPages,
      }));
      setPaginationChange(false); //za da se pali na sleden page
      setIsDoSearch(false); //za da se pali na Search Click

      setRecords(records);

      //napravi sum records
      makeSumRecord(records);

      //Load Finished
      result = msgHelper.setPhase(enumPage.LoadSuccess);
      setMessageData(result);
    } else {
      //Load with Error
      setIsDoSearch(false); //za da se pali na Search Click
      result = msgHelper.setPhase(enumPage.ErrorServer, responses);
      setMessageData(result);
    }
  }

  
  async function GetEmployeeFinDetailsAsync(employeeId, year) {
    //sega loadiraj Contracts by employee
    let payload = { faStaffID: employeeId, faYear: year };
    console.log(payload);
    let responses = await new FinAllocationActions().GetEmployeeFinDetailsAsync(
      payload
    );
    if (!responses.error) {
      //Load Finished
      let employeeFinDetailsData = responses[0].data;
      return employeeFinDetailsData;
    } else {
      //Load with Error
      msgHelper.setPhase(enumPage.ErrorServer, responses);
      return null;
    }
  }

  async function GetFinBudgetDetails(budgetId) {
    //sega loadiraj Contracts by employee
    let payload = { faBudgetID: budgetId };
    let responses = await new FinAllocationActions().GetFinBudgetDetailsAsync(
      payload
    );
    if (!responses.error) {
      let finBudgetDetailsData = responses[0].data;
      return finBudgetDetailsData;
    } else {
      //Load with Error
      msgHelper.setPhase(enumPage.ErrorServer, responses);
      return null;
    }
  }


  const handleChangeSearch = (e) => {
    const { name, value, text } = e.target;
    console.log(name + ":" + value);
    //setRecord((prevState) => ({ ...prevState, [name]: value, ["PageIndex"]: 0 }));

    setRecord((a) => ({ ...a, [name]: value }));
  };

  const handleChangeRecord = (e) => {
    async function fetchData(e) {
      const { name, value, primaryKey, text } = e.target; //vo name-ot go imame proektot
      console.error(name + ":" + value + ":" + primaryKey);

      //najdi go od nizata samiot record i updatetiraj go
      let newRecords = [...Records]; //Records.map( x => ({...x}) )
      let currentCheck = newRecords.find((a) => a.faAllocationID.toString() === primaryKey.toString());
      currentCheck[name] = value;
      currentCheck["IsChanged"] = true; //VAZNO za da se upali memo-to dole

      //za faBudgetID i faStaffID iskoristi Text i mapiraj soodvetno samo za prikaz
      if (name === "faProjectID") {
        currentCheck["faProjectName"] = text; 
        //currentCheck
      }

      //za faBudgetID i faStaffID iskoristi Text i mapiraj soodvetno samo za prikaz
      else if (name === "faBudgetID") {
        currentCheck["faBudgetName"] = text;

        //FIND MORE DETAILS REGARDING THE STAFF ITSELF
        let finBudgetDetails = await GetFinBudgetDetails(value);

        if (finBudgetDetails) {
          currentCheck.faBudgetAmount = finBudgetDetails.fbAmount;
          currentCheck.faBudgetMgmtFee = finBudgetDetails.fbMgmtFee;
          currentCheck.fbCostPlannedCalc = finBudgetDetails.fbCostPlannedCalc;
        } else {
          currentCheck.faStaffName = "";
        }
      } else if (name === "faStaffID") {
        //Kalkulacii za Staff
        currentCheck["faStaffName"] = text;

        //FIND MORE DETAILS REGARDING THEchro STAFF ITSELF
        let employeeFinDetails = await GetEmployeeFinDetailsAsync(value, Record.Year);

        if (employeeFinDetails) {
          currentCheck.faStaffName = employeeFinDetails.eyFirstName + " " + employeeFinDetails.eyLastName;
          currentCheck.faStaffContractStart = employeeFinDetails.ContractStartDate;
          currentCheck.faStaffContractEnd = employeeFinDetails.ContractEndDate;
          currentCheck.faStaffSalaryAmount = employeeFinDetails.ContractSallary;
          currentCheck.faStaffSalaryCurrency = employeeFinDetails.ContractCurrency;
          currentCheck.faStaffWorkingDays = employeeFinDetails.ContractWorkingDays;
          

          currentCheck.faMonthFL1 = employeeFinDetails.faMonthFL1;
          currentCheck.faMonthFL2 = employeeFinDetails.faMonthFL2;
          currentCheck.faMonthFL3 = employeeFinDetails.faMonthFL3;
          currentCheck.faMonthFL4 = employeeFinDetails.faMonthFL4;
          currentCheck.faMonthFL5 = employeeFinDetails.faMonthFL5;
          currentCheck.faMonthFL6 = employeeFinDetails.faMonthFL6;
          currentCheck.faMonthFL7 = employeeFinDetails.faMonthFL7;
          currentCheck.faMonthFL8 = employeeFinDetails.faMonthFL8;
          currentCheck.faMonthFL9 = employeeFinDetails.faMonthFL9;
          currentCheck.faMonthFL10 = employeeFinDetails.faMonthFL10;
          currentCheck.faMonthFL11 = employeeFinDetails.faMonthFL11;
          currentCheck.faMonthFL12 = employeeFinDetails.faMonthFL12;
        } else {
          currentCheck.faStaffName = "";
        }
      } else {
        //presmetaj total
        currentCheck.faYearPlanned =
          parseFloat(currentCheck.faMonth1) +
          parseFloat(currentCheck.faMonth2) +
          parseFloat(currentCheck.faMonth3) +
          parseFloat(currentCheck.faMonth4) +
          parseFloat(currentCheck.faMonth5) +
          parseFloat(currentCheck.faMonth6) +
          parseFloat(currentCheck.faMonth7) +
          parseFloat(currentCheck.faMonth8) +
          parseFloat(currentCheck.faMonth9) +
          parseFloat(currentCheck.faMonth10) +
          parseFloat(currentCheck.faMonth11) +
          parseFloat(currentCheck.faMonth12);
        //currentCheck.faMonthPlanned = 88;
      }

      //za vo koj red od nizata da go setira so findInex
      let propertyIndex = newRecords.findIndex(
        (x) => x.faAllocationID.toString() === primaryKey.toString()
      );
      newRecords[propertyIndex] = currentCheck;

      setRecords(newRecords);
    }

    fetchData(e);
  };

  const makeSumRecord = (currentRecords) => {
    let newSumRecord = {};

    newSumRecord.faBudgetAmount = currentRecords.reduce(
      (sum, row) => (sum = sum + parseFloat(row.faBudgetAmount)),
      0
    );
    newSumRecord.faMonth1 = currentRecords.reduce(
      (sum, row) => (sum = sum + parseFloat(row.faMonth1)),
      0
    );
    newSumRecord.faMonth1 = currentRecords.reduce(
      (sum, row) => (sum = sum + parseFloat(row.faMonth1)),
      0
    );
    newSumRecord.faMonth2 = currentRecords.reduce(
      (sum, row) => (sum = sum + parseFloat(row.faMonth2)),
      0
    );
    newSumRecord.faMonth3 = currentRecords.reduce(
      (sum, row) => (sum = sum + parseFloat(row.faMonth3)),
      0
    );
    newSumRecord.faMonth4 = currentRecords.reduce(
      (sum, row) => (sum = sum + parseFloat(row.faMonth4)),
      0
    );
    newSumRecord.faMonth5 = currentRecords.reduce(
      (sum, row) => (sum = sum + parseFloat(row.faMonth5)),
      0
    );
    newSumRecord.faMonth6 = currentRecords.reduce(
      (sum, row) => (sum = sum + parseFloat(row.faMonth6)),
      0
    );
    newSumRecord.faMonth7 = currentRecords.reduce(
      (sum, row) => (sum = sum + parseFloat(row.faMonth7)),
      0
    );
    newSumRecord.faMonth8 = currentRecords.reduce(
      (sum, row) => (sum = sum + parseFloat(row.faMonth8)),
      0
    );
    newSumRecord.faMonth9 = currentRecords.reduce(
      (sum, row) => (sum = sum + parseFloat(row.faMonth9)),
      0
    );
    newSumRecord.faMonth10 = currentRecords.reduce(
      (sum, row) => (sum = sum + parseFloat(row.faMonth10)),
      0
    );
    newSumRecord.faMonth11 = currentRecords.reduce(
      (sum, row) => (sum = sum + parseFloat(row.faMonth11)),
      0
    );
    newSumRecord.faMonth12 = currentRecords.reduce(
      (sum, row) => (sum = sum + parseFloat(row.faMonth12)),
      0
    );

    newSumRecord.faYearPlanned = currentRecords.reduce(
      (sum, row) => (sum = sum + parseFloat(row.faYearPlanned)),
      0
    );

    newSumRecord.faYearCostPlanned = currentRecords.reduce(
      (sum, row) => (sum = sum + parseFloat(row.faYearCostPlanned)),
      0
    );

    setRecordsSum(newSumRecord);
  };

 
  const handleEditRow = (row) => {
    // console.error(row);
    //najdi go od nizata samiot record i updatetiraj go
    let newRecords = [...Records]; //Records.map( x => ({...x}) )

    //remove the orophan records
    newRecords = newRecords.filter((a) => a.faAllocationID !== 0);

    //if there are any EDIT records close them
    let currentEditRecord = newRecords.find((a) => a.IsEdit === true);
    if (currentEditRecord) {
      let propertyEditRecordIndex = newRecords.findIndex((x) => x.IsEdit == true);
      currentEditRecord["IsEdit"] = false;
      newRecords[propertyEditRecordIndex] = currentEditRecord;
    }

    let currentCheck = newRecords.find((a) => a.faAllocationID.toString() === row.faAllocationID.toString());
    currentCheck["IsEdit"] = true;

    //za vo koj red od nizata da go setira so findInex
    let propertyIndex = newRecords.findIndex((x) => x.faAllocationID.toString() === row.faAllocationID.toString());
    newRecords[propertyIndex] = currentCheck;
    setRecords(newRecords);
  };

  const handleAddRow = () => {
    let hasRecordToDelete = false;
    const index = 0;
    const newRecord = {
      faProjectID: ProjectID,
      faYear: Record.Year,
      faAllocationID: 0,
      IsEdit: true,
      faBudgetID: -1, //mora za da se refreshira CACHE-ot
      faStaffID: -1, //mora za da se refreshira CACHE-ot
      faMonth1: 0,
      faMonth2: 0,
      faMonth3: 0,
      faMonth4: 0,
      faMonth5: 0,
      faMonth6: 0,
      faMonth7: 0,
      faMonth8: 0,
      faMonth9: 0,
      faMonth10: 0,
      faMonth11: 0,
      faMonth12: 0,
      faYearPlanned: 0,
      faMonthFL1: false,
      faMonthFL2: false,
      faMonthFL3: false,
      faMonthFL4: false,
      faMonthFL5: false,
      faMonthFL6: false,
      faMonthFL7: false,
      faMonthFL8: false,
      faMonthFL9: false,
      faMonthFL10: false,
      faMonthFL11: false,
      faMonthFL12: false,
    };

    let newRecords = [];
    if (Records.length === 0) {
      newRecords.push(newRecord);
    } else {
      //ako ima nekoj record koj sto bil prethodno vo Records najdi go i smeni go vo -99 za da go izbrises pokasno
      let recordToDelete = Records.find(
        (a) => a.faAllocationID.toString() === "0"
      );
      if (recordToDelete) {
        hasRecordToDelete = true;
        recordToDelete.faAllocationID = -99;
      }

      for (let i = 0; i < Records.length; i++) {
        if (i === index) {
          newRecords.push(newRecord);
        }

        //markij gi site recordi vo ovoj state na False
        let currentRecord = Records[i];
        currentRecord.IsEdit = false;
        newRecords.push(currentRecord);
      }
    }

    //izbrisi go orphan record-ot
    if (hasRecordToDelete) {
      newRecords = newRecords.filter((a) => a.faAllocationID !== -99);
    }

    setRecords(newRecords);
  };

  const handleCloseRow = (row) => {
    console.error(row);
    //najdi go od nizata samiot record i updatetiraj go
    let newRecords = [...Records]; //Records.map( x => ({...x}) )

    newRecords = newRecords.filter((a) => a.faAllocationID !== 0);

    if (row.faAllocationID > 0) {
      let currentCheck = newRecords.find(
        (a) => a.faAllocationID.toString() === row.faAllocationID.toString()
      );
      currentCheck["IsEdit"] = false;
      currentCheck["IsChanged"] = false;

      //za vo koj red od nizata da go setira so findInex
      let propertyIndex = newRecords.findIndex(
        (x) => x.faAllocationID.toString() === row.faAllocationID.toString()
      );
      newRecords[propertyIndex] = currentCheck;
    }

    setRecords(newRecords);
  };
 
  const handleSaveRow = (row) => {
    async function fetchData() {
      //Load Start
      let result = msgHelper.setPhase(
        enumPage.LoadStartWithMsg,
        null,
        "This process could take a while, please wait!"
      );

      setMessageData(result);

      let response = await new FinAllocationActions().SaveAsync(row);
      if (!response.error) {
        await searchDataAsync();

        result = msgHelper.setPhase(enumPage.SaveSuccess);
        setMessageData(result);

        //napravi sum records
        makeSumRecord(Records);

        //refreshiraj Project
        //await GetProjectByIdAsync();
      } else {
        //Finished  with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, response);
        setMessageData(result);
        setRecord((a) => ({
          ...a,
          ValidationMessages: result.ValidationMessages,
        })); //Samo ovdeka mu treba ako ima Validacioni greski da dodade
      }
    }

    fetchData();
  };

  const handleSearch = (e) => {
    setRecord((a) => ({ ...a, ["PageIndex"]: 0 }));
    setIsDoSearch(true);
  };

  const handleModalOpenForDelete = (row) => {
    setRecordToDelete(row);
    setIsModalOpen(true); //Otvori popup
  };

  const handleModalCloseForDelete = (IsOk) => {
    if (IsOk) {
      async function fetchData() {
        let result = msgHelper.setPhase(enumPage.LoadStart);
        setMessageData(result);

        let response = await new FinAllocationActions().DeleteAsync(
          RecordToDelete
        );
        if (!response.error) {
          await searchDataAsync();

          result = msgHelper.setPhase(enumPage.DeleteSuccess);
          setMessageData(result);
        }
      }

      fetchData();
    }
    setIsModalOpen(false);
  };
  
  const handleExcelExport = (typeView) => {
    async function fetchData() {
      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStartWithAwait);
      setMessageData(result);

      Record.TypeView = typeView;
      let responses = await new FinReportsActions().ExportEmployeeAllocation(
        Record
      );

      if (!responses.error) {
        //Load Finished
        let result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);

        //DOWNLOADING EXCEL FILE
        const excelFileUrl = responses[0].data;
        let payload = "?inputFile=" + excelFileUrl;
        let downloadResponse = await new DownloadActions().DownloadFileAsync(
          payload
        );

        // create file link in browser's memory
        const href = URL.createObjectURL(downloadResponse.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", excelFileUrl); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        return;
      } else {
        //Load with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }
    }
    fetchData();
  };

  const handleExcelAllocVsTS = () => {
    async function fetchData() {
      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStartWithAwait);
      setMessageData(result);

      let responses =
        await new FinAllocationActions().ExportAllocationSearchAsync(Record);

      if (!responses.error) {
        //Load Finished
        let result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);

        //DOWNLOADING EXCEL FILE
        const excelFileUrl = responses[0].data;
        let payload = "?inputFile=" + excelFileUrl;
        let downloadResponse = await new DownloadActions().DownloadFileAsync(
          payload
        );

        // create file link in browser's memory
        const href = URL.createObjectURL(downloadResponse.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", excelFileUrl); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        return;
      } else {
        //Load with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }
    }
    fetchData();
  };

  return (
    <Typography sx={{ my: 5, mx: 1 }} color="text.secondary" align="left">
      <MyAlert {...MessageData} />

      <Card sx={{ minWidth: 275, borderRadius: 2, mb: 2 }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={1}>
              <MyDropDown
                Id="Year"
                Record={Record}
                PlaceHolder="Select Year"
                HandleChange={handleChangeSearch}
                Options={enumData.YEARS}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <MyAutoSuggestMulti
                Id="ProjectIDs"
                Record={Record}
                PlaceHolder="Approved Projects"
                HandleChange={handleChangeSearch}
                SQLExtraParams={Record.Year} //go pusta parametarot za Search
                //ValidationMessages={Record.ValidationMessages}
                SQLGetList={enumSQLS.KEY_LIST_FinProjectSearchActive} //cita od LOOKUP
                SQLGetRecord={enumSQLS.KEY_GET_MULTI_FinProject} //cita od LOOKUP
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <MyAutoSuggestMulti
                Id="StaffIDs"
                Record={Record}
                PlaceHolder="Employees"
                HandleChange={handleChangeSearch}
                SQLGetList={enumSQLS.KEY_LIST_EmployeeSearch}
                SQLGetRecord={enumSQLS.KEY_GET_MULTI_Employee}
              />
            </Grid>

            {/* <Grid item xs={12} sm={12} md={2}>
              <MyDropDown
                Id="StatusID"
                Record={Record}
                PlaceHolder="Statuses"
                HandleChange={handleChangeSearch}
                Options={enumData.FINPROJECT_STATUSES}  
                />
            </Grid> */}

            <Grid item xs={12} sm={12} md={4}>
              <Button
                sx={{ mt: 1, mr:2 }}
                variant="contained"
                color="warning"
                size="small"
                disabled={MessageData.Loading}
                onClick={() => handleExcelExport(2)}
              >
                Export by Month
              </Button>
            
              <Button
                sx={{ mt: 1 }}
                variant="contained"
                color="warning"
                size="small"
                disabled={MessageData.Loading}
                onClick={() => handleExcelExport(1)}
              >
                Export by Year
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
 
    <MyDialogYesNo
        IsOpen={IsModalOpen}
        HandleModalClose={handleModalCloseForDelete}
        Title={enumSharedMsgs.WariningMsg}
        Content={"Are you sure you want to delete this budget allocation?"}
      />

    </Typography>
  );
}


const RenderTable = ({
  RecordsSum,
  Records,
  Record,
  DataArray,
  onHandleChange,
  onSaveRow,
  onCloseRow,
  onHandleSave,
  onHandleLockUnlock,
  MessageData,
  IsLocked,
  onEditRow,
  onAddRow,
  onDeleteRow,
  onExportExcel,
  onExportExcelAllocVsTS,
}) => {
  return React.useMemo(() => {
    return (
      <TableContainer
        sx={{ display: { xs: "block", sm: "block", md: "block" } }}
      >
        <Table
          sx={{ minWidth: 650, border: "0", padding:"0"}}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell colSpan={20} align="center">
                <Button
                  sx={{ mr: 2, mb: 1 }}
                  variant="contained"
                  startIcon={<AddIcon />}
                  size="small"
                  color="warning"
                  disabled={MessageData.Loading || IsLocked}
                  onClick={() => onAddRow()}
                >
                  Add Allocation
                </Button>

                <Button
                  sx={{ mr: 2, mb: 1 }}
                  variant="contained"
                  startIcon={<FileDownloadIcon />}
                  size="small"
                  color="warning"
                  disabled={MessageData.Loading}
                  onClick={() => onExportExcel()}
                >
                  Export Overview
                </Button>

                <Button
                      sx={{ mb: 1 }}
                      variant="contained"
                      startIcon={<FileDownloadIcon />}
                      size="small"
                      color="warning"
                      disabled={MessageData.Loading}
                      onClick={() => onExportExcelAllocVsTS()}
                    >
                      Export BvS
                    </Button>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell>
                <Typography variant="h6" align="center">
                  Project / Budget Line
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="center">
                  Staff Member
                </Typography>
              </TableCell>
              <TableCell sx={{ width: "15%" }}>
                <Typography variant="h6" align="center">
                  Bugdet
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="center">
                  Jan
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="center">
                  Feb
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="center">
                  Mar
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="center">
                  Apr
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="center">
                  May
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="center">
                  Jun
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="center">
                  Jul
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="center">
                  Aug
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="center">
                  Sep
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="center">
                  Oct
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="center">
                  Nov
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="center">
                  Dec
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="center">
                  Plan. Days
                </Typography>
              </TableCell>
              <TableCell sx={{ width: "10%" }}>
                <Typography variant="h6" align="center">
                  Mngmt Fee
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="center">
                  Plan. Cost
                </Typography>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Records.map((row, index) =>
              //ako ne e EDIT samo prikazi go RED-ot
              !row.IsEdit ? (
                <RenderRow
                  row={row}
                  index={index}
                  onEditRow={onEditRow}
                  //onHandleDelete={onHandleDelete}
                  MessageData={MessageData}
                  DataArray={DataArray}
                  IsLocked={IsLocked}
                  Year={Record.Year}
                />
              ) : (
                //ako  e vo EDIT togas iscrtaj go vo edit mode
                <ManageRow
                  row={row}
                  index={index}
                  onHandleChange={onHandleChange}
                  onSaveRow={onSaveRow}
                  onCloseRow={onCloseRow}
                  onDeleteRow={onDeleteRow}
                  //onHandleDelete={onHandleDelete}
                  MessageData={MessageData}
                  DataArray={DataArray}
                />
              )
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={3}>
                <Typography variant="h6" align="right">
                  Total
                </Typography>
              </TableCell>

              <TableCell>
                <Typography variant="h6" align="right">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    RecordsSum.faMonth1,
                    1
                  )}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="right">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    RecordsSum.faMonth2,
                    1
                  )}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="right">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    RecordsSum.faMonth3,
                    1
                  )}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="right">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    RecordsSum.faMonth4,
                    1
                  )}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="right">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    RecordsSum.faMonth5,
                    1
                  )}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="right">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    RecordsSum.faMonth6,
                    1
                  )}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="right">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    RecordsSum.faMonth7,
                    1
                  )}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="right">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    RecordsSum.faMonth8,
                    1
                  )}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="right">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    RecordsSum.faMonth9,
                    1
                  )}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="right">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    RecordsSum.faMonth10,
                    1
                  )}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="right">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    RecordsSum.faMonth11,
                    1
                  )}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="right">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    RecordsSum.faMonth12,
                    1
                  )}
                </Typography>
              </TableCell>
               <TableCell>
                <Typography variant="h6" align="right">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    RecordsSum.faYearPlanned,
                    1
                  )}
                </Typography>
              </TableCell>              
              <TableCell></TableCell>
              <TableCell>
              <Typography variant="h6" align="right">
                  
                  {/* {new CurrencyHelper().ToMoneyFormatDigits(
                    RecordsSum.faYearCostPlanned,
                    2
                  )} */}
                </Typography>

              </TableCell>
              <TableCell>

              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    );
  }, [Records, MessageData]);
};

const ManageRow = ({
  row,
  index,
  onHandleChange,
  onSaveRow,
  onCloseRow,
  DataArray,
  onHandleDelete,
  MessageData,
  onDeleteRow,
}) => {
  //Record = Pagination

  return React.useMemo(() => {
    return (
      <TableRow key={row.faAllocationID}>
        <TableCell colSpan={19}>
          <Table
            sx={{
              minWidth: 650,
              border: "1",
              mt: 0,
              mb: 0,
              backgroundColor: "#E4F6F8",
            }}
            size="small"
            aria-label="a dense table"
          >
            <TableRow key={row.faAllocationID}>
            <TableCell colSpan={4} sx={[styles.TDSmall]}>
                <ZAutoSuggestField
                  Id="faProjectID"
                  Value={row.faProjectID}
                  PrimaryKey={row.faAllocationID}
                  PlaceHolder="Enter Project (Draft Only)"
                  HandleChange={onHandleChange}
                  Required={true}
                  SQLGetList={enumSQLS.KEY_LIST_FinProjectSearch}
                  SQLGetRecord={enumSQLS.KEY_GET_FinProject}
                  SQLExtraValue={row.faYear}
                  OptionsDisplayText="DisplayText"
                  
                />
              </TableCell>

              <TableCell colSpan={4} sx={[styles.TDSmall]}>
                <ZAutoSuggestField
                  Id="faBudgetID"
                  Value={row.faBudgetID}
                  PrimaryKey={row.faAllocationID}
                  PlaceHolder="Enter Budget"
                  HandleChange={onHandleChange}
                  Required={true}
                  SQLGetList={enumSQLS.KEY_LIST_FinBudgetSearch}
                  SQLGetRecord={enumSQLS.KEY_GET_FinBudget}
                  SQLExtraValue={row.faProjectID + "," + row.faYear}
                  OptionsDisplayText="DisplayText"
                  Disabled={!row.faProjectID || row.faProjectID === 0}
                />
              </TableCell>

              <TableCell colSpan={6} sx={[styles.TDSmall]}>
                <ZAutoSuggestField
                  Id="faStaffID"
                  Value={row.faStaffID}
                  PrimaryKey={row.faAllocationID}
                  PlaceHolder="Enter Staff"
                  HandleChange={onHandleChange}
                  Required={true}
                  SQLGetList={enumSQLS.KEY_LIST_EmployeeSearch}
                  SQLGetRecord={enumSQLS.KEY_GET_Employee}
                  Disabled={!row.faProjectID || row.faProjectID === 0}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={8}>
                <Typography variant="h6" align="left">
                  {row.faBudgetAmount > 0 && (
                    <React.Fragment>
                      Budget Amount:{" "}
                      {new CurrencyHelper().ToMoneyFormatDigits(
                        row.faBudgetAmount,
                        0
                      )}{" "}
                      €
                    </React.Fragment>
                  )}

                  {row.faBudgetMgmtFee > 0 && (
                    <React.Fragment>
                      Mngmt Fee:{" "}
                      {new CurrencyHelper().ToMoneyFormatDigits(
                        row.faBudgetMgmtFee,
                        0
                      )}{" "}
                      €
                    </React.Fragment>
                  )}

                  {row.fbCostPlannedCalc > 0 && (
                    <React.Fragment>
                      , Planned Cost:{" "}
                      {new CurrencyHelper().ToMoneyFormatDigits(
                        row.fbCostPlannedCalc,
                        0
                      )}{" "}
                      €
                    </React.Fragment>
                  )}
                </Typography>
              </TableCell>
              <TableCell colSpan={6}>
                <Typography variant="h6" align="left">
                  {row.faStaffName ? (
                    <React.Fragment>
                      Contract: {moment(row.faStaffContractStart).format("DD/MM/YYYY")} - {moment(row.faStaffContractEnd).format("DD/MM/YYYY")},
                      Amount: {new CurrencyHelper().ToMoneyFormatDigits(row.faStaffSalaryAmount, 0)}{row.faStaffSalaryCurrency},
                      Days: {row.faStaffWorkingDays}
                    </React.Fragment>
                  ) : (
                    ""
                  )}
                </Typography>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell align="right" sx={[styles.TDSmall, { width: "3%" }]}>
                <Typography variant="h6" align="center">
                  Jan
                </Typography>
              </TableCell>
              <TableCell align="right" sx={[styles.TDSmall, { width: "3%" }]}>
                <Typography variant="h6" align="center">
                  Feb
                </Typography>
              </TableCell>
              <TableCell align="right" sx={[styles.TDSmall, { width: "3%" }]}>
                <Typography variant="h6" align="center">
                  Mar
                </Typography>
              </TableCell>
              <TableCell align="right" sx={[styles.TDSmall, { width: "3%" }]}>
                <Typography variant="h6" align="center">
                  Apr
                </Typography>
              </TableCell>
              <TableCell align="right" sx={[styles.TDSmall, { width: "3%" }]}>
                <Typography variant="h6" align="center">
                  May
                </Typography>
              </TableCell>
              <TableCell align="right" sx={[styles.TDSmall, { width: "3%" }]}>
                <Typography variant="h6" align="center">
                  Jun
                </Typography>
              </TableCell>
              <TableCell align="right" sx={[styles.TDSmall, { width: "3%" }]}>
                <Typography variant="h6" align="center">
                  Jul
                </Typography>
              </TableCell>
              <TableCell align="right" sx={[styles.TDSmall, { width: "3%" }]}>
                <Typography variant="h6" align="center">
                  Aug
                </Typography>
              </TableCell>
              <TableCell align="right" sx={[styles.TDSmall, { width: "3%" }]}>
                <Typography variant="h6" align="center">
                  Sep
                </Typography>
              </TableCell>
              <TableCell align="right" sx={[styles.TDSmall, { width: "3%" }]}>
                <Typography variant="h6" align="center">
                  Oct
                </Typography>
              </TableCell>
              <TableCell align="right" sx={[styles.TDSmall, { width: "3%" }]}>
                <Typography variant="h6" align="center">
                  Nov
                </Typography>
              </TableCell>
              <TableCell align="right" sx={[styles.TDSmall, { width: "3%" }]}>
                <Typography variant="h6" align="center">
                  Dec
                </Typography>
              </TableCell>
              <TableCell align="right" sx={[styles.TDSmall, { width: "3%" }]}>
                <Typography variant="h6" align="center">
                  Planned Days
                </Typography>
              </TableCell>
              <TableCell sx={[styles.TDSmall, { width: "3%" }]}>
                <Typography variant="h6" align="center"></Typography>
              </TableCell>
            </TableRow>

            <TableRow key={row.eyEmployeeID}>
              <TableCell align="right" sx={[styles.TDSmall, { width: "3%" }]}>
                <ZCurrencyField
                  Id="faMonth1"
                  Value={row.faMonth1}
                  Disabled={!row.faMonthFL1}
                  HandleChange={onHandleChange}
                  PrimaryKey={row.faAllocationID}
                  Digits="1"
                />
              </TableCell>

              <TableCell align="right" sx={[styles.TDSmall, { width: "3%" }]}>
                <ZCurrencyField
                  Id="faMonth2"
                  Value={row.faMonth2}
                  Disabled={!row.faMonthFL2}
                  HandleChange={onHandleChange}
                  PrimaryKey={row.faAllocationID}
                  Digits="1"
                />
              </TableCell>
              <TableCell align="right" sx={[styles.TDSmall, { width: "3%" }]}>
                <ZCurrencyField
                  Id="faMonth3"
                  Value={row.faMonth3}
                  Disabled={!row.faMonthFL3}
                  HandleChange={onHandleChange}
                  PrimaryKey={row.faAllocationID}
                  Digits="1"
                />
              </TableCell>
              <TableCell align="right" sx={[styles.TDSmall, { width: "3%" }]}>
                <ZCurrencyField
                  Id="faMonth4"
                  Value={row.faMonth4}
                  Disabled={!row.faMonthFL4}
                  HandleChange={onHandleChange}
                  PrimaryKey={row.faAllocationID}
                  Digits="1"
                />
              </TableCell>
              <TableCell align="right" sx={[styles.TDSmall, { width: "3%" }]}>
                <ZCurrencyField
                  Id="faMonth5"
                  Value={row.faMonth5}
                  Disabled={!row.faMonthFL5}
                  HandleChange={onHandleChange}
                  PrimaryKey={row.faAllocationID}
                  Digits="1"
                />
              </TableCell>
              <TableCell align="right" sx={[styles.TDSmall, { width: "3%" }]}>
                <ZCurrencyField
                  Id="faMonth6"
                  Value={row.faMonth6}
                  Disabled={!row.faMonthFL6}
                  HandleChange={onHandleChange}
                  PrimaryKey={row.faAllocationID}
                  Digits="1"
                />
              </TableCell>
              <TableCell align="right" sx={[styles.TDSmall, { width: "3%" }]}>
                <ZCurrencyField
                  Id="faMonth7"
                  Value={row.faMonth7}
                  Disabled={!row.faMonthFL7}
                  HandleChange={onHandleChange}
                  PrimaryKey={row.faAllocationID}
                  Digits="1"
                />
              </TableCell>
              <TableCell align="right" sx={[styles.TDSmall, { width: "3%" }]}>
                <ZCurrencyField
                  Id="faMonth8"
                  Value={row.faMonth8}
                  Disabled={!row.faMonthFL8}
                  HandleChange={onHandleChange}
                  PrimaryKey={row.faAllocationID}
                  Digits="1"
                />
              </TableCell>
              <TableCell align="right" sx={[styles.TDSmall, { width: "3%" }]}>
                <ZCurrencyField
                  Id="faMonth9"
                  Value={row.faMonth9}
                  Disabled={!row.faMonthFL9}
                  HandleChange={onHandleChange}
                  PrimaryKey={row.faAllocationID}
                  Digits="1"
                />
              </TableCell>
              <TableCell align="right" sx={[styles.TDSmall, { width: "3%" }]}>
                <ZCurrencyField
                  Id="faMonth10"
                  Value={row.faMonth10}
                  Disabled={!row.faMonthFL10}
                  HandleChange={onHandleChange}
                  PrimaryKey={row.faAllocationID}
                  Digits="1"
                />
              </TableCell>
              <TableCell align="right" sx={[styles.TDSmall, { width: "3%" }]}>
                <ZCurrencyField
                  Id="faMonth11"
                  Value={row.faMonth11}
                  Disabled={!row.faMonthFL11}
                  HandleChange={onHandleChange}
                  PrimaryKey={row.faAllocationID}
                  Digits="1"
                />
              </TableCell>
              <TableCell align="right" sx={[styles.TDSmall, { width: "3%" }]}>
                <ZCurrencyField
                  Id="faMonth12"
                  Value={row.faMonth12}
                  Disabled={!row.faMonthFL12}
                  HandleChange={onHandleChange}
                  PrimaryKey={row.faAllocationID}
                  Digits="1"
                />
              </TableCell>
              <TableCell align="right" sx={[styles.TDSmall, { width: "3%" }]}>
                {new CurrencyHelper().ToMoneyFormatDigits(
                  row.faYearPlanned,
                  1
                )}
              </TableCell>
              <TableCell sx={[styles.TDSmall, { textAlign: "right" }]}>
                <Button
                  sx={{ mt: 0.3, mb: 0.5 }}
                  variant="contained"
                  color="success"
                  size="small"
                  onClick={() => onSaveRow(row)}
                >
                  Save
                </Button>
                <br />
                <Button
                  sx={{ mb: 0.5 }}
                  variant="contained"
                  color="warning"
                  size="small"
                  onClick={() => onCloseRow(row)}
                >
                  Cancel
                </Button>
                <br />
                {row.faAllocationID > 0 && (
                  <Button
                    //sx={{ mt: 1 }}
                    variant="contained"
                    color="error"
                    size="small"
                    onClick={() => onDeleteRow(row)}
                  >
                    Delete
                  </Button>
                )}
              </TableCell>
            </TableRow>
          </Table>
        </TableCell>
      </TableRow>
    );
  }, [
    row.faAllocationID,
    row.faBudgetID,
    row.faProjectID,
    row.faStaffName,
    row.faMonth1,
    row.faMonth2,
    row.faMonth3,
    row.faMonth4,
    row.faMonth5,
    row.faMonth6,
    row.faMonth7,
    row.faMonth8,
    row.faMonth9,
    row.faMonth10,
    row.faMonth11,
    row.faMonth12,
    row.faBudgetAmount,
    row.faBudgetMgmtFee,
    row.faYearPlanned,
    row.faYear,
  ]);
};

const RenderRow = ({ index, row, onEditRow, IsLocked }) => {
  //Record = Pagination

  return React.useMemo(() => {
    return (
      <TableRow key={row.eyEmployeeID}>
        <TableCell sx={[styles.TDSmall, { width: "15%" }]}>
        {index+1}.<Link
                    href={enumRoutes.Timesheet_FinAllocation_Edit.replace(
                      ":ProjectID",
                      row.fpProjectID
                    )}
                    underline="none"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {row.fpName} - Ver:{row.fpVersionNo} - {row.PhaseInfo}
        </Link><br/> 
        {row.faBudgetName}
        </TableCell>

        <TableCell sx={[styles.TDSmall, { width: "10%" }]}>
          {row.faStaffName}
        </TableCell>

        <TableCell sx={[styles.TDSmall, { textAlign: "right" }]}>
          {row.faBudgetAmount > 0 &&
            new CurrencyHelper().ToMoneyFormatDigits(row.faBudgetAmount, 2)}
        </TableCell>

        <TableCell align="right" sx={[styles.TDSmall, { width: "3%" }]}>
          {new CurrencyHelper().ToMoneyFormatDigits(row.faMonth1, 1)}
        </TableCell>

        <TableCell align="right" sx={[styles.TDSmall, { width: "3%" }]}>
          {new CurrencyHelper().ToMoneyFormatDigits(row.faMonth2, 1)}
        </TableCell>

        <TableCell align="right" sx={[styles.TDSmall, { width: "3%" }]}>
          {new CurrencyHelper().ToMoneyFormatDigits(row.faMonth3, 1)}
        </TableCell>
        <TableCell align="right" sx={[styles.TDSmall, { width: "3%" }]}>
          {new CurrencyHelper().ToMoneyFormatDigits(row.faMonth4, 1)}
        </TableCell>
        <TableCell align="right" sx={[styles.TDSmall, { width: "3%" }]}>
          {new CurrencyHelper().ToMoneyFormatDigits(row.faMonth5, 1)}
        </TableCell>
        <TableCell align="right" sx={[styles.TDSmall, { width: "3%" }]}>
          {new CurrencyHelper().ToMoneyFormatDigits(row.faMonth6, 1)}
        </TableCell>
        <TableCell align="right" sx={[styles.TDSmall, { width: "3%" }]}>
          {new CurrencyHelper().ToMoneyFormatDigits(row.faMonth7, 1)}
        </TableCell>
        <TableCell align="right" sx={[styles.TDSmall, { width: "3%" }]}>
          {new CurrencyHelper().ToMoneyFormatDigits(row.faMonth8, 1)}
        </TableCell>
        <TableCell align="right" sx={[styles.TDSmall, { width: "3%" }]}>
          {new CurrencyHelper().ToMoneyFormatDigits(row.faMonth9, 1)}
        </TableCell>
        <TableCell align="right" sx={[styles.TDSmall, { width: "3%" }]}>
          {new CurrencyHelper().ToMoneyFormatDigits(row.faMonth10, 1)}
        </TableCell>
        <TableCell align="right" sx={[styles.TDSmall, { width: "3%" }]}>
          {new CurrencyHelper().ToMoneyFormatDigits(row.faMonth11, 1)}
        </TableCell>
        <TableCell align="right" sx={[styles.TDSmall, { width: "3%" }]}>
          {new CurrencyHelper().ToMoneyFormatDigits(row.faMonth12, 1)}
        </TableCell>
        <TableCell align="right" sx={[styles.TDSmall, { width: "3%" }]}>
          {new CurrencyHelper().ToMoneyFormatDigits(row.faYearPlanned, 1)}
        </TableCell>
        <TableCell sx={[styles.TDSmall, { textAlign: "right" }]}>
          {row.faBudgetMgmtFee > 0 &&
            new CurrencyHelper().ToMoneyFormatDigits(row.faBudgetMgmtFee, 2)}
        </TableCell>
        <TableCell align="right" sx={[styles.TDSmall, { width: "3%" }]}>
          {new CurrencyHelper().ToMoneyFormatDigits(row.faYearCostPlanned, 2)}
        </TableCell>

        <TableCell sx={[styles.TDSmall, { textAlign: "right" }]}>
          {row.AllowEdit && (
            <Link
              sx={{ mr: 1.5 }}
              component="button"
              onClick={() => onEditRow(row)}
              underline="none"
              rel="noopener noreferrer"
              //target="_blank"
            >
              Edit
            </Link>
          )}
        </TableCell>
      </TableRow>
    );
  }, [
    row.faMonth1,
    row.faMonth2,
    row.faMonth3,
    row.faMonth4,
    row.faMonth5,
    row.faMonth6,
    row.faMonth7,
    row.faMonth8,
    row.faMonth9,
    row.faMonth10,
    row.faMonth11,
    row.faMonth12,
    row.faYearPlanned,
    row.faYearCostPlanned,
  ]);
};
