// @ts-check 
import { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import MyText from '../../controls/MyText';

import { useState } from 'react';
import MyAlert from '../../controls/MyAlert';
import { enumPagination, enumBreadcrumbPage, enumData, enumLeaveRequest, enumPage, enumRoutes, enumSharedMsgs } from '../../helpers/common.constants';
import NotificationActions from '../../actions/NotificationActions';
import Button from '@mui/material/Button';
import { Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow, Tooltip } from '@mui/material';
import { useParams, useLocation, useNavigate } from "react-router-dom";
import CurrencyHelper from '../../helpers/CurrencyHelper';
import moment from 'moment';
import MyDateTime from '../../controls/MyDateTime';
import LeaveRequestBreadcrumbs from './LeaveRequestBreadcrumbs';
import { MessageHelper } from '../../helpers/MessageHelper';
import LeaveRequestActions from '../../actions/LeaveRequestActions';
import MyDate from '../../controls/MyDate';
import NotesIcon from '@mui/icons-material/Notes';
import VisibilityIcon from '@mui/icons-material/Visibility';
import MyDropDown from '../../controls/MyDropDown';
import React from 'react';
import DeleteIcon from "@mui/icons-material/Delete";
import { Margin } from '@mui/icons-material';

const RecordDefault =
{
  "rqRequestID": 0,
  "rqTypeID": enumLeaveRequest.Leave,
  "rqEmployeeID": 0,
  "rqDatetimeStart": moment(Date.now()).format("YYYY-MM-DD HH:mm"),
  "rqDatetimeEnd": moment(Date.now()).format("YYYY-MM-DD HH:mm"),
  "rqTotalHours": 8,
  "rqApproved": true,
  "rqSubmited": true,
  "rqReviewed": true,
  "rqSuperiorID": 0,
  "rqYear": 0,
  "rqLeftHours": 0,
  "rqNote": "",
  "ContractedHours": 0,
  "TakenHours": 0,
  "BalanceHours": 0,
  "SuperiorID": 0,
  "SuperiorName": ""
}


const RecordDefaultHourBalance =
{
  "TakenHours": 0,
  "BalanceHours": 0,
  "SuperiorID": 0,
  "SuperiorName": "",
  "ContractedHours": 0
}

export default function LeaveRequestCalendar(args) {

  //iniicjalno EntityObject e prazen posle dole na useEffect ke se napolni
  const [CalendarEvents, setCalendarEvents] = useState([]);


  //ocekuva vakov format
  // MonthDays = [{ Date: '2021.03.01' }, { Date: '2021.03.02' }, { Date: '2021.03.03' }, { Date: '2021.03.04' }]
  const [CalendarDays, setCalendarDays] = useState([]); //niza
  const [MessageData, setMessageData] = useState({});
  const [CurrentMonthTitle, setCurrentMonthTitle] = useState([]);
  const [CurrentDate, setCurrentDate] = useState([]);



  //Za Refreshiranje na strana mu treba;
  const location = useLocation();

  //za poraki pri loadiranje na strana
  const msgHelper = new MessageHelper();

  //DEFAULT HOOK CONSTRUCTOR
  useEffect(() => {
    let inputDate = moment(new Date()); //moment('2021.05.01');
    LoadPage({ inputDate });
  }, [location.pathname + "/" + location.search]);   //default



  const ShowDay = ({ dayOfWeek, item, events }) => {

    if (!item) return;
    let currentDay = moment(item.Date, "YYYY-MM-DD HH:mm:ss").format('YYYY-MM-DD');
    //let currentDayOfWeek = moment(item.Date, "YYYY-MM-DD HH:mm:ss").format('dddd');

    //if (dayOfWeek !== currentDayOfWeek) return;
    //alert(currentDayOfWeek);
    //console.error(item)
    //console.log(events)

    let eventsHtml = events && events.map((item, i) => {
      let tooltip =
        <div>
          {item.StaffName}
          <br />Office: {item.OfficeName}
          <br />Type of Leave: {item.TypeName}
          <br />Status: {item.StatusName}
          <br />Period: {moment(item.rqDatetimeStart).format("DD/MM/YYYY")} - {moment(item.rqDatetimeEnd).format("DD/MM/YYYY")}
        </div>
      return (
        <React.Fragment>
          <Tooltip title={tooltip} style={{'background-color':'#4267b2', 'color': '#fff', 'margin':'5px', 'textAlign':'left'}}>
            <Button>{item.StaffName} {moment(item.rqDatetimeStart).format("DD/MM")} - {moment(item.rqDatetimeEnd).format("DD/MM")}</Button>
          </Tooltip>
          <br />
        </React.Fragment>
      )
    });

    return (
      <Table size="small" border={0} padding='none'>
        <TableHead>
          <TableRow >
            <TableCell style={{ verticalAlign: 'top' }} align="center"><b>{moment(item.Date, "YYYY-MM-DD HH:mm:ss").format('D')}</b></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              {eventsHtml}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    )
  }

  const ResetPage = () => {
    setCalendarEvents([]);
    setCalendarDays([]);
    setMessageData({ Loading: false, Message: '', HasError: '', HasInfo: '' });
  }

  const LoadPage = ({ inputDate }) => {
    async function fetchData() {

      //Reset the screen
      ResetPage();

      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      let startDate = moment(inputDate).startOf('month');
      let endDate = moment(startDate).endOf('month');

      setCurrentDate(inputDate);
      setCurrentMonthTitle(moment(inputDate).format("MMMM, yyyy"));


      let payload = { StartDate: startDate, endDate: endDate, PageSize: enumPagination.PageSizeMax }
      let responses = await new LeaveRequestActions().CalendarAsync(payload);
      if (!responses.error) {
        let calendarEvents = responses[0].data;
        setCalendarEvents(calendarEvents);


        //kreira denovi za daden mesec 
        let MonthDays = [];

        //alert(startDate + "\n" + endDate);
        while (startDate <= endDate) {

          MonthDays.push({ Date: moment(startDate).format('YYYY/MM/DD') })
          startDate = moment(startDate).add(1, 'days');
        }
        setCalendarDays(MonthDays);

        //Load Finished
        result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);
      } else {
        //Load with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }
    };

    fetchData();
  }

  const GoToPrevNextMonth = (index) => {
    let prevNextMOnthDate = moment(CurrentDate).add(index, 'month');
    //alert(prevNextMOnthDate);
    LoadPage({ inputDate: prevNextMOnthDate });
  }

  function RenderItems(daysInMonth) {

    //alert(daysInMonth.length);

    //make complex object per week
    //and after use map object to show it per week

    let daySunday = null;
    let dayMonday = null;
    let dayTuesday = null;
    let dayWednesday = null;
    let dayThursday = null;
    let dayFriday = null;
    let daySaturday = null;

    let daySundayEvents = null;
    let dayMondayEvents = null;
    let dayTuesdayEvents = null;
    let dayWednesdayEvents = null;
    let dayThursdayEvents = null;
    let dayFridayEvents = null;
    let daySaturdayEvents = null;


    let counter = 1;
    let daysInWeek = [];
    let isEndOfWeek = false;
    let isEndOfLoop = false;
    for (let i = 0, l = daysInMonth.length; i < l; i = i + 1) {

      isEndOfLoop = (daysInMonth.length - 1 === i);
      let currentItem = daysInMonth[i];
      let currentDate = moment(currentItem.Date);

      let dateForEvents = moment(currentItem.Date).add(-1, 'minutes');    //24/10/2023-26/10/2023 --> 26/10/2023 ne vlaga i poradi toa go pravime 25/10/2023 23:59
      let dateForEvents2 = moment(currentItem.Date).add(1, 'minutes');    //24/10/2023-26/10/2023 --> 24/10/2023 ne vlaga i poradi toa go pravime 24/10/2023 00:01

      let currentDayOfWeek = moment(currentDate, "YYYY-MM-DD HH:mm:ss").format('dddd');


      if (currentDayOfWeek === "Sunday") {
        daySunday = currentItem;
        daySundayEvents = CalendarEvents.filter(a => moment(dateForEvents).isBetween(a.rqDatetimeStart, a.rqDatetimeEnd) ||
          moment(dateForEvents2).isBetween(a.rqDatetimeStart, a.rqDatetimeEnd)
        );
      }
      else if (currentDayOfWeek === "Monday") {
        dayMonday = currentItem;
        dayMondayEvents = CalendarEvents.filter(a => moment(dateForEvents).isBetween(a.rqDatetimeStart, a.rqDatetimeEnd) ||
          moment(dateForEvents2).isBetween(a.rqDatetimeStart, a.rqDatetimeEnd)
        );
      }
      else if (currentDayOfWeek === "Tuesday") {
        dayTuesday = currentItem;
        dayTuesdayEvents = CalendarEvents.filter(a => moment(dateForEvents).isBetween(a.rqDatetimeStart, a.rqDatetimeEnd) ||
          moment(dateForEvents2).isBetween(a.rqDatetimeStart, a.rqDatetimeEnd)
        );
      }
      else if (currentDayOfWeek === "Wednesday") {
        dayWednesday = currentItem;
        dayWednesdayEvents = CalendarEvents.filter(a => moment(dateForEvents).isBetween(a.rqDatetimeStart, a.rqDatetimeEnd) ||
          moment(dateForEvents2).isBetween(a.rqDatetimeStart, a.rqDatetimeEnd)
        );

        console.log("-----------Wednesday------------");
        console.log(currentDate)

      }
      else if (currentDayOfWeek === "Thursday") {
        dayThursday = currentItem;
        dayThursdayEvents = CalendarEvents.filter(a => moment(dateForEvents).isBetween(a.rqDatetimeStart, a.rqDatetimeEnd) ||
          moment(dateForEvents2).isBetween(a.rqDatetimeStart, a.rqDatetimeEnd)
        );
      }
      else if (currentDayOfWeek === "Friday") {
        dayFriday = currentItem;
        console.error("FRIDAY");
        console.error(currentDate)


        dayFridayEvents = CalendarEvents.filter(a => moment(dateForEvents).isBetween(a.rqDatetimeStart, a.rqDatetimeEnd) ||
          moment(dateForEvents2).isBetween(a.rqDatetimeStart, a.rqDatetimeEnd)
        );
        console.log(dayFridayEvents);
      }
      else if (currentDayOfWeek === "Saturday") {
        daySaturday = currentItem;

        // console.log("SATURDAY");
        // console.log(currentDate)

        daySaturdayEvents = CalendarEvents.filter(a => moment(dateForEvents).isBetween(a.rqDatetimeStart, a.rqDatetimeEnd) ||
          moment(dateForEvents2).isBetween(a.rqDatetimeStart, a.rqDatetimeEnd)
        );
        // console.log(daySaturdayEvents);

        // console.log("----------");
        // console.log(dayFridayEvents);
        isEndOfWeek = true;
      }

      if (isEndOfWeek || isEndOfLoop) {
        isEndOfWeek = false;
        daysInWeek.push({
          Sunday: daySunday,
          SundayEvents: daySundayEvents,

          Monday: dayMonday,
          MondayEvents: dayMondayEvents,

          Tuesday: dayTuesday,
          TuesdayEvents: dayTuesdayEvents,

          Wednesday: dayWednesday,
          WednesdayEvents: dayWednesdayEvents,

          Thursday: dayThursday,
          ThursdayEvents: dayThursdayEvents,

          Friday: dayFriday,
          FridayEvents: dayFridayEvents,

          Saturday: daySaturday,
          SaturdayEvents: daySaturdayEvents,
        });

        daySunday = null;
        dayMonday = null;
        dayTuesday = null;
        dayWednesday = null;
        dayThursday = null;
        dayFriday = null;
        daySaturday = null;

        daySundayEvents = null;
        dayMondayEvents = null;
        dayTuesdayEvents = null;
        dayWednesdayEvents = null;
        dayThursdayEvents = null;
        dayFridayEvents = null;
        daySaturdayEvents = null;


      }

    }


    let output = daysInWeek.map((item, i) => {
      return (
        <TableRow >
          <TableCell style={{ verticalAlign: 'top' }} >{ShowDay({ dayOfWeek: 'Sunday', item: item.Sunday, events: item.SundayEvents })}</TableCell>
          <TableCell style={{ verticalAlign: 'top' }}>{ShowDay({ dayOfWeek: 'Monday', item: item.Monday, events: item.MondayEvents })}</TableCell>
          <TableCell style={{ verticalAlign: 'top' }}>{ShowDay({ dayOfWeek: 'Tuesday', item: item.Tuesday, events: item.TuesdayEvents })}</TableCell>
          <TableCell style={{ verticalAlign: 'top' }}>{ShowDay({ dayOfWeek: 'Wednesday', item: item.Wednesday, events: item.WednesdayEvents })}</TableCell>
          <TableCell style={{ verticalAlign: 'top' }}> {ShowDay({ dayOfWeek: 'Thursday', item: item.Thursday, events: item.ThursdayEvents })}</TableCell>
          <TableCell style={{ verticalAlign: 'top' }}>{ShowDay({ dayOfWeek: 'Friday', item: item.Friday, events: item.FridayEvents })}</TableCell>
          <TableCell style={{ verticalAlign: 'top' }}>{ShowDay({ dayOfWeek: 'Saturday', item: item.Saturday, events: item.SaturdayEvents })}</TableCell>
        </TableRow>
      )
    });
    return output;
  }


  let y = 0;
  return (
    <TableContainer sx={{ display: { xs: "block", sm: "block", md: "block" } }} >
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table" border={1} padding='none'>
        <TableHead>
          <TableRow>
            <TableCell align="center" >
              <Button size="small" color="primary" disabled={MessageData.Loading} onClick={() => GoToPrevNextMonth(-1)}> &lt;&lt; </Button>
            </TableCell>

            <TableCell colSpan={5} align="center" >
              <Typography variant="h4" align="center">{CurrentMonthTitle}</Typography>
            </TableCell>

            <TableCell align="center" >
              <Button size="small" color="primary" disabled={MessageData.Loading} onClick={() => GoToPrevNextMonth(1)}> &gt;&gt; </Button>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell valign='top' ><Typography variant="h4" align="center">Sunday</Typography></TableCell>
            <TableCell valign='top'><Typography variant="h4" align="center">Monday</Typography></TableCell>
            <TableCell valign='top'><Typography variant="h4" align="center">Tuesday</Typography></TableCell>
            <TableCell valign='top'><Typography variant="h4" align="center">Wednesday</Typography></TableCell>
            <TableCell valign='top'><Typography variant="h4" align="center">Thursday</Typography></TableCell>
            <TableCell valign='top'><Typography variant="h4" align="center">Friday</Typography></TableCell>
            <TableCell valign='top'><Typography variant="h4" align="center">Saturday</Typography></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {RenderItems(CalendarDays)}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell colSpan={10} >
              <MyAlert {...MessageData} />
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )
}

