import { RestHelper } from "../helpers/RestHelper";

export class DocumentService {
  private _restHelper;

  constructor() {
    this._restHelper = new RestHelper();
  }

  async GetFolderContentByProject(payload: any) {
    const url: string = "GoogleDrive/GetFolderContentByProject";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async CreateFolder(payload: any) {
    const url: string = "GoogleDrive/CreateFolder";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async RenameFolder(payload: any) {
    const url: string = "GoogleDrive/RenameFolder";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async DeleteFolder(payload: any) {
    const url: string = "GoogleDrive/DeleteFolder";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async DeleteFile(payload: any) {
    const url: string = "GoogleDrive/DeleteFile";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async DownloadFile(payload: any) {
    // const url : string  = "GoogleDrive/DownloadFile";
    // let response = await this._restHelper.post(url, payload);
    // return response;

    const url: string = "GoogleDrive/DownloadFile";
    let response = await this._restHelper.downloadFile(url, payload);
    return response;
  }

  async GetFolderContentByFolderId(rootFolderId: any, folderId: any) {
    const url: string =
      "GoogleDrive/GetFolderContent/" + rootFolderId + "/" + folderId;
    let response = await this._restHelper.post(url, {});
    return response;
  }

  async UploadFile(payload: any) {
    const url: string = "GoogleDrive/UploadFile";
    let response = await this._restHelper.postFile(url, payload);
    return response;
  }
}
