// @ts-check
import * as React from "react";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import MyAlert from "../../controls/MyAlert";
import { enumPage, enumPagination } from "../../helpers/common.constants";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableFooter,
  Button,
} from "@mui/material";
import { useLocation, useParams } from "react-router-dom";
import { MessageHelper } from "../../helpers/MessageHelper";
import DownloadActions from "../../actions/DownloadActions";
import FinAllocationActions from "../../actions/FinAllocationActions";
import ZCurrencyField from "../../controls/ZCurrencyField";
import CurrencyHelper from "../../helpers/CurrencyHelper";
import FinProjectActions from "../../actions/FinProjectActions";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

const RecordDefault = {
  EmployeeID: 0,
  StaffID: 0,
  BudgetID: 0,
  MonthPlanned: 0,
  Year: 2024,
  PageIndex: 0,
  PageSize: enumPagination.PageSizeLow,
  TotalRecords: 0,
  TotalPages: 0,
};

const styles = {
  TDSmall: { mt: 0, mb: 0, pt: 0, pb: 0 },
  TDProjectName: { textAlign: "left", width: "25%" },
};

export default function FinAllocationByEmployee() {
  const [Record, setRecord] = useState(RecordDefault);
  const [RecordToDelete, setRecordToDelete] = useState(RecordDefault);
  const [MessageData, setMessageData] = useState({});
  const [Project, setProject] = useState({});
  const [Records, setRecords] = useState([]);
  const [RecordsSum, setRecordsSum] = useState([]);
  const [IsInitDone, setIsInitDone] = useState(false);
  const [IsPaginationChange, setPaginationChange] = useState(false);
  const [IsDoSearch, setIsDoSearch] = useState(false);
  const [IsLocked, setIsLocked] = useState(false);
  const [LookupData, setLookupData] = useState([]);
  const { EmployeeID, Year, ProjectID } = useParams();

  //Za Refreshiranje na strana mu treba;
  const location = useLocation();

  //za poraki pri loadiranje na strana
  const msgHelper = new MessageHelper();

  //****************************************** */
  //HOOKS SECTION HERE
  //****************************************** */

  //1 --> Ke se povika ovoj HOOK samo dokolku se SMENI url-ot poradi toa na new dodavame Ticks() za da forcirame refresh na strana na sekoj click
  useEffect(() => {
    initData();
  }, [location.pathname + "/" + location.search]);

  //2 --> Od koga e sredena InitData() povikaj Search  (https://stackoverflow.com/questions/54069253/the-usestate-set-method-is-not-reflecting-a-change-immediately)
  useEffect(() => {
    if (IsInitDone) {
      searchData();
    }
  }, [IsInitDone]);

  // //3 --> Pagination Change
  useEffect(() => {
    if (IsPaginationChange) {
      searchData();
    }
  }, [IsPaginationChange]);

  //4 --> Search Click
  useEffect(() => {
    if (IsDoSearch) {
      searchData();
    }
  }, [IsDoSearch]);

  //****************************************** */
  //METHOD  SECTION HERE
  //****************************************** */
  const initData = () => {
    async function fetchData() {
      //Clean all statesLock      setIsInitDone(false);
      setIsInitDone(false);

      let record = { ...RecordDefault, EmployeeID, Year };
      setRecord(record);

      console.log("Initialized Record:", record);

      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      let responses = await new FinAllocationActions().InitAsync(); // await LookupActions.InitAsync();
      if (!responses.error) {
        let lookupData = responses[0].data;

        setLookupData(lookupData);

        //await GetProjectByIdAsync();

        //setYearsData(yearsData);
        setIsInitDone(true);

        if (IsLocked) {
          let result = msgHelper.setPhase(
            enumPage.LoadSuccessWithWarningMsg,
            null,
            "Project is locked and can't be edited, please make revision if you need to make changes"
          );
          setMessageData(result);
        } else {
          //Load Finished
          result = msgHelper.setPhase(enumPage.LoadSuccess);
          setMessageData(result);
        }
      } else {
        //Load with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }
    }
    fetchData();
  };

  const searchData = () => {
    searchDataAsync();
  };

  async function searchDataAsync() {
    //Load Start
    setRecords([]);
    let result = msgHelper.setPhase(enumPage.LoadStart);
    setMessageData(result);

    // Log the parameters
    console.log("EmployeeID from useParams:", EmployeeID);
    console.log("Year from useParams:", Year);

    let payload = { StaffID: EmployeeID, Year: Year };
    console.log("Payload:", payload);

    let responses =
      await new FinAllocationActions().GetByEmployeeAsync(
        payload
      );

    console.log(responses);
    if (!responses.error) {
      const records = responses[0].data;

      if (records.length > 0) {
        setRecord((prevRecord) => ({
          ...prevRecord,
          StaffName: records[0].StaffName,
        }));
      }
      //For Pagination purposes
      const pageIndex = responses[0].headers["content-pageindex"];
      const totalRecords = responses[0].headers["content-totalrecords"];
      const totalPages = responses[0].headers["content-totalpages"];
      setRecord((a) => ({
        ...a,
        ["PageIndex"]: pageIndex,
        ["TotalRecords"]: totalRecords,
        ["TotalPages"]: totalPages,
      }));
      setPaginationChange(false); //za da se pali na sleden page
      setIsDoSearch(false); //za da se pali na Search Click

      setRecords(records);
      makeSumRecord(records);

      //Load Finished
      result = msgHelper.setPhase(enumPage.LoadSuccess);
      setMessageData(result);
    } else {
      //Load with Error
      setIsDoSearch(false); //za da se pali na Search Click
      result = msgHelper.setPhase(enumPage.ErrorServer, responses);
      setMessageData(result);
    }
  }

  const makeSumRecord = (currentRecords) => {
    let newSumRecord = {};

    newSumRecord.BudgetAmount = currentRecords.reduce(
      (sum, row) => (sum = sum + parseFloat(row.faBudgetAmount)),
      0
    );
    newSumRecord.Jan = currentRecords.reduce(
      (sum, row) => (sum = sum + parseFloat(row.Jan)),
      0
    );
    newSumRecord.Feb = currentRecords.reduce(
      (sum, row) => (sum = sum + parseFloat(row.Feb)),
      0
    );
    newSumRecord.Mar = currentRecords.reduce(
      (sum, row) => (sum = sum + parseFloat(row.Mar)),
      0
    );
    newSumRecord.Apr = currentRecords.reduce(
      (sum, row) => (sum = sum + parseFloat(row.Apr)),
      0
    );
    newSumRecord.May = currentRecords.reduce(
      (sum, row) => (sum = sum + parseFloat(row.May)),
      0
    );
    newSumRecord.Jun = currentRecords.reduce(
      (sum, row) => (sum = sum + parseFloat(row.Jun)),
      0
    );
    newSumRecord.Jul = currentRecords.reduce(
      (sum, row) => (sum = sum + parseFloat(row.Jul)),
      0
    );
    newSumRecord.Aug = currentRecords.reduce(
      (sum, row) => (sum = sum + parseFloat(row.Aug)),
      0
    );
    newSumRecord.Sep = currentRecords.reduce(
      (sum, row) => (sum = sum + parseFloat(row.Sep)),
      0
    );
    newSumRecord.Oct = currentRecords.reduce(
      (sum, row) => (sum = sum + parseFloat(row.Oct)),
      0
    );
    newSumRecord.Nov = currentRecords.reduce(
      (sum, row) => (sum = sum + parseFloat(row.Nov)),
      0
    );
    newSumRecord.Dec = currentRecords.reduce(
      (sum, row) => (sum = sum + parseFloat(row.Dec)),
      0
    );

    newSumRecord.YearPlanned = currentRecords.reduce(
      (sum, row) => (sum = sum + parseFloat(row.YearPlanned)),
      0
    );

    newSumRecord.YearCostPlanned = currentRecords.reduce(
      (sum, row) => (sum = sum + parseFloat(row.YearCostPlanned)),
      0
    );

    setRecordsSum(newSumRecord);
  };

  const handleExcelExport = () => {
    async function fetchData() {
      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStartWithAwait);
      setMessageData(result);
      const recordWithStaffID = { ...Record, StaffID: Record.EmployeeID };

      console.log(recordWithStaffID);

      let responses =
        await new FinAllocationActions().ExportByEmployeeAsync(
          recordWithStaffID
        );

      if (!responses.error) {
        //Load Finished
        let result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);

        //DOWNLOADING EXCEL FILE
        const excelFileUrl = responses[0].data;
        let payload = "?inputFile=" + excelFileUrl;
        let downloadResponse = await new DownloadActions().DownloadFileAsync(
          payload
        );

        // create file link in browser's memory
        const href = URL.createObjectURL(downloadResponse.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", excelFileUrl); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        return;
      } else {
        //Load with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }
    }
    fetchData();
  };

  return (
    <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
      <MyAlert {...MessageData} />

      <Grid container>
        <Grid item xs={12} sx={{ textAlign: "center", mb: 3 }}>
          <Typography variant="h4" display="block">
            Staff Name: {Record.StaffName}
          </Typography>
        </Grid>
      </Grid>

      <RenderTable
        Project={Project}
        RecordsSum={RecordsSum}
        Records={Records}
        Record={Record}
        MessageData={MessageData}
        DataArray={[LookupData]}
        IsLocked={IsLocked}
        onExportExcel={handleExcelExport}
      />
    </Typography>
  );
}

const RenderTable = ({
  RecordsSum,
  Records,
  Record,
  DataArray,
  onHandleChange,
  onSaveRow,
  onCloseRow,
  onHandleSave,
  onHandleLockUnlock,
  MessageData,
  IsLocked,
  onEditRow,
  onAddRow,
  onDeleteRow,
  onExportExcel,
}) => {
  return React.useMemo(() => {
    return (
      <TableContainer
        sx={{ display: { xs: "block", sm: "block", md: "block" } }}
      >
        <Table
          sx={{ minWidth: 650, border: "1" }}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell colSpan={19} align="center">
                <Button
                  sx={{ mr: 2, mb: 1 }}
                  variant="contained"
                  startIcon={<FileDownloadIcon />}
                  size="small"
                  color="warning"
                  disabled={MessageData.Loading}
                  onClick={() => onExportExcel()}
                >
                  Export Excel
                </Button>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align="left">
                <Typography variant="h6" align="left">
                  Project Name
                </Typography>
              </TableCell>
              <TableCell align="left">
                <Typography variant="h6" align="left">
                  Budget Name
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="h6" align="right">
                  Budget
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="h6" align="right">
                  Jan
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="h6" align="right">
                  Feb
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="h6" align="right">
                  Mar
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="h6" align="right">
                  Apr
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="h6" align="right">
                  May
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="h6" align="right">
                  Jun
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="h6" align="right">
                  Jul
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="h6" align="right">
                  Aug
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="h6" align="right">
                  Sep
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="h6" align="right">
                  Oct
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="h6" align="right">
                  Nov
                </Typography>
              </TableCell>
              <TableCell align="right">
                <Typography variant="h6" align="right">
                  Dec
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="center">
                  Month Planed
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="center">
                  Mngmt  fee
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="center">
                  Plann. cost
                </Typography>
              </TableCell>              
            </TableRow>
          </TableHead>
          <TableBody>
            {Records.map((row, index) => (
              <RenderRow
                row={row}
                index={index}
                onEditRow={onEditRow}
                MessageData={MessageData}
                DataArray={DataArray}
                IsLocked={IsLocked}
              />
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell colSpan={3}>
                <Typography variant="h6" align="right">
                  Total
                </Typography>
              </TableCell>

              <TableCell>
                <Typography variant="h6" align="right">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    RecordsSum.Jan,
                    1
                  )}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="right">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    RecordsSum.Feb,
                    1
                  )}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="right">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    RecordsSum.Mar,
                    1
                  )}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="right">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    RecordsSum.Apr,
                    1
                  )}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="right">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    RecordsSum.May,
                    1
                  )}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="right">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    RecordsSum.Jun,
                    1
                  )}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="right">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    RecordsSum.Jul,
                    1
                  )}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="right">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    RecordsSum.Aug,
                    1
                  )}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="right">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    RecordsSum.Sep,
                    1
                  )}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="right">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    RecordsSum.Oct,
                    1
                  )}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="right">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    RecordsSum.Nov,
                    1
                  )}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="right">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    RecordsSum.Dec,
                    1
                  )}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h6" align="right">
                  {new CurrencyHelper().ToMoneyFormatDigits(
                    RecordsSum.YearPlanned,
                    1
                  )}
                </Typography>
              </TableCell>              
              <TableCell></TableCell>
              <TableCell>
                <Typography variant="h6" align="right">
                  {new CurrencyHelper().ToMoneyFormatDigits(RecordsSum.YearCostPlanned, 2)}
                </Typography>
              </TableCell>              
              
              <TableCell colSpan={2}></TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    );
  }, [Records, MessageData]);
};

const RenderRow = ({ row, onEditRow, IsLocked }) => {
  return React.useMemo(() => {
    return (
      <TableRow key={row.eyEmployeeID}>
        <TableCell
          sx={[styles.TDProjectName, { textAlign: "left", width: "15%" }]}
        >
          {row.ProjectName}  - Ver:{row.VersionNo} - {row.PhaseInfo}
        </TableCell>
        <TableCell
          sx={[styles.TDProjectName, { textAlign: "left", width: "10%" }]}
        >
          {row.BudgetName}
        </TableCell>
        <TableCell sx={[styles.TDSmall, { textAlign: "right" }]}>
          {row.Budget > 0 &&
            new CurrencyHelper().ToMoneyFormatDigits(row.Budget, 2)}
        </TableCell>
        <TableCell align="right" sx={[styles.TDSmall, { width: "5%" }]}>
          {new CurrencyHelper().ToMoneyFormatDigits(row.Jan, 1)}
        </TableCell>
        <TableCell align="right" sx={[styles.TDSmall, { width: "5%" }]}>
          {new CurrencyHelper().ToMoneyFormatDigits(row.Feb, 1)}
        </TableCell>
        <TableCell align="right" sx={[styles.TDSmall, { width: "5%" }]}>
          {new CurrencyHelper().ToMoneyFormatDigits(row.Mar, 1)}
        </TableCell>
        <TableCell align="right" sx={[styles.TDSmall, { width: "5%" }]}>
          {new CurrencyHelper().ToMoneyFormatDigits(row.Apr, 1)}
        </TableCell>
        <TableCell align="right" sx={[styles.TDSmall, { width: "5%" }]}>
          {new CurrencyHelper().ToMoneyFormatDigits(row.May, 1)}
        </TableCell>
        <TableCell align="right" sx={[styles.TDSmall, { width: "5%" }]}>
          {new CurrencyHelper().ToMoneyFormatDigits(row.Jun, 1)}
        </TableCell>
        <TableCell align="right" sx={[styles.TDSmall, { width: "5%" }]}>
          {new CurrencyHelper().ToMoneyFormatDigits(row.Jul, 1)}
        </TableCell>
        <TableCell align="right" sx={[styles.TDSmall, { width: "5%" }]}>
          {new CurrencyHelper().ToMoneyFormatDigits(row.Aug, 1)}
        </TableCell>
        <TableCell align="right" sx={[styles.TDSmall, { width: "5%" }]}>
          {new CurrencyHelper().ToMoneyFormatDigits(row.Sep, 1)}
        </TableCell>
        <TableCell align="right" sx={[styles.TDSmall, { width: "5%" }]}>
          {new CurrencyHelper().ToMoneyFormatDigits(row.Oct, 1)}
        </TableCell>
        <TableCell align="right" sx={[styles.TDSmall, { width: "5%" }]}>
          {new CurrencyHelper().ToMoneyFormatDigits(row.Nov, 1)}
        </TableCell>
        <TableCell align="right" sx={[styles.TDSmall, { width: "5%" }]}>
          {new CurrencyHelper().ToMoneyFormatDigits(row.Dec, 1)}
        </TableCell>
        <TableCell align="right" sx={[styles.TDSmall, { width: "5%" }]}>
          {new CurrencyHelper().ToMoneyFormatDigits(row.YearPlanned, 1)}
        </TableCell>
        <TableCell align="right" sx={[styles.TDSmall, { width: "5%" }]}>
          {new CurrencyHelper().ToMoneyFormatDigits(row.BudgetMgmtFee, 1)}
        </TableCell>

        <TableCell align="right" sx={[styles.TDSmall, { width: "5%" }]}>
          {new CurrencyHelper().ToMoneyFormatDigits(row.YearCostPlanned, 1)}
        </TableCell>
        
        

      </TableRow>
    );
  }, [
    row.faMonth1,
    row.faMonth2,
    row.faMonth3,
    row.faMonth4,
    row.faMonth5,
    row.faMonth6,
    row.faMonth7,
    row.faMonth8,
    row.faMonth9,
    row.faMonth10,
    row.faMonth11,
    row.faMonth12,
    row.YearPlanned,
    row.YearCostPlanned,
    row.BudgetMgmtFee,
  ]);
};
