import * as React from 'react';
import { TextField, TextFieldPropsSizeOverrides } from '@mui/material';
import { IValidationMessage } from '../helpers/common.interfaces';
import { OverridableStringUnion } from '@mui/types';
import CurrencyHelper from '../helpers/CurrencyHelper';
import moment from 'moment';

//export interface IValidationMessage {controlId: string, message: string}

export interface Props {
  Record: {},
  Id: string,
  Required?: boolean,
  Disabled?: boolean,
  PlaceHolder: string,
  Type?: string,
  Label?: string,
  AutoFocus?: boolean,
  HandleChange(e: any): void,
  HandleBlur(e: React.FocusEvent<HTMLInputElement>): void,
  HandleFocus(e: React.FocusEvent<HTMLInputElement>): void,
  ValidationMessages?: IValidationMessage[],
  MultiLine?: boolean
  Rows?: number
  TextAlign?: string
  Size?: string,
  IsMoney?: boolean
};


export default function MyDateTime(args: Props) {
  let hasError = false;
  let message = "";
  let Record = args.Record;
  let controlId = args.Id;
  let required = args.Required;
  let disabled = args.Disabled;
  let placeHolder = args.PlaceHolder;
  let type = "text";
  let isMultiLine = args.MultiLine ? args.MultiLine : false;
  let rows = args.Rows;
  let textAllignment = args.TextAlign;

  if (args.Type) type = args.Type;


  // const [dateValue, setDateValue] = React.useState("");
  // const [timeValue, setTimeValue] = React.useState("");


  //Vo zavisnot od Value i HasError se refreshira komponentata
  let value = Record ? Record[args.Id as keyof {}] : "";
  let dateValue = moment(value).format("YYYY-MM-DD");
  let timeValue = moment(value).format("HH:mm");


  // React.useEffect(() => {
    
  //   let currentDateValue = moment(Date.now()).add(-1, 'months').format("YYYY-MM-DD"); //moment(value).format("YYYY-MM-DD");
  //   let inputValue = moment(value).format("YYYY-MM-DD");
  //   let currentTimeValue = moment(value).format("HH:mm");
  //   setDateValue(inputValue);
  //   setTimeValue(currentTimeValue);
  // }, [value]);


  //Proveri dali ima greska
  if (required === true) {
    let errorCollection = args.ValidationMessages;
    if (errorCollection != null && errorCollection.length > 0) {
      let currentOption = errorCollection.find((x: IValidationMessage) => x.controlId === controlId); //vidi vo validation.helper.js
      if (currentOption != null) {
        hasError = true;
        message = currentOption.message;
      }
    }
  }

  const handleChangeDateTime = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;


    let formattedValue = "";
    if (value.length === 5) //it is time 
    {
      let currentDate : string = Record[args.Id as keyof {}];
      currentDate = moment(currentDate).format("YYYY-MM-DD")
      currentDate = currentDate + " " + value;
      formattedValue = moment(currentDate).format("YYYY-MM-DD HH:mm")
    }
    else {
      let currentTime : string = Record[args.Id as keyof {}];
      currentTime = moment(currentTime).format("HH:mm");
      let currentDate = value + " " + currentTime;
      formattedValue = moment(currentDate).format("YYYY-MM-DD HH:mm")
    }

    if (formattedValue === "Invalid date")
    {
      formattedValue = moment(Date.now()).format("YYYY-MM-DD HH:mm");
    }
    args.HandleChange({target: {name: args.Id, value: formattedValue}});
    
    //setRecord((prevState) => ({ ...prevState, [name]: formattedValue }));
  };

  // const handleChangeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = e.target;
  //   let outputValue  = value;
  //   //outputValue = moment(outputValue).format("YYYY-MM-DD HH:mm");
  //   args.HandleChange({name: name, value: outputValue});
  // };

  // const handleChangeTime = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = e.target;
  //   console.log(name + ":" + value);
  //   let outputValue  = value;
  //   args.HandleChange({name: name, value: outputValue});
  //   //setValue(value);
  // };

  return React.useMemo(() => {

    return (
      <React.Fragment>

        <TextField 
          type='date'
          //size={size = 'small' ? 'small' : 'medium'}
          size='small'
          variant={"outlined"}
          margin="dense" //normal
          disabled={disabled}
          id={controlId}
          name={controlId}
          //helperText={args.Help}
          label={placeHolder}
          autoFocus={args.AutoFocus}
          onChange={handleChangeDateTime}
          //onBlur={args.HandleBlur}
          onFocus={args.HandleFocus}
          value={dateValue}
          //defaultValue=" "
          helperText={message}
          error={hasError}
          placeholder={placeHolder}
          sx={{ pr: 0.5, input: { textAlign: textAllignment ? textAllignment : "left" } }}
        />

        <TextField
          type='time'
          //size={size = 'small' ? 'small' : 'medium'}
          size='small'
          variant={"outlined"}
          margin="dense" //normal
          disabled={disabled}
          id={controlId}
          name={controlId}
          //helperText={args.Help}
          //label={placeHolder}
          autoFocus={args.AutoFocus}
          onChange={handleChangeDateTime}
          //onBlur={args.HandleBlur}
          onFocus={args.HandleFocus}
          value={timeValue}
          //defaultValue=" "
          helperText={message}
          error={hasError}
          //placeholder={placeHolder}
          sx={{ input: { textAlign: textAllignment ? textAllignment : "left" } }}
        />
      </React.Fragment>
    );
  }, [controlId, value, message, disabled, hasError]);
};
