import { IErrorsInput, ValidatorHelper } from "../helpers/ValidatorHelper";
import { AuthenticateService } from "../services/AuthenticateService";

export default class AuthenticateActions {
  
  
  async LoginAsync(Record : any) {
  
    let jsonInputData : IErrorsInput[]  = [
      {
        element: "Username",
        type: "required",
        value: Record.Username,
        message: 'Email is required'
      },
      {
        element: "Password",
        type: "required",
        value: Record.Password,
        message: 'Password is required'
      }
    ];


    //validacija
    let validationMessages = new ValidatorHelper().validateClient(jsonInputData);
    if (validationMessages.length > 0) {
      let response = {
        error: true, //oznaci deka ima greska
        error_description: "Please enter valid credentials",
        ValidationMessages: validationMessages //vrati gi greskite za GUI
      };
      return response;
    }

    let response = await new AuthenticateService().Login(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async GetUserDetails() {
    let response = await new AuthenticateService().GetUserDetails();
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  // async LoginExternalAsync(Record : any) {
  //   let response = await new AuthenticateService().LoginExternal(Record);
  //   let responses = [response];
  //   let output = new ValidatorHelper().validateServerResponses(responses);
  //   return output;
  // }
  
  
  async LoginGoogle(Record : any) {
    let response = await new AuthenticateService().LoginGoogle(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async LoginMicrosoft(Record : any) {
    let response = await new AuthenticateService().LoginMicrosoft(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }
  
}

