import { enumPage, enumSharedMsgs } from './common.constants';
import { IValidationMessage } from './common.interfaces';


export interface IMessage {
  Message?: any,
  Loading?: boolean,
  HasInfo?: boolean,
  HasError?: boolean
  ValidationMessages?: IValidationMessage[],
  Timing?: number
};


export class MessageHelper {

  constructor() {
    //new ValidatorHelper().getError
  }

  setPhase(state: number, response?: any, customSuccessMsg?: string): IMessage {
    
    let output : IMessage = {};

    if (state == enumPage.LoadStart) {
      output = {Message: "Please wait...", Loading: true, HasInfo: true, Timing: 90000};
    } else if (state == enumPage.LoadSuccess) {
      output = {Message: null};
    } else if (state == enumPage.LoadStartWithMsg) {
      output = {Message: customSuccessMsg, Loading: true, HasInfo: true};
    } else if (state == enumPage.LoadSuccessWithMsg) {
      output = {Message: customSuccessMsg, Loading: false, HasError: false, HasInfo: true};
    }else if (state == enumPage.LoadSuccessWithWarningMsg) {
      output = {Message: customSuccessMsg, Loading: false, HasError: true, HasInfo: false};
    } else if (state == enumPage.ErrorServer) {
      if (response.ValidationMessages) {
        //setiraj 
          output = {ValidationMessages: response.ValidationMessages}
      }
      output.Message  = response.error_description;
      output.HasError  = true;
      output.Loading  = false;
     
    } else if (state == enumPage.SaveSuccess) {
      output = {Message: enumSharedMsgs.Save, Loading: false, HasError: false, HasInfo: false, ValidationMessages:[]};
    } else if (state == enumPage.DeleteSuccess) {
      output = {Message: enumSharedMsgs.Delete, Loading: false, HasError: false, HasInfo: false, ValidationMessages:[]};      
    } else if (state == enumPage.ActionSuccessWithMsg) {
      output = {Message: customSuccessMsg, Loading: false, HasError: false, HasInfo: true, ValidationMessages:[]};
    }
   else if (state == enumPage.LoadStartWithAwait) {
    output = {Message: "Please wait...", Loading: true, HasInfo: true, Timing: 90000}; //6000 - 6 sec
    }    
    
    return output;
  }
}
