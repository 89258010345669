// @ts-check
import * as React from "react";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import MyAlert from "../../controls/MyAlert";
import { enumPage, enumPagination } from "../../helpers/common.constants";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import Link from "@mui/material/Link";
import { MessageHelper } from "../../helpers/MessageHelper";
import LinkActions from "../../actions/LinkActions";

const RecordDefault = {
  PageSize: enumPagination.PageSize,
  OfficeID: 0,
  CountryIDs: "",
  Name: "",
  PhaseIDs: "",
  ActiveFL: "1",
};

export default function Dashboard() {
  const [Record, setRecord] = useState(RecordDefault);
  const [MessageData, setMessageData] = useState({});
  const [Records, setRecords] = useState([]);
  const [IsInitDone, setIsInitDone] = useState(false);
  const [YesNoAllData, setYesNoAllData] = useState([]);

  const [LinkData, setLinkData] = useState([]);

  //Za Refreshiranje na strana mu treba;
  const location = useLocation();

  //za poraki pri loadiranje na strana
  const msgHelper = new MessageHelper();

  //****************************************** */
  //HOOKS SECTION HERE
  //****************************************** */

  //1 --> Ke se povika ovoj HOOK samo dokolku se SMENI url-ot poradi toa na new dodavame Ticks() za da forcirame refresh na strana na sekoj click
  useEffect(() => {
    initData();
  }, [location.pathname + "/" + location.search]);

  //****************************************** */
  //METHOD  SECTION HERE
  //****************************************** */

  const initData = () => {
    async function fetchData() {
      //Clean all states
      setIsInitDone(false);
      setRecord(RecordDefault);

      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      let Record = {};
      let responses = await new LinkActions().InitAsync(Record); // await LookupActions.InitAsync();
      if (!responses.error) {
        let response = responses[0].data;

        setLinkData(response);

        setIsInitDone(true);

        //Load Finished
        result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);
      } else {
        //Load with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }
    }
    fetchData();
  };

  //****************************************** */
  //GUI DISPLAY
  //****************************************** */

  return (
    <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
      <MyAlert {...MessageData} />
      <Typography
        sx={{
          my: 2,
          mx: 2,
          color: "#4267b2",
          fontFamily: '"Futura", "Century Gothic", sans-serif',
          fontWeight: "bold",
        }}
        align="left"
        //color="text.secondary"
      >
        SPARK’s mission is to create impactful jobs for youth in growth sectors,
        support ambitious and innovative entrepreneurs and grow businesses
      </Typography>
      <Typography
        sx={{
          my: 0.5, // Adjust this to reduce the gap between texts
          mx: 2,
          mb: 5,
          color: "#4267b2",
          fontFamily: "'Futura', 'Century Gothic', sans-serif",
          fontWeight: "bold",
          fontSize: "0.875rem", // Adjust fontSize to be slightly smaller
        }}
        align="left"
      >
        Link to SPARK{" "}
        <Link
          href="https://spark.ngo/introducing-sparks-2030-strategy/"
          sx={{ color: "#1A2F80", textDecoration: "none", fontWeight: "bold" }}
        >
          Strategy 2030
        </Link>
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={3} align="left">
          <Card
            sx={{
              maxWidth: 345,
              minHeight: 320,
              bgcolor: "#1A2F80", // Brand Blue for the card background
              color: "#FFF", // White color for the text
              "&:hover": {
                bgcolor: "#1A2F80", // Optional: Change on hover
              },
              ".MuiCardMedia-root": {
                height: 140, // Adjust this as needed
              },
              ".MuiCardContent-root": {
                color: "#FFF", // Ensures content text is also white
              },
              ".MuiLink-root": {
                color: "#C5D5E0", // Light Blue for links
                "&:hover": {
                  color: "#FFF", // Optional: Change color on hover
                },
              },
            }}
          >
            <CardMedia sx={{ height: 140 }} image="../images/photo5.jpg" />
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                fontSize="1.1rem"
                sx={{ color: "#FFF" }}
              >
                About SPARK
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ color: "#FFF" }}
              >
                Get to know our staff and our history: <br />
                <Link
                  underline="none"
                  rel="noopener noreferrer"
                  target="_blank"
                  fontSize={12}
                  sx={{ color: "#C5D5E0" }}
                  href="https://spark.ngo/about-us/"
                >
                  {" "}
                  - History of SPARK
                </Link>
                <br />
                <Link
                  underline="none"
                  rel="noopener noreferrer"
                  target="_blank"
                  fontSize={12}
                  sx={{ color: "#C5D5E0" }}
                  href="https://drive.google.com/file/d/1IHU8aRoH25QcZ9TK8d_dqL_hEgM4iTwb/view"
                >
                  {" "}
                  - Annual Plan 2024
                </Link>
                <br />
                <Link
                  underline="none"
                  rel="noopener noreferrer"
                  target="_blank"
                  fontSize={12}
                  sx={{ color: "#C5D5E0" }}
                  href="https://drive.google.com/drive/u/3/folders/0BwFRXXwm_I49MGtNUWRWeXlsaGM?resourcekey=0-rPMQ6jpmPmS1L8WEmFTcUg"
                >
                  {" "}
                  - Brand Manual
                </Link>
                <br />
                <Link
                  underline="none"
                  rel="noopener noreferrer"
                  target="_blank"
                  fontSize={12}
                  sx={{ color: "#C5D5E0" }}
                  href="https://spark.ngo/staff"
                >
                  {" "}
                  - Staff at SPARK
                </Link>
                <br />
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={3} align="left">
          <Card
            sx={{
              maxWidth: 345,
              minHeight: 320,
              bgcolor: "#1A2F80", // Brand Blue for the card background
              color: "#FFF", // White color for the text
              "&:hover": {
                bgcolor: "#1A2F80", // Optional: Change on hover
              },
              ".MuiCardMedia-root": {
                height: 140, // Adjust this as needed
              },
              ".MuiCardContent-root": {
                color: "#FFF", // Ensures content text is also white
              },
              ".MuiLink-root": {
                color: "#C5D5E0", // Light Blue for links
                "&:hover": {
                  color: "#FFF", // Optional: Change color on hover
                },
              },
            }}
          >
            <CardMedia sx={{ height: 140 }} image="../images/photo2.jpg" />
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                fontSize="1.1rem"
                sx={{ color: "#FFF" }}
              >
                Follow SPARK
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ color: "#FFF" }}
              >
                <Link
                  underline="none"
                  rel="noopener noreferrer"
                  target="_blank"
                  fontSize={12}
                  sx={{ color: "#C5D5E0" }}
                  href="https://www.linkedin.com/search/results/all/?fetchDeterministicClustersOnly=true&heroEntityKey=urn%3Ali%3Aorganization%3A18601&keywords=stichting%20spark&origin=RICH_QUERY_SUGGESTION&position=0&searchId=3bc4d427-3988-49a6-aa5c-ae4a82f5cca0&sid=~Za&spellCorrectionEnabled=false"
                >
                  {" "}
                  - LinkedIn
                </Link>
                <br />
                <Link
                  underline="none"
                  rel="noopener noreferrer"
                  target="_blank"
                  fontSize={12}
                  sx={{ color: "#C5D5E0" }}
                  href="https://www.facebook.com/SPARKignites"
                >
                  {" "}
                  - Facebook
                </Link>
                <br />
                <Link
                  underline="none"
                  rel="noopener noreferrer"
                  target="_blank"
                  fontSize={12}
                  sx={{ color: "#C5D5E0" }}
                  href="https://www.instagram.com/sparkorg/"
                >
                  {" "}
                  - Instagram
                </Link>
                <br />
                <Link
                  underline="none"
                  rel="noopener noreferrer"
                  target="_blank"
                  fontSize={12}
                  sx={{ color: "#C5D5E0" }}
                  href="https://www.tiktok.com/@spark.ngo"
                >
                  {" "}
                  - Tik Tok
                </Link>
                <br />
                <Link
                  underline="none"
                  rel="noopener noreferrer"
                  target="_blank"
                  fontSize={12}
                  sx={{ color: "#C5D5E0" }}
                  href="https://www.youtube.com/c/sparkignites"
                >
                  {" "}
                  - YouTube
                </Link>
                <br />
                <Link
                  underline="none"
                  rel="noopener noreferrer"
                  target="_blank"
                  fontSize={12}
                  sx={{ color: "#C5D5E0" }}
                  href="https://x.com/sparkorg?lang=en"
                >
                  {" "}
                  - X
                </Link>
                <br />
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={3} align="left">
          <Card
            sx={{
              maxWidth: 345,
              minHeight: 320,
              bgcolor: "#1A2F80", // Brand Blue for the card background
              color: "#FFF", // White color for the text
              "&:hover": {
                bgcolor: "#1A2F80", // Optional: Change on hover
              },
              ".MuiCardMedia-root": {
                height: 140, // Adjust this as needed
              },
              ".MuiCardContent-root": {
                color: "#FFF", // Ensures content text is also white
              },
              ".MuiLink-root": {
                color: "#C5D5E0", // Light Blue for links
                "&:hover": {
                  color: "#FFF", // Optional: Change color on hover
                },
              },
            }}
          >
            <CardMedia sx={{ height: 140 }} image="../images/photo4.jpg" />
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                fontSize="1.1rem"
                sx={{ color: "#FFF" }}
              >
                On Boarding
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ color: "#FFF" }}
              >
                Are you new here? Welcome! Take a look at the below guides
                created to help you onboard.
                <br />
                <Link
                  underline="none"
                  rel="noopener noreferrer"
                  target="_blank"
                  fontSize={12}
                  sx={{ color: "#C5D5E0" }}
                  href="https://docs.google.com/document/d/13mya4F6uO-_DhTX3113cLVM1PqLfDz2g/edit"
                >
                  {" "}
                  - Onboarding Guide
                </Link>
                <br />
                <Link
                  underline="none"
                  rel="noopener noreferrer"
                  target="_blank"
                  fontSize={12}
                  sx={{ color: "#C5D5E0" }}
                  href="https://drive.google.com/file/d/1Dm0LnAxVgvbhdCLUFCBeARWv4pwnMuU5/view"
                >
                  {" "}
                  - Staff Manual
                </Link>
                <br />
                <Link
                  underline="none"
                  rel="noopener noreferrer"
                  target="_blank"
                  fontSize={12}
                  sx={{ color: "#C5D5E0" }}
                  href="https://pims.spark-online.org/resource-library?folderPath=01.%20PIMS%20Manual"
                >
                  {" "}
                  - PIMS Manual
                </Link>
                <br />
                <Link
                  underline="none"
                  rel="noopener noreferrer"
                  target="_blank"
                  fontSize={12}
                  sx={{ color: "#C5D5E0" }}
                  href="https://docs.google.com/presentation/d/1zvFmiyqDN6W0AVUmV7oYE-1tST-bI7taWFXjt5LpNU4/edit#slide=id.g24aed4f8c17_2_75"
                >
                  {" "}
                  - Employee Portal Manual
                </Link>
                <br />
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={3} align="left">
          <Card
            sx={{
              maxWidth: 345,
              minHeight: 320,
              bgcolor: "#1A2F80", // Brand Blue for the card background
              color: "#FFF", // White color for the text
              "&:hover": {
                bgcolor: "#1A2F80", // Optional: Change on hover
              },
              ".MuiCardMedia-root": {
                height: 140, // Adjust this as needed
              },
              ".MuiCardContent-root": {
                color: "#FFF", // Ensures content text is also white
              },
              ".MuiLink-root": {
                color: "#C5D5E0", // Light Blue for links
                "&:hover": {
                  color: "#FFF", // Optional: Change color on hover
                },
              },
            }}
          >
            <CardMedia sx={{ height: 140 }} image="../images/photo6.jpg" />
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                fontSize="1.1rem"
                sx={{ color: "#FFF" }}
              >
                Tools and Platforms
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ color: "#FFF" }}
              >
                Easily access SPARK’s main communication, filing and programme
                management tools and platforms: <br />
                <Link
                  underline="none"
                  rel="noopener noreferrer"
                  target="_blank"
                  fontSize={12}
                  sx={{ color: "#C5D5E0" }}
                  href="https://spark-ngo.slack.com"
                >
                  {" "}
                  - Slack
                </Link>
                <br />
                <Link
                  underline="none"
                  rel="noopener noreferrer"
                  target="_blank"
                  fontSize={12}
                  sx={{ color: "#C5D5E0" }}
                  href="https://drive.google.com"
                >
                  {" "}
                  - ZDrive
                </Link>
                <br />
                <Link
                  underline="none"
                  rel="noopener noreferrer"
                  target="_blank"
                  fontSize={12}
                  sx={{ color: "#C5D5E0" }}
                  href="https://pims.spark-online.org"
                >
                  {" "}
                  - PIMS
                </Link>
                <br />
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={3} align="left">
          <Card
            sx={{
              maxWidth: 345,
              minHeight: 320,
              bgcolor: "#1A2F80", // Brand Blue for the card background
              color: "#FFF", // White color for the text
              "&:hover": {
                bgcolor: "#1A2F80", // Optional: Change on hover
              },
              ".MuiCardMedia-root": {
                height: 140, // Adjust this as needed
              },
              ".MuiCardContent-root": {
                color: "#FFF", // Ensures content text is also white
              },
              ".MuiLink-root": {
                color: "#C5D5E0", // Light Blue for links
                "&:hover": {
                  color: "#FFF", // Optional: Change color on hover
                },
              },
            }}
          >
            <CardMedia sx={{ height: 140 }} image="../images/photo3.jpg" />
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                fontSize="1.1rem"
                sx={{ color: "#FFF" }}
              >
                Manuals and policies
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ color: "#FFF" }}
              >
                Please find{" "}
                <Link
                  href="https://drive.google.com/drive/folders/0B9jYUVrlMCFQeE5kTU16VGFsQlU?resourcekey=0-N9W9GIlpZEYAgm8Whw8Iew"
                  sx={{
                    color: "#1A2F80",
                    textDecoration: "none",
                    fontWeight: "bold",
                  }}
                >
                  here
                </Link>{" "}
                a link to most of our important manuals and policies. Reach out
                to your people manager if you can’t find what you are looking
                for.
                <br />
                {/* <Link
                  underline="none"
                  rel="noopener noreferrer"
                  target="_blank"
                  fontSize={12}
                  sx={{ color: "#C5D5E0" }}
                  href="https://drive.google.com/file/d/1C3CyjlmZoN8ivo9_au_M4tbNEnlH4lVN/view"
                >
                  {" "}
                  - ICT Manual
                </Link> */}
                {/* <br />
                <Link
                  underline="none"
                  rel="noopener noreferrer"
                  target="_blank"
                  fontSize={12}
                  sx={{ color: "#C5D5E0" }}
                  href="https://drive.google.com/drive/u/0/folders/1vq58s4OpLBZMQ2B0wzUTQFJUFJRFmKKl"
                >
                  {" "}
                  - Security policy
                </Link> */}
                {/* <br />
                <Link
                  underline="none"
                  rel="noopener noreferrer"
                  target="_blank"
                  fontSize={12}
                  sx={{ color: "#C5D5E0" }}
                  href="https://drive.google.com/drive/u/0/folders/1T-KW1LdX7uGX-7zPLMrSSxyWjq9ERH65"
                >
                  {" "}
                  - Complaints procedure
                </Link> */}
                {/* <br />
                <Link
                  underline="none"
                  rel="noopener noreferrer"
                  target="_blank"
                  fontSize={12}
                  sx={{ color: "#C5D5E0" }}
                  href="https://drive.google.com/drive/u/0/folders/1T-KW1LdX7uGX-7zPLMrSSxyWjq9ERH65"
                >
                  {" "}
                  - Procurement Manual
                </Link> */}
                {/* <br />
                <Link
                  underline="none"
                  rel="noopener noreferrer"
                  target="_blank"
                  fontSize={12}
                  sx={{ color: "#C5D5E0" }}
                  href="https://drive.google.com/drive/u/0/folders/1T-KW1LdX7uGX-7zPLMrSSxyWjq9ERH65"
                >
                  {" "}
                  - RACI
                </Link>
                <br />
                <Link
                  underline="none"
                  rel="noopener noreferrer"
                  target="_blank"
                  fontSize={12}
                  sx={{ color: "#C5D5E0" }}
                  href="https://drive.google.com/file/d/10SwYP95PPXeU3VPrTIoOdFcouryBqO1b/view?usp=drive_link"
                >
                  {" "}
                  - Brand Manual
                </Link> */}
                {/* <br /> */}
                {/* <Link
                  underline="none"
                  rel="noopener noreferrer"
                  target="_blank"
                  fontSize={12}
                  href="https://drive.google.com/file/d/1Dm0LnAxVgvbhdCLUFCBeARWv4pwnMuU5/view?usp=drive_link"
                >
                  {" "}
                  - HR Manual
                </Link>
                <br /> */}
                {/* <Link
                  underline="none"
                  rel="noopener noreferrer"
                  target="_blank"
                  fontSize={12}
                  href="https://drive.google.com/drive/u/3/folders/11xUh0UMMPPfyUn-nppEuDmy5-qNfZJ5U"
                >
                  {" "}
                  - ICT Manual
                </Link>
                <br /> */}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={3} align="left">
          <Card
            sx={{
              maxWidth: 345,
              minHeight: 320,
              bgcolor: "#1A2F80", // Brand Blue for the card background
              color: "#FFF", // White color for the text
              "&:hover": {
                bgcolor: "#1A2F80", // Optional: Change on hover
              },
              ".MuiCardMedia-root": {
                height: 140, // Adjust this as needed
              },
              ".MuiCardContent-root": {
                color: "#FFF", // Ensures content text is also white
              },
              ".MuiLink-root": {
                color: "#C5D5E0", // Light Blue for links
                "&:hover": {
                  color: "#FFF", // Optional: Change color on hover
                },
              },
            }}
          >
            <CardMedia sx={{ height: 140 }} image="../images/photo8.jpg" />
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                fontSize="1.1rem"
                sx={{ color: "#FFF" }}
              >
                SPARK Academy
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ color: "#FFF" }}
              >
                The SPARK Academy is the biweekly internal training and
                professional growth platform. Join to learn or share your
                knowledge:
                <br />
                <Link
                  underline="none"
                  rel="noopener noreferrer"
                  target="_blank"
                  fontSize={12}
                  sx={{ color: "#C5D5E0" }}
                  href="https://docs.google.com/document/d/1z1uVX8SLaavFNHzctJMyzIars722M-HcwYDfsn4wd3c/edit"
                >
                  {" "}
                  - SPARK Academy explainer
                </Link>
                <br />- Have a cool and interesting training to give? <br />
                Send us an email today:
                <br />
                <Link
                  underline="none"
                  rel="noopener noreferrer"
                  target="_blank"
                  fontSize={12}
                  sx={{ color: "#C5D5E0" }}
                  href="mailto:academy@spark-online.org"
                >
                  {" "}
                  - academy@spark-online.org
                </Link>
                <br />
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={3} align="left">
          <Card
            sx={{
              maxWidth: 345,
              minHeight: 320,
              bgcolor: "#1A2F80", // Brand Blue for the card background
              color: "#FFF", // White color for the text
              "&:hover": {
                bgcolor: "#1A2F80", // Optional: Change on hover
              },
              ".MuiCardMedia-root": {
                height: 140, // Adjust this as needed
              },
              ".MuiCardContent-root": {
                color: "#FFF", // Ensures content text is also white
              },
              ".MuiLink-root": {
                color: "#C5D5E0", // Light Blue for links
                "&:hover": {
                  color: "#FFF", // Optional: Change color on hover
                },
              },
            }}
          >
            <CardMedia sx={{ height: 140 }} image="../images/photo1.jpg" />
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                fontSize="1.1rem"
                sx={{ color: "#FFF" }} // White color for the title text
              >
                Travel
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ color: "#FFF" }}
              >
                If you travel for SPARK, you can make your travel and
                accommodation request here:
                <br />
                <Link
                  underline="none"
                  rel="noopener noreferrer"
                  target="_blank"
                  fontSize={12}
                  href="https://drive.google.com/file/d/1SUGO8key1q2GvOwi--oWvIwOaIXjRVMo/view"
                  sx={{ color: "#C5D5E0" }} // Light blue for link text
                >
                  {" "}
                  - Travel and expenses policy
                </Link>
                <br />
                <Link
                  underline="none"
                  rel="noopener noreferrer"
                  target="_blank"
                  fontSize={12}
                  href="https://docs.google.com/a/spark-online.org/forms/d/e/1FAIpQLSfBjYueZ2-wQcc_27Qs93dc9cn43xmcv1-6Weg1BngK730fSw/viewform?c=0&w=1"
                  sx={{ color: "#C5D5E0" }} // Light blue for link text
                >
                  {" "}
                  - Travel Request Form
                </Link>
                <br />
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={12} sm={12} md={3} align="left">
          <Card
            sx={{
              maxWidth: 345,
              minHeight: 320,
              bgcolor: "#1A2F80", // Brand Blue for the card background
              color: "#FFF", // White color for the text
              "&:hover": {
                bgcolor: "#1A2F80", // Optional: Change on hover
              },
              ".MuiCardMedia-root": {
                height: 140, // Adjust this as needed
              },
              ".MuiCardContent-root": {
                color: "#FFF", // Ensures content text is also white
              },
              ".MuiLink-root": {
                color: "#C5D5E0", // Light Blue for links
                "&:hover": {
                  color: "#FFF", // Optional: Change color on hover
                },
              },
            }}
          >
            <CardMedia sx={{ height: 140 }} image="../images/photo7.jpg" />
            <CardContent>
              <Typography
                gutterBottom
                variant="h5"
                component="div"
                fontSize="1.1rem"
                sx={{ color: "#FFF" }}
              >
                Hiring
              </Typography>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ color: "#FFF" }}
              >
                Here are two guides to explain hiring policies and practices at
                SPARK: <br />
                <Link
                  underline="none"
                  rel="noopener noreferrer"
                  target="_blank"
                  fontSize={12}
                  sx={{ color: "#C5D5E0" }}
                  href="https://drive.google.com/file/d/1YVGxTLMCpZfKyfIaAv26MlNACSxtTQxl/view?usp=sharing"
                >
                  {" "}
                  - Short guide
                </Link>
                <br />
                <Link
                  underline="none"
                  rel="noopener noreferrer"
                  target="_blank"
                  fontSize={12}
                  sx={{ color: "#C5D5E0" }}
                  href="https://docs.google.com/document/d/11Z37b3w-gWP7OCiDkQpCbFRZpNgajzv339J1-MP1wgg/edit"
                >
                  {" "}
                  - Long Guide (Onboarding and offboarding)
                </Link>
                <br />
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <br />
      {/* <RenderCards Records={LinkData} /> */}
    </Typography>
  );
}

const RenderCards = ({ Records }) => {
  const navigate = useNavigate();

  return React.useMemo(() => {
    return (
      <Typography variant="body2" align="left">
        {Records.map((row, index) => (
          <Card sx={{ minWidth: 275, borderRadius: 2, mb: 2 }}>
            <CardContent>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    <Link
                      underline="none"
                      rel="noopener noreferrer"
                      target="_blank"
                      href={row.liActionUrl}
                    >
                      {row.liActionName}
                    </Link>
                  </Typography>
                  <Typography variant="subtitle2">
                    {row.liDescription}
                  </Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ))}
      </Typography>
    );
  }, [Records]);
};
