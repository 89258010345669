import { IErrorsInput, ValidatorHelper } from "../helpers/ValidatorHelper";
import { StaffService } from "../services/StaffService";
import { SystemUserService } from "../services/SystemUserService";
import { LogTypeService } from "../services/LogTypeService";
import { SystemLogService } from "../services/SystemLogService";
import { LookupDataService } from "../services/LookupDataService";
import { NotificationService } from "../services/NotificationService";


export default class NotificationActions {

  async InitAsync() {
    let responseLookup  = await new LookupDataService().GetAll();
    let responseYesOrNoOrAll   = await new LookupDataService().GetYesOrNoOrAll();
    let responses  = [responseLookup, responseYesOrNoOrAll]

    //napravi validacija
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }
 
  async SearchAsync(Record : any) {
   
    //napravi validacija
    let response = await new NotificationService().Search(Record);
    let responses  = [response]
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async PrepareAsync(Record : any) {
   
    let jsonInputData : IErrorsInput[]  = [
      {
        element: "noTypeID",
        type: "SelectEmpty",
        value: Record.noTypeID,
        message: 'Notification Type is required'
      } 
    ];


    //validacija
    let validationMessages = new ValidatorHelper().validateClient(jsonInputData);
    if (validationMessages.length > 0) {
      let response = {
        error: true, //oznaci deka ima greska
        error_description: "Unable to save",
        ValidationMessages: validationMessages //vrati gi greskite za GUI
      };
      return response;
    }
    //napravi validacija
    let response = await new NotificationService().Prepare(Record);
    let responses  = [response]
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async EditAsync(Record : any) {
   
    //napravi validacija
    let response = await new NotificationService().Edit(Record);
    let responses  = [response]
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  
  async SaveAsync(Record : any) {

    let jsonInputData : IErrorsInput[]  = [
      {
        element: "noName",
        type: "required",
        value: Record.noName,
        message: 'Name is required'
      },
      {
        element: "noDescription",
        type: "required",
        value: Record.noDescription,
        message: 'Description is required'
      },      
      {
        element: "noEmail",
        type: "required",
        value: Record.noEmail,
        message: 'Description is required'
      } 
    ];


    //validacija
    let validationMessages = new ValidatorHelper().validateClient(jsonInputData);
    if (validationMessages.length > 0) {
      let response = {
        error: true, //oznaci deka ima greska
        error_description: "Unable to continue",
        ValidationMessages: validationMessages //vrati gi greskite za GUI
      };
      return response;
    }

    
    //napravi validacija
    let response = await new NotificationService().Save(Record);
    let responses  = [response]
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }
  
}