import * as React from 'react';
import { TextField } from '@mui/material';
import CurrencyHelper from '../helpers/CurrencyHelper';

//export interface IValidationMessage {controlId: string, message: string}

export default function ZCurrencyField(args) {
  let hasError = false;
  let message = "";
  let controlId = args.Id;
  let required = args.Required;
  let disabled = args.Disabled;
  let placeHolder = args.PlaceHolder;
  let rows = args.Rows;
  let digits = args.Digits ? args.Digits : 0;
  let primaryKey = args.PrimaryKey;

  //Vo zavisnot od Value i HasError se refreshira komponentata
  let inputValue = args.Value ? args.Value : "0";

  const [value, setValue] = React.useState('');
  const [type, setType] = React.useState('text');

  //1 --> Go zapaluva pri sekoja promena na InputValue i go formatira vo EUR format 
  React.useEffect(() => {
    console.error("MY VALUE:" + inputValue);
    //sekogas na vlez formatiraj vo pari
    let newValue = new CurrencyHelper().ToMoneyFormatDigits(inputValue.toString(), digits);
    console.error("MY NEW VALUE:" + newValue);
    setType('text');
    setValue(newValue);

  }, [inputValue]); //default


  //MNOGU E VAZNO NA BLUR da proveris da ne VIKNAT od SAVE za da prvo se snimi vrednosta vo STATE pa duri togas da se vikne SAVE
  //bidejki taka nema da se vnese poslednata izmena
  const handleBlurMoney = (e) => {
    console.error("CALLED handleBlurMoney");
    console.error(e);
    const { name, value } = e.target;

    //ke go vrati vo PARI 
    let newValue = new CurrencyHelper().ToMoneyFormatDigits(value.toString(), digits);
    setType('text');
    setValue(newValue);

    let currentValue = value;
    if (currentValue == "") currentValue = "0";
    //args.HandleBlur(e);
    let customerArgs = { target: { name: args.Id, value: currentValue, primaryKey: primaryKey } };
    args.HandleChange(customerArgs);

  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name + ":" + value);

    let newValue = value.replace("e", "");
    setValue(newValue);

    
    //NIKAKO ovdeka bidejki seuste ne e snimena
    //args.HandleChange(e);

    //slucjano ako treba NEKOJ drug field da se restira bazirano na HANDLE change za to e staven ovoj ExternalHandleChange
    if (args.ExternalHandleChange)
    {
      let customerArgs = { target: { name: args.Id, value: value, primaryKey: primaryKey } };
      args.ExternalHandleChange(customerArgs);
    }
  };

  const handleFocus = (e) => {
    let newValue = new CurrencyHelper().FromMoneyFormatDigits(value.toString(), digits);
    setType('number');
    setValue(newValue);
    //  e.refs.input.select(); 
    //setTimeout((e) => e.target.select(), 1000);
  };

  //Proveri dali ima greska
  if (required === true) {
    let errorCollection = args.ValidationMessages;
    if (errorCollection != null && errorCollection.length > 0) {
      let currentOption = errorCollection.find((x: IValidationMessage) => x.controlId === controlId); //vidi vo validation.helper.js
      if (currentOption != null) {
        hasError = true;
        message = currentOption.message;
      }
    }
  }


  return React.useMemo(() => {
    console.error("CALLED ZCURRENCY");
    return (

      <TextField
        type={type}
        size='small'
        variant={"outlined"}
        margin="dense" //normal
        fullWidth
        disabled={disabled}
        id={controlId}
        name={controlId}
        //helperText={args.Help}
        label={placeHolder}
        autoFocus={args.AutoFocus}
        onFocusCapture={handleFocus}
        onChange={handleChange} //Negov HandleChange
        onBlur={handleBlurMoney}
        onFocus={args.HandleFocus}
        //onChange={e => dispatch({ type: 'UserName_Change', payload: e.target.value })}
        value={value}
        //defaultValue=" "
        helperText={message}
        error={hasError}
        placeholder={placeHolder}
        rows={rows}
        sx={{ input: { textAlign: "right" } }}
        //onKeyDown={alert('x')}
        InputProps={{
          sx: {
            '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
              display: 'none'
            }
          }
        }}
      />
    );
  }, [type, controlId, value, message, disabled, hasError]);
};
