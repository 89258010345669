import { IErrorsInput, ValidatorHelper } from "../helpers/ValidatorHelper";
import { LookupDataService } from "../services/LookupDataService";
import { CountryService } from "../services/CountryService";
import { EmployeeService } from "../services/EmployeeService";
import { StaffService } from "../services/StaffService";
import { ContractService } from "../services/ContractService";


export default class ContractActions {

  async InitAsync(Record : any) {
    let responseEmployee = await new EmployeeService().Edit(Record);
    let responseLookup = await new LookupDataService().GetAll();
    let responseContactNew = await new ContractService().New(Record);
    let responses  = [responseLookup, responseEmployee, responseContactNew]

    //napravi validacija
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }


  async GetByEmployeeIDAsync(Record : any) {
    let response = await new ContractService().GetByEmployeeID(Record);
    let responses  = [response]

    //napravi validacija
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async SaveAsync(Record : any) {

    let jsonInputData : IErrorsInput[]  = [
      {
        element: "ctContractedHours",
        type: "required",
        value: Record.ctContractedHours,
        message: 'Contract Hours is required'
      },
      {
        element: "ctStartDateContract",
        type: "DateTimeEmpty",
        value: Record.ctStartDateContract,
        message: 'Start Date is required'
      },  
      {
        element: "ctEndDateContract",
        type: "DateTimeEmpty",
        value: Record.ctEndDateContract,
        message: 'End Date is required'
      },
      {
        element: "ctTypeOfContractID",
        type: "SelectEmpty",
        value: Record.ctTypeOfContractID,
        message: 'Type of Contract is required'
      },
      {
        element: "ctContractCurrencyID",
        type: "SelectEmpty",
        value: Record.ctContractCurrencyID,
        message: 'Contract Currency is required'
      },
      {
        element: "ctGrossNetID",
        type: "SelectEmpty",
        value: Record.ctGrossNetID,
        message: 'Gross/Net is required'
      }      
    ];


    //validacija
    let validationMessages = new ValidatorHelper().validateClient(jsonInputData);
    if (validationMessages.length > 0) {
      let response = {
        error: true, //oznaci deka ima greska
        error_description: "Unable to save",
        ValidationMessages: validationMessages //vrati gi greskite za GUI
      };
      return response;
    }

    
    //napravi validacija
    let response = await new ContractService().Save(Record);
    let responses  = [response]
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }
 
  async EditAsync(Record : any) {
   
    //napravi validacija
    let response = await new ContractService().Edit(Record);
    let responses  = [response]
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async CalculateAsync(Record : any) {
   
    //napravi validacija
    let response = await new ContractService().Calculate(Record);
    let responses  = [response]
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }
}