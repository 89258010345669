import * as React from 'react';
import { TextField, TextFieldPropsSizeOverrides } from '@mui/material';
import { IValidationMessage } from '../helpers/common.interfaces';
import { OverridableStringUnion } from '@mui/types';
import CurrencyHelper from '../helpers/CurrencyHelper';

//export interface IValidationMessage {controlId: string, message: string}

export interface Props {
  Record: {},
  Id: string,
  Required?: boolean,
  Disabled?: boolean,
  PlaceHolder: string,
  Type?: string,
  Label?: string,
  AutoFocus?: boolean,
  HandleChange(e: React.ChangeEvent<HTMLInputElement>): void,
  HandleBlur(e: React.FocusEvent<HTMLInputElement>): void,
  HandleFocus(e: React.FocusEvent<HTMLInputElement>): void,
  ValidationMessages?: IValidationMessage[],
  MultiLine?: boolean
  Rows?: number
  TextAlign?: string
  Size?: string,
  IsMoney?: boolean
};


export default function MyNumber(args: Props) {
  let hasError = false;
  let message = "";
  let Record = args.Record;
  let controlId = args.Id;
  let required = args.Required;
  let disabled = args.Disabled;
  let placeHolder = args.PlaceHolder;
  let type = "text";
  let isMultiLine = args.MultiLine ? args.MultiLine : false;
  let rows = args.Rows;
  let textAllignment = args.TextAlign;
  let size = args.Size ? args.Size : 'small';
  let isMoney = args.IsMoney;

  if (args.Type) type = args.Type;

  //Vo zavisnot od Value i HasError se refreshira komponentata
  let inputValue = Record ? Record[args.Id as keyof {}] : "";

  const [value, setValue] = React.useState(inputValue);

  //1 --> Go zapaluva pri sekoja promena na InputValue i go formatira vo EUR format 
  React.useEffect(() => {
    setValue(inputValue);
  }, [inputValue]); //default


  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let intNumber = "0";
    if (isMoney)
    {
      intNumber = new CurrencyHelper().ToFloat2Decimals(value); 
    }
    else {
      intNumber = new CurrencyHelper().ToInt(value).toString();
    }

    setValue(intNumber.toString());

    let customerArgs = { target: { name: name, value: intNumber } };
    //args.HandleBlur(e);
    args.HandleChange(customerArgs as any);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    console.log(name + ":" + value);

    // let floatNumber = value;
    // if (isNaN(parseFloat(value)))
    // {
    //   alert("NO");
    //   floatNumber = '0';
    // }


    setValue(value);
  };

  //Proveri dali ima greska
  if (required === true) {
    let errorCollection = args.ValidationMessages;
    if (errorCollection != null && errorCollection.length > 0) {
      let currentOption = errorCollection.find((x: IValidationMessage) => x.controlId === controlId); //vidi vo validation.helper.js
      if (currentOption != null) {
        hasError = true;
        message = currentOption.message;
      }
    }
  }


  return React.useMemo(() => {

    return (

      <TextField
        type='text'
        size='small'
        variant={"outlined"}
        margin="dense" //normal
        fullWidth
        disabled={disabled}
        id={controlId}
        name={controlId}
        //helperText={args.Help}
        label={placeHolder}
        autoFocus={args.AutoFocus}
        onChange={handleChange} //Negov HandleChange
        onBlur={handleBlur}
        onFocus={args.HandleFocus}
        //onChange={e => dispatch({ type: 'UserName_Change', payload: e.target.value })}
        value={value}
        //defaultValue=" "
        helperText={message}
        error={hasError}
        placeholder={placeHolder}
        multiline={isMultiLine}
        rows={rows}
        sx={{ input: { textAlign: "right" } }}
      />
    );
  }, [controlId, value, message, disabled, hasError]);
};
