// @ts-check
import * as React from "react";
import Typography from "@mui/material/Typography";
import MyText from "../../controls/MyText";
import { useEffect, useState } from "react";
import MyAlert from "../../controls/MyAlert";
import {
  enumBreadcrumbPage,
  enumData,
  enumLookupTypes,
  enumPage,
  enumPagination,
  enumRoutes,
  enumSharedMsgs,
} from "../../helpers/common.constants";
import EmployeeActions from "../../actions/EmployeeActions";
import Button from "@mui/material/Button";
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import LogHelper from "../../helpers/LogHelper";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import MyDropDown from "../../controls/MyDropDown";
import Link from "@mui/material/Link";
import { MessageHelper } from "../../helpers/MessageHelper";
import LeaveRequestActions from "../../actions/LeaveRequestActions";
import AuthenticateHelper from "../../helpers/AuthenticateHelper";
import moment from "moment";

const RecordDefault = {
  PageSize: enumPagination.PageSize,
  StatusID: "2", //Pending for Approval
  SuperiorID: 0,
  EmployeeID: 0,
  OfficeID: 0,
  Year: 2024,
};

export default function MyLeaveRequestApprovals() {
  const [Record, setRecord] = useState(RecordDefault);
  const [MessageData, setMessageData] = useState({});
  const [Records, setRecords] = useState([]);
  const [IsInitDone, setIsInitDone] = useState(false);
  const [YesNoAllData, setYesNoAllData] = useState([]);
  const [CountryData, setCountryData] = useState([]);
  const [EmployeesData, setEmployeesData] = useState([]);
  const [YearsData, setYearsData] = useState([]);
  const [ApprovedSubmittedAllData, setApprovedSubmittedAllData] = useState(enumData.LEAVEREQUEST_STATUS_FOR_APPROVAL);

  const [LookupData, setLookupData] = useState([]);

  //Za Refreshiranje na strana mu treba;
  const location = useLocation();

  //za poraki pri loadiranje na strana
  const msgHelper = new MessageHelper();

  //****************************************** */
  //HOOKS SECTION HERE
  //****************************************** */

  //1 --> Ke se povika ovoj HOOK samo dokolku se SMENI url-ot poradi toa na new dodavame Ticks() za da forcirame refresh na strana na sekoj click
  useEffect(() => {
    initData();
  }, [location.pathname + "/" + location.search]);

  //2 --> Od koga e sredena InitData() povikaj Search  (https://stackoverflow.com/questions/54069253/the-usestate-set-method-is-not-reflecting-a-change-immediately)
  useEffect(() => {
    if (IsInitDone) {
      searchData();
    }
  }, [IsInitDone]);

  //****************************************** */
  //METHOD  SECTION HERE
  //****************************************** */

  const initData = () => {
    async function fetchData() {
      //Clean all states
      setIsInitDone(false);
      setRecord(RecordDefault);

      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      let responses = await new LeaveRequestActions().InitSearchAsync(); // await LookupActions.InitAsync();
      if (!responses.error) {
        let lookupData = responses[0].data;
        let countryData = responses[1].data;
        let employeesData = responses[2].data;
        let yearsData = responses[3].data;
        //let approvedSubmittedAllData = responses[4].data;

        setLookupData(lookupData);
        setCountryData(countryData);
        setEmployeesData(employeesData);
        setYearsData(yearsData);
        //setApprovedSubmittedAllData(approvedSubmittedAllData);

        setIsInitDone(true);

        //Load Finished
        result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);
      } else {
        //Load with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }
    }
    fetchData();
  };

  const searchData = () => {
    async function fetchData() {
      //Load Start
      setRecords([]);
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      let responses = await new LeaveRequestActions().MyApprovalsAsync(Record);
      if (!responses.error) {
        const records = responses[0].data;

        setRecords(records);

        //Load Finished
        result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);
      } else {
        //Load with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }
    }
    fetchData();
  };



  //****************************************** */
  //HANDLERS SECTION HERE
  //****************************************** */
  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log(name + ":" + value);
    setRecord((prevState) => ({ ...prevState, [name]: value }));
  };

  //****************************************** */
  //GUI DISPLAY
  //****************************************** */
  const ToolbarButtons = () => {
    return (
      <React.Fragment>
        <Button
          sx={{ ml: 3, mr: 2, mt: 3.5 }}
          variant="contained"
          color="success"
          size="small"
          disabled={MessageData.Loading}
          onClick={() => searchData()}
        >
          Search
        </Button>
        {/* <Button
          sx={{ mr: 0, mt: 3.5 }}
          variant="contained"
          color="secondary"
          size="small"
          disabled={MessageData.Loading}
          onClick={() => exportData()}
        >
          Export
        </Button> */}
      </React.Fragment>
    );
  };

  return (
    <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
      <MyAlert {...MessageData} />

      <Card sx={{ minWidth: 275, borderRadius: 2, mb: 2 }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={2}>
              <MyDropDown
                Id="Year"
                Record={Record}
                PlaceHolder="Year"
                HandleChange={handleChange}
                Options={YearsData}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <MyDropDown
                Id="StatusID"
                Record={Record}
                PlaceHolder="Status"
                HandleChange={handleChange}
                Options={ApprovedSubmittedAllData}
              />
            </Grid>

            <Grid>
              <ToolbarButtons />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="subtitle1" align="left">
                Total Records found: {Records.length}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>


      <RenderTable Records={Records} />
    </Typography>
  );
}

const RenderTable = ({ Records }) => {
  const navigate = useNavigate();

  return React.useMemo(() => {
    return (
      <TableContainer sx={{ display: { xs: "none", sm: "none", md: "block" } }}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Typography variant="h4" align="center">
                  Employee
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h4" align="center">
                  Office
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h4" align="center">
                  Superior
                </Typography>
              </TableCell>
              {/* <TableCell ><Typography variant="h4" align="center">Created</Typography></TableCell> */}

              <TableCell>
                <Typography variant="h4" align="center">
                  Type of Request
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h4" align="center">
                  Start Date
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="h4" align="center">
                  End Date
                </Typography>
              </TableCell>
              <TableCell><Typography variant="h4" align="center">Total Hours</Typography> </TableCell>
              <TableCell>
                <Typography variant="h4" align="center">
                  Status
                </Typography>
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Records.map((row, index) => (
              <TableRow
                key={row.rqRequestID}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  {index + 1}.{row.StaffName}
                </TableCell>

                <TableCell>{row.OfficeName}</TableCell>

                <TableCell>{row.SuperiorName}</TableCell>


                <TableCell>
                  {row.TypeName}
                </TableCell>


                <TableCell>
                  {moment(row.rqDatetimeStart).format("DD/MM/YYYY")}
                </TableCell>

                <TableCell>
                  {moment(row.rqDatetimeEnd).format("DD/MM/YYYY")}
                </TableCell>


                <TableCell align="right">
                  {row.rqTotalHours}
                </TableCell>

                <TableCell>{row.StatusName}</TableCell>


                <TableCell align="center">
                  <Tooltip title={"Go To Approval " + row.deName}>
                    <IconButton
                      size="small"
                      name="details"
                      onClick={() => navigate(enumRoutes.LeaveRequest_Approval.replace(":RequestID", row.rqRequestID))}
                    >
                      <EditIcon sx={{ fontSize: 15 }} />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }, [Records]);
};
