import { RestHelper } from "../helpers/RestHelper";

export class VacancyService {
  private _restHelper;

  constructor() {
    this._restHelper = new RestHelper();
  }

  async Save(payload: any) {
    const url: string = "Vacancy/Save";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async SaveApplicant(payload: any) {
    const url: string = "VacancyApplicant/Save";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async Search(payload: any) {
    const url: string = "Vacancy/Search";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async SearchApplicant(payload: any) {
    const url: string = "VacancyApplicant/Search";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async Export(payload: any) {
    const url: string = "Employee/Export";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async ExportEmployeeLeaveRequestAllYears(payload: any) {
    const url: string = "Employee/ExportEmployeeLeaveRequestAllYears";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async Edit(payload: any) {
    const url: string = "Vacancy/Edit";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async EditApplicant(payload: any) {
    console.log(payload);
    const url: string = "VacancyApplicant/Edit";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async GeneralSearch(payload: any) {
    const url: string = "Employee/GeneralSearch";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async GeneralFindTextById(payload: any) {
    //     @TableName nvarchar(100),
    // @ColumnToReturn nvarchar(100),
    // @ColumnToReturn2 nvarchar(100),
    // @ColumnToSearch nvarchar(100),
    // @ValueToFind nvarchar(100)
    const url: string = "Employee/GeneralFindTextById";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async UploadCost(payload: any) {
    const url: string = "VacancyApplicant/Upload";
    let response = await this._restHelper.postFile(url, payload);
    return response;
  }

  async DownloadCost(payload: any) {
    const url: string = "VacancyApplicant/Download";
    let response = await this._restHelper.downloadFile(url, payload);
    return response;
  }
}
