// @ts-check 
import { useEffect } from 'react';
import AuthenticateHelper from '../../helpers/AuthenticateHelper';
import NavigationHelper from '../../helpers/NavigationHelper';


export default function Logout() {

  useEffect(() => {
    new AuthenticateHelper().SignOut();
    new NavigationHelper().GoToUrl("login");
  }, [true]); 



  return (
    <div/>
  );
}
