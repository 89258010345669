// @ts-check 
import { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import MyText from '../../controls/MyText';
import MyDropDown from '../../controls/MyDropDown';

import { useState } from 'react';
import MyAlert from '../../controls/MyAlert';
import { enumBreadcrumbPage, enumLookupTypes, enumPage, enumSharedMsgs } from '../../helpers/common.constants';
import NotificationActions from '../../actions/NotificationActions';
import Button from '@mui/material/Button';
import MyCheckList from '../../controls/MyCheckList';
import { Grid } from '@mui/material';
import { useParams, useLocation, useNavigate } from "react-router-dom";
import CurrencyHelper from '../../helpers/CurrencyHelper';
import MyMoney from '../../controls/MyMoney';
import MyNumber from '../../controls/MyNumber';
import NotificationBreadcrumbs from './NotificationBreadcrumbs';
import MyDate from '../../controls/MyDate';
import moment from 'moment';
import MyDateTime from '../../controls/MyDateTime';

const RecordDefault = {
  noTypeID: 0,
  noScheduledDate: moment(Date.now()).add(10, 'minutes').format("YYYY-MM-DD HH:mm")
};

export default function NotificationBulkManage(args) {

  //Page MAIN Hooks
  const [MessageData, setMessageData] = useState({});
  const [Record, setRecord] = useState(RecordDefault);
  const [LookupData, setLookupData] = useState([]);

  //za Navigacija 
  const navigate = useNavigate();

  //Za Refreshiranje na strana mu treba;
  const location = useLocation();

  //za convertiranje na Pari
  let currencyHelper = new CurrencyHelper();

  //****************************************** */
  //HOOKS SECTION HERE 
  //****************************************** */

  //1 --> Ke se povika ovoj HOOK samo dokolku se SMENI url-ot poradi toa na new dodavame Ticks() za da forcirame refresh na strana
  useEffect(() => {
    initData();
  }, [location.pathname + '/' + location.search]);



  //****************************************** */
  //METHOD  SECTION HERE 
  //****************************************** */

  const setPageMsg = (state, response) => {

    if (state == enumPage.LoadStart) {
      setMessageData((a) => ({ ...a, Message: '', Loading: true }));
    }
    else if (state == enumPage.LoadSuccess) {
      setMessageData((a) => ({ ...a, Loading: false }));
    }
    else if (state == enumPage.ErrorServer) {
      if (response.ValidationMessages) {
        setRecord((prevState) => ({ ...prevState, ValidationMessages: response.ValidationMessages })); //za da se reflekgiraat po ZSNText,
      }
      setMessageData((a) => ({ ...a, HasError: true, Message: response.error_description, Loading: false }));
    }
    else if (state == enumPage.SaveSuccess) {
      setMessageData((a) => ({ ...a, Loading: false, HasError: false, Message: "Generation of data successful finished" }));
    }
  };

  const initData = () => {
    async function fetchData() {

     //NAJVAZNO da napravi prvo reset na s'e
      setRecord(RecordDefault);
      setPageMsg(enumPage.LoadStart);

      let responses = await new NotificationActions().InitAsync(); // await LookupActions.InitAsync();
      if (!responses.error) {

        let lookupData = responses[0].data;
        setLookupData(lookupData);
        setPageMsg(enumPage.LoadSuccess);
      }
      else {
        setPageMsg(enumPage.ErrorServer, responses);
      }
    }

    fetchData();
  };



  const saveData = () => {
    console.error("saveData");
    async function fetchData() {
      setMessageData((a) => ({ Loading: true }));

      //mora za da moze pravilno da se snimi inaku pravi Validation Error: Status Code: 400
      Record.noScheduledDate = moment(Record.noScheduledDate).format("YYYY-MM-DDTHH:mm:ss.sssZ");

      let response = await new NotificationActions().PrepareAsync(Record);

      if (!response.error) {

        setPageMsg(enumPage.SaveSuccess);
      }
      else {
        setPageMsg(enumPage.ErrorServer, response);
      }
    }

    fetchData();
  };


  //****************************************** */
  //HANDLERS SECTION HERE 
  //****************************************** */
  const handleChange = (e) => {
    const { name, value } = e.target;

    console.log(name + ":" + value);
    setRecord((prevState) => ({ ...prevState, [name]: value }));
  };



  return (
    <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="center">

      <NotificationBreadcrumbs page={enumBreadcrumbPage.Master} Id={Record.noNotificationID} disabled={MessageData.Loading} />

      <MyAlert {...MessageData} />

      <Grid container align="center">
        <Grid item xs={12}>
          <Typography variant="h6" display="block">Generates Notifications for the Staff and Supperiors for the given week based on the selected Date</Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={4} >
          <MyDropDown  Id='noTypeID'
            Record={Record}
            PlaceHolder='Notification Type'
            HandleChange={handleChange}
            ValidationMessages={Record.ValidationMessages}
            Options={LookupData}
            LookupTypeId={enumLookupTypes.Notifications}
            Required={true} />
        </Grid>

        <Grid item xs={4} align="left" sx={{ ml: 1 }}>
          <MyDateTime Id='noScheduledDate' PlaceHolder='Scheduled Date' Record={Record}
            HandleChange={handleChange}
          />
          <Button name="btnSave" variant="contained" sx={{ mt: 1.5, ml: 1 }} color="success" size="small" disabled={MessageData.Loading} onClick={() => saveData()}>Generate</Button>
        </Grid>
      </Grid>

      <MyAlert {...MessageData} />


    </Typography>
  );
}
