// @ts-check 
import Typography from '@mui/material/Typography';

import { Breadcrumbs } from '@mui/material';
import { enumBreadcrumbPage, enumRoutes } from '../../helpers/common.constants';
import { useEffect, useState } from 'react';
import Link from "@mui/material/Link";

export default function DeclarationBreadcrumbs({ page, Id, disabled }) {

  const [showPage, setShowPage] = useState(false);

  //1 --> Go zapaluva pri sekoja promena na InputValue i go formatira vo EUR format 
  useEffect(() => {
    if (!disabled) {
      setShowPage(true);
    }
  }, [disabled]); //default


  const RenderPage = () => {
    if (!showPage) {
      return <div />
    }
    if (page === enumBreadcrumbPage.Search) {
      return <Search />;
    }
    else if (page === enumBreadcrumbPage.Master) {
      return <Master />;
    }
    else if (page === enumBreadcrumbPage.Section_1) {
      return <Costs />;
    }
    else if (page === enumBreadcrumbPage.Section_2) {
      return <Diems />;
    }
    else if (page === enumBreadcrumbPage.Section_3) {
      return <PreviewSubmit />
    }
  }

  const Search = () => {
    return (
      <Breadcrumbs color="primary" variant="body2" aria-label="breadcrumb">
        <Link underline="none" rel="noopener noreferrer" href={enumRoutes.Declaration_Dashboard}>Home</Link>
        <Typography variant="body2" color="text.primary">Search</Typography>
      </Breadcrumbs>
    );
  }

  const Costs = () => {
    return (
      <Breadcrumbs color="primary" variant="body2" aria-label="breadcrumb">
        <Link underline="none" rel="noopener noreferrer" href={enumRoutes.Declaration_Dashboard}>Home</Link>
        <Link underline="none" rel="noopener noreferrer" href={enumRoutes.Declaration_MySearch}>Search</Link>
        <Link underline="none" rel="noopener noreferrer" href={enumRoutes.Declaration_Edit.replace(":DeclarationID", Id)}>Declaration</Link>
        <Typography variant="body2" color="text.primary">Costs</Typography>
      </Breadcrumbs>
    );
  }


  const Diems = () => {
    return (
      <Breadcrumbs color="primary" variant="body2" aria-label="breadcrumb">
        <Link underline="none" rel="noopener noreferrer" href={enumRoutes.Declaration_Dashboard}>Home</Link>
        <Link underline="none" rel="noopener noreferrer" href={enumRoutes.Declaration_MyDeclarations}>Search</Link>
        <Link underline="none" rel="noopener noreferrer" href={enumRoutes.Declaration_Edit.replace(":DeclarationID", Id)}>Declaration</Link>
        <Typography variant="body2" color="text.primary">Diems</Typography>
      </Breadcrumbs>
    );
  }

  const PreviewSubmit = () => {
    return (
      <Breadcrumbs color="primary" variant="body2" aria-label="breadcrumb">
        <Link underline="none" rel="noopener noreferrer" href={enumRoutes.Declaration_Dashboard}>Home</Link>
        <Link underline="none" rel="noopener noreferrer" href={enumRoutes.Declaration_MyDeclarations}>Search</Link>
        <Link underline="none" rel="noopener noreferrer" href={enumRoutes.Declaration_Edit.replace(":DeclarationID", Id)}>Declaration</Link>
        <Typography variant="body2" color="text.primary">Preview & Submit</Typography>
      </Breadcrumbs>
    );
  }

  const Master = () => {
    return (
      <Breadcrumbs color="primary" variant="body2" aria-label="breadcrumb">
        <Link underline="none" rel="noopener noreferrer" href={enumRoutes.LeaveRequest_Dashboard}>Home</Link>
        <Link underline="none" rel="noopener noreferrer" href={enumRoutes.Declaration_MyDeclarations}>Search</Link>
        <Typography variant="body2" color="text.primary">Declaration</Typography>
      </Breadcrumbs>
    );
  }

  return (
    <RenderPage />
  )
}


