import * as React from "react";
import {
  Checkbox,
  TextField,
  TextFieldPropsSizeOverrides,
  Typography,
} from "@mui/material";
import { IValidationMessage } from "../helpers/common.interfaces";
import { OverridableStringUnion } from "@mui/types";
import CurrencyHelper from "../helpers/CurrencyHelper";

//export interface IValidationMessage {controlId: string, message: string}

export interface Props {
  Record: {};
  Id: string;
  Required?: boolean;
  Disabled?: boolean;
  PlaceHolder: string;
  Type?: string;
  Label?: string;
  AutoFocus?: boolean;
  HandleChange(e: React.ChangeEvent<HTMLInputElement>): void;
  HandleBlur(e: React.FocusEvent<HTMLInputElement>): void;
  HandleFocus(e: React.FocusEvent<HTMLInputElement>): void;
  ValidationMessages?: IValidationMessage[];
  MultiLine?: boolean;
  Rows?: number;
  TextAlign?: string;
  Size?: string;
  IsMoney?: boolean;
}

export default function MyCheckboxTable(args: Props) {
  const [checked, setChecked] = React.useState(false);
  let hasError = false;
  let message = "";
  let Record = args.Record;
  let controlId = args.Id;
  controlId = controlId.substring(0, controlId.indexOf("_"));

  let disabled = args.Disabled;
  let placeHolder = args.PlaceHolder;

  //Vo zavisnot od Value i HasError se refreshira komponentata
  let value: boolean = false;
  let currentValue = Record[controlId as keyof {}];
  if (currentValue) {
    value = currentValue;
  }

  const handleChange = (event: any) => {
    //setChecked(event.target.checked);
    let customerArgs: any = { target: { name: args.Id, value: event.target.checked } };
    args.HandleChange(customerArgs);
  };

  //let value : boolean = true;

  return React.useMemo(() => {
    return (
      <Typography variant="subtitle2">
        <Checkbox
          key={args.Id}
          size="small"
          id={args.Id}
          name={args.Id}
          checked={value}
          disabled={disabled}
          onChange={handleChange}
        />
        {placeHolder}
      </Typography>
    );
  }, [args.Id, value, disabled]);
}
