import { IErrorsInput, ValidatorHelper } from "../helpers/ValidatorHelper";
import { LookupDataService } from "../services/LookupDataService";
import { CountryService } from "../services/CountryService";
import { MyEmployeeService } from "../services/MyEmployeeService";
import { StaffService } from "../services/StaffService";
import { enumSharedMsgs } from "../helpers/common.constants";

export default class MyEmployeeActions {
  async InitAsync() {
    let responseLookup = await new LookupDataService().GetAll();
    let responseCountry = await new CountryService().GetAll();
    let responseSuperiors = await new LookupDataService().GetSuperiors();
    let responseYesOrNoOrAll = await new LookupDataService().GetYesOrNoOrAll();
    let responseYesNo = await new LookupDataService().GetYesNo();
    let contractMonthData = await new LookupDataService().GetContractMonths();

    let responses = [
      responseLookup,
      responseCountry,
      responseSuperiors,
      responseYesOrNoOrAll,
      responseYesNo,
      contractMonthData,
    ];

    //napravi validacija
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async RegisterAsync(Record: any) {
    let jsonInputData: IErrorsInput[] = [
      {
        element: "eyFirstName",
        type: "required",
        value: Record.eyFirstName,
        message: "Fist Name is required",
      },
      {
        element: "eyConsentFL",
        type: "requiredBool",
        value: Record.eyConsentFL,
        message:
          "I agree to the collection and processing of my personal data by SPARK",
      },
      {
        element: "eyLastName",
        type: "required",
        value: Record.eyLastName,
        message: "Last Name is required",
      },
      {
        element: "eyEmail",
        type: "required",
        value: Record.eyEmail,
        message: "Email is required",
      },
      {
        element: "eyEmail",
        type: "email",
        value: Record.eyEmail,
        message: "Valid Email is required",
      },
      {
        element: "eyPrivateEmail",
        type: "required",
        value: Record.eyPrivateEmail,
        message: "Email is required",
      },
      {
        element: "eyPrivateEmail",
        type: "email",
        value: Record.eyPrivateEmail,
        message: "Valid Email is required",
      },

      {
        element: "eyBirthDate",
        type: "DateTimeEmpty",
        value: Record.eyBirthDate,
        message: "Birth Date is required",
      },
      {
        element: "eyAddress",
        type: "required",
        value: Record.eyAddress,
        message: "Address is required",
      },
      {
        element: "eyCity",
        type: "required",
        value: Record.eyCity,
        message: "City is required",
      },
      {
        element: "eyCountryID",
        type: "SelectEmpty",
        value: Record.eyCountryID,
        message: "Country is required",
      },
      {
        element: "eyOfficeID",
        type: "SelectEmpty",
        value: Record.eyOfficeID,
        message: "Office is required",
      },
      {
        element: "eySuperiorID",
        type: "SelectEmpty",
        value: Record.eySuperiorID,
        message: "Superior is required",
      },
      // {
      //   element: "eyFunctionID",
      //   type: "SelectEmpty",
      //   value: Record.eyFunctionID,
      //   message: "Function is required",
      // },
    ];

    if (!Record.SkipValidation) {
      //validacija
      let validationMessages = new ValidatorHelper().validateClient(
        jsonInputData
      );
      if (validationMessages.length > 0) {
        let response = {
          error: true, //oznaci deka ima greska
          error_description: enumSharedMsgs.SaveClientError,
          ValidationMessages: validationMessages, //vrati gi greskite za GUI
        };
        return response;
      }
    }

    //napravi validacija
    let response = await new MyEmployeeService().Register(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async UpdateAsync(Record: any) {
    let jsonInputData: IErrorsInput[] = [
      {
        element: "eyFirstName",
        type: "required",
        value: Record.eyFirstName,
        message: "Fist Name is required",
      },
      {
        element: "eyConsentFL",
        type: "requiredBool",
        value: Record.eyConsentFL,
        message: "Please check the consent form for agreement.",
      },
      {
        element: "eyLastName",
        type: "required",
        value: Record.eyLastName,
        message: "Last Name is required",
      },
      {
        element: "eyEmail",
        type: "required",
        value: Record.eyEmail,
        message: "Email is required",
      },
      {
        element: "eyEmail",
        type: "email",
        value: Record.eyEmail,
        message: "Valid Email is required",
      },
      {
        element: "eyPrivateEmail",
        type: "required",
        value: Record.eyPrivateEmail,
        message: "Email is required",
      },
      {
        element: "eyPrivateEmail",
        type: "email",
        value: Record.eyPrivateEmail,
        message: "Valid Email is required",
      },

      {
        element: "eyBirthDate",
        type: "DateTimeEmpty",
        value: Record.eyBirthDate,
        message: "Birth Date is required",
      },
      {
        element: "eyAddress",
        type: "required",
        value: Record.eyAddress,
        message: "Address is required",
      },
      {
        element: "eyCity",
        type: "required",
        value: Record.eyCity,
        message: "City is required",
      },
      {
        element: "eyCountryID",
        type: "SelectEmpty",
        value: Record.eyCountryID,
        message: "Country is required",
      },
      {
        element: "eyOfficeID",
        type: "SelectEmpty",
        value: Record.eyOfficeID,
        message: "Office is required",
      },
      {
        element: "eySuperiorID",
        type: "SelectEmpty",
        value: Record.eySuperiorID,
        message: "Superior is required",
      },
      // {
      //   element: "eyFunctionID",
      //   type: "SelectEmpty",
      //   value: Record.eyFunctionID,
      //   message: "Function is required",
      // },
      {
        element: "eyEmergencyName",
        type: "required",
        value: Record.eyEmergencyName,
        message: "Emergency Name is required",
      },
      {
        element: "eyEmergencyPhone",
        type: "required",
        value: Record.eyEmergencyPhone,
        message: "Emergency Phone is required",
      },
      {
        element: "eyEmergencyEmail",
        type: "required",
        value: Record.eyEmergencyEmail,
        message: "Emergency Email is required",
      },
    ];

    if (!Record.SkipValidation) {
      //validacija
      let validationMessages = new ValidatorHelper().validateClient(
        jsonInputData
      );
      if (validationMessages.length > 0) {
        let response = {
          error: true, //oznaci deka ima greska
          error_description: enumSharedMsgs.SaveClientError,
          ValidationMessages: validationMessages, //vrati gi greskite za GUI
        };
        console.error("ERRORS FOUND");
        console.error(validationMessages);
        return response;
      }
    }

    //napravi validacija
    let response = await new MyEmployeeService().Update(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async SearchAsync(Record: any) {
    console.log(Record);
    //napravi validacija
    let response = await new MyEmployeeService().Search(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async ExportAsync(Record: any) {
    //napravi validacija
    let response = await new MyEmployeeService().Export(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async GetMyProfileAsync() {
    //napravi validacija
    let response = await new MyEmployeeService().GetMyProfile({});
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }

  async GetOrgChartAsync(Record: any) {
    //napravi validacija
    let response = await new MyEmployeeService().GetOrgChart(Record);
    let responses = [response];
    let output = new ValidatorHelper().validateServerResponses(responses);
    return output;
  }
}
