import LocalStorageHelper from "../helpers/LocalStorageHelper";
import { RestHelper } from "../helpers/RestHelper";
import { enumLocalStorageArgs } from "../helpers/common.constants";

export class FinBudgetService {
  private _restHelper;

  constructor() {
    this._restHelper = new RestHelper();
  }

  async Search(payload: any) {
    const url: string = "FinBudget/Search";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async GetByProjectID(payload: any) {
    const url: string = "FinBudget/GetByProjectID";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async Save(payload: any) {
    const url: string = "FinBudget/Save";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async Export(payload: any) {
    const url: string = "FinBudget/Export";
    let response = await this._restHelper.post(url, payload);
    return response;
  }

  async Delete(payload: any) {
    const url: string = "FinBudget/Delete";
    let response = await this._restHelper.post(url, payload);
    return response;
  }
 
}
