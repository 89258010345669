
export default class NavigationHelper {


  private _jsonData: any;


  constructor(menu : any) {
    this._jsonData = menu;
  }

  GoToUrl(url: string, target: string = '_self'): void {
    window.open(url, target);
  }

  GetPageTitle(url: string): string {

    url = url.toLowerCase();
    let item: any = this.GetItem(url);
    if (item) {
      return item.title;
    }
    return "N/A";
  }

  GetItem(url: string): any {

    url = url.toLowerCase();

    
    //url = this.AdjustTheUrl(url); //ako e Edit ke gi trgne parametrite site sto s'e posle EDIT 
    let rootUrl = url.split('/')[1]; //from "/notification/new" return "notification"
    let section: any = this._jsonData.find((x: any) => x.url.toLowerCase() === rootUrl);
    if (section) {
      let item = section.data.find((x: any) => url.indexOf(x.url.toLowerCase()) > -1);
      if (item) {
        return item;
      }
    }
    return null;
  }
}
